import React from "react";
import Button from "./button";

const SubcriptionPlans = ({ addClassParent, sref, checkIfSubscribeToCreator, creator, subscribeToCreator, t, id, amountForMonth, amountForTreMonth, amountForSixMonth, amountForTwelvfMonth, releaseRef, isLoading, isLoading3Months, isLoading6Months, isLoading12Months, title }) => {
    const reduction3Mont = creator?.subscriptions_details?.african_fee?.quarterly_fee?.reduction
    const reduction6Mont = creator?.subscriptions_details?.african_fee?.half_yearly_fee?.reduction
    const reduction12Mont = creator?.subscriptions_details?.african_fee?.yearly_fee?.reduction


    return (
        <div className={`w-100 ${addClassParent} `}>
            {title && <p className="text-uppercase my-2">{t('navigation.subscriptionPlans')}</p>}
            {amountForMonth &&
                <div className="w-100 mt-0 pt-0 mb-3 pb-2" style={{ boxShadow: "0px 5px 0px #EDF2F6" }}>
                    <Button
                        addClassName={'mb-2 box-shadow'}
                        checkIfSubscribeToCreator={checkIfSubscribeToCreator}
                        isLoading={isLoading}
                        creator={creator}
                        subscribeToCreator={subscribeToCreator}
                        t={t}
                        id={id}
                        amount={amountForMonth}
                        releaseRef={releaseRef}
                        sref={sref}
                        time={t('words.month')}
                        text={t('words.subscribe')}
                        subscriptionPeriod={1}
                        key={0}
                    />

                </div>
            }
            {(reduction3Mont !== undefined) &&
                <Button
                    addClassName={'mt-0'}
                    checkIfSubscribeToCreator={checkIfSubscribeToCreator}
                    isLoading={isLoading3Months}
                    creator={creator}
                    subscribeToCreator={subscribeToCreator}
                    t={t}
                    id={id}
                    amount={amountForTreMonth}
                    releaseRef={releaseRef}
                    time={``}
                    text={`${3} ${t('words.months')} (-${reduction3Mont}%)`}
                    subscriptionPeriod={3}
                    key={1}
                    sref={sref}

                />}
            {(reduction6Mont !== undefined) &&
                <Button
                    addClassName={'mt-2'}
                    checkIfSubscribeToCreator={checkIfSubscribeToCreator}
                    isLoading={isLoading6Months}
                    creator={creator}
                    subscribeToCreator={subscribeToCreator}
                    t={t}
                    id={id}
                    amount={amountForSixMonth}
                    releaseRef={releaseRef}
                    time={``}
                    text={`${6} ${t('words.months')} (-${reduction6Mont}%)`}
                    subscriptionPeriod={6}
                    key={2}
                    sref={sref}
                />}
            {(reduction12Mont !== undefined) &&
                <Button
                    addClassName={'mt-2'}
                    checkIfSubscribeToCreator={checkIfSubscribeToCreator}
                    isLoading={isLoading12Months}
                    creator={creator}
                    subscribeToCreator={subscribeToCreator}
                    t={t}
                    id={id}
                    amount={amountForTwelvfMonth}
                    releaseRef={releaseRef}
                    time={``}
                    text={`${12} ${t('words.months')} (-${reduction12Mont}%)`}
                    subscriptionPeriod={12}
                    key={3}
                    sref={sref}
                />
            }
        </div>


    )

}
export default SubcriptionPlans