import { useState, useEffect } from 'react';

function getCurrentScreenSize(width) {
    if (width < 576) {
        return 'Extra Small';
    } else if (width >= 576 && width < 768) {
        return 'Small';
    } else if (width >= 768 && width < 992) {
        return 'Medium';
    } else if (width >= 992 && width < 1200) {
        return 'Large';
    } else {
        return 'Extra Large';
    }
}

function useScreenSize() {
    const [screenSize, setScreenSize] = useState('');

    useEffect(() => {
        function handleResize() {
            const screenWidth = window.innerWidth;
            const currentSize = getCurrentScreenSize(screenWidth);
            setScreenSize(currentSize);
        }

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return screenSize;
}

export default useScreenSize;
