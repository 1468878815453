import React from "react";
import CollectionsContainer from "../../feature/collections/collectionsContainer";
import { RequestFanbusy } from "../../Services";
import { useSelector } from "react-redux";
import { AppContainer, AsideContainer } from "../../Containers";
import { useTranslation } from "react-i18next";
import { getCreatorFollows, getReleasePost } from "../../Utils";


const MyCollection = () => {
    const users = useSelector(state => state.users);
    const params = useSelector(state => state.params);
    const { t } = useTranslation();
    const [state, setState] = React.useState({
        collections: [],
        follows: [],
        releaseCollection: [],
        isLoading: true,
    });

    React.useEffect(() => {

        let follows = getCreatorFollows(users.access_token);
        follows.then((value) => {
            setState((prev) => ({ ...prev, follows: value, isLoading: false }));
        });

        let releasePost = getReleasePost(users.access_token);
        releasePost.then((value) => {
            setState((prev) => ({ ...prev, releaseCollection: value, isLoading: false }));
        });

        const getCollectionSelfBycreator = async () => {
            let res = await RequestFanbusy("collection/release/collection", 'GET', '', users.access_token);
            console.log("res", res);
            // const pagination = res.response.paginage;
            if (res.status === 200) {
                setState((prev) => ({ ...prev, collections: res.response.data, isLoadingCollection: false }));
                // setPagination(prevState => ({ ...prevState, total: pagination.total, last_page: pagination.last_page, current_page: pagination.current_page }));

            }
        }
        getCollectionSelfBycreator();

    }, [params.refresh, users.access_token]);

    console.log(state.collections);

    return (
        <AppContainer>
            <section className="col col-xl-6 order-xl-2 col-lg-12 order-lg-1 col-md-12 col-sm-12 col-12 border border-top-0 border-fw-light px-0  border-bottom-0 ">
                <div style={{ height: "50px" }} className="d-flex align-items-center border-bottom pb-2 fix-sidebar bg-white top-0 ">
                    <a href={`/`} className={`text-dark material-icons text-decoration-none m-none ms-3`}>arrow_back</a>
                    <p className={`text-body ms-2 mb-0 fw-bold fs-6 text-uppercase`}>
                        {t('navigation.collections')}
                    </p>
                </div>
                <CollectionsContainer
                    addClassName={'pt-3'}
                    collections={state.collections}
                    // fee={fee}
                    released={state.releaseCollection}
                    follows={state.follows}
                    // setListPostRelease={setListPostRelease}
                    // deleteCollection={deleteCollection}
                    isLoading={state.isLoadingCollection}

                />
            </section>
            <AsideContainer />
        </AppContainer>
    )
}

export default MyCollection