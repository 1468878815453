import React from "react";
import { useTranslation } from "react-i18next";

import { AppContainer, AsideContainer } from "../../Containers";
import { Images, Metrics } from "../../Constants";
import { Loader } from "../../Components";
import { RequestFanbusy } from "../../Services";
import { useDispatch, useSelector } from "react-redux";

const CreateStoryView = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const users = useSelector(state => state.users);
    const videoRef = React.useRef(null)
    const [medias] = React.useState([]);
    const [state, setState] = React.useState({
        storiesData: [],
        storyContent: '',
        mediaType: '',
        videoDurationMessage: '',
        likes: 0,
        Comments: 0,
        storyText: false,
        selectedButton: Metrics.background1,
        changeBackground: true,
        background: Metrics.background1,
        isVisible: false,
        isLoading: true,
        isLoaded: false
    });

    React.useEffect(() => {
        const getStoriesSelfBycreator = async () => {
            let res = await RequestFanbusy("stories/self/creator", 'GET', '', users.access_token);
            if (res.status === 200) {
                setState((prev) => ({ ...prev, storiesData: res.response.data.stories }));
            }
        };
        getStoriesSelfBycreator();
    }, [users.access_token]);

    React.useEffect(() => {
        if (state.videoDurationMessage !== '') {
            const timer = setInterval(() => {
                setState((prev) => ({ ...prev, videoDurationMessage: '' }));
            }, 10000);

            return () => clearInterval(timer);
        }
    }, [state.videoDurationMessage]);

    const [media, setMedia] = React.useState({});

    const handleFileChange = ({ target }) => {
        const { files } = target;

        if (files && files.length > 0) {
            const file = files[0];
            const url = URL.createObjectURL(file);
            const mediaType = file.type.split('/')[0];

            if (mediaType === 'video') {
                const tempVideo = document.createElement('video');
                tempVideo.src = url;

                tempVideo.onloadedmetadata = () => {
                    const duration = tempVideo.duration;
                    const maxDuration = 15;

                    if (duration <= maxDuration) {
                        const startTime = Math.min(duration, maxDuration);
                        setMedia({ url, file, mediaType, startTime });

                        if (videoRef.current) {
                            videoRef.current.currentTime = startTime;
                            videoRef.current.play();
                        }
                    } else {
                        setState((prev) => ({ ...prev, videoDurationMessage: 'The video must not exceed 15 seconds.' }));
                    }
                };
            } else {
                setMedia({ url, file, mediaType });
            }
        }
    };


    const handleDeleteFile = () => {
        setMedia({});
    }

    const toogleAddText = () => {
        setState((prev) => ({ ...prev, isVisible: !prev.isVisible }))
    }

    const toogleAddStoryText = () => {
        setState((prev) => ({ ...prev, storyText: !prev.storyText }))
    }

    const toogleChangeBackground = () => {
        setState((prev) => ({ ...prev, changeBackground: !prev.changeBackground }))
    }

    const handleChangeBackground = (background) => {
        setState((prev) => ({ ...prev, background: background, selectedButton: background }))
    }

    const textInputChange = (input) => {
        const target = input.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (name === 'storyContent') {
            setState({ ...state, storyContent: value });
        }
    }

    const addStory = async () => {
        setState((prev) => ({ ...prev, isLoaded: true }));
        const { storyContent, storyText } = state;
        const formData = new FormData();
        const form = {
            content: storyContent,
            extra: '',
            illustrations: {},
        };

        if (storyText) {
            form.extra = state.background
            const newStory = await RequestFanbusy('stories/', 'POST', form, users.access_token);
            if (newStory.status === 201) {
                setState({ ...state, storyContent: '', background: Metrics.background1, storyText: false, isLoaded: false });
                dispatch({ action: "REFRESH", value: 1 });
            }
        } else {
            const file = media.file;
            formData.append('fileobject', file);
            const res = await RequestFanbusy('uploads/STORIES/', 'POST', formData, users.access_token);

            if (res.status === 201) {
                form.illustrations = res.response.data;
                const newStory = await RequestFanbusy('stories/', 'POST', form, users.access_token);

                if (newStory.status === 201) {
                    setState({ ...state, storyContent: '', isLoaded: false });
                    setMedia({});
                    dispatch({ action: "REFRESH", value: 1 });
                }
            }
        }
    };

    return (
        <AppContainer>
            <main className="col col-xl-6 order-xl-2 col-lg-12 order-lg-1 col-md-12 col-sm-12 col-12  my-4 border border-top-0 border-bottom-0">
                <div className="main-content">
                    <div className="d-flex align-items-center mb-3">
                        <a href={`/`} className="material-icons text-dark text-decoration-none m-none me-3">arrow_back</a>
                        <p className="ms-2 mb-0 fw-bold text-body fs-6 text-uppercase">
                            Create Story
                        </p>
                    </div>
                    <div className="mb-4  mb-lg-0">
                        {Object.keys(media).length > 0 ?
                            <div className="p-3 shadow-sm bg-white">
                                <div className="d-flex align-items-center justify-content-between mb-2">
                                    <h6 className="mb-0">Apercu</h6>
                                    <label htmlFor="imageFile" className="rounded-circle text-black md-26 p-2" style={{ width: 40, height: 40, overflow: 'hidden', backgroundColor: '#D8DADF', position: 'relative' }}>
                                        <span className="material-icons md-26" style={{ width: '100%', height: '100%', cursor: 'pointer' }}>
                                            add
                                        </span>
                                        <input type="file" id="imageFile" name="image" accept=".jpg,.jpeg,.png,.mp4" style={{ opacity: 0, position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', cursor: 'pointer' }} onChange={handleFileChange} />
                                    </label>
                                </div>
                                <div id="carouselAddStory" className="carousel slide" data-bs-ride="false">
                                    <div className="carousel-indicators">
                                        {/* {medias.length > 1 &&
                                            medias.map((file, index) => {
                                                return index === 0 ? (
                                                    <button type="button" data-bs-target="#carouselAddStory" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                                                ) : (
                                                    <button type="button" data-bs-target="#carouselAddStory" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                                )
                                            })} */}
                                    </div>
                                    <div className="carousel-inner" style={{ height: 550 }}>
                                        {/*                                         
                                            // let type = media.file.type.split('/')[0];
                                            // const activeClass =  'active' ; */}
                                        {media.url && (
                                            <div className={`carousel-item active overflow-hidden position-relative`} style={{ height: '100%' }}>
                                                {media.mediaType === 'video' ? (
                                                    <video controls ref={videoRef} style={{ height: 550, width: '100%', objectFit: 'fill' }}>
                                                        <source src={media.url} type={media.file.type} />
                                                    </video>
                                                ) : (
                                                    <img src={media.url} className="d-block w-100" alt="post-img" style={{ height: 550, width: '100%' }} />
                                                )}
                                                {medias.length > 1 && (
                                                    <p className="position-absolute top-0 end-0 m-2 px-2 py-1 text-white bg-black rounded-pill opacity-50" style={{ fontSize: '1.2em' }}>{1}/{medias.length}</p>
                                                )}
                                                <button className="position-absolute top-0 start-0 text-white bg-black opacity-50 border-0 m-2 px-2 py-1 rounded-circle" name="deleteImg" style={{ fontSize: '1.2em' }} onClick={handleDeleteFile}>
                                                    <span className="material-icons">delete</span>
                                                </button>
                                                <button title="Add text" className="position-absolute top-0 text-white bg-black opacity-50 border-0 m-2 px-3 py-1 rounded-pill fw-bold" onClick={toogleAddText} style={{ right: '50px', fontSize: '1.2em' }}>
                                                    Aa
                                                </button>
                                                {state.isVisible && (
                                                    <div className="form-floating position-absolute w-50 top-50 start-50 translate-middle">
                                                        <textarea className="form-control rounded-5 border-0 bg-black opacity-50 text-white " placeholder='Add a description to your story' id="floatingTextarea2" name="storyContent" value={state.storyContent} onChange={textInputChange}></textarea>
                                                        <label htmlFor="floatingTextarea2" className="h6 text-white mb-0">Add a description to your story</label>
                                                    </div>
                                                )}
                                            </div>
                                        )}

                                    </div>
                                    {medias.length > 1 &&
                                        <>
                                            <button className="carousel-control-prev" type="button" data-bs-target="#carouselAddStory" data-bs-slide="prev">
                                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                                <span className="visually-hidden">Previous</span>
                                            </button>
                                            <button className="carousel-control-next" type="button" data-bs-target="#carouselAddStory" data-bs-slide="next">
                                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                                <span className="visually-hidden">Next</span>
                                            </button>
                                        </>
                                    }
                                </div>
                                <button className="btn fb-btn-color text-white btn-block rounded-5 fw-bold w-100 mt-3" onClick={addStory}>
                                    {state.isLoaded ?
                                        <Loader />
                                        :
                                        <div className="d-flex justify-content-center gap-1">
                                            <span className="material-icons">send</span>
                                            <span className="pb-0">{t('form.new_post.post')}</span>
                                        </div>
                                    }
                                </button>
                            </div>
                            :
                            <>
                                {!state.storyText ?
                                    <>
                                        {state.videoDurationMessage.length > 0 &&
                                            <div className="alert alert-danger alert-dismissible" role="alert">
                                                <div>{state.videoDurationMessage}</div>
                                                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                            </div>
                                        }
                                        <div className="d-flex py-5 px-3 gap-3 ">
                                            <label htmlFor="imageFile" className=" text-center rounded-3 text-white d-flex flex-column align-items-center justify-content-center gap-3" style={{ backgroundImage: `url(${Images.story_media})`, backgroundPosition: 'center', height: '330px', width: '220px', backgroundSize: 'cover', overflow: 'hidden', position: 'relative' }}>
                                                <span className="material-icons rounded-circle text-black shadow-sm  md-26 py-1" style={{ backgroundColor: '#D8DADF', width: 38, height: 38, cursor: 'pointer' }}>
                                                    add_a_photo
                                                </span>
                                                <input type="file" id="imageFile" name="image" style={{ opacity: 0, position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', cursor: 'pointer' }} onChange={handleFileChange} />
                                                <h6>Create a story with photo</h6>
                                            </label>
                                            <button className="rounded-3 text-white border-0 d-flex  flex-column align-items-center justify-content-center gap-3" onClick={toogleAddStoryText} style={{ backgroundImage: `url(${Images.story_text})`, backgroundPosition: 'center', height: '330px', width: '220px', backgroundSize: 'cover' }}>
                                                <span className="rounded-circle text-black px-2 py-1 shadow-sm  fw-bold fs-5" style={{ backgroundColor: '#D8DADF' }}>Aa</span>
                                                <h6>Create a story with text</h6>
                                            </button>
                                        </div>
                                    </>
                                    :
                                    <div className="p-3 shadow-sm bg-white position-relative">
                                        <div className="d-flex align-items-center justify-content-between mb-2">
                                            <h6 className="mb-0">Apercu</h6>
                                            <div className="d-flex align-items-center gap-2">
                                                <button onClick={toogleChangeBackground} title="Background" className="rounded-circle text-black border-0 p-2" style={{ width: 40, height: 40, backgroundColor: '#D8DADF' }}>
                                                    <span className="material-icons md-26" >palette</span>
                                                </button>
                                                <button onClick={toogleAddStoryText} className="rounded-circle text-black border-0 p-2" style={{ width: 40, height: 40, backgroundColor: '#D8DADF' }}>
                                                    <span className="material-icons md-26" >close</span>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="bg-dark rounded-3  py-3 d-flex align-item-center justify-content-center" style={{ height: 490 }}>
                                            <div className="h-100 rounded-3" style={{ width: 250, background: state.background }}>
                                                <textarea placeholder="Start Writing" className="w-100 h-100 bg-transparent border-0 overflow-hidden p-2 fs-5 fw-bold text-white" style={{ outline: 'none' }} name="storyContent" value={state.storyContent} onChange={textInputChange}></textarea>
                                            </div>
                                            {state.changeBackground &&
                                                <div className="d-flex flex-wrap gap-2 position-absolute bg-white p-2 rounded-3" style={{ top: 65, right: 30, width: 107 }}>
                                                    <button onClick={() => handleChangeBackground(Metrics.background1)} className={`rounded-circle text-black  p-2 ${state.selectedButton === Metrics.background1 ? 'border border-3 border-info' : 'border-0'}`} style={{ width: 25, height: 25, background: Metrics.background1 }}></button>
                                                    <button onClick={() => handleChangeBackground(Metrics.background2)} className={`rounded-circle text-black  p-2 ${state.selectedButton === Metrics.background2 ? 'border border-3 border-info' : 'border-0'}`} style={{ width: 25, height: 25, background: Metrics.background2 }}></button>
                                                    <button onClick={() => handleChangeBackground(Metrics.background3)} className={`rounded-circle text-black  p-2 ${state.selectedButton === Metrics.background3 ? 'border border-3 border-info' : 'border-0'}`} style={{ width: 25, height: 25, background: Metrics.background3 }}></button>
                                                    <button onClick={() => handleChangeBackground(Metrics.background4)} className={`rounded-circle text-black  p-2 ${state.selectedButton === Metrics.background4 ? 'border border-3 border-info' : 'border-0'}`} style={{ width: 25, height: 25, background: Metrics.background4 }}></button>
                                                    <button onClick={() => handleChangeBackground(Metrics.background5)} className={`rounded-circle text-black  p-2 ${state.selectedButton === Metrics.background5 ? 'border border-3 border-info' : 'border-0'}`} style={{ width: 25, height: 25, background: Metrics.background5 }}></button>
                                                    <button onClick={() => handleChangeBackground(Metrics.background6)} className={`rounded-circle text-black  p-2 ${state.selectedButton === Metrics.background6 ? 'border border-3 border-info' : 'border-0'}`} style={{ width: 25, height: 25, background: Metrics.background6 }}></button>
                                                </div>
                                            }
                                        </div>
                                        <button className="btn fb-btn-color text-white btn-block rounded-5 fw-bold w-100 mt-3" onClick={addStory}>
                                            {state.isLoaded ?
                                                <Loader />
                                                :
                                                <div className="d-flex justify-content-center gap-1">
                                                    <span className="material-icons">send</span>
                                                    <span className="pb-0">{t('form.new_post.post')}</span>
                                                </div>
                                            }
                                        </button>
                                    </div>
                                }
                            </>
                        }
                    </div>
                </div>
            </main>
            <AsideContainer />
        </AppContainer>
    )
}
export default CreateStoryView;