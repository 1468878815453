import React, { useRef, useState } from 'react'
import { Loader, PaymentModeImage, TransactionsModal } from '../../Components'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { convertCurrency, getUserAccount } from '../../Utils'
import { RequestFanbusy } from '../../Services'

const Pay4meCodeCreate = ({ classParent, setListCode }) => {
    const [userAccount, setuserAccount] = React.useState()
    const { t } = useTranslation()
    const params = useSelector((state) => state.params);
    const [minAmountCredit, setMinAmountCredit] = React.useState(1);
    const [maxAmount, setMaxAmount] = React.useState(0);
    const users = useSelector((state) => state.users);
    const refs = useRef();
    const balance = convertCurrency(params.currency, params.currencies, users.account?.balance, 'GET')

    const [state, setState] = useState({
        amount: 0,
        alertMessage: '',
        atertType: '',
        isLoaded: false
    })

    const addCode = async () => {
        if (parseFloat(balance.replace(/\s/g, '')) < parseFloat(state.amount)) {
            if (refs) {
                refs.current?.click();
            }
            setState((prev) => ({ ...prev, isLoaded: false }));

        } else {
            setState((prev) => ({ ...prev, isLoaded: true }));
            let form = {
                value: state.amount,
                currencies: params.currency
            }

            let res = await RequestFanbusy(`pay4me/`, 'POST', form, users.access_token);
            if (res.status === 201) {
                setState((prev) => ({ ...prev, alertMessage: t('views.pay4me.codeCreated'), atertType: 'success' }));
                let res = await RequestFanbusy("pay4me/", 'GET', '', users.access_token)
                if (res.status === 200) {
                    setListCode(res.response.data)
                }
            } else if (res.response.status_code === 406) {
                setState((prev) => ({ ...prev, alertMessage: t('views.pay4me.tryAgain'), atertType: 'warning' }));
            } else if (res.response.status_code === 402) {
                setState((prev) => ({ ...prev, alertMessage: t('views.pay4me.insufisantFund'), atertType: 'warning' }));
            } else {
                setState((prev) => ({ ...prev, alertMessage: t('views.pay4me.badRequest'), atertType: 'warning' }));
            }
        }
        setState({ ...state, isLoaded: false });
    }


    const handleChange = (event) => {
        const { type, name, value, checked } = event.target;
        let fieldValue = type === 'checkbox' ? checked : value;

        if (name === 'number') {
            setState({ ...state, amount: fieldValue })
        }

    };

    React.useEffect(() => {
        let minAmount = params.currencies?.filter((dataCurrency) => dataCurrency.to_currency === params.currency)[0];
        setMinAmountCredit(params.currency === 'USD' ? 1 : minAmount?.value)
        let maxAmountPost = convertCurrency(params.currency, params.currencies, 200, 'GET')
        setMaxAmount(maxAmountPost)

        let userAccount = getUserAccount(users.access_token);
        userAccount.then((value) => {
            let convert = convertCurrency(params.currency, params.currencies, value.balance, 'GET');
            setuserAccount(convert)
        });

    }, [params.currencies, params.currency, params.refresh, users.access_token]);

    const handleDataReady = (data) => {
        setState((prevState) => ({ ...prevState, alertMessage: (data) }))
    };

    return (
        <>
            <div className={`mt-web-5 ${classParent}`} >
                <p className={` text-center text-muted pb-1 pt-0 mb-0 web-none`} >{t('views.pay4me.paySubscriptionsFor')}</p>
                <div className={`bg-white border account-follow mb-4`}>
                    <h5 className={`text-body fw-bold ps-2 pt-2 mb-1`}>{userAccount} {params.currency}</h5>
                    <h6 className={`text-body fw-bold p-2 pt-0  mb-0 border-bottom`}>{t('views.account.currentBalance')}</h6>
                    <div className="m-2 ">

                        <p >{t('views.pay4me.createsPay4meCode')}</p>
                        <div className="form-floating mb-1 d-flex align-items-center">
                            <input type="number" className={` form-control rounded-5`} id="Rising" placeholder="Amount" name="number" value={state.amount === 0 ? '' : state.amount} onChange={handleChange} style={{ height: '5px' }} />
                            <label htmlFor="Rising" className="pt-2 mt-0">{t('navigation.amount')} {params.currency}</label>
                        </div>
                        <div className="d-flex align-items-center mb-2" style={{ marginLeft: '5px', fontSize: '0.9em' }}>
                            <p className="mb-0">Minimum {minAmountCredit} {params.currency}</p>

                            <p className="mb-0">, Maximum {maxAmount} {params.currency}</p>

                        </div>


                        <div className="mt-2 d-flex flex-column gap-2 ">
                            <button onClick={() => { addCode() }} className={`btn gradient-button text-white btn-block rounded-pill py-1 px-0 fw-bold  w-100 ${state.amount === '' || (state.amount < minAmountCredit) || ((state.amount > parseFloat(maxAmount.replace(/\s/g, '')))) ? 'disabled' : ''} `} type="button" >
                                {state.isLoaded ? <Loader /> : 'Pay4me'}
                                {parseFloat(balance) < parseFloat(state.amount) &&
                                    <span data-bs-toggle="modal" data-bs-target={`#sentTipModal${users.users._id}`} ref={refs}></span>
                                }
                            </button>
                            {state.alertMessage?.length > 0 &&
                                <div className={`alert alert-${state.atertType} alert-dismissible mb-0 p-2`} role="alert">
                                    <div className="me-4">{state.alertMessage}</div>
                                    <button type="button" className="btn-close pt-2 pe-2" data-bs-dismiss="alert" aria-label="Close"></button>
                                </div>
                            }

                        </div>
                    </div>

                </div>
                <PaymentModeImage styleResp={'small-none'} shadowTop={{ boxShadow: '0px -5px 0px #EDF2F6' }} />
            </div>


            {parseFloat(balance) < parseFloat(state.amount) &&
                < TransactionsModal
                    onDataReady={handleDataReady}
                    data={{ id: users.users?._id, photo: users.users?.photo, display_name: users.users?.display_name, pseudo: users.users?.pseudo, account_type: users.users?.account_type, postId: users.users?._id }}
                    action={'pay4me'}
                    nameForm={`${t('navigation.paidForMe')}`}
                    amount={state.amount}
                    setListCode={setListCode}

                />}
        </>

    )
}

export default Pay4meCodeCreate