import { React, useState, useEffect } from 'react';
import { useSelector, } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { RiSearchLine } from 'react-icons/ri';

import { Avatar, Loader, NoContent } from '../../Components';
import { RequestFanbusy, TchatRequest } from '../../Services';
import { Images } from '../../Constants';
import { getRoomAndAllStatusReceiverByUser, shortenName } from '../../Utils';
import { getStatutByUser } from '../../Utils';
// import { useSearch } from '../../Utils';



const CreateRoom = ({ onSelectReceiver, statusUsers, room }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const users = useSelector(state => state.users);
    const [isCreatingRoom, setIsCreatingRoom] = useState(false);
    const [isOnLine, setIsOnLine] = useState({});
    const [shosearchInput, setShosearchInput] = useState(false);
    // const { searchQuery, setSearchQuery, searchResults, searchLoad } = useSearch("/process/search/my/process");
    const [state, setState] = useState({
        isLoading: true,
        isLoadingFans: true,
        creatorsFollows: [],
        creatorsFans: [],
        statusUsers,
        errorType: ''
    })

    useEffect(() => {
        const getUsersFollows = async () => {
            let res = await RequestFanbusy(`souscriptions/my/follows`, 'GET', '', users.access_token);
            if (res.status === 200) {
                const follow = res.response.data;
                setState((prev) => ({ ...prev, creatorsFollows: follow, isLoading: false }));
                const onlineStatus = {};
                for (const creator of follow) {
                    const online = await getStatutByUser(creator.creator._id);
                    onlineStatus[creator.creator._id] = online;
                }
                setIsOnLine((prevStatus) => ({ ...prevStatus, ...onlineStatus }));

                return follow;
            }
        };

        const getCreatorsFans = async () => {
            const follow = await getUsersFollows();
            const usersFollowsIds = follow.map(creator => creator.creator._id);
            let res = await RequestFanbusy(`souscriptions/my/fans`, 'GET', '', users.access_token);
            if (res.status === 200) {
                const fan = res.response.data;
                const finalFanTab = fan.filter(fan => !usersFollowsIds.includes(fan.fan._id));
                setState((prev) => ({ ...prev, creatorsFans: finalFanTab, isLoadingFans: false }));
                const onlineStatus = {};
                for (const fan of finalFanTab) {
                    const online = await getStatutByUser(fan.fan._id);
                    onlineStatus[fan.fan._id] = online;
                }
                setIsOnLine((prevStatus) => ({ ...prevStatus, ...onlineStatus }));
            }
        };

        getCreatorsFans();

        let stat = getRoomAndAllStatusReceiverByUser(users.users._id);
        stat.then((value) => {
            setState(prevState => ({ ...prevState, statusUsers: value }));
        });

    }, [users.access_token, users.users._id]);


    const createChatWithCreator = async (creator_id) => {
        const creatorResponse = await RequestFanbusy(`creators/${creator_id}`, 'GET', '', users.access_token);
        const newCreatorObject = creatorResponse.response.data;
        try {
            if (isCreatingRoom) {
                return;
            }
            setIsCreatingRoom(true);
            let data = {
                sender: {
                    id: users.users._id,
                },
                receiver: {
                    id: newCreatorObject._id,
                }
            }
            const response = await TchatRequest('room', 'POST', data, '');
            if (response.response.status_code === 201) {
                const newRoom = response.response.data._id;
                const res = await TchatRequest(`room/Room/souscriptions/${users.users._id}/${creator_id}`, 'PUT', '', '')
                if (res.response.status_code === 200) {
                    TchatRequest(`room/get/write/by/${room}/${users.users._id}/${newCreatorObject.tchat_fee}`, 'GET', '', '');
                    onSelectReceiver(newRoom, newCreatorObject);
                    navigate(`my/chat/${newRoom}/${newCreatorObject._id}`)
                }
            } else {
                const roomexist = response.response.data[0]._id;
                onSelectReceiver(roomexist, newCreatorObject);
                navigate(`my/chat/${roomexist}/${newCreatorObject._id}`)
            }

        } catch (error) {
            setState(prevState => ({ ...prevState, errorType: error.message }));
        }
    }


    return (
        <div className="main-content ">
            <div className="border-bottom  bg-white fix-sidebar top-0 shadow-sm" style={{ padding: '19px', height: '60px' }}>
                <div className='d-flex align-items-center justify-content-between'>

                    <div className='d-flex'>
                        <div className='rounded-circle'>
                            {!shosearchInput ?
                                <a href='/messages' className="material-icons text-dark text-decoration-none border-0 bg-transparent pt-1 ">arrow_back</a>
                                : <span style={{ cursor: 'pointer' }} className={`bg-transparent text-decoration-none  material-icons border-0 pt-1`} onClick={() => { setShosearchInput(!shosearchInput) }}>close</span>

                            }
                        </div>
                        {!shosearchInput ?
                            <div className="align-items-center w-100 mb-0 fw-bold text-body fs-6  text-black d-flex ms-2">
                                {t('views.messages.contact')}
                            </div>
                            :
                            <form className={`bg-white input-group overflow-hidden rounded-pill d-flex align-items-center `}>
                                <input type="text" name="search" placeholder={t('navigation.findCreator')} className={`form-control border-0 fw-light ps-1`} />
                            </form>}
                    </div>

                    <button className={`bg-transparent text-black material-icons border-0  justify-content-end text-decoration-none pb-1 ${shosearchInput && 'text-muted'}`} disabled={shosearchInput} onClick={() => { setShosearchInput(!shosearchInput) }}><RiSearchLine size={20} /></button>
                </div>


            </div>

            <div>
                {(state.isLoading || state.isLoadingFans) ?
                    <div style={{ marginTop: '50px' }}>
                        <Loader styleColor={true} />
                    </div>
                    :
                    <div className={`lg-0 size-contener-room `} style={{ overflowY: 'scroll', paddingBottom: "70px" }} >
                        {(state.creatorsFollows.length <= 0 && state.creatorsFans.length <= 0) ?
                            <div className="sticky-sidebar2 mb-3 me-1">
                                <div class="py-5 text-center">
                                    <div class="text-center pb-5 mx-5">
                                        <NoContent />
                                    </div>
                                </div>
                            </div>
                            :
                            <>
                                {state.creatorsFollows.map(creator => {
                                    const onlineStatus = isOnLine[creator.creator._id];
                                    return (
                                        <div className=' bouton-survol'>
                                            <div key={creator._id} onClick={() => createChatWithCreator(creator.creator._id)} className={`p-2 d-flex text-dark text-decoration-none account-item bg-transparent custom-border-style bouton-survol`}>
                                                <div className="position-relative border-bottom-0" >
                                                    <Avatar user={creator.creator} width={50} height={50} fontSize="fs-6" />
                                                    {(onlineStatus) &&
                                                        <span className="position-absolute translate-middle p-1 border border-green rounded-circle" style={{ top: '39px', left: '84%', backgroundColor: '#35D063' }}>
                                                            <span className="visually-hidden ">New alerts</span>
                                                        </span>
                                                    }
                                                </div>
                                                <div className='ms-3'>
                                                    <p className="fw-bold mb-0 pe-1 d-flex align-items-center">
                                                        <span> {shortenName(creator.creator.display_name, 10)}</span>
                                                        {creator.creator.account_type === 'CREATOR' &&
                                                            <span className="ms-0 material-icons  fw-bold text-secondary  rounded-circle ov-icon md-0 mt-0 ">{Images.verif}</span>
                                                        }
                                                    </p>
                                                    <div className="text-muted fw-light d-flex justify-content-between ">
                                                        <span className="text-muted d-flex align-items-center small">
                                                            @ {shortenName(creator.creator.pseudo, 10)}
                                                        </span>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>)
                                })}

                                {
                                    <div>
                                        {
                                            (state.isLoading) ? (<Loader styleColor={true} />) : (
                                                state.creatorsFans.map(fan => {
                                                    const onlineStatus = isOnLine[fan.fan._id];
                                                    return (
                                                        <div className='bouton-survol' key={fan._id} onClick={() => createChatWithCreator(fan.fan._id)}>
                                                            <div className={`p-2 d-flex text-dark text-decoration-none account-item bg-transparent custom-border-style bouton-survol `}>
                                                                <div className="position-relative">
                                                                    <Avatar user={fan.fan} width={50} height={50} fontSize="fs-6" />
                                                                    {(onlineStatus) && (
                                                                        <span className="position-absolute translate-middle p-1  border border-green rounded-circle" style={{ top: '39px', left: '84%', backgroundColor: '#35D063' }}>
                                                                            <span className="visually-hidden ">New alerts</span>
                                                                        </span>
                                                                    )}
                                                                </div>
                                                                <div className='ms-3'>
                                                                    <p className="fw-bold mb-0 pe-1 d-flex align-items-center">
                                                                        <span>{shortenName(fan.fan.display_name, 10)}</span>
                                                                        {fan.fan.account_type === 'CREATOR' && (
                                                                            <span className="ms-0 material-icons p-0 fw-bold text-secondary  rounded-circle ov-icon md-0 mb-1 ">{Images.verif}</span>
                                                                        )}
                                                                    </p>
                                                                    <div className="text-muted fw-light d-flex justify-content-between ">
                                                                        <span className="text-muted d-flex align-items-center small">
                                                                            @ {shortenName(fan.fan.pseudo, 10)}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                            )
                                        }
                                    </div>
                                }
                            </>

                        }
                    </div>
                }
            </div>
        </div>
    )
}

export default CreateRoom;