import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getBookmark, getCreatorFollows, getLikes, getReleasePost, useScreenSize } from '../../Utils';
import { RequestFanbusy } from '../../Services';
import { Loader, Post, NoContent } from '../../Components';
import { AppContainer, AsideContainer } from '../../Containers';

const BookmarksView = () => {

    const darkMode = useSelector((state) => state.theme.darkMode);
    const { t } = useTranslation();
    const users = useSelector(state => state.users);
    const params = useSelector(state => state.params);
    const [state, setState] = React.useState({
        posts: [],
        follows: [],
        isLoading: true
    });
    const [listBookmark, setListBookmark] = React.useState([]);
    const [listLikes, setListLikes] = React.useState([]);
    const [listPostRelease, setListPostRelease] = React.useState([]);
    const screenSize = useScreenSize()
    const isMobile = screenSize === 'Extra Small' || screenSize === 'Small' || screenSize === 'Medium'

    React.useEffect(() => {
        let follows = getCreatorFollows(users.access_token);
        follows.then((value) => {
            setState((prev) => ({ ...prev, follows: value }));
        });
        let bookmarks = getBookmark(users.access_token);
        bookmarks.then((value) => {
            setListBookmark(value);
        });
        let likes = getLikes(users.access_token, 'POST');
        likes.then((value) => {
            setListLikes(value);
        });
        let releasePost = getReleasePost(users.access_token);
        releasePost.then((value) => {
            setListPostRelease(value);
        });
        const getCreatorPost = async () => {
            let res = await RequestFanbusy('bookmarks/self/creator/type/POST', 'GET', '', users.access_token);
            if (res.status === 200) {
                setState((prev) => ({ ...prev, posts: res.response.data, isLoading: false }));
            } else if (res.status === 401) {
                setState((prev) => ({ ...prev, isLoading: false }));
            }
        };
        getCreatorPost();
    }, [params.refresh, users.access_token]);

    return (
        <AppContainer>
            <main className={`col col-xl-6 order-xl-2 col-lg-12 order-lg-1 col-md-12 col-sm-12 col-12 py-2 border border-top-0 border-fw-light px-0 border-bottom-0 ${isMobile && 'pt-5 fixed-content pb-5'} `}>
                <div className="main-content">
                    <div style={{ height: "50px" }} className="d-flex align-items-center m-none border-bottom pb-2 fix-sidebar bg-white top-0 ">
                        <a href={`/`} className={`${darkMode ? 'text-white' : 'text-dark'} material-icons text-decoration-none m-none ms-3`}>arrow_back</a>
                        <p className={`${darkMode ? 'text-white' : 'text-body'} ms-2 mb-0 fw-bold fs-6 text-uppercase`}>
                            {t('navigation.bookmarks')}
                        </p>
                    </div>
                    <div className="pt-3 feeds">
                        {state.isLoading ?
                            <Loader styleColor={true} /> :
                            <>
                                {state.posts.length > 0 ?
                                    state.posts.map((post, index) => {
                                        return (
                                            <Post key={index} postLength={{ length: state.posts?.length, index: index }} post={post} creatorId={users.users._id} bookmarks={listBookmark} likes={listLikes} released={listPostRelease} follows={state.follows} />
                                        )
                                    }) : <NoContent message={"You haven't added anything to favorites"} />
                                }
                            </>
                        }
                    </div>
                </div>
            </main>

            <AsideContainer />
        </AppContainer>
    );
};

export default BookmarksView;