//React
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
//import { CgGift } from 'react-icons/cg';

//App
import { RequestFanbusy } from '../../Services';


const PostFooter = ({ isOpen, disabled, toggleComment, checkIfReleasePost, post, likes, checkIfSubscribeToCreator, getCommentByPost, creatorId, bookmarks, setIsOpen, textInputChange, commentContent }) => {
    const users = useSelector((state) => state.users);
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const disabledCreator = users.users?._id === post.creator._id ? 'pe-none' : '';
    const [addBookmark, setAddBookmark] = React.useState(false);
    const [isLiking, setIsLiking] = React.useState(false);
    const [processingLike, setProcessingLike] = React.useState(false);
    const [likeNumber, setLikeNumber] = React.useState(post?.like_number);
    const isButtonDisabled = !checkIfReleasePost(post._id) || users.users?._id === post.creator?._id || !checkIfSubscribeToCreator(creatorId);
    const likePost = async (post_id) => {
        setProcessingLike(true)
        if (!isLiking) {
            setIsLiking(true)
            setLikeNumber(likeNumber + 1)
        } else {
            setIsLiking(false)
            setLikeNumber(likeNumber - 1)
        }
        let form = {
            post_id: post_id
        }
        let res = await RequestFanbusy('likes/post', 'POST', form, users.access_token);

        if (res.status === 201) {
            dispatch({ type: "REFRESH", value: 1 });
            setProcessingLike(false)
        } else {
            setProcessingLike(false)
        }
    }

    const addToBookmark = async (post_id) => {
        setProcessingLike(true)
        if (!addBookmark) {
            setAddBookmark(true)
        } else {
            setAddBookmark(false)
        }
        let form = {
            post_id: post_id,
            type: "POST"
        }
        let res = await RequestFanbusy('bookmarks/', 'POST', form, users.access_token);

        if (res.status === 201) {
            dispatch({ type: "REFRESH", value: 1 });
            setProcessingLike(false)
        } else {
            setProcessingLike(false)
        }
    }

    const checkIfLikes = React.useCallback((post_id) => {
        const myLikes = likes.filter(likesList => likesList === post_id);
        if (myLikes.length > 0) {
            setIsLiking(true)
        }
        else {
            setIsLiking(false)
        }
    }, [likes])

    const checkIfBookmark = React.useCallback((post_id) => {
        const myBookmarks = bookmarks.filter(bookmarkList => bookmarkList._id === post_id);
        if (myBookmarks.length > 0) {
            setAddBookmark(true)
        }
        else {
            setAddBookmark(false)
        }

    }, [bookmarks])

    React.useEffect(() => {
        checkIfLikes(post._id)
        checkIfBookmark(post._id)
    }, [checkIfLikes, checkIfBookmark, post._id]);

    const submitAction = (action, post_id) => {
        if (action === 'LIKE' && !processingLike) {
            likePost(post_id)
        } else if (action === 'BOOKMARK' && !processingLike) {
            addToBookmark(post_id)
        }
    }

    return (
        <>
            <div className={`d-flex align-items-center justify-content-between mx-3  mt-3 ${disabled}`}>

                <div className={`d-flex align-items-center justify-content-between w-100`}>
                    <div>
                        <button disabled={isButtonDisabled} title="Send Tip" data-bs-toggle="modal" data-bs-target={`#sentTipModal${post._id}`} className={` text-muted text-decoration-none d-flex align-items-start fw-light text-decoration-none border-0 bg-white ${!checkIfReleasePost(post._id) || users.users?._id === post.creator?._id ? 'opacity-50' : ''}  ${disabled} ${disabledCreator}`} >
                            <span className={`material-icons md-25 me-1 p-0 pe-auto ${isButtonDisabled ? '' : 'text-gradient '}`}>redeem</span>
                            <span className={`ms-2 pt-1 ${isButtonDisabled ? '' : 'text-gradient'}`}>{t('words.leave_tip')}</span>
                        </button>
                    </div>
                    <div>
                        <button onClick={() => (!checkIfReleasePost(post._id) || !checkIfSubscribeToCreator(creatorId) || processingLike) ? () => { } : submitAction('LIKE', post._id)} title={t('words.like')} className={`text-muted text-decoration-none border-0 bg-white d-flex align-items-start fw-light ${!checkIfReleasePost(post._id) ? 'opacity-50' : ''} ${disabled} ${disabledCreator}`} >
                            {isLiking ?
                                <span className="material-icons md-20 me-2 p-0 pe-auto text-gradient ">favorite</span>
                                :
                                <span className="material-icons md-20 me-2 pe-auto">favorite_outline</span>
                            }
                            <span>{likeNumber}</span>
                        </button>
                    </div>
                    <div>
                        <button title={t('words.comments')} onClick={() => { getCommentByPost(); toggleComment() }} className={`text-muted border-0 bg-transparent d-flex align-items-start fw-light ${!checkIfReleasePost(post._id) ? 'opacity-50' : ''}`}>
                            {isOpen ?
                                <span className="material-icons md-20 mb-0">close</span>
                                :
                                <>
                                    <span className="material-icons md-20 me-2">chat_bubble_outline</span>
                                    {!post.comment_disabled &&
                                        <span>{post.comment_number}</span>
                                    }
                                </>
                            }

                        </button>
                    </div>
                    <div>
                        <button onClick={() => submitAction('BOOKMARK', post._id)} title={t('views.post.add_to_bookmarks')} className={`text-muted text-decoration-none border-0 bg-white d-flex align-items-start fw-light${disabled} ${disabledCreator}`} >
                            {addBookmark ?
                                <span className={`material-icons md-18 me-2 fb-color ${!checkIfReleasePost(post._id) ? 'opacity-50' : ''}`}>bookmark</span> :
                                <span className={`material-icons md-18 me-2 ${!checkIfReleasePost(post._id) ? 'opacity-50' : ''}`}>bookmark_outline</span>
                            }
                        </button>
                    </div>
                </div>
            </div>

        </>

    )
}
export default PostFooter