import React from 'react';
import { Images, Routes } from '../../Constants';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { AppContainer } from '../../Containers';
import { RequestFanbusy } from '../../Services';
import { Loader, ShareModal } from '../../Components';
import { formatDateRelativePost, getLikes } from '../../Utils';
const StoryView = () => {
    const { id } = useParams();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const users = useSelector((state) => state.users);
    const darkMode = useSelector((state) => state.theme.darkMode);
    const canvasRef = React.useRef(null);
    const carouselRef = React.useRef(null);
    const linkRef = React.useRef(null);
    const videoRef = React.useRef(null);
    const [progress, setProgress] = React.useState(0);
    const [progressFinich, setProgressFinich] = React.useState(0);
    const [currentProgress, setCurrentProgress] = React.useState(0);
    const [completedRequests, setCompletedRequests] = React.useState(0);
    const [isPlaying, setIsPlaying] = React.useState(true);
    const [isMuted, setIsMuted] = React.useState(false);
    const [mediaTypes, setMediaTypes] = React.useState([]);
    const [state, setState] = React.useState({
        stories: [],
        comments: [],
        follows: [],
        ViewByStory: [],
        creatorData: {},
        storyData: {},
        activeStory: null,
        viewStoryData: '',
        commentContent: '',
        reportContent: '',
        releaseErrors: '',
        activeStoryIndex: 0,
        currentStoryIndex: 0,
        isVisible: false,
        isFinich: false,
        isViewComment: false,
        isOpen: true,
        isLoading: true,
        isLoaded: true,
    });
    const [listLikes, setListLikes] = React.useState([]);
    const [isModalOpen, setIsModalOpen] = React.useState(true);

    React.useEffect(() => {
        let likes = getLikes(users.access_token);
        likes.then((value) => {
            setListLikes(value);
        });
        const getPostByCreatorId = async () => {
            let res = await RequestFanbusy(`stories/creator/${id}`, 'GET', '', users.access_token);
            if (res.status === 200) {
                setState((prev) => ({ ...prev, storyData: res.response.data[0] }));
            }
            setCompletedRequests(prev => prev + 1);
        };

        const getCreatorById = async () => {
            let res = await RequestFanbusy(`creators/${id}`, 'GET', '', users.access_token);
            if (res.status === 200) {
                setState((prev) => ({ ...prev, creatorData: res.response.data }));
            }
            setCompletedRequests(prev => prev + 1);
        };

        const getAllStoriesIfNotView = async () => {
            let res = await RequestFanbusy("stories/", 'GET', '', users.access_token);
            if (res.status === 200) {
                let resViewed = await RequestFanbusy("stories/view/creator", 'GET', '', users.access_token);
                if (resViewed.status === 200) {
                    const viewedStoriesIds = resViewed.response.data
                    const filteredStories = res.response.data?.map((post) => {
                        const filteredStories = post.stories?.filter((story) => !viewedStoriesIds?.includes(story.id))
                        return { ...post, stories: filteredStories };
                    }).filter((post) => post.stories.length > 0);
                    setState((prev) => ({ ...prev, stories: filteredStories }));

                }
            }
            setCompletedRequests(prev => prev + 1);
        };

        const getCreatorFollows = async () => {
            let res = await RequestFanbusy('creators/my/follows', 'GET', '', users.access_token);
            if (res.status === 200) {
                setState((prev) => ({ ...prev, follows: res.response.data, isLoading: false }));
            }
            setCompletedRequests(prev => prev + 1);
        };
        getCreatorFollows()
        getAllStoriesIfNotView();
        getPostByCreatorId();
        getCreatorById();
    }, [users.access_token, id]);

    React.useEffect(() => {
        if (state.storyData?.stories?.length > 0) {
            const activeStory = state.storyData?.stories[0];
            setState((prev) => ({ ...prev, activeStory: activeStory }));
            const types = state.storyData?.stories?.map((file) => file.illustrations?.mime_type);
            setMediaTypes(types);
            if (mediaTypes[0] === 'video' && videoRef.current) {
                videoRef.current.play();
            }
        }
    }, [mediaTypes, state.storyData?.stories]);

    const putViewStories = React.useCallback(async () => {
        if (users.users._id !== id) {
            let form = {
                stories_id: state.storyData?._id,
                stories_content_id: state.activeStory?.id
            }
            let res = await RequestFanbusy(`stories/view`, 'PUT', form, users.access_token);
            if (res.status === 200) {
                dispatch({ type: "REFRESH", value: 1 });
            }
            setCompletedRequests(prev => prev + 1);
        }
    }, [dispatch, users.access_token, state.activeStory?.id, state.storyData?._id, users.users._id, id]);

    const checkIfSubscribeToCreator = React.useCallback(() => {
        if (state.follows.length > 0) {
            let followId = state.follows.filter((follow) => follow.fan._id === id || follow.creator._id === id)
            if (followId.length > 0) {
                return true

            }
            return false
        }
    }, [id, state.follows]);

    React.useEffect(() => {
        if (completedRequests === 5) {
            setState((prev) => ({ ...prev, isLoading: false }));
        }
    }, [completedRequests]);

    React.useEffect(() => {
        if (!state.isLoading && checkIfSubscribeToCreator() && isPlaying) {
            let startTime = Date.now();
            const updateProgress = () => {
                const elapsedTime = Date.now() - startTime;
                let tmpProgress;
                if (elapsedTime <= 15000) {
                    tmpProgress = progress !== 0 ? progress + ((elapsedTime / 15000) * 100) : (elapsedTime / 15000) * 100;
                    putViewStories();
                }
                else {
                    tmpProgress = 0;
                    setIsPlaying(false);
                    if (carouselRef.current) {
                        carouselRef.current.click();
                        setState((prev) => ({ ...prev, isVisible: false, isViewComment: false, isFinich: true }));
                        setProgressFinich(100)
                    }
                    if (state.storyData?.stories?.length > state.currentStoryIndex + 1) {
                        const activeStory = state.storyData.stories[state.currentStoryIndex + 1];
                        setState((prev) => ({ ...prev, activeStory: activeStory, currentStoryIndex: prev.currentStoryIndex + 1, }));
                        setIsPlaying(true);
                        putViewStories();
                    }
                }
                setProgress(tmpProgress);
            };


            if (isPlaying) {
                startTime = Date.now();
            } else {
                setProgress(0);
            }

            const interval = setInterval(updateProgress, 100);

            if (state.currentStoryIndex === state.storyData?.stories?.length) {
                clearInterval(interval);
                return;
            }

            return () => {
                clearInterval(interval);
            };
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPlaying, state.currentStoryIndex, state.storyData, putViewStories, checkIfSubscribeToCreator, state.isLoading]);

    const handlePrevSlide = () => {
        if (state.currentStoryIndex - 1 >= 0) {
            const activeNextStory = state.storyData.stories[state.currentStoryIndex + 1];
            if (activeNextStory.illustrations.mime_type === 'video' && videoRef.current) {
                toggleSound()
            }
            const activeStory = state.storyData.stories[state.currentStoryIndex - 1];
            setState((prev) => ({ ...prev, activeStory: activeStory, currentStoryIndex: prev.currentStoryIndex - 1, isVisible: false, isViewComment: false, isFinich: true }));
            setProgressFinich(100)
            setIsPlaying(true);
            if (activeStory.illustrations.mime_type === 'video' && videoRef.current) {
                videoRef.current.play();
            }
            return
        }
        if (state.currentStoryIndex === 0) {
            const index = (Math.abs(state.stories.findIndex(story => story.creator._id === id)) - 1)
            if (index <= 0) {
                navigate(Routes.HOME);
                return
            }
            const prevCreator = state.stories[index]
            navigate(`/story/${prevCreator["creator"]?._id}`)
            setIsPlaying(true);

            if (prevCreator.stories[0]?.illustrations?.mime_type === 'video' && videoRef.current) {
                videoRef.current.play();
            }
        }
    };

    const handleNextSlide = () => {
        if (state.storyData?.stories?.length > state.currentStoryIndex + 1) {
            const activePrevStory = state.storyData.stories[state.currentStoryIndex];
            if (activePrevStory.illustrations.mime_type === 'video' && videoRef.current) {
                toggleSound()
            }

            const activeStory = state.storyData.stories[state.currentStoryIndex + 1];
            setState((prev) => ({ ...prev, activeStory: activeStory, currentStoryIndex: prev.currentStoryIndex + 1, isVisible: false, isViewComment: false, isFinich: true }));
            setProgressFinich(100)
            setIsPlaying(true);
            if (activeStory.illustrations.mime_type === 'video' && videoRef.current) {
                videoRef.current.play();
            }
            return
        }
        if (state.storyData?.stories?.length === state.currentStoryIndex + 1) {
            const index = (Math.abs(state.stories.findIndex(story => story.creator._id === id)) + 1)

            if (index + 1 > state.stories.length) {
                navigate(Routes.HOME);
                return
            }
            const nextCreator = state.stories[index]
            navigate(`/story/${nextCreator["creator"]?._id}`)
            setIsPlaying(true);
            if (nextCreator.stories[0]?.illustrations?.mime_type === 'video' && videoRef.current) {
                videoRef.current.play();
            }
        }
    };

    const handleClose = () => {
        setState((prev) => ({ ...prev, isOpen: false }));
    };

    if (!state.isOpen) {
        return null;
    }

    const toggleSound = () => {
        const video = videoRef.current;
        video.muted = !video.muted;
        setIsMuted(video.muted);
    };

    const togglePause = () => {
        setIsPlaying(false);
        if (progress <= 100) {
            setCurrentProgress(progress);
            if (mediaTypes[state.activeStoryIndex] === 'video' && videoRef.current) {
                videoRef.current.pause();
            }
        }
    };

    const togglePlay = () => {
        setIsPlaying(true);
        setProgress(currentProgress);
        if (mediaTypes[state.activeStoryIndex] === 'video' && videoRef.current) {
            videoRef.current.play();
        }
    }

    const getViewStory = async (story_id) => {
        setState((prev) => ({ ...prev, isVisible: !prev.isVisible, isViewComment: false }))
        let res = await RequestFanbusy(`stories/view/content/${story_id}`, 'GET', '', users.access_token);
        if (res.status === 200) {
            setState((prev) => ({ ...prev, ViewByStory: res.response.data, isLoaded: false }));
        }
    }

    const textInputChange = (input) => {
        const target = input.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (name === 'commentContent') {
            setState({ ...state, commentContent: value });
        }
        else if (name === 'reportContent') {
            setState({ ...state, reportContent: value });
        }
    }

    const checkIfLikes = (story_id) => {
        const myLikes = listLikes.filter(likesList => likesList === story_id);
        if (myLikes.length > 0) {
            return true
        }

        return false;
    }

    const likePost = async (story_id) => {
        let form = {
            stories_id: state.storyData?._id,
            stories_content_id: story_id
        }

        let res = await RequestFanbusy('likes/stories', 'POST', form, users.access_token);

        if (res.status === 201) {
            dispatch({ type: "REFRESH", value: 1 });
        }
    }

    const getCommentByStory = async () => {
        setState((prev) => ({ ...prev, isViewComment: !prev.isViewComment, isVisible: false }))
        let res = await RequestFanbusy(`comments/STORIES/${state.activeStory?.id}`, 'GET', '', users.access_token);
        if (res.status === 200) {
            setState((prev) => ({ ...prev, comments: res.response.data, isLoaded: false }));
        }
    }

    const handleComment = async (story_id) => {
        const form = {
            referral_id: story_id,
            content: state.commentContent,
            stories_id: state.storyData?._id
        };
        let res = await RequestFanbusy('comments/stories', 'POST', form, users.access_token);
        if (res.status === 201) {
            setState({ ...state, commentContent: '' });
            dispatch({ type: "REFRESH", value: 1 });
        }
    }

    const handleDeleteComment = async (commentId) => {
        let res = await RequestFanbusy(`comments/${commentId}`, 'DELETE', '', users.access_token);

        if (res.status === 200) {
            dispatch({ type: "REFRESH", value: 1 });
        }
    }

    const handleReportPost = async (story_id) => {
        setState((prev) => ({ ...prev, isLoaded: true }));
        let form = {
            comment: state.reportContent,
            post_id: story_id
        }
        let res = await RequestFanbusy('reports/', 'POST', form, users.access_token);
        if (res.status === 201) {
            setState({ ...state, reportContent: '', isLoaded: false });
            dispatch({ type: "REFRESH", value: 1 });
            if (linkRef.current) {
                linkRef.current.click();
            }
            setIsModalOpen(false);
        }
    }

    const handleDeleteStory = async (story_id) => {
        if (state.activeStory) {
            let res = await RequestFanbusy(`stories/${story_id}`, 'DELETE', '', users.access_token);

            if (res.status === 200) {
                navigate(Routes.HOME);
            }
        }
    }

    const subscribeToCreator = async () => {
        setState((prev) => ({ ...prev, isLoaded: true }));
        const form = {
            creator_id: id,
        };
        if (checkIfSubscribeToCreator() === false) {
            let res = await RequestFanbusy('creators/', 'POST', form, users.access_token);

            if (res.status === 200) {
                setState((prev) => ({ ...prev, isLoaded: false }));
                dispatch({ type: "REFRESH", value: 1 });
            }
            else if (res.status === 400 && res.response.response_type === "Insuffisant fund") {
                setState({ ...state, releaseErrors: t('errors_messages.insuffisant_fund'), isLoaded: false });
            }
            else if (res.status === 500 && res.response.response_type === "Internal Server Error") {
                setState({ ...state, releaseErrors: 'Internal server error', isLoaded: false });
            }
        }
    };

    return (
        <AppContainer>
            {state.isLoading ?
                <Loader />
                :
                <>
                    <main className="col col-xl-9 order-xl-2 col-lg-12 order-lg-1 col-md-12 col-sm-12 col-12  my-4 ">
                        <div className="main-content" style={{ height: !checkIfSubscribeToCreator() ? '' : '100vh', backgroundColor: 'rgba(0, 0, 0, 0.7)' }}>
                            <div className="d-flex" style={{ height: '100%' }}>
                                {!checkIfSubscribeToCreator() ?
                                    <div className="position-relative pt-3 px-3" style={{ height: '80%', width: '80%' }}>
                                        <img src={Images.to_release} className="img-fluid mb-0 w-100 rounded-3" alt="post-img" style={{ height: '80%' }} />
                                        <div className=" border rounded-3 p-1 my-3" style={{ width: '100%' }}>
                                            <div className="d-flex align-item-center justify-content-between">
                                                <div className="d-flex align-item-center justify-content-center gap-3 mb-1">
                                                    {state.storyData?.stories?.filter(file => file.illustrations?.mime_type?.includes('image')).length > 0 &&
                                                        <div className="d-flex align-item-center justify-content-center" style={{ color: '#AFB0B4' }}>
                                                            <span className="material-icons m-0 p-0 me-1" style={{ fontSize: 20 }}>image</span>
                                                            <p className="m-0 p-0">{state.storyData?.stories?.filter(file => file.illustrations?.mime_type?.includes('image')).length}</p>
                                                        </div>
                                                    }
                                                    {state.storyData?.stories?.filter(file => file.illustrations?.mime_type?.includes('video')).length > 0 &&
                                                        <div className="d-flex align-item-center justify-content-center" style={{ color: '#AFB0B4' }}>
                                                            <span className="material-icons m-0 p-0 me-2" style={{ fontSize: 20 }}>videocam</span>
                                                            <p className="m-0 p-0">{state.storyData?.stories?.filter(file => file.illustrations?.mime_type?.includes('video')).length}</p>
                                                        </div>
                                                    }
                                                    {state.storyData?.stories?.filter(file => !Object.keys(file.illustrations).length).length > 0 && (
                                                        <div className="d-flex align-item-center justify-content-center" style={{ color: '#AFB0B4' }}>
                                                            <span className="material-icons m-0 p-0 me-2" style={{ fontSize: 20 }}>article</span>
                                                            <p className="m-0 p-0">{state.storyData?.stories?.filter(file => !Object.keys(file.illustrations).length).length}</p>
                                                        </div>
                                                    )}
                                                </div>
                                                <span className="material-icons" style={{ fontSize: 20, color: '#AFB0B4' }}>lock</span>
                                            </div>
                                            <button onClick={() => subscribeToCreator()} className="btn fb-btn-color text-white btn-block rounded-pill fw-bold d-flex align-items-center justify-content-between w-100 p-1 px-4 mb-1">
                                                {/* {state.isLoaded ?
                                                        <Loader /> : */}
                                                <>
                                                    Subscribe to creator
                                                    {state.storyData?.creator?.is_free_account ?
                                                        <span className="fw-bold">Free</span>
                                                        :
                                                        <span className="fw-bold" >${state.storyData?.creator?.subscription_fee}/month</span>
                                                    }
                                                </>
                                                {/* } */}
                                            </button>
                                            {state.releaseErrors.length > 0 &&
                                                <div className="alert alert-danger alert-dismissible" role="alert">
                                                    <div>{state.releaseErrors}</div>
                                                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    :
                                    <div className="d-flex m-0 flex-column " style={{ height: '100%', width: '100%' }}>
                                        <div className="col-lg-6 col-xl-6 col-sm-12 px-0 col-md-12 d-flex align-item-center justify-content-center my-2" style={{ height: '88%', width: '100%' }}>
                                            <div className="carousel slide rounded-3 overflow-hidden col-lg-8 col-xl-8 col-sm-12 col-md-12" style={{ height: '100%', width: '80%' }} data-bs-ride="false">
                                                <div className="carousel-inner" style={{ height: '100%' }}>
                                                    {state.storyData?.stories?.map((story, index) => {
                                                        const activeClass = story.id === state.activeStory?.id ? 'active' : '';
                                                        return Object.keys(story?.illustrations).length > 0 ? (
                                                            <div key={index} className={`carousel-item position-relative ${activeClass}`} style={{ height: '100%', width: '100%', transition: 'ease 0.3s' }}>
                                                                {story.illustrations.mime_type === 'image' ? (
                                                                    <img src={story.illustrations.link} className="d-block w-100" alt="post-img" style={{ height: '100%', width: '100%' }} />
                                                                ) : (
                                                                    <>
                                                                        <video className="col-sm-12 col-md-12" style={{ height: '100%', width: '100%', objectFit: 'fill' }} ref={videoRef}>
                                                                            <source src={story.illustrations.link} type="video/mp4" />
                                                                        </video>
                                                                        <canvas ref={canvasRef} style={{ display: 'none' }} />
                                                                    </>
                                                                )}
                                                                {state.activeStory?.content && (
                                                                    <h6 className="mb-0 p-2 w-100 position-absolute start-0 bottom-0 text-white" style={{ backgroundColor: 'rgba(0, 0, 0, .5)' }}>
                                                                        {state.activeStory?.content}
                                                                    </h6>
                                                                )}
                                                            </div>
                                                        )
                                                            : (
                                                                <div key={index} className={`carousel-item ${activeClass}`} style={{ height: '100%', width: '100%', background: state.activeStory?.extra }}>
                                                                    {state.activeStory?.content && (
                                                                        <p className="mb-0 p-2 h-100 w-100 text-white d-flex align-items-center justify-content-center fs-5 text-capitalize" >
                                                                            {state.activeStory?.content}
                                                                        </p>
                                                                    )}
                                                                </div>
                                                            );
                                                    })}
                                                </div>
                                                <div className="m-2  position-absolute top-0 start-0" style={{ width: '96.3%' }}>
                                                    <div className="row gap-2 mx-1 " >
                                                        {state.storyData?.stories?.map((story) => {
                                                            return (
                                                                <div key={story.id} className="progress mb-2 rounded-pill col p-0" style={{ height: 5, backgroundColor: '#B6B0AA' }}>
                                                                    {isPlaying ?
                                                                        state.isFinich ?
                                                                            <div className="progress-bar bg-white" style={{ width: `${state.activeStory?.id !== story.id && progressFinich + '%'}` }}></div>
                                                                            :
                                                                            <div className="progress-bar bg-white" style={{ width: `${state.activeStory?.id === story.id && progress + '%'}` }}></div>
                                                                        :
                                                                        state.isFinich ?
                                                                            <div className="progress-bar bg-white" style={{ width: `${state.activeStory?.id !== story.id && progressFinich + '%'}` }}></div>
                                                                            :
                                                                            <div className="progress-bar bg-white" style={{ width: `${state.activeStory?.id === story.id && currentProgress + '%'}` }}></div>
                                                                    }
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <div className="d-flex align-items-center ms-1 gap-3">
                                                            <img src={state.storyData?.creator?.photo} className="img-fluid rounded-circle  user-img" alt="..." style={{ width: 40, height: 40 }} />
                                                            <h6 className="text-white mb-0 text-capitalize" >{state.storyData?.creator?.display_name}</h6>
                                                            <h6 className="text-white mb-0" >{formatDateRelativePost(state.activeStory?.updated_at,t)}</h6>
                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            <div className="m-none">
                                                                <button className="btn text-white pb-0" onClick={isPlaying ? togglePause : togglePlay}>
                                                                    <i className="material-icons">{isPlaying ? 'pause' : 'play_arrow'}</i>
                                                                </button>
                                                                {state.activeStory?.illustrations?.mime_type === 'video' &&
                                                                    <button className="btn text-white pb-0" onClick={toggleSound}>
                                                                        <i className="material-icons">{isMuted ? 'volume_off' : 'volume_up'}</i>
                                                                    </button>
                                                                }
                                                            </div>
                                                            <div className="dropdown me-2  p-0">
                                                                <a href={`/`} className="text-white text-decoration-none material-icons md-26 " id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">more_horiz</a>
                                                                <ul className="dropdown-menu fs-13 dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                                                                    {id === users.users._id ?
                                                                        <li>
                                                                            <button className="dropdown-item text-muted" onClick={() => handleDeleteStory(state.activeStory?.id)}>
                                                                                <span className="material-icons md-13 me-1">delete</span> {t('words.delete')}
                                                                            </button>
                                                                        </li> :
                                                                        <li>
                                                                            <a href={`/`} className={`dropdown-item text-muted`} data-bs-toggle="modal" data-bs-target="#reportStoryModal">
                                                                                <span className="material-icons md-13 me-1">dangerous</span> {t('words.report')}
                                                                            </a>
                                                                        </li>
                                                                    }
                                                                    <li>
                                                                        <a href={`/`} className="dropdown-item text-muted d-flex align-items-center" data-bs-toggle="modal" data-bs-target="#shareModal">
                                                                            <span className="material-icons md-13 me-1">share</span> {t('words.share')}
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <div className="web-none ">
                                                                <a href={`/`} className="text-white text-decoration-none material-icons md-26" onClick={handleClose}>close</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {(state.storyData?.stories?.length > 1 || !state.stories[0]) &&
                                                    <button className="carousel-control-prev" onClick={handlePrevSlide} type="button">
                                                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                                        <span className="visually-hidden">Previous</span>
                                                    </button>
                                                }
                                                <button className="carousel-control-next" onClick={handleNextSlide} type="button" ref={carouselRef}>
                                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                                    <span className="visually-hidden">Next</span>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-xl-6 col-sm-12 content-body px-web-0 col-md-12 " style={{ width: '100%' }}>
                                            <div className="d-flex flex-column pb-0 pt-0 mt-auto col-md-12 col-sm-12">
                                                <div className="d-flex align-items-center justify-content-center gap-3 mx-2 mb-3 mt-1 ">
                                                    {users.users._id === state.storyData?.creator?._id &&
                                                        <div>
                                                            <button title='Viewer' className="text-white border-0 d-flex align-items-start fw-light p-2 rounded-4 " style={{ backgroundColor: '#5F5F5F' }} onClick={() => getViewStory(state.activeStory?.id)}>
                                                                <span className="material-icons md-20 me-2">visibility</span>
                                                                <span>{state.activeStory?.view_number}</span>
                                                            </button>
                                                        </div>
                                                    }
                                                    <div className="m-none">
                                                        <button title={t('words.comments')} className="text-white border-0 d-flex align-items-start fw-light p-2 rounded-4" style={{ backgroundColor: '#5F5F5F' }} onClick={getCommentByStory} >
                                                            <span className="material-icons md-20 me-2">chat_bubble_outline</span>
                                                            <span>{state.activeStory?.comment_number}</span>
                                                        </button>
                                                    </div>
                                                    <div className="d-flex align-items-center border rounded-4  py-0 px-2 " style={{ backgroundColor: '#5F5F5F' }}>
                                                        <input type="text" className="form-control form-control-sm p-0 text-white bg-transparent border-0" placeholder={t('views.home.your_comment')} name="commentContent" value={state.commentContent} onChange={textInputChange} />
                                                        <button className="bg-white border-0 text-white ps-2 bg-transparent" onClick={() => handleComment(state.activeStory?.id)}>Post</button>
                                                    </div>
                                                    {users.users._id !== id &&
                                                        <>
                                                            <div>
                                                                <button title="Send Tip" className="btn btn-secondary rounded-pill  p-1 pb-0 " data-bs-toggle="modal" data-bs-target="#sentTipModal">
                                                                    <span className="material-icons m-0 p-1 fs-6">local_atm</span>
                                                                </button>
                                                            </div>
                                                            <div>
                                                                <div title={t('words.like')} className="text-muted text-decoration-none d-flex align-items-start fw-light" style={{ cursor: 'pointer' }}>
                                                                    <div className="d-flex align-items-center gap-2">
                                                                        <button title="Like" className="btn btn-danger rounded-pill  p-1 pb-0 " onClick={() => likePost(state.activeStory?.id)}>
                                                                            {checkIfLikes(state.activeStory?.id) ?
                                                                                <span className="material-icons m-0 p-1 fs-6 fb-color ">favorite</span> :
                                                                                <span className="material-icons m-0 p-1 fs-6">favorite_outline</span>
                                                                            }
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                                {state.isVisible &&
                                                    <div className="overflow-auto custom-scrollbar web-none" style={{ maxHeight: 555 }}>
                                                        <div className="p-2 d-flex flex-column gap-2">
                                                            {state.isLoaded ? (
                                                                <Loader />
                                                            ) : state.ViewByStory?.length > 0 ? (
                                                                state.ViewByStory.map((view) => (
                                                                    <div className="shadow-sm rounded-3 p-2 pb-0" style={{ backgroundColor: '#EDF2F6' }}>
                                                                        <div className="d-flex justify-content-between align-items-center mb-2">
                                                                            <div className="d-flex gap-2 align-items-center">
                                                                                <img src={view.fan.photo} className="img-fluid rounded-circle" style={{ width: 26, height: 26 }} alt="profile-img" />
                                                                                <h6 className="text-capitalize mb-0 fw-bold text-black">{view.fan.display_name.length > 8 ? view.fan.display_name.substring(0, 6) + "..." : view.fan.display_name}</h6>
                                                                            </div>
                                                                            <h6 className="mb-0" style={{ fontSize: '0.9em' }}>{formatDateRelativePost(view.updated_at,t)}</h6>
                                                                        </div>
                                                                    </div>
                                                                ))
                                                            ) : (
                                                                <h6>No view for the moment</h6>
                                                            )}
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className="d-flex flex-column m-none me-5">
                                    <div className="d-flex align-item-center gap-3 m-3 me-2" >
                                        <a href={users.users.account_type === 'CREATOR' ? '/story/create' : '/become-creator'} className="text-decoration-none">
                                            <span className=" material-icons  rounded-circle  text-black md-26 p-2" style={{ backgroundColor: '#D8DADF' }}>add</span>
                                        </a>
                                        <div>
                                            <a href={`/message`} type="button" className="btn position-relative rounded-circle p-2 pb-0" style={{ backgroundColor: '#D8DADF' }}>
                                                <span className=" material-icons  text-black md-26 " >forum</span>
                                                <span className="position-absolute top-0 start-100 translate-middle badge rounded-circle fb-btn-color px-1">
                                                    10
                                                    <span className="visually-hidden">unread messages</span>
                                                </span>
                                            </a>
                                        </div>
                                        <div>
                                            <a href={`/notifications`} type="button" className="btn position-relative rounded-circle p-2 pb-0" style={{ backgroundColor: '#D8DADF' }}>
                                                <span className=" material-icons  text-black md-26 " >notifications</span>
                                                <span className="position-absolute top-0 start-100 translate-middle badge rounded-circle fb-btn-color px-1">
                                                    99+
                                                    <span className="visually-hidden">unread messages</span>
                                                </span>
                                            </a>
                                        </div>
                                        <div>
                                            <a href={`/`} className="text-white text-decoration-none rounded-circle bg-secondary p-2 material-icons md-26" onClick={handleClose}>close</a>
                                        </div>
                                    </div>
                                    {state.isVisible &&
                                        <div className="overflow-auto custom-scrollbar" style={{ maxHeight: 555 }}>
                                            <div className="p-2 d-flex flex-column gap-2">
                                                {state.isLoaded ? (
                                                    <Loader styleColor={true} />
                                                ) : state.ViewByStory?.length > 0 ? (
                                                    state.ViewByStory.map((view) => (
                                                        <div className="shadow-sm rounded-3 p-2 pb-0" style={{ backgroundColor: '#EDF2F6' }}>
                                                            <div className="d-flex justify-content-between align-items-center mb-2">
                                                                <div className="d-flex gap-2 align-items-center">
                                                                    <img src={view.fan.photo} className="img-fluid rounded-circle" style={{ width: 26, height: 26 }} alt="profile-img" />
                                                                    <h6 className="text-capitalize mb-0 fw-bold text-black">{view.fan.display_name.length > 11 ? view.fan.display_name.substring(0, 8) + "..." : view.fan.display_name}</h6>
                                                                </div>
                                                                <h6 className="mb-0" style={{ fontSize: '0.9em' }}>{formatDateRelativePost(view.updated_at,t)}</h6>
                                                            </div>
                                                        </div>
                                                    ))
                                                ) : (
                                                    <h6>No view for the moment</h6>
                                                )}
                                            </div>
                                        </div>
                                    }
                                    {state.isViewComment &&
                                        <div className="overflow-auto custom-scrollbar" style={{ maxHeight: 555 }}>
                                            <div className="p-2 d-flex flex-column">
                                                {state.isLoaded ? (
                                                    <Loader styleColor={true} />
                                                ) : state.comments?.length > 0 ? (
                                                    state.comments.map((comment) => (
                                                        <div className="shadow-sm rounded-3 p-2" style={{ backgroundColor: '#EDF2F6' }}>
                                                            <div className="d-flex justify-content-between align-items-center mb-2">
                                                                <div className="d-flex gap-2 align-items-center">
                                                                    <img src={comment.creator.photo} className="img-fluid rounded-circle" style={{ width: 26, height: 26 }} alt="profile-img" />
                                                                    <h6 className="text-capitalize mb-0 fw-bold text-black">{comment.creator.display_name}</h6>
                                                                </div>
                                                                <h6 className="mb-0">{formatDateRelativePost(comment.updated_at,t)}</h6>
                                                                {(id === users.users._id || users.users._id === comment.creator._id) &&
                                                                    <div className="dropdown me-2  p-0">
                                                                        <a href={`/`} className="text-white text-decoration-none material-icons md-26 " id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">more_horiz</a>
                                                                        <ul className="dropdown-menu fs-13 dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                                                                            <li>
                                                                                <button className="dropdown-item text-muted" onClick={() => handleDeleteComment(comment._id)}>
                                                                                    <span className="material-icons md-13 me-1">delete</span> {t('words.delete')}
                                                                                </button>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                }
                                                            </div>
                                                            <h6 className="mb-0 text-capitalize">{comment.content}</h6>
                                                        </div>
                                                    ))
                                                ) : (
                                                    <h6>No Comments for the moment</h6>
                                                )}
                                            </div>
                                        </div>
                                    }
                                </div>
                                {!checkIfSubscribeToCreator() &&
                                    <div className='web-none ms-4 mt-2'>
                                        <a href={`/`} className="text-white text-decoration-none rounded-circle bg-secondary p-1 material-icons md-26 " onClick={handleClose}>close</a>
                                    </div>
                                }
                            </div>
                        </div>
                    </main>

                    <div className={`modal fade ${isModalOpen ? 'show' : ''}`} id="reportPostModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true" >
                        <div className={`modal-dialog modal-dialog-centered ${isModalOpen ? 'show' : ''}`} >
                            <div className="modal-content rounded-4 p-4 border-0 bg-light">
                                <div className="modal-header d-flex align-items-center justify-content-start border-0 p-0 mb-3">
                                    <a href={`/`} className="text-muted text-decoration-none material-icons" data-bs-dismiss="modal" ref={linkRef}>arrow_back_ios_new</a>
                                    <div className="modal-title text-muted ms-3 d-flex align-items-center" id="staticBackdropLabel">
                                        <img src={state.storyData?.creator?.photo} className="img-fluid rounded-circle" width={40} alt="profile-img" />
                                        <div className="text-decoration-none d-flex flex-column">
                                            <h6 className="fw-bold mb-0 text-body mt-0">
                                                <span> {state.storyData?.creator?.display_name} </span>
                                                {state.storyData.creator.account_type ==='CREATOR' &&
                                                    <span className="ms-2 p-0 md-16 fw-bold ">{Images.verif}</span>
                                                }
                                            </h6>
                                            <small className="text-muted">@{state.storyData?.creator?.display_name}</small>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-body p-0 mb-3" >
                                    <h5 className="mb-4">Report this content</h5>
                                    <div className="form-floating mt-3">
                                        <textarea className={`${darkMode ? 'bg-dark border-secondary text-white' : 'border-0'} form-control rounded-5  shadow-sm`} placeholder={'Please enter a reason,' + users.users.display_name} id="floatingTextarea2" style={{ height: '100px' }} name="reportContent" value={state.reportContent} onChange={textInputChange}></textarea>
                                        <label htmlFor="floatingTextarea2" className="h6 text-muted mb-0">Please enter a reason, {users.users.display_name}</label>
                                    </div>
                                </div>
                                <div className="mt-2">
                                    <button className="btn fb-btn-color text-white btn-block rounded-pill fw-bold w-100" onClick={handleReportPost}>
                                        {state.isLoaded ? (
                                            <Loader />
                                        ) : (
                                            t('words.send')
                                        )}
                                    </button>
                                    {state.releaseErrors.length > 0 &&
                                        <div className="alert alert-danger alert-dismissible" role="alert">
                                            <div>{state.releaseErrors}</div>
                                            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <SendTipModal data={state.creatorData} id={id} /> */}
                    <ShareModal id={state.activeStory?.id} />
                </>
            }
        </AppContainer>
    )
}
export default StoryView;