import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { renderFormatteContent } from '../Utils';

function DescriptionParagraph({ bio, checkIfReleasePost, checkIfSubscribeToCreator, profile, postRef }) {
    const { t } = useTranslation();
    const darkMode = useSelector((state) => state.theme.darkMode);
    const [isExpanded, setIsExpanded] = useState(false);
    let truncatedBio = "";
    let truncatedLength = 0;
    if ((!checkIfReleasePost || !checkIfSubscribeToCreator) && !profile) {
        truncatedLength = 60;
    } else {
        truncatedLength = isExpanded ? bio?.length : (profile ? 150 : 150);
    }
    truncatedBio = bio?.slice(0, truncatedLength);

    if (bio?.length > truncatedLength) {
        truncatedBio = truncatedBio + '...'
    }

    React.useEffect(() => {
        if (isExpanded && postRef) {
            postRef.current.scrollIntoView({ behavior: "smooth" });
        }

        if (bio?.length > truncatedLength && !(!checkIfReleasePost || !checkIfSubscribeToCreator) && !profile && window.location.pathname.includes("post")) {
            setIsExpanded(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isExpanded, postRef]);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div className={`mb-0 fs-6 ${darkMode ? 'text-white bg-dark' : 'text-black'} pe-1`}>
            <p className='control-content-post'>{renderFormatteContent(truncatedBio)}</p>
            {(bio?.length > truncatedLength && !(!checkIfReleasePost || !checkIfSubscribeToCreator) && !profile) && (
                <button style={{ marginLeft: 0, paddingLeft: 0 }} type="button" className="btn btn-link text-decoration-none small pt-0 mt-0" onClick={toggleExpand}>
                    {t('navigation.viewMore')}
                </button>
            )}
            {(bio?.length > truncatedLength && profile && !isExpanded) && (
                <button style={{ marginLeft: 0, paddingLeft: 0 }} type="button" className="btn btn-link text-decoration-none small pt-0 mt-0" onClick={toggleExpand}>
                    {t('navigation.viewMore')}
                </button>
            )}
            {(profile && isExpanded &&
                (<button style={{ marginLeft: 0, paddingLeft: 0 }} type="button" className="btn btn-link text-decoration-none small pt-0 mt-0" onClick={toggleExpand}>
                    {t('navigation.viewLess')}
                </button>)
            )}
        </div>
    );
}

export default DescriptionParagraph;