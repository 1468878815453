import React, { createContext, useContext, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { createSocketStatus } from './TchatSocket';

const WebSocketContext = createContext();

export const WebSocketProvider = ({ children }) => {
    const [socket, setSocket] = useState(null);
    const [dataSocketG, setDataSocketG] = useState(null);
    const usersId = useSelector(state => state.users.users?._id)
    useEffect(() => {
        if (socket) {
            socket.close();
        }
        const newSocket = createSocketStatus(usersId);
        setSocket(newSocket);
        return () => {
            if (newSocket) {
                newSocket.close();
            }
        };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [usersId]);

    useEffect(() => {
        if (socket) {
            socket.onmessage = (event) => {
                const dataSocket = JSON.parse(event.data);
                if (dataSocket) {
                    setDataSocketG(dataSocket);
                }
            };
        }
        return () => {
            if (socket) {
                socket.onmessage = null;
            }
        };
    }, [socket]);

    return (
        <WebSocketContext.Provider value={dataSocketG}>
            {children}
        </WebSocketContext.Provider>
    );
};

export const useWebSocket = () => {
    return useContext(WebSocketContext);
};
