function getReleasePrice(post, userContinentCode) {
    let releasePrice;
    switch (userContinentCode.toUpperCase()) {
        case 'AF':
            releasePrice = post?.release_details?.african_fee;
            break;
        case 'AS':
            releasePrice = post?.release_details?.asian_fee;
            break;
        default:
            releasePrice = post?.release_details?.other_fee;
            break;
    }

    return releasePrice;
}
export default getReleasePrice;