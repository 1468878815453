import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { RiSearchLine } from 'react-icons/ri';
import { useTranslation } from "react-i18next";

import { AppContainer, AsideContainer, SuggestionContainer } from "../Containers";
import Notification from "./Notification";
import { useScreenSize, useSearch } from "../Utils";

const SearchResult = () => {
    const { t } = useTranslation();
    const screenSize = useScreenSize()
    const isMobile = screenSize === 'Extra Small' || screenSize === 'Small' || screenSize === 'Medium'
    const darkMode = useSelector((state) => state.theme.darkMode);
    const { searchQuery, setSearchQuery, searchResults, searchLoad } = useSearch("creators/search");
    const inputRef = useRef(null);

    return (
        <AppContainer>
            <main className={`col col-xl-6 order-xl-2 col-lg-12 order-lg-1 col-md-12 col-sm-12 col-12  py-4 border border-fw-light border-bottom-0 ${isMobile && 'pt-5'}`}>
                <div className="main-content">
                    {/* <div className="d-flex align-items-center mb-3 m-none">
                        <a href={`/`} className={`${darkMode ? 'text-white' : 'text-dark'} material-icons text-decoration-none m-none me-3`}>arrow_back</a>
                        <p className={`${darkMode ? 'text-white' : 'text-body'} ms-2 mb-0 fw-bold fs-6`}>
                            Search result
                        </p>
                    </div> */}
                    <div className={` web-none pt-2 ${isMobile && 'pt-5 fix-content'}`}>
                        <div className="w-100 px-4">
                            <form className={`${darkMode ? 'bg-dark' : 'bg-white'}  input-group mb-4 border border-fw-light overflow-hidden rounded-pill d-flex align-items-center `}>
                                <span className={`${darkMode ? 'bg-dark text-white' : 'bg-white'} input-group-text material-icons border-0  fan_color d-flex align-items-center `}><RiSearchLine /></span>
                                <input ref={inputRef} type="text" value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)} name="search" placeholder={t('navigation.findCreator')} className={`${darkMode && 'bg-dark text-white'} form-control border-0 fw-light ps-1`} />
                            </form>
                        </div>
                        <span className='fw-bold mb-2 ms-4 fs-5'> {t('navigation.searchTerms')}: {searchQuery.length > 0 ? searchQuery : `${t('navigation.emty')}`}</span>
                        <h6 className=" fw-bold pt-4 px-4 text-black fs-5 text-gradient">{t('navigation.whoToFollow')}</h6>
                        {searchQuery.length === 0 && <SuggestionContainer styleResp={'web-none mt-4 ms-4'} />}
                        {searchQuery.length > 0 && searchResults.length === 0 && !searchLoad ? (
                            <p className="text-center mt-2 mb-0">
                                <span className='fw-bold'>{t('navigation.searching')}...{searchQuery}</span>
                            </p>
                        ) : searchQuery.length > 0 && searchResults.length > 0 && searchLoad ? (
                            searchResults.map((user, index) => (
                                <Notification key={index} notification={true} data={{ _id: user._id, avatar: user, pseudo: user.pseudo, slug: user.display_name, account_verify: true }} />
                            ))
                        ) : searchQuery.length > 0 && searchResults.length === 0 && searchLoad === true ? (
                            <p className="text-center mt-2 mb-0">
                                <span className='fw-bold'>{searchQuery}</span> {t('navigation.changeUsername')}
                            </p>
                        ) : null}
                    </div>
                </div>
            </main>
            <AsideContainer />
        </AppContainer>
    )
}

export default SearchResult;


