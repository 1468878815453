import AsidePeople from "./AsidePeople";

const AsideContainer = () => {
    return (
        <div className="col col-xl-3 order-xl-3 col-lg-6 order-lg-3 col-md-6 col-sm-6 col-12 px-2">
            <AsidePeople />
        </div>
    )
}

export default AsideContainer;