const PaymentModeImage = ({shadowTop, margin, styleResp}) => {
    return (
        <div className={`${margin} py-3 ${styleResp}`} style={shadowTop}>
            <div className=" d-flex align-items-center justify-content-center mb-1 gap-2">
                <img className="rounded-1 pe-1" src={require("../assets/logo/visa.png")} alt="" style={{ width: 35, height: 27, boxShadow: "inset 0px 0px 5px 2px #EDF2F6" }} />
                <img className="p-1 bg-light rounded-1" src={require("../assets/logo/mast1.png")} alt="" style={{ width: 35, height: 27 }} />
                <img className="p-1 bg-light rounded-1" src={require("../assets/logo/maestro.png")} alt="" style={{ width: 35, height: 27 }} />
                <img className="p-1 bg-light rounded-1" src={require("../assets/logo/diners-club.png")} alt="" style={{ width: 35, height: 27 }} />
                <img className="p-1 bg-light rounded-1" src={require("../assets/logo/discover.png")} alt="" style={{ width: 35, height: 27 }} />
                <img className="p-1 bg-light rounded-1" src={require("../assets/logo/jcb-carte.png")} alt="" style={{ width: 35, height: 27 }} />
                <img className="p-1 bg-light rounded-1" src={require("../assets/logo/orangeMoney.png")} alt="" style={{ width: 35, height: 27 }} />
                <img className="p-1 bg-light rounded-1" src={require("../assets/logo/mobile-money-logo.png")} alt="" style={{ width: 35, height: 27 }} />
            </div>
            {/* <p style={{ fontSize: "0.8em", textAlign: "center", marginBottom: '0px' }}>We accept the following payment methods. Please choose the one that suits you best</p> */}
        </div>
    )
}

export default PaymentModeImage