import React from 'react'
import { useLocation } from 'react-router-dom';

const useIsBack = () => {
    const location = useLocation();
    const [isBack, setIsBack] = React.useState(false);

    React.useEffect(() => {
        setIsBack(sessionStorage[location.pathname + "_key"] === location.key);
        sessionStorage[location.pathname + "_key"] = location.key;
    }, [location]);

    return isBack;
}
export default useIsBack