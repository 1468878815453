import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

import { getBookmark, getCreatorFollows, getLikes, getReleasePost, useScreenSize, restore_numbers_in_filename } from '../../Utils';
import { RequestFanbusy } from '../../Services';
import { Loader, Post } from '../../Components';
import { AppContainer, AsideContainer } from '../../Containers';


const PostByIdView = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const darkMode = useSelector((state) => state.theme.darkMode);
    const { postId } = useParams();
    const users = useSelector(state => state.users);
    const params = useSelector(state => state.params);
    const baseLink = 'https://dyu0tgtvswtj8.cloudfront.net/fanbusy_'
    const [state, setState] = React.useState({
        post: {},
        follows: [],
        isLoading: true,
        creator: {}

    });
    const [listBookmark, setListBookmark] = React.useState([]);
    const [listLikes, setListLikes] = React.useState([]);
    const [listPostRelease, setListPostRelease] = React.useState([]);
    const screenSize = useScreenSize()
    const isMobile = screenSize === 'Extra Small' || screenSize === 'Small' || screenSize === 'Medium'

    React.useEffect(() => {
        let follows = getCreatorFollows(users.access_token);
        follows.then((value) => {
            setState((prev) => ({ ...prev, follows: value }));
        });
        let bookmarks = getBookmark(users.access_token);
        bookmarks.then((value) => {
            setListBookmark(value);
        });
        let likes = getLikes(users.access_token, 'POST');
        likes.then((value) => {
            setListLikes(value);
        });
        let releasePost = getReleasePost(users.access_token);
        releasePost.then((value) => {
            setListPostRelease(value);
        });
        const getPostById = async () => {
            let res = await RequestFanbusy(`posts/${postId}`, 'GET', '', users.access_token);
            if (res.status === 200) {
                const post = {
                    ...res.response.data,
                    illustrations: res.response.data.illustrations.map(illustration => {

                        if (illustration.link.startsWith('http')) {
                            return illustration;
                        } else {
                            return {
                                ...illustration,
                                link: `${baseLink}${restore_numbers_in_filename(illustration.link)}`
                            };
                        }
                    })
                };
                setState((prev) => ({ ...prev, post: post, isLoading: false }));
            }
        };

        getPostById();

    }, [params.refresh, users?.access_token, postId]);

    const redirectBack = () => {
        navigate('/')
    };

    return (
        <AppContainer>
            <main className={`col col-xl-6 order-xl-2 col-lg-12 order-lg-1 col-md-12 col-sm-12 col-12 py-2 border border-top-0 border-fw-light px-0 border-bottom-0 ${isMobile && 'pt-5 fixed-content pb-5'} `}>
                <div className={`main-content ${isMobile && 'pb-5'}`}>
                    <div style={{ height: "50px" }} className="d-flex align-items-center m-none border-bottom pb-2 fix-sidebar bg-white top-0 ">
                        <button onClick={redirectBack} className={`${darkMode ? 'text-white' : 'text-dark'} btn btn-link material-icons text-decoration-none m-none ms-3`}>arrow_back</button>
                        <p className={`${darkMode ? 'text-white' : 'text-body'} ms-2 mb-0 fw-bold fs-6 text-uppercase`}>
                            {t('navigation.post')}
                        </p>
                    </div>
                    <div className="pt-3 feeds">
                        {state.isLoading ?
                            <Loader styleColor={true} /> :
                            <Post post={state.post} creatorId={state.post?.creator._id} bookmarks={listBookmark} likes={listLikes} released={listPostRelease} follows={state.follows} setListPostRelease={setListPostRelease} />
                        }
                    </div>
                </div>
            </main>

            <AsideContainer />
        </AppContainer>
    );
};

export default PostByIdView;