import React from "react";
import { useDispatch } from "react-redux";

import { Images, Metrics } from "../Constants";
import { RequestFanbusy, TchatRequest } from "../Services";
import Loader from "./Loader";
import { useTranslation } from "react-i18next";
import CardProfile from "./CardProfile";
import { i18n } from "../Services";


const SignUpcomponent = ({ profile, closeModal, showLoginOrSign, profileLogOrSignRef, creator }) => {
    const [currentLanguage, setCurrentLanguage] = React.useState(
        localStorage.getItem('currentLanguage') || navigator.language || navigator.userLanguage
    );
    const dispatch = useDispatch()
    const { t } = useTranslation();
    const [state, setState] = React.useState({
        full_name: "",
        display_name: "",
        email: "",
        isValidEmail: true,
        password: "",
        confirm_password: "",
        isPasswordMatch: true,
        isValidPassword: true,
        submitData: false,
        submitError: false,
        submitErrorMessage: "",
        showPassword: false
    });

    const togglePasswordVisibility = () => {
        setState({ ...state, showPassword: !state.showPassword });
    }

    const textInputChange = (input) => {
        const target = input.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;

        if (name === "display_name") {
            setState({ ...state, display_name: value });
        }
        else if (name === "email") {
            setState({ ...state, email: value, isValidEmail: false });
            if (value.trim().match(Metrics.mailFormat)) {
                setState({ ...state, email: value, isValidEmail: true });
            }
        }
        else if (name === "password") {
            const isValidPassword = value.trim().length >= 8;
            const isPasswordMatch = value === state.confirm_password;
            setState({
                ...state,
                password: value,
                confirm_password: value,
                isValidPassword,
                isPasswordMatch,
            });
        }
        else if (name === "confirm_password") {
            const isPasswordMatch = value === state.password;
            setState({
                ...state,
                confirm_password: value,
                isPasswordMatch,
            });
        }
    }

    const createStatutByUser = async (creatorId) => {
        let res = await TchatRequest(`statut/${creatorId}`, 'POST', '', '');
        if (res.status === 201) {
        }
    }

    const getAccount = async (token) => {
        let res = await RequestFanbusy("accounts/self/creator/", 'GET', '', token);
        if (res.status === 200) {
            let action = {
                type: "ACCOUNT",
                value: res.response.data
            };
            dispatch(action);
            fetchGeoLocation(token);
        }
    };

    const fetchGeoLocation = async (token) => {
        let res = await RequestFanbusy("auth/locate-me", 'GET', '', token);
        if (res.status === 200) {
            let action = {
                type: "LOCALIZATION",
                value: res.response.data
            };
            dispatch(action);
            getAllCurrencies(token);
        }
    };

    const getAllCurrencies = async (token) => {
        let res = await RequestFanbusy(`currencies/?limit=${1000}&page=${1}`, 'GET', '', token);
        if (res.status === 200) {
            let action = {
                type: "CURRENCIES",
                value: res.response.data
            };
            dispatch(action);

            // if (!profile) {
            //     navigate(Routes.HOME);
            // } else {
            //     if (profileLogOrSignRef) {
            //         profileLogOrSignRef.current.click()
            //     }
            //     setState({ ...state, isloading: false });
            // }

            // (profileLogOrSignRef) {
            //     profileLogOrSignRef.current.click();
            // }
            // closeModal();
        }
    };

    const createAccount = async (event) => {
        event.preventDefault();
        setState({ ...state, isloading: true });

        if (state.display_name.length === 0 || state.email.length === 0 || state.password.length === 0) {
            setState({ ...state, submitError: true, submitErrorMessage: `${t('views.auth.theFieldCannotBeEmpty')}` });
            return;
        }
        else if (!state.isValidPassword) {
            setState({ ...state, submitError: true, submitErrorMessage: `${t('views.auth.thePasswordMustBeLeast8Characters')}` });
            return;
        }

        let form = {
            full_name: state.full_name,
            display_name: state.display_name,
            email: state.email,
            password: state.password,
            passwordConfirm: state.password,
            callback_url: window.location.href,
        };
        let response = await RequestFanbusy("auth/register/", "POST", form, "");

        if (response.status === 201) {
            let action = {
                type: "LOGIN",
                value: {
                    users: response.response.data.creator,
                    access_token: response.response.data.access_token
                },
            };
            dispatch(action);
            createStatutByUser(response.response.data.creator._id);
            getAccount(response.response.data.access_token);
            closeModal();

            // navigate(Routes.HOME);
            // window.location.href = "/auth/verifymail";
            // window.location.href = "/auth/verifymail";
            // await createStatutByUser(response.response.data.creator._id);

            // if (profileLogOrSignRef) {
            //     profileLogOrSignRef.current.click();
            // }
            closeModal();
        }
        else if (response.status === 400) {
            setState({ ...state, submitError: true, submitErrorMessage: t('views.auth.badRequest') });
        }
        else if (response.status === 409 && response.response.description === "Account already exist") {
            setState({ ...state, submitError: true, submitErrorMessage: t('views.auth.accountAlreadyExist'), isLoading: false });
        }
        else if (response.status === 409 && response.response.description === "Pseudo unavailable") {
            setState({ ...state, submitError: true, submitErrorMessage: t('views.auth.pseudoUnavailable'), isloading: false });
        }

        setState({ ...state, isLoading: false });
    }

    React.useEffect(() => {
        changeLanguage(currentLanguage);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentLanguage]);

    const changeLanguage = (language) => {
        let updateLanguage = language === 'fr-FR' || language === 'fr' ? 'fr' : 'en';
        i18n.changeLanguage(updateLanguage);

        let action = {
            type: "LANGUAGE",
            value: {
                language: updateLanguage,
                languageName: updateLanguage === 'fr' ? t('words.french') : t('words.english')
            }
        };
        dispatch(action);
        setCurrentLanguage(updateLanguage);
        localStorage.setItem('currentLanguage', updateLanguage);
    };



    return (
        <main className={` ${profile ? '' : 'col col-xl-6 order-xl-2 col-lg-12 order-lg-1 col-md-12 col-sm-12 col-12'}`}>
            <div className="main-content">
                <div className={`${profile ? 'mt-0' : 'mt-5 '}`}>
                    <header className={`profile d-flex align-items-center ${profile ? "" : "ml-25"}`}>
                        <img style={{ width: "150px", height: "30px", objectFit: "contain" }} src={Images.logov2} alt="Logo" className="web-none" />
                        <img style={{ width: "250px", height: "70px", objectFit: "contain" }} src={Images.logov2} alt="Logo" className="m-none" />
                    </header>
                    <div>
                        <p className="mb-0 text-dark m-none d-flex align-items-center">
                            <label className={`me-3 ${!profile ? 'fs-2' : 'fs-5'}`}>{!profile && t('views.auth.aWorlOfInfinite')}</label>
                            {/* {!profile && <img className="ms-5" src={require("../assets/img/2.png")} alt="" style={{ maxHeight: 105 }} />} */}
                        </p>
                        <p className="mb-0 text-dark web-none">
                            <span className={`${!profile ? 'fs-4' : 'fs-6'}`}>{!profile && t('views.auth.aWorlOfInfinite')}</span>
                            {creator && <CardProfile fan={false} creator={creator} profile={profile} />}
                        </p>
                    </div>
                </div>
                <div className="feeds">
                    <div className={`bg-white py-2  feed-item  mb-3 faq-page mb-sm-none pb-sm-none `} >
                        <div className="mb-1">
                            <h5 className=" text-body fs-6 mb-0 text-gradient ">
                                {t('views.auth.signUp')}
                            </h5>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-lg-12">
                                {state.submitError &&
                                    <div className="alert alert-danger">
                                        {state.submitErrorMessage}
                                    </div>
                                }
                                <form>
                                    <div className="form-floating mb-3 d-flex align-items-end">
                                        <input type="text" className="form-control rounded-5" id="display_name" name="display_name" value={state.display_name} onChange={textInputChange} placeholder={t('views.auth.name')} />
                                        <label htmlFor="display_name">{t('views.auth.name')}</label>
                                    </div>
                                    <div className="form-floating d-flex align-items-center">
                                        <input type="email" className={`form-control rounded-5 ${!state.isValidEmail ? "border border-danger" : ""}`}
                                            id="email" name="email" value={state.email} onChange={textInputChange} placeholder={t('views.auth.email')} />
                                        <label htmlFor="email" className={!state.isValidEmail ? "text-danger" : ""} >
                                            {t('views.auth.email')}
                                        </label>
                                    </div>
                                    <small className={`d-flex ps-1 text-danger ${state.isValidEmail ? "d-none" : ""}`} >
                                        {t('views.auth.enterValidEmailAddress')}
                                    </small>
                                    <div className="form-floating my-3 ">
                                        <div className={`form-floating border form-floating-focus-within rounded-5 d-flex align-items-center ${!state.isValidPassword ? "border border-danger" : ""}`}>
                                            <input type={state.showPassword ? "text" : "password"} className="form-control rounded-5 border-0"
                                                id="password" name="password" value={state.password} onChange={textInputChange} placeholder={t('views.auth.password')} />
                                            <label htmlFor="password" className={!state.isValidPassword ? "text-danger" : ""}>
                                                {t('views.auth.password')}
                                            </label>
                                            <span style={{ cursor: "pointer" }} className="me-2 cursor-pointed" onClick={togglePasswordVisibility}>
                                                {state.showPassword ? (<i className="material-icons fs-5">visibility_off</i>)
                                                    : (<i className="material-icons fs-5">remove_red_eye</i>)}
                                            </span>
                                        </div>
                                    </div>
                                    <small className={`d-flex ps-1 text-danger ${state.isValidPassword ? "d-none" : ""}`}>
                                        {t('views.auth.passwordMustBe')}
                                    </small>
                                    <div className="d-grid mt-3">
                                        {<button onClick={createAccount} className="btn fb-btn-color text-white rounded-pill w-100 text-decoration-none py-2 fw-bold text-uppercase m-0 gradient-button"> {state.isloading ? <Loader /> : t('views.auth.signUp')}  </button>}
                                    </div>
                                </form>
                            </div>
                            <div className="mt-3">
                                <p className="mb-sm-none">
                                    {t('views.auth.account_exist')}{" "}
                                    {!profile ?
                                        <a href={`/auth/login`} className="fb-color  text-decoration-none text-bold mb-sm-none text-gradient">
                                            {t('views.auth.login')}
                                        </a>
                                        :
                                        <span className="border-0 bg-transparent fb-color text-bold mb-sm-none text-gradient " style={{ cursor: "pointer" }} onClick={() => showLoginOrSign(true)}>{t('views.auth.login')}</span>
                                    }
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}
export default SignUpcomponent;