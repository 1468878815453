import React from 'react'

const EditPostModal = ({ postId, t, title, releaseErrors, showReleaseError, editContent, setShowReleaseError, handleEditPost, handleEditChange }) => {
    return (
        <div class="modal" tabindex="-1" id={`editpost${postId}`}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content rounded-4 p-0 shadow-sm border-0 bg-white">
                    <div className="modal-header py-2">
                        <h5>{title}</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">

                        <textarea className="form-control rounded-4 border-0 shadow-sm mb-2 h-100 " autoFocus={true} value={editContent} rows={4} name="postContent" onChange={handleEditChange}></textarea>

                        {(releaseErrors?.length > 0 && showReleaseError) &&
                            <div className="alert alert-danger alert-dismissible" role="alert">
                                <div>{releaseErrors}</div>
                                <button type="button" className="btn-close" onClick={() => setShowReleaseError(false)} aria-label="Close"></button>
                            </div>
                        }

                    </div>
                    <div className="modal-footer">
                        <button type="button" class="btn btn-secondary rounded-pill" data-bs-dismiss="modal">{t('words.close')}</button>
                        <button type="button" class="btn gradient-button text-white rounded-pill " data-bs-dismiss="modal" onClick={handleEditPost}>{t('words.edit')}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default EditPostModal