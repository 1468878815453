import React, { useState } from "react";
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

//App
import { AppContainer, AsideContainer } from "../../Containers";
import { Avatar, CheckPremiumComponent, Loader, Alert, CheckChat } from "../../Components";
import { RequestFanbusy } from "../../Services";
import { Images, Metrics, Routes } from "../../Constants";
import { convertCurrencyBalance, useScreenSize } from "../../Utils";


export const SubcribeCreatorView = () => {
	const { t } = useTranslation();
	const darkMode = useSelector((state) => state.theme.darkMode);
	const users = useSelector((state) => state.users);
	const params = useSelector((state) => state.params);
	const token = useSelector((state) => state.users.access_token);
	const [isLOading, setIsLoding] = useState(false);
	const [selectedImage, setSelectedImage] = useState(null);
	const [previewImage, setPreviewImage] = useState(null);
	const [preview, setPreview] = useState(null);
	const [selectedProfile, setSelectedProfile] = useState(null);
	const screenSize = useScreenSize();
	const [refreshKey, setRefreshKey] = React.useState(0);
	const isMobile = screenSize === 'Extra Small' || screenSize === 'Small' || screenSize === 'Medium';

	const [state, setState] = React.useState({
		pseudo: users.users.pseudo,
		full_name: users.users.full_name,
		phone_number: users.users.phone_number,
		display_name: users.users.display_name,
		localisation: users.users.localisation,
		bio: users.users.bio,
		web_Site: users.users.web_Site,
		is_free_account: users.users.is_free_account,
		subscription_fee: 0,
		subscription_fee_asian: 0,
		subscription_fee_world: 0,
		active_tchat: users.users.tchat_fee > 0,
		tchat_fee: 0,
		photo: users.users.photo,
		banner: users.users.banner,
		country_code: users.users.country_code,
		isValidLink: true,
		isValidNumber: true,
		social_link: users.users.social_link,
	});

	const [plans, setPlans] = useState([]);



	const [alert, setAlert] = React.useState({
		isActive: false,
		type: '',
		message: ''
	});

	const [check, setCheck] = useState({
		definedChatPrice: users.users.tchat_fee > 0,
		definedSubscriptionPrice: users.users.subscription_fee > 0
	});
	const [amount, setAmount] = React.useState({
		maxAmount: 0,
		minAmount: 0,
	})

	const handlePlanSave = (newPlans) => {
		setPlans(newPlans);
	};

	const handlePlanDelete = (index) => {
		setPlans((prevPlans) => prevPlans.filter((_, i) => i !== index));
	};



	React.useEffect(() => {
		let maxAmountPost = convertCurrencyBalance(params.currency, params.currencies, 200, 'GET')
		let minAmountpost = convertCurrencyBalance(params.currency, params.currencies, 1, 'GET')
		setAmount({ ...amount, maxAmount: maxAmountPost, minAmount: minAmountpost });

	}, [params.currencies, params.currency, amount]);

	const handleImageChange = (event) => {
		const imageFile = event.target.files[0];
		setSelectedImage(imageFile);
		const reader = new FileReader();
		reader.onload = () => {
			setPreviewImage(reader.result);
		};
		reader.readAsDataURL(imageFile);
	};

	const handleChangeProfile = (event) => {
		const imageFile = event.target.files[0];
		setSelectedProfile(imageFile);
		const reader = new FileReader();
		reader.onload = () => {
			setPreview(reader.result);
		};
		reader.readAsDataURL(imageFile);
	};

	const handleChangePhoneNumber = (value, meta) => {
		setState(prevState => ({ ...prevState, phone_number: value, country_code: meta.country.iso2 }));
	};


	const textInputchange = (e) => {
		const { name, value } = e.target;
		switch (name) {
			case "full_name":
				setState({ ...state, full_name: value });
				break;
			case "pseudo":
				setState({ ...state, pseudo: value });
				break;
			case "social_link":
				setState({ ...state, social_link: value });
				break;
			case "display_name":
				setState({ ...state, display_name: value });
				break;
			case "phone_number":
				const isValidNumber = value !== "" && Metrics.phoneRegex.test(value);
				setState({ ...state, phone_number: value, isValidNumber: isValidNumber });
				break;
			case "bio":
				setState({ ...state, bio: value });
				break;
			case "web_Site":
				const isValidLink = value.trim().match(Metrics.urlRegex);
				setState({ ...state, web_Site: value, isValidLink: isValidLink });
				break;
			case "isFreeAccount":
				if (e.target.checked) {
					setCheck({ ...check, definedSubscriptionPrice: e.target.checked });
					setState({ ...state, is_free_account: false });
				} else {
					setCheck({ ...check, definedSubscriptionPrice: false });
					setState({ ...state, subscription_fee: 0, is_free_account: true });
				}
				break;
			case "subscription_fee":
				if (value === "") {
					setState((prevState) => ({ ...prevState, subscription_fee: 0 }));
				} else {

					setState((prevState) => ({ ...prevState, subscription_fee: value }));
				}
				break;
			case "activeTchat":
				if (e.target.checked) {
					setCheck({ ...check, definedChatPrice: e.target.checked });
				} else {
					setCheck({ ...check, definedChatPrice: false });
					setState({ ...state, tchat_fee: 0 });
				}
				break;
			case "tchat_fee":
				if (value === "") {
					setState((prevState) => ({ ...prevState, tchat_fee: 0, }));
				} else {

					setState((prevState) => ({ ...prevState, tchat_fee: value }));
				}
				break;
			case "subscription_fee_asian":
				setState({ ...state, subscription_fee_asian: value });
				break;
			case "subscription_fee_world":
				setState({ ...state, subscription_fee_world: value });
				break;
			default:
				break;
		}
	};


	async function updateSubscriptionDetail() {
		const reductions = {
			"3 months": "0",
			"6 months": "0",
			"12 months": "0"
		};

		const flattenedPlans = plans.flatMap(plan => Array.isArray(plan) ? plan : [plan]);

		flattenedPlans.forEach(plan => {
			if (reductions.hasOwnProperty(plan.duration)) {
				reductions[plan.duration] = plan.discount.replace('%', '');
			}
		});

		const quarterly_reduction = reductions["3 months"];
		const half_yearly_reduction = reductions["6 months"];
		const yearly_reduction = reductions["12 months"];
		const amount = parseFloat(state.subscription_fee)
		let form = {
			african_fee: amount,
			asian_fee: amount,
			other_fee: amount
		}
		let response = await RequestFanbusy(`creators/subscription/details?currency=${params.currency}&quarterly_reduction=${quarterly_reduction}&half_yearly_reduction=${half_yearly_reduction}&yearly_reduction=${yearly_reduction}`, "PUT", form, token);
		if (response.status === 200) {
			setIsLoding(false)
		} else if (response.response.status_code === 400 && response.response.description === "This pseudo is no longer available") {
			setAlert({ isActive: true, message: t('views.become.thisPseudoIsNoLongerAvailable'), type: "warning" });
		}
	}

	const validatePhoneNumber = (phoneNumber) => {
		return Metrics.phoneRegex.test(phoneNumber);
	};

	const submitForm = async (event) => {
		event.preventDefault();
		setIsLoding(true)

		if (!validatePhoneNumber(state.phone_number)) {
			setAlert({ isActive: true, message: t('views.become.validPhoneNumber'), type: "warning" });
			setIsLoding(false)
			return
		}

		if (check.definedChatPrice && convertCurrencyBalance(params.currency, params.currencies, users.users?.tchat_fee, 'GET') !== state.tchat_fee && (state.tchat_fee > parseFloat(amount.maxAmount.replace(/\s/g, '')) || state.tchat_fee < parseFloat(amount.minAmount.replace(/\s/g, '')))) {
			setAlert({ isActive: true, message: t('views.editProfile.amountBetweenAnd') + ' ' + amount.minAmount + ' ' + t('words.and') + ' ' + amount.maxAmount, type: 'warning' })
			setIsLoding(false)
			setRefreshKey((key) => key + 1)
			return
		}

		if (check.definedSubscriptionPrice && convertCurrencyBalance(params.currency, params.currencies, users.users?.subscription_fee, 'GET') !== state.subscription_fee && (state.subscription_fee > parseFloat(amount.maxAmount.replace(/\s/g, '')) || state.subscription_fee < parseFloat(amount.minAmount.replace(/\s/g, '')))) {
			setAlert({ isActive: true, message: t('views.editProfile.amountBetweenAnd') + ' ' + amount.minAmount + ' ' + t('words.and') + ' ' + amount.maxAmount, type: 'warning' })
			setIsLoding(false)
			setRefreshKey((key) => key + 1)
			return
		}

		if (state.social_link.length === 0) {
			setIsLoding(false)
			setAlert({ isActive: true, message: t('views.become.socialLink'), type: "warning" });
			setRefreshKey((key) => key + 1)
			return
		}
		if (selectedProfile === undefined || selectedProfile === null) {
			setIsLoding(false)
			setAlert({ isActive: true, message: t('views.become.profilePhoto'), type: "warning" });
			setRefreshKey((key) => key + 1)
			return
		}
		if (selectedImage === undefined || selectedImage === null) {
			setIsLoding(false)
			setAlert({ isActive: true, message: t('views.become.banner'), type: "warning" });
			setRefreshKey((key) => key + 1)
			return
		}

		if (isNaN(state.tchat_fee) || isNaN(state.subscription_fee)) {
			setIsLoding(false)
			setAlert({ isActive: true, message: t('views.become.tchatFeeOrSubscriptionFee'), type: "warning" });
		}
		else if (check.definedChatPrice && state.tchat_fee === 0) {
			setIsLoding(false)
			setAlert({ isActive: true, message: t('views.become.pleaseEnterAChatFee'), type: "warning" });
			setRefreshKey((key) => key + 1)
		}
		else if (check.definedSubscriptionPrice && state.subscription_fee === 0) {
			setIsLoding(false)
			setAlert({ isActive: true, message: t('views.become.pleaseEnterASubscriptionFee'), type: "warning" });
			setRefreshKey((key) => key + 1)
		}
		else {
			try {
				let bannerLink = "";
				let profilLink = "";

				if (selectedImage instanceof File) {
					const formBanner = new FormData();
					formBanner.append("fileobject", selectedImage);
					const bannerResponse = await RequestFanbusy("uploads/PROFILE", "POST", formBanner, token)
					if (bannerResponse.response && bannerResponse.response.data) {
						bannerLink = bannerResponse.response.data.link;
					}
				}
				if (selectedProfile instanceof File) {
					const formProfil = new FormData();
					formProfil.append("fileobject", selectedProfile);
					const profilResponse = await RequestFanbusy("uploads/PROFILE", "POST", formProfil, token);
					if (profilResponse.response && profilResponse.response.data) {
						profilLink = profilResponse.response.data.link;
					}
				}
				const form = {
					full_name: state.full_name,
					display_name: users.users.display_name,
					phone_number: state.phone_number,
					bio: state.bio,
					localisation: state.localisation,
					web_site: state.web_Site,
					is_free_account: state.is_free_account,
					subscription_fee: state.subscription_fee,
					active_tchat: state.active_tchat,
					tchat_fee: state.tchat_fee,
					banner: state.banner,
					currency: params.currency,
					photo: state.photo,
					country_code: state.country_code,
					pseudo: state.pseudo,
					updated_at: users.users.updated_at,
					social_link: state.social_link,
				};

				if ((bannerLink.length > 0)) {
					form.banner = bannerLink;
				}
				if ((profilLink.length > 0)) {
					form.photo = profilLink;
				}
				if (form.subscription_fee === "") {
					form.subscription_fee = 0;
					form.is_free_account = true;
				}
				if (form.tchat_fee === "") {
					form.tchat_fee = 0;
					form.active_tchat = false;
				}

				let response = await RequestFanbusy("creators/request-creator", "PUT", form, token);
				if (response.status === 200) {
					updateSubscriptionDetail();
					setIsLoding(false)
					window.location.href = ` ${Routes.VERIFICATION} `
				} else if (response.response.status_code === 400 && response.response.description === "This pseudo is no longer available") {
					setAlert({ isActive: true, message: t('views.become.thisPseudoIsNoLongerAvailable'), type: "warning" });
				}

				setIsLoding(false)

			} catch (error) {
				console.error("Erreur lors des appels API:", error);
			}
			setIsLoding(false)
		}
	};

	const isDisabled = () => {
		const {
			pseudo,
			full_name,
			display_name,
			bio,
			social_link,
		} = state;

		return (
			pseudo === '' ||
			full_name === '' ||
			display_name === '' ||
			bio === "" ||
			social_link === ""
		)
	};

	function handlePrevent(e) {
		e.preventDefault();
	}
	return (
		<>
			<AppContainer>
				<main key={refreshKey} style={{ marginBottom: isMobile ? '20px' : '' }} className={`col col-xl-6 order-xl-2 col-lg-12 order-lg-1 col-md-12 col-sm-12 col-12 border border-fw-light border-bottom-0 px-0 ${isMobile && 'fixed-content pb-5'}  `}>
					<div className="main-content ">
						<div className={`${darkMode ? 'bg-dark' : 'bg-white'} border-bottom profile position-relative pb-2  border-bottom`} style={{ position: 'relative' }}>
							{previewImage ? (
								<>
									<div style={{ backgroundImage: `url(${previewImage})`, height: "200px", backgroundSize: "cover", }}>
										<div style={{ backgroundColor: "rgba(0, 0, 0, .10)", height: "200px", }}><div className="d-flex align-items-center  ">
											<a href={`/`}
												className={`text-white material-icons text-decoration-none  me-3`}>
												arrow_back
											</a>
											<p className={`text-white ms-2 mb-0 fw-bold fs-6`}>
												{t('views.home.become_Creator')}
											</p>
										</div></div>
									</div>
								</>
							) : (
								<>
									<div style={{ backgroundImage: `url(${users.users.banner.length === 0 ? Images.background : users.users.banner})`, height: "200px", backgroundSize: "cover", }}>
										<div style={{ backgroundColor: "rgba(0, 0, 0, .10)", height: "200px", }} > <div className="d-flex align-items-center ">
											<a href={`/`}
												className={`text-white material-icons text-decoration-none me-0`}>
												arrow_back
											</a>
											<p className={`text-white ms-2 mb-0 fw-bold fs-6 text-uppercase`}>
												{t('views.home.become_Creator')}
											</p>
										</div></div>
									</div>
								</>
							)}
							<div className="d-flex align-items-center ps-4 pt-1">
								<div style={{ marginTop: -50 }}>
									<span style={{ marginLeft: "60px", marginTop: "55px", }} className=" material-icons md-24 position-absolute top-10 end-80 fb-color ">
										<input type="file" id="fileobject" accept="image/*" onChange={handleChangeProfile} className="position-absolute w-100 h-100 " style={{ top: 0, left: 0, opacity: 0, cursor: "pointer", }} />
										photo_camera
									</span>
									{preview ? (
										<>
											<img src={preview} size={60} className="img-fluid rounded-circle border border-2 border-white" alt="profile-img" style={{ height: "85px", width: "85px" }} />
										</>
									) : <Avatar user={users.users} width={85} height={85} fontSize="fs-2" />}
								</div>
							</div>
							<div style={{ position: "absolute", top: 5, right: 5 }}>
								<span className="material-icons md-24 me-1 fb-color  " style={{ position: "relative" }} >
									photo_camera
									<input type="file" id="fileobject" accept="image/*" onChange={handleImageChange} className="position-absolute w-100 h-100" style={{ top: 0, left: 0, opacity: 0, cursor: "pointer" }} />
								</span>
							</div>
						</div>
						<div className={`${darkMode && 'bg-dark'} row justify-content-center mt-2 px-2`}>
							<div className="col-lg-12 pb-4">
								<p className="text-danger py-0">{t('navigation.allField')}</p>
								<form onSubmit={handlePrevent}>
									<div className="form-floating mb-3">
										<input className={`${darkMode && 'bg-dark text-white border-secondary'} form-control rounded-5 `} name="pseudo" type="text" value={state.pseudo} onChange={textInputchange} placeholder="" data-sb-validations="required" />
										<label className="text-uppercase" htmlFor="name">{t('views.editProfile.username')}<span className="text-danger">*</span ></label>
										<div className="invalid-feedback" data-sb-feedback="name:required"></div>
									</div>
									<div className="form-floating mb-3">
										<input className={`${darkMode && 'bg-dark text-white border-secondary'} form-control rounded-5 `} name="social_link" type="text" value={state.social_link} onChange={textInputchange} placeholder="" data-sb-validations="required" />
										<label className="text-uppercase" htmlFor="name">{t('views.become.socialLink')}<span className="text-danger">*</span > </label>
										<div className="invalid-feedback" data-sb-feedback="name:required"></div>
									</div>
									<div className="form-floating mb-3">
										<input className={`${darkMode && 'bg-dark text-white border-secondary'} form-control rounded-5 `} name="full_name" type="text" value={state.full_name} onChange={textInputchange} placeholder="" data-sb-validations="required" />
										<label className="text-uppercase" htmlFor="name">{t('views.editProfile.fullname')}<span className="text-danger">*</span ></label>
										<div className="invalid-feedback" data-sb-feedback="name:required"></div>
									</div>
									<div className="form-floating mb-3">
										<input className={`${darkMode && 'bg-dark text-white border-secondary'} form-control rounded-5`} name="display_name" type="text" value={state.display_name} onChange={textInputchange} placeholder="" data-sb-validations="required" />
										<label className="text-uppercase" htmlFor="name">{t('views.editProfile.displayName')}<span className="text-danger">*</span ></label>
										<div className="invalid-feedback" data-sb-feedback="name:required"></div>
									</div>
									<div className="form-floating mb-3">
									</div>

									<PhoneInput
										inputClassName="w-100 "
										defaultCountry="cm"
										value={state.phone_number}
										onChange={handleChangePhoneNumber}
									/>



									<p className="d-flex justify-content-center"> <small className={`d-flex ps-4 text-danger ${state.isValidNumber ? "d-none" : ""}`} >{t('views.editProfile.enterAValidInput')}  </small></p>
									<div className="form-floating my-3">
										<textarea className={`${darkMode && 'bg-dark text-white border-secondary'} form-control rounded-5`} id="bio" type="text" name="bio" value={state.bio} onChange={textInputchange} placeholder={`${t('views.editProfile. enterYourBiography')}`} style={{ height: "10rem" }} data-sb-validations="required" >
										</textarea>
										{state.bio.length === 0 &&
											<label htmlFor="message">{t('views.editProfile.bioDescription')}</label>
										}

										<div className="invalid-feedback" data-sb-feedback="message:required">{t('views.editProfile.aMessageRequired')}</div>
									</div>

									<div className={`${darkMode ? 'bg-dark text-white' : 'bg-white'} p-4 feed-item rounded-4  faq-page`}>
										<CheckPremiumComponent
											params={params}
											definedSubscriptionPrice={check.definedSubscriptionPrice}
											subscriptionFee={state.subscription_fee}
											textInputchange={textInputchange}
											subscriptionFeeInWorld={state.subscription_fee_world}
											subscriptionFeeInAsian={state.subscription_fee_asian}
											onSavePlans={handlePlanSave}
											isEditingProfile={false}
											onDeletePlan={handlePlanDelete}
										/>
										<CheckChat
											params={params}
											definedChatPrice={check.definedChatPrice}
											tchatFee={state.tchat_fee}
											currentTchatFee={users.users.tchat_fee}
											textInputchange={textInputchange}
										/>
									</div>
									{alert.message.length > 0 &&
										<Alert type={alert.type} msg={alert.message} />
									}
									<div className="d-grid">
										<button disabled={isDisabled()} onClick={submitForm} className={`btn gradient-button text-white rounded-pill w-100 text-decoration-none py-2 fw-bold text-uppercase m-0 `}>
											{isLOading ? <Loader /> : t('words.next')}
										</button>
									</div>

								</form>
							</div>
						</div>
					</div>
				</main>
				<AsideContainer />
			</AppContainer>
		</>
	);
};



