//React component
import React from 'react'
import { useSelector } from 'react-redux'


//App component
import { Avatar, Loader } from '../../Components'
import { formatTimeToAmPm } from '../../Utils'
import { useTranslation } from 'react-i18next'


const PostComment = ({ post, checkIfReleasePost, checkIfSubscribeToCreator, creatorId, textInputChange, commentContent, isLoading, comments, isLoaded, handleDeleteComment, handleComment }) => {
    const users = useSelector(state => state.users)
    const { t } = useTranslation()


    return (
        <div className="d-flex flex-column mt-3 border-top p-3 pb-0">
            {!post.comment_disabled &&
                <div className="">
                    <div className="d-flex align-items-center">
                        <Avatar user={users.users} width={50} height={45} />
                        <div className="input-group d-flex align-items-center border gap-1 rounded-4 px-2 ms-2 py-1 w-100">
                            {!checkIfReleasePost(post._id) || !checkIfSubscribeToCreator(creatorId) ?
                                <h6 className="text-danger text-center mt-2 fw-bold">{!checkIfReleasePost(post._id) ? "Unblock post to comment" : "Subscribe to creator to comment"}</h6>
                                :
                                <>
                                    <textarea className={`form-control form-control-sm p-0 border-0 `} placeholder={t('views.home.your_comment')} name="commentContent" value={commentContent} onChange={textInputChange}></textarea>
                                    <button disabled={isLoaded} className={`btn border-0  text-decoration-none rounded-circle fw-bold text-white d-flex align-items-center justify-content-center p-2 ${commentContent === '' ? 'disabled bg-secondary opacity-75' : 'gradient-button'}`} onClick={() => handleComment()}>
                                        <span className="material-icons md-20 ">send</span>
                                    </button>
                                </>
                            }
                        </div>
                    </div>
                </div>
            }
            {(isLoading) ?
                <Loader styleColor={true} /> :
                <div className={`comments ${!post.comment_disabled ? 'mt-3' : ''} overflow-auto`} style={{ maxHeight: 315 }}>
                    {!post.comment_disabled ?
                        comments.length > 0 ?
                            comments.map((comment, index) => {
                                return (
                                    <div className="d-flex mb-2" key={index}>
                                        <Avatar user={comment.creator} width={35} height={35} />
                                        <div className="ms-2 small" style={{ maxWidth: 300 }}>
                                            <div className="bg-light rounded-3  p-2">
                                                <div className="d-flex align-item-center justify-content-between gap-3">
                                                    <span className="fw-bold mb-0 p-0 text-capitalize">
                                                        {comment.creator.display_name}
                                                    </span>
                                                    {users.users?._id === comment.creator._id &&
                                                        <div className="dropdown ">
                                                            <a href={`/`} className="text-muted text-decoration-none material-icons md-26 " id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">more_horiz</a>
                                                            <ul className="dropdown-menu fs-13 dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                                                                <li>
                                                                    <button className="dropdown-item text-muted" onClick={() => handleDeleteComment(comment._id)}>
                                                                        <span className="material-icons md-13 me-1">delete</span> {t('words.delete')}
                                                                    </button>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    }
                                                </div>
                                                <span className="text-muted w-100 text-break">{comment.content}</span>
                                            </div>
                                            <div className="d-flex align-items-center ms-2">
                                                <span className="small text-muted">{formatTimeToAmPm(comment.created_at, t)}</span>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                            :
                            <div className="rounded-2 d-flex justify-content-center align-items-center ">
                                <span className="material-icons md-20 me-2" style={{ color: '#AFB0B4' }}>chat_bubble_outline</span>
                                <h6 className="mb-2">{t('views.post.beFirstToComment')}.</h6>
                            </div>
                        :
                        <div className="rounded-3 p-4 d-flex justify-content-center align-items-center flex-column" style={{ boxShadow: "inset 0px 0px 5px 2px rgba(0, 0, 0, 0.1)" }}>
                            <span className="material-icons md-36 me-2" style={{ color: '#AFB0B4' }}>chat_bubble_outline</span>
                            <div className="text-center mt-2">
                                <h5 className="fw-bold" style={{ fontSize: '1.3em' }}>{t('views.post.commentsDisabled')} !</h5>
                                <h6 style={{ fontSize: '0.9em' }}>{t('views.post.theCreator')} {post.creator.display_name} {t('views.post.hasDisabledomments')}.</h6>
                            </div>
                        </div>
                    }
                </div>
            }
        </div>
    )
}

export default PostComment