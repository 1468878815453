import { countries } from 'countries-list';

function getCountriesWithPhoneCodesAndFlags() {
    const countryList = Object.entries(countries).map(([code, country]) => {
        const phoneCode = country.phone || '';
        const flag = country.emoji || '';
        return { code, name: country.name, phoneCode, flag };
    });
    return countryList;
}

const countriesWithPhoneCodesAndFlags = getCountriesWithPhoneCodesAndFlags();
const phoneCodeFlagList = countriesWithPhoneCodesAndFlags.map(country => ({
    phoneCode: country.phoneCode, flag: country.flag, code: country.code
}));

export { countriesWithPhoneCodesAndFlags, phoneCodeFlagList };
