import React from 'react';
import { useSelector } from 'react-redux';

import Avatar from './Avatar';
import { Images } from '../Constants';
import { shortenName } from '../Utils';

const Notification = ({ notification, data }) => {
    const darkMode = useSelector((state) => state.theme.darkMode);
    const users = useSelector((state) => state.users);
   
    const render = () => {
        if (notification) {
            return (
                <div style={{ backgroundImage: `url(${data.banner})`, backgroundSize: 'contain' }} className={` px-3 mb-2 pt-1  notifications ${darkMode ? 'bg-dark border-0 text-white' : 'text-dark '}`}>
                    <p className={`  d-flex justify-content-start  text-decoration-none  mb-0`}>
                        <Avatar user={data.avatar} width={35} height={35} fontSize="small" />
                        <div className='d-flex row ms-1 py-1 w-100 border-bottom'>
                            <a href={users.users.pseudo === data.pseudo ? '/profile' : `/${data.pseudo}`} className="mb-0 pe-3 d-flex align-items-center text-decoration-none text-dark notification-author">
                                <span style={{ fontSize: "15px" }} className='fw-bold'> {shortenName(data.pseudo, 10)} </span>
                                <span className='ms-1'> @{shortenName(data.slug, 15)} </span>
                                {data.account_type === 'CREATOR' &&
                                    <span className="ms-1  p-0 md-16 fw-bold ">{Images.verif} </span>
                                }
                            </a>
                            <div className='d-flex row'>
                                <div>{data.notification}</div>
                            </div>
                            {
                                window.location.pathname !== '/searchresult' &&
                                <div>
                                    <i className="material-icons  small">schedule</i>
                                    <span className='text-muted'>{data.date}</span>
                                </div>
                            }
                        </div>
                    </p>
                </div>
            )
        }
    }
    return (
        render()
    )
}
export default Notification;