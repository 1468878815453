import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { RequestFanbusy } from "../Services";
import Loader from "./Loader";


const ValidationModal = ({ id, pseudo, profile }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const users = useSelector((state) => state.users);
    const subscribeRef = useRef(null);
    const [subscribeError, setSubscribeError] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);

    const unsubscribeToCreator = async (creatorId) => {
        setIsLoaded(true)
        let form = {
            creator_id: creatorId
        }
        let res = await RequestFanbusy('souscriptions/unsubscribe', "PUT", form, users.access_token);
        if (res.status === 201) {
            if (subscribeRef.current) {
                subscribeRef.current.click();
            }
            setIsLoaded(false)
            profile && (window.location.href = (`/${pseudo}`))
            dispatch({ type: "REFRESH", value: 1 });
        }
        else {
            setIsLoaded(false)
            setSubscribeError(t('navigation.tryAgain'));
        }
    }

    return (
        <div className="modal fade" id={`validationModal${id}`} data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true" >
            <div className="modal-dialog modal-dialog-centered " >
                <div className="modal-content rounded-4 p-4  border-0 bg-white">
                    <div className="modal-header border-0 p-0 pb-3 mb-2">
                        <h4 className="fw-bold mb-0 fs-6 text-black text-uppercase">{t('navigation.subscriptions')}</h4>
                    </div>
                    <div className="modal-body p-0 d-flex justify-content-between flex-wrap w-100" >
                        <h6 className="mb-3 text-black">{t('views.profile.cancelYourSubscription')}</h6>
                        <div className="d-flex align-items-center justify-content-end gap-3 w-100">
                            <button className="btn btn-block rounded-pill p-0 px-3 fw-bold text-uppercase" data-bs-dismiss="modal" ref={subscribeRef} style={{ backgroundColor: "#F0F0F0" }}>{t('words.cancel')}</button>
                            <button onClick={() => unsubscribeToCreator(id)} className="btn btn-block fw-bold rounded-pill bg-danger p-0 px-3 text-white">
                                {isLoaded ? (
                                    <Loader />
                                ) : (
                                    `${t('views.profile.unsubscribe')}`
                                )}
                            </button>
                        </div>
                    </div>
                    {subscribeError.length > 0 &&
                        <div className="alert alert-danger alert-dismissible mt-1 mb-0 mx-2 px-3 py-2" role="alert">
                            <div>{subscribeError}</div>
                            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default ValidationModal;