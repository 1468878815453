import React from "react";
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";
import { Loader, NoContent, Notification } from '../../Components';
import { AppContainer, AsideContainer } from '../../Containers';
import { RequestFanbusy } from "../../Services";
import { formatDateRelativePost, useScreenSize } from "../../Utils";

const NotificationView = () => {
    const { t } = useTranslation();
    const darkMode = useSelector((state) => state.theme.darkMode);
    const users = useSelector((state) => state.users);
    const params = useSelector((state) => state.params);
    const screenSize = useScreenSize();
    const isMobile = (screenSize === 'Extra Small' || screenSize === 'Small' || screenSize === 'Medium') ? true : false;
    const [state, setState] = React.useState({
        notifications: [],
        notificationsList: [],
        notificationTag: 'all',
        isLoading: true
    });

    React.useEffect(() => {
        const getNotifications = async () => {
            let res = await RequestFanbusy("notifications/", 'GET', '', users.access_token);
            if (res.status === 200) {
                setState((prev) => ({
                    ...prev,
                    notifications: res.response.data,
                    notificationsList: res.response.data,
                    isLoading: false
                }));
            }
            else {
                setState((prev) => ({
                    ...prev,
                    isLoading: false
                }));
            }
        }
        getNotifications();
    }, [params.refresh, users.access_token]);

    const sliderItems = [
        {
            label: 'All',
            value: 'all'
        },
        {
            label: 'Fans',
            value: 'subscription_fans'
        },
        {
            label: 'Messages',
            value: 'messages'
        },
        {
            label: 'Subscriptions',
            value: 'subscription_fees'
        },
        {
            label: 'Comments',
            value: 'comments'
        },
        {
            label: 'Likes',
            value: 'like_post'
        }
    ];

    const displayNotificationMessage = (notification) => {
        let message = "";

        switch (notification.tags) {
            case "subscription_fans":
                message = t('notif.subscription_fans');
                break;
            case "messages":
                message = t('notif.messages');
                break;
            case "subscription_fees":
                message = t('notif.subscription_fees') + notification.message + t('notif.subscription_fees_to') + users.users.subscription_fee;
                break;
            case "comments":
                message = t('notif.comments');
                break;
            case "like_post":
                message = t('notif.like_post');
                break;
            default:
                break;
        }

        return message;
    }

    const selectNotificationTag = (tag) => {
        switch (tag) {
            case "all":
                setState((prev) => ({ ...prev, notificationTag: "all", notifications: state.notificationsList }));
                break;

            default:
                let notificationsList = state.notificationsList.filter(notification => notification.tags === tag);
                setState((prev) => ({ ...prev, notificationTag: tag, notifications: notificationsList }));
                break;
        }
    }

    return (
        <AppContainer>
            <main className={`col col-xl-6 order-xl-2 col-lg-12 order-lg-1 col-md-12 col-sm-12 col-12  border border-top-0  border-bottom-0  border-fw-light px-0 ${isMobile && 'pt-5 col col-xl-9'}`}>
                <div className="">
                    <div style={{ height: "50px" }} className="d-flex align-items-center m-none border-bottom pb-2 fix-sidebar bg-white top-0 ">
                        <a href={`/`} className={`${darkMode ? 'text-white' : 'text-dark'} material-icons text-decoration-none m-none ms-3`}>arrow_back</a>
                        <p className={`${darkMode ? 'text-white' : 'text-body'} ms-2 mb-0 fw-bold fs-6 text-uppercase`}>
                            Notifications
                        </p>
                    </div>
                    {state.isLoading ?
                        <Loader styleColor={true} marginTop={true} /> :
                        <>
                            <div style={{ display: "flex", flexWrap: "wrap" }} className=" pt-2">
                                {sliderItems.map((item) => (
                                    <div key={item.id} className="m-2 slider" role="presentation">
                                        <button onClick={() => selectNotificationTag(item.value)} className={`rounded-pill border-0 px-3 py-1 ${state.notificationTag === item.value ? "fb-btn-color fw-bold text-light" : "text-secondary"}`}>
                                            {item.label}
                                        </button>
                                    </div>
                                ))}
                            </div>
                            {state.notifications.length > 0 ?
                                <>
                                    {
                                        state.notifications.map((item) => (
                                            <Notification
                                                key={item.id}
                                                notification={true}
                                                data={{
                                                    _id: item.fan._id,
                                                    avatar: item.fan,
                                                    pseudo: item.fan.display_name,
                                                    slug: item.fan.pseudo,
                                                    account_verify: item.fan.verified,
                                                    notification: displayNotificationMessage(item),
                                                    date: `${formatDateRelativePost(item.created_at,t)}`
                                                }}
                                            />
                                        ))
                                    }
                                </> :
                                <NoContent message={`Vous n'avez pas de notifications`} />
                            }
                        </>
                    }
                </div>
            </main>
            <AsideContainer />
        </AppContainer>
    )
}

export default NotificationView;
