import React, { useState } from 'react'

const AccordionItem = ({ id, title, content }) => {
    const [isCollapsed, setIsCollapsed] = useState(true);

    const toggleAccordion = () => {
        setIsCollapsed((prev) => !prev);
    };

    return (
        <div className="accordion-item">
            <h3 className="accordion-header" id={`heading${id}`}>
                <button
                    className={`accordion-button ${isCollapsed ? "collapsed" : ""} `}
                    type="button"
                    onClick={toggleAccordion}
                    aria-expanded={!isCollapsed}
                    aria-controls={`collapse${id}`}
                    style={{ backgroundColor: "transparent", border: "0px", }}
                >
                    {title}
                </button>
            </h3>
            <div
                id={`collapse${id}`}
                className={`accordion-collapse collapse ${!isCollapsed ? "show" : ""}`}
                aria-labelledby={`heading${id}`}
                data-bs-parent="#accordionExample"
            >
                <div className="accordion-body">{content}</div>
            </div>
        </div>
    );
};

export default AccordionItem
