import React, { useState, useEffect, useRef } from "react";
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";

import { RequestFanbusy, TchatRequest } from "../../Services";
import { Loader, TransactionsModal } from "../../Components";
import { convertCurrency } from "../../Utils";



const TchatForm = ({ onRefreshMessages, socket, receiver, can_not_writeUsers, setCan_not_writeUsers, room, subscription }) => {
    const { t } = useTranslation();
    const users = useSelector(state => state.users);
    const params = useSelector(state => state.params);
    const [medias, setMedias] = useState([]);
    const contentInputRef = useRef(null)
    const transactionRef = useRef(null)
    const [isMobile, setIsMobile] = useState(false);
    const [showAlert, setShowAlert] = useState(can_not_writeUsers);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [rows, setRows] = useState(1);
    const [refreh, setRefreh] = useState(0);
    const maxRows = 5;
    const [state, setState] = useState({
        mediaType: '',
        tipMessage: "",
        content: "",
        is_lock: false,
        unlock_fee: 0,
        isLoaded: false,
        isLoading: false,
        load: false,
        transactionResponse: '',
        responseType: '',
        showAlert: false

    });
    const isDisabled = can_not_writeUsers || !subscription || state.isLoading || (state.content.trim().length === 0 && medias.length === 0);

    const [amount, setAmount] = React.useState({
        maxAmount: 0,
        minAmount: 0,
    });

    useEffect(() => {
        let maxAmountPost = convertCurrency(params.currency, params.currencies, 200, 'GET')
        let minAmountpost = convertCurrency(params.currency, params.currencies, 1, 'GET')
        setAmount({ ...amount, maxAmount: maxAmountPost, minAmount: minAmountpost })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const textInputChange = (input) => {
        const target = input.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        switch (name) {
            case 'isLock':
                if (value) {
                    setState({ ...state, is_lock: value });
                } else {
                    setState({ ...state, is_lock: value, unlock_fee: 0 });
                }
                break;
            case 'tipMessage':
                setState({ ...state, tipMessage: value });
                break;
            case 'content':
                setState({ ...state, content: value });
                break;
            case 'unlock_fee':
                setState({ ...state, unlock_fee: value });
                break;
            default:
                break;
        }
    }

    const handleFileChange = async ({ target }) => {
        const { files } = target;
        const mediasCopy = [...medias];
        for (let file of files) {
            const url = URL.createObjectURL(file);
            mediasCopy.push({ url, file });
        }
        setMedias(mediasCopy);
    }


    const convertAmount = (amountConvert, type) => {
        let convert = convertCurrency(params.currency, params.currencies, amountConvert, type);
        return parseFloat(convert);
    }

    const checkConvertAmount = (a) => {
        if (isNaN(a)) {
            return 0;
        } else {
            return a;
        }
    }



    const addContent = async (e) => {

        try {
            e.preventDefault();
            if (socket || socket.readyState === WebSocket.OPEN) {
                if (state.is_lock && (state.unlock_fee < parseFloat(amount.minAmount.replace(/\s/g, '')) || state.unlock_fee > parseFloat(amount.maxAmount.replace(/\s/g, '')))) {
                    setState(prev => ({ ...prev, showAlert: true }));
                }
                else {
                    setState(prev => ({ ...prev, isLoading: true }));
                    const message = {
                        sender_id: users.users._id,
                        content: state.content,
                        file: [],
                        is_lock: state.is_lock,
                        unlock_fee: checkConvertAmount(convertAmount(state.unlock_fee, "")),
                        reply_to: '',
                        he_read: false,
                        type: 'message',
                        email: receiver.email,
                        receiver_display_name: receiver.display_name,
                        sender_display_name: users.users.display_name,
                        lang: receiver.lang,
                        photo: users.users?.photo
                    };

                    const files = medias.map(media => media.file);
                    if (files.length > 0) {
                        const formData = new FormData();
                        files.forEach(file => formData.append('fileobject', file));
                        const uploadResponse = await RequestFanbusy('uploads/POST/multiple', 'POST', formData, users.access_token);
                        if (uploadResponse.status === 201) {
                            message.file = uploadResponse.response.data;
                        } else {
                            setState(prev => ({ ...prev, responseType: "Échec du téléchargement des fichiers." }));
                            return;
                        }
                    }

                    socket.send(JSON.stringify(message));
                    setRows(1)
                    setRefreh(refreh => refreh + 1);
                    setMedias([]);
                    setState(prev => ({ ...prev, content: "", unlock_fee: 0, isLoading: false, is_lock: false }));
                    onRefreshMessages();
                    contentInputRef.current.focus();
                }
            }
        } catch (error) {
            setState(prev => ({ ...prev, isLoading: false, responseType: error }));
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            if (state.content.trim().length > 0 || medias.length > 0) {
                addContent(event);
            }
            event.preventDefault();
        }
    };

    const handleDeleteFile = (index) => {
        const mediasCopy = [...medias].filter((_, i) => i !== index);
        setMedias(mediasCopy);
    }

    useEffect(() => {
        checkIsMobile();
        setShowAlert(can_not_writeUsers);
        window.addEventListener("resize", checkIsMobile);
        return () => window.removeEventListener("resize", checkIsMobile);
    }, [can_not_writeUsers]);

    function checkIsMobile() {
        setIsMobile(window.innerWidth <= 768);
    }

    const unlockRoomByUsers = async (e) => {
        e.preventDefault();
        setState((prevState) => ({ ...prevState, load: true }))
        if (users.account?.balance < receiver?.tchat_fee) {
            if (transactionRef) {
                transactionRef.current?.click();
            }
        }
        else {
            try {
                const res = await TchatRequest(`room/${receiver._id}/${users.users._id}`, "PUT", '', users.access_token);
                if (res.response.status_code === 200) {
                    setState((prev) => ({ ...prev, transactionResponse: "success" }));
                    setCan_not_writeUsers(res.response.data.is_lock);
                }
                else if (res.response.status_code === 400 && res.response.response_type === "Error transaction") {
                    setState((prev) => ({ ...prev, transactionResponse: 'insuffisant fund' }));
                }
                else if (res.response.status_code === 400 && res.response.response_type === "Bad Request") {
                    setState((prev) => ({ ...prev, transactionResponse: "You dont follow this creator" }));
                }
                else {
                    setState((prev) => ({ ...prev, transactionResponse: "An error has occurred please try again...." }));
                }
                setShowSuccessMessage(true);
                setTimeout(() => {
                    setShowSuccessMessage(false);
                }, 2000);
            } catch (error) {
                setState((prev) => ({ ...prev, transactionResponse: "Error unlocking room" }));
            }
        }
        setState((prevState) => ({ ...prevState, load: false }))
    }

    const handleDataReady = (data) => {
        setState((prev) => ({ ...prev, transactionResponse: data }));
    };

    const handleInput = (event) => {
        const textareaLineHeight = 24;
        const previousRows = event.target.rows;
        event.target.rows = 1;
        const currentRows = ~~(event.target.scrollHeight / textareaLineHeight);

        if (currentRows === previousRows) {
            event.target.rows = currentRows;
        }

        if (currentRows >= maxRows) {
            event.target.rows = maxRows;
            event.target.scrollTop = event.target.scrollHeight;
        }

        setRows(currentRows < maxRows ? currentRows : maxRows);
        textInputChange(event);
    };


    return (
        <>
            <form key={refreh} className={`${isMobile ? 'position-fixed ' : 'position-absolute'} bottom-0 bg-white border-top  w-100 `}>
                {!showSuccessMessage ?
                    (
                        (showAlert && subscription) && (
                            ((receiver.tchat_fee !== 0 && can_not_writeUsers) &&
                                <button onClick={(e) => unlockRoomByUsers(e)} disabled={state.load} className={`fb-color d-flex justify-content-center p-2 fw-bold  border-0 w-100 fs-6 ${isMobile && 'small'}`} style={{ backgroundColor: '#CDEAFC' }}>

                                    {state.load ? <div> <Loader /></div>
                                        :
                                        <>
                                            <span>{t('views.messages.click_here_to_unlock_chat_for')}</span>
                                            <span className='mx-2'>{convertCurrency(params.currency, params.currencies, receiver?.tchat_fee, 'GET')}{params.currency}.</span>
                                            {users.account?.balance < receiver?.tchat_fee &&
                                                <span data-bs-toggle="modal" data-bs-target={`#sentTipModal${receiver._id}`} ref={transactionRef}>.</span>
                                            }
                                        </>}
                                </button>)
                        )
                    )
                    : (<span className={`fb-color d-flex justify-content-center p-2 fw-bold  border-0 w-100 fs-6  bg-info ${isMobile && 'small'}`}>
                        <span className="text-white">{state.transactionResponse}</span>
                    </span>)
                }
                {subscription === false &&
                    <a href={receiver.account_type === 'CREATOR' && `/${receiver.pseudo}`} className={`d-flex justify-content-center bg-info text-white p-2 fw-bold  border-0 w-100 fs-6 mb-0 text-decoration-none ${isMobile && 'small'}`} >
                        {users.users.account_type === 'CREATOR' ? t('views.messages.endOfsubscription') : t('views.messages.renew_your_subs')}
                    </a>
                }
                <div className={`d-flex align-items-center `}  >
                    <div className={`align-items-center py-0 justify-content-between  w-100  ${subscription === false ? 'bgagrey' : 'bg-white'}`} >
                        <div className="modal-body p-0 mb-2 ">
                            {medias.length > 0 &&
                                <div className="d-flex gap-2 flex-wrap mt-auto ">
                                    {medias.map((media, index) => {
                                        let type = media.file.type.split('/')[0];
                                        if (type === 'image') {
                                            return (
                                                <div key={index} className='d-flex justify-content-between start-0'>
                                                    <div className="containHover position-relative mx-1">
                                                        <img src={media.url} className="rounded-5" alt="post_picture" style={{ width: 100, height: 100 }} />
                                                        <div className="position-absolute top-0 bottom-0 end-0 start-0 bg-black opacity-50 rounded-5 overlayBackground"></div>
                                                        <div className="position-absolute top-0 end-0 p-2">
                                                            <button className="text-white bg-transparent border-0 overlayIcon p-2 rounded-circle" name="deleteImg" onClick={() => handleDeleteFile(index)}>
                                                                <span className="material-icons">delete</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        }

                                        else {
                                            return (
                                                <div key={index} className="containHover position-relative overflow-hidden d-flex start-0">
                                                    <video controls className="rounded-5" style={{ width: 200, height: 100 }}>
                                                        <source src={media.url} type={media.file.type} />
                                                    </video>
                                                    <button className="position-absolute bg-transparent text-white border-0 top-0 start-0 p-2 rounded-circle overlayDeleteMovie" name="deleteVideo" onClick={() => handleDeleteFile(index)}>
                                                        <span className="material-icons">delete</span>
                                                    </button>
                                                </div>
                                            )
                                        }

                                    }
                                    )}

                                    <label href="#" className=" btn fb-btn-color mx-1 text-muted px-1 text-decoration-none pt-3 ">
                                        <span className="my-3" style={{ fontSize: 45 }}>+</span>
                                        <input type="file" id="imageFile" onChange={handleFileChange} name="image" hidden accept=".jpg,.jpeg,.png,.mp4" disabled={can_not_writeUsers || subscription === false} />
                                    </label>
                                </div>
                            }

                        </div>
                        <div >
                            <textarea type="text" placeholder={`${t('views.messages.typeMessage')} ...`}
                                rows={rows}
                                id='ContentMessage'
                                name='content'
                                onChange={handleInput}
                                onKeyPress={(event) => handleKeyPress(event)}
                                value={state.content}
                                className={`form-control form-control-sm  rounded-3 border-0 mt-auto  w-100 me-1 ${subscription === false ? 'bgagrey' : 'bg-white'}`}
                                ref={contentInputRef}
                                disabled={can_not_writeUsers || subscription === false}
                                style={{ minHeight: "38px", resize: "auto" }}
                            />


                        </div>
                        <div className='d-flex justify-content-between w-100 position-relative'>
                            <div className="d-flex justify-content-between" >
                                <div className='d-flex justify-content-between mt-2'>
                                    <label href="#" className="text-muted w-25  text-decoration-none ms-2">
                                        <span className="material-icons md-20">add_photo_alternate</span>
                                        <input type="file" id="imageFile" disabled={can_not_writeUsers || subscription === false} onChange={handleFileChange} name="image" hidden accept=".jpg,.jpeg,.png,.mp4" multiple />
                                    </label>

                                    {receiver?.account_type === 'CREATOR' &&
                                        <button className="text-muted text-decoration-none d-flex align-items-start bg-transparent border-0 " onClick={(e) => { e.preventDefault() }} disabled={can_not_writeUsers || subscription === false} title="Send Tip" data-bs-toggle="modal" data-bs-target={`#sentTipModal${receiver._id}`}>
                                            <span className="material-icons md-20 text-gradient">redeem</span>
                                        </button>
                                    }

                                    {(users.users.account_type === 'CREATOR' && medias.length > 0) &&
                                        <div className=' ms-1 d-flex justify-content-between'>
                                            <span className="form-switch form-check">
                                                <input className="form-check-input" disabled={can_not_writeUsers || subscription === false} type="checkbox" checked={state.is_lock} id="flexCheckDefault" name="isLock" onChange={textInputChange} />
                                            </span>
                                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                                {state.is_lock ? t('words.paid') : t('words.free')}
                                            </label>
                                        </div>
                                    }
                                </div>
                                <div className="ms-1 mx-2">
                                    {(state.is_lock === true && medias.length > 0) &&
                                        <input id="price" value={state.unlock_fee === 0 ? '' : state.unlock_fee} className="form-control form-control-sm rounded-4 text-center px-0" type="text" placeholder={`${t('words.price')},${params.currency}`} name="unlock_fee" onChange={textInputChange} />
                                    }
                                </div>
                            </div>

                            <div className={`d-flex justify-content-between mb-2`}>
                                <button className={`btn btn-secondary  bg-transparent align-items-center me-1 border-0`}
                                    onClick={(e) => addContent(e)}
                                    id='btnSend'
                                    type="submit"
                                    disabled={isDisabled}
                                >
                                    {state.isLoading ? <Loader /> : <span className={`material-icons md-30 pt-1 ${isDisabled ? "text-secondary" : "text-gradient"} `}>send</span>}
                                </button>
                            </div>

                            {state.showAlert &&
                                <div class="alert alert-warning alert-dismissible fade show  rounded-4 shadow-sm p-3 border-0 position-absolute start-50 bottom-50 me-3" role="alert" style={{ width: "51%" }}>
                                    <span > {t('views.messages.pleaseEnterAnAmountBetween')} {amount.minAmount}{params.currency} {t('words.and')} {amount.maxAmount}{params.currency}</span>
                                    <button type="button" className="btn-close small px-2" data-bs-dismiss="alert" aria-label="Close"></button>
                                </div>
                            }

                        </div>
                    </div>
                </div>
            </form>
            <TransactionsModal
                onDataReady={handleDataReady}
                data={{ id: receiver?._id, photo: receiver?.photo, display_name: receiver?.display_name, pseudo: receiver?.pseudo, account_type: receiver.account_type, postId: receiver?._id, room: room }}
                action={can_not_writeUsers ? 'unlockTchat' : 'leaveTip'}
                nameForm={can_not_writeUsers ? `${t('navigation.unlockTchat')}` : `${t('navigation.leaveTip')}`} amount={can_not_writeUsers ? convertCurrency(params.currency, params.currencies, receiver?.tchat_fee, 'GET')?.replace(/\s/g, '') : 0}
                setCan_not_writeUsers={setCan_not_writeUsers}
            />
        </>
    )
}
export default TchatForm