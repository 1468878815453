
import { t } from 'i18next';
import React from 'react';
import {
    FacebookShareButton, TwitterShareButton, FacebookIcon, WhatsappIcon,
    WhatsappShareButton, FacebookMessengerShareButton, FacebookMessengerIcon, TwitterIcon, TelegramIcon,
    TelegramShareButton
} from 'react-share';

const ShareModal = ({ id, postId = false, share }) => {
    const inputRef = React.useRef(null);
    const [copySuccess, setCopySuccess] = React.useState(false);
    let shareLink = ''
    const baseUrl = window.location.origin;

    if (window.location.href.includes('') && postId === false) {
        shareLink = `${baseUrl}/${id}`;
    } else {
        shareLink = `${baseUrl}/post/${id}`;
    }



    const instagramShareUrl = "https://www.instagram.com/share?url=" + encodeURIComponent(shareLink);

    const copyToClipboard = () => {
        if (inputRef.current) {
            inputRef.current.select();
            document.execCommand('copy');
            setCopySuccess(true);
            setTimeout(() => {
                setCopySuccess(false);
            }, 15000);
        }
    };


    return (
        <div className="modal fade " id={`shareModal${id}`} data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true" >
            <div className="modal-dialog modal-dialog-centered ">
                <div className="modal-content rounded-4 p-4 shadow-sm border-0 bg-white">
                    <div className="modal-header d-flex align-items-center justify-content-between border-2 p-0 pb-3 mb-3">
                        <h5 className="fw-bold mb-0 fs-6">{share}</h5>
                        <button className="text-muted border-0 material-icons p-2 rounded-circle small md-20" style={{ backgroundColor: "#F0F0F0" }} data-bs-dismiss="modal">close</button>
                    </div>
                    <h6 className="fw-bold mb-3 small">{t('navigation.shareThisLinkVia')}</h6>
                    <div className="modal-body p-0 mb-3 d-flex justify-content-between flex-wrap text-center " >
                        <WhatsappShareButton url={shareLink} className="d-flex flex-column align-items-center">
                            <WhatsappIcon className="rounded-circle" style={{ width: 23.5, height: 23.5 }} />
                            <span className="text-center fs-sm">Whatsapp</span>
                        </WhatsappShareButton>
                        <FacebookShareButton url={shareLink} className="d-flex flex-column align-items-center">
                            <FacebookIcon className="rounded-circle" style={{ width: 23.5, height: 23.5 }} />
                            <span className="text-center fs-sm">Facebook</span>
                        </FacebookShareButton>
                        <a href={instagramShareUrl} target="_blank" rel="noopener noreferrer" className="text-decoration-none d-flex flex-column align-items-center">
                            <img src={require("../assets/logo/instagram.png")} n className="rounded-circle" alt="" style={{ width: 23.5, height: 23.5 }} />
                            <span className="text-center text-muted fs-sm">Instagram</span>
                        </a>
                        <TwitterShareButton url={shareLink} className="d-flex flex-column align-items-center">
                            <TwitterIcon className="rounded-circle" style={{ width: 23.5, height: 23.5 }} />
                            <span className="text-center fs-sm">Twitter</span>
                        </TwitterShareButton>
                        <FacebookMessengerShareButton url={shareLink} className="d-flex flex-column align-items-center">
                            <FacebookMessengerIcon className="rounded-circle" style={{ width: 23.5, height: 23.5 }} />
                            <span className="text-center fs-sm">Messenger</span>
                        </FacebookMessengerShareButton>
                        <TelegramShareButton url={shareLink} className="d-flex flex-column align-items-center">
                            <TelegramIcon className="rounded-circle" style={{ width: 23.5, height: 23.5 }} />
                            <span className="text-center fs-sm">Telegram</span>
                        </TelegramShareButton>

                    </div>
                    <div>
                        <h6 className="fw-bold mb-3">{t('navigation.orCopyLink')}</h6>
                        <div className=" d-flex align-items-center border rounded-3 px-2  py-1 w-100">
                            <span className="material-icons me-2">link</span>
                            <input type="text" ref={inputRef} className="form-control form-control-sm p-0 rounded-3 fw-light border-0 bg-transparent" defaultValue={shareLink} />
                            <button className="fb-btn-color text-white p-2  border-0 rounded-pill px-3" onClick={copyToClipboard}>
                                {copySuccess ? `${t('words.copied')}` : `${t('words.copy')}`}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ShareModal;