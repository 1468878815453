const Metrics = {
    borderWidth: 0.4,

    borderRadius: 8,

    horizontalLineHeight: 1,

    buttonRadius: 8,

    waitingTime: 8000,

    mailFormat: /^[\w-\\.]+@([\w-]+\.)+[\w-]{2,4}$/,
    // validEmailRegex: RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i),

    urlRegex: /^(https?:\/\/)?([\w.-]+)\.([a-z]{2,})(\/[\w .-]*)*\/?$/,
    phoneRegex: /^\+?\d{1,3}\s?\d{3,14}$/,

    background1: '#3A7CE6',
    background2: '#E04C60',
    background3: 'linear-gradient(to bottom, #6B20A5, #E04C60)',
    background4: 'linear-gradient(to bottom, #FEBC00, #C800A5)',
    background5: '#6BCEBB',
    background6: 'linear-gradient(to right top, #C800A5, #FF53A0, #C800A5, #FF53A0)',
};

export default Metrics;
