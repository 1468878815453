import React from 'react'

const Avatar = ({ user, width, height, fontSize, editPhoto }) => {

    if (user?.photo === "") {
        return (
            <div style={{ width: width, height: height, backgroundColor: "#F0F0F0", objectFit: "fill" }} className={`rounded-circle border border-2 border-white fw-bold text-uppercase d-flex justify-content-center align-items-center ${fontSize}`}>
                <span className='fb-color outline-none'>{user?.display_name.slice(0, 2)}</span>
            </div>
        )
    } else {
        return (
            <div>
                <img src={editPhoto ? user : user?.photo} className="rounded-circle user-img border border-2 border-white" alt="profile-img" style={{ width: width, height: width, objectFit: "cover", backgroundColor: "#F0F0F0" }} />
            </div>
        )
    }
}

export default Avatar



