//React
import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

//App

import { convertCurrency, getReleasePost, getReleasePrice, getSubscriptionFees } from "../../Utils"
import { useRef } from "react";
import { RequestFanbusy } from "../../Services";
import { Loader, TransactionsModal } from "../../Components";



const CollectionLocked = ({ collection, setListPostRelease, checkIfSubscribeToCreator, collection_number, subscribeToCreator }) => {
    const { t } = useTranslation();
    const params = useSelector(state => state.params);
    const users = useSelector(state => state.users);
    const releaseRef = useRef();
    const dispatch = useDispatch()
    const [state, setState] = useState({
        isLoaded: false,
        releaseErrors: ""

    });
    const sref = useRef();
    const [alertType, setAlertType] = useState('')
    const releasePrice = getReleasePrice(collection, params.continent);
    let fee = getSubscriptionFees(collection?.creator?.subscriptions_details, params.continent);

    const checkPaiementPlan = (collection) => {
        if (!checkIfSubscribeToCreator(collection)) {
            if (fee.quarterly === 0 && fee.halfYearly === 0 && fee.yearly === 0) {
                subscribeToCreator(1);
            } else {
                sref && sref.current.click();
            }
        } else {
            releaseCollection(collection._id);
        }
    };

    const releaseCollection = async (collection_id) => {
        if (users.account.balance < releasePrice) {
            if (releaseRef) {
                releaseRef?.current.click();
            }
        }
        else {
            let form = {
                payment_mode: "ACCOUNT"
            }
            setState((prev) => ({ ...prev, isLoaded: true }));
            let res = await RequestFanbusy(`collection/${collection_id}/release-collection`, 'POST', form, users.access_token);


            if (res.status === 201) {
                setState({ ...state, isLoaded: false, releaseErrors: 'success' })
                setAlertType('success');
                dispatch({ type: "REFRESH", value: 1 });
                let releasePost = getReleasePost(users.access_token);
                releasePost.then((value) => {
                    setListPostRelease(value);
                });
            }
            else if (res.status === 400) {
                setState({ ...state, haveFunds: false, isLoaded: false });
            } else {
                //  setShowReleaseError(true)
                setState({ ...state, releaseErrors: 'Error, please try again.', isLoaded: false });
                setAlertType('warning');
            }
        }

    }

    const convertAmount = (amount) => {
        let convert = convertCurrency(params.currency, params.currencies, amount, 'GET');
        return convert;
    }

    const handleDataReady = (data) => {
        setState((prevState) => ({ ...prevState, releaseErrors: (data) }))
    };


    return (
        <div className="col-6" style={{ height: "200px", width: "180px" }}>
            <div className="card h-100" style={{ backgroundImage: `url(${collection?.banner[0].link})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                <div className="card-body text-white bg-transparent" style={{ height: '200px' }}>
                    <h5 className="card-title">{collection.titre}</h5>
                    {/* <p className="card-text">Description</p> */}
                </div>
                <div className="card-footer border-0 position-relative">
                    <div className="position-absolute bottom-0 start-50 translate-middle-x border rounded-3 p-1 mb-2 mt-5 w-100" >
                        <div className="d-flex align-item-center justify-content-between px-1">
                            <div className="d-flex align-item-center justify-content-center gap-3 mb-0">

                                <div className="d-flex align-item-center justify-content-center" style={{ color: '#AFB0B4' }}>
                                    <span className="material-icons m-0 p-0 me-1" style={{ fontSize: 15 }}>image</span>
                                    <p className="m-0 p-0">{collection_number}</p>
                                </div>


                                {/* <div className="d-flex align-item-center justify-content-center" style={{ color: '#AFB0B4' }}>
                                    <span className="material-icons m-0 p-0 me-2" style={{ fontSize: 15 }}>videocam</span>
                                    <p className="m-0 p-0">3</p>
                                </div> */}

                            </div>
                            <span className="material-icons" style={{ fontSize: 15, color: '#AFB0B4' }}>lock</span>
                        </div>

                        {users.authenticated && users.access_token !== "" ?
                            <button onClick={() => { checkPaiementPlan(collection) }} className={`fb-btn-color text-white rounded-pill d-flex align-items-center justify-content-between w-100 small border-0 py-1 `} >
                                {!checkIfSubscribeToCreator(collection) ?
                                    (
                                        state.isLoaded ?
                                            <Loader />
                                            :
                                            <>
                                                {t('words.subscribe')}
                                                {collection.creator.is_free_account ?
                                                    <span className="fw-bold">{t('words.free')}</span>
                                                    :
                                                    <>
                                                        {(users.account.balance < releasePrice || users.account.balance < fee.monthly) &&
                                                            <span data-bs-toggle="modal" data-bs-target={users.users && `#sentTipModal${collection._id}`} ref={releaseRef}></span>
                                                        }
                                                        <span data-bs-toggle="modal" data-bs-target={`#subscriptionPlansModal`} ref={sref}></span>
                                                        <span className="fw-bold" >{convertAmount(fee.monthly)} {params.currency}/{t('words.month')}</span>
                                                    </>
                                                }
                                            </>

                                    ) : (
                                        state.isLoaded ?
                                            <Loader styleColor={false} fontsize={'fs-6'} />
                                            :
                                            <>
                                                <span className="" >{t('navigation.unlockFor')}</span>
                                                {users.account?.balance < releasePrice &&
                                                    <span data-bs-toggle="modal" data-bs-target={`#sentTipModal${collection._id}`} ref={releaseRef}></span>
                                                }
                                                <span className=''>{convertCurrency(params.currency, params.currencies, releasePrice, 'GET')}{params.currency}</span>
                                            </>
                                    )}
                            </button>
                            :
                            <a href="/" data-bs-toggle="modal" data-bs-target={`#signOrLogModal${collection.creator.pseudo}`} className="btn gradient-button text-white btn-block rounded-pill fw-bold text-decoration-none d-flex align-items-center justify-content-between w-100 small border-0 p-1 px-4 mb-1">
                                {!checkIfSubscribeToCreator(collection) ? (
                                    <>
                                        {t('words.subscribe')}
                                        {collection?.creator.is_free_account ?
                                            <span className="fw-bold">{t('words.free')}</span>
                                            :
                                            <span className="fw-bold" >{convertAmount(fee.monthly)} {params.currency}/{t('words.month')}</span>
                                        }
                                    </>

                                ) : (
                                    <>
                                        {t('navigation.unlockFor')}
                                        <span>{convertAmount(releasePrice)} {params.currency}</span>
                                    </>
                                )}
                            </a>

                        }

                        {state.releaseErrors.trim().length > 0 &&
                            <div className={`alert alert-${alertType} alert-dismissible p-1`} role="alert">
                                <div>{state.releaseErrors}</div>
                                <button type="button" className="btn-close p-2" data-bs-dismiss="alert" aria-label="Close"></button>
                            </div>
                        }
                    </div>
                </div>
            </div>

            <TransactionsModal
                onDataReady={handleDataReady}
                data={{
                    id: collection._id, photo: collection.creator.photo, display_name: collection.creator.display_name, pseudo: collection.creator?.pseudo,
                    account_type: collection.creator?.account_type, postId: collection._id
                }}
                action={'PayCollection'} nameForm={`${t('views.collection.payCollection')}`}
                amount={convertCurrency(params.currency, params.currencies, releasePrice, 'GET')?.replace(/\s/g, '')} />

        </div>
    )
}

export default CollectionLocked