import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { getBookmark, getCreatorFollows, getLikes, getReleasePost, getStatutByUser, useScreenSize } from '../../Utils';
import { RequestFanbusy, TchatRequest } from "../../Services";
import { AppContainer, AsideContainer } from "../../Containers";
import { Avatar, DescriptionParagraph, EndOfPage, Loader, NoContent, Post, TransactionsModal } from "../../Components";
import { Images } from "../../Constants";
import { MdLocalAtm, MdOutlineChat } from "react-icons/md";
import { shortenName } from "../../Utils";


const PostByIdCollection = () => {
    const darkMode = useSelector((state) => state.theme.darkMode);
    const { id } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const users = useSelector((state) => state.users);
    const [isLoading, setLoading] = React.useState(true);
    const [completedRequests, setCompletedRequests] = React.useState(1);
    const [state, setState] = React.useState({
        releaseErrors: '',
        posts: [],
        creatorData: {},
        collections: [],
        collection: {},
        isLoading: false,
        isLoadingMorePost: false
    });
    const [follows, setFollows] = useState([]);

    const [pagination, setPagination] = useState({
        total: 0,
        per_page: 5,
        last_page: 0,
        current_page: 0,
    });

    const nextPage = pagination.current_page + 1;

    const [listBookmark, setListBookmark] = React.useState([]);
    const [listLikes, setListLikes] = React.useState([]);
    const [listPostRelease, setListPostRelease] = React.useState([]);
    const [scrollY, setScrollY] = useState(0);
    const screenSize = useScreenSize()
    const isMobile = screenSize === 'Extra Small' || screenSize === 'Small' || screenSize === 'Medium'
    const [isOnLine, setIsOnLine] = React.useState(false);

    const getPostByCreatorId = React.useCallback(async (creator_id) => {
        let res = await RequestFanbusy(`collection/post/${creator_id}`, 'GET', '', users.access_token);

        if (res.status === 200) {
            const paginage = res.response.paginage
            setPagination(prevState => ({ ...prevState, total: paginage.total, last_page: paginage.last_page, current_page: paginage.current_page }));
            setState((prev) => ({ ...prev, posts: res.response.data }));
        }
        setCompletedRequests(prev => prev + 1);
    }, [users.access_token]);

    const loadMorePost = useCallback(async () => {

        try {
            setState(prevState => ({ ...prevState, isLoadingMorePost: true }));
            const res = await RequestFanbusy(`collection/post/${id}?skip=${nextPage}&limit=${pagination.per_page}`, 'GET', '', users.access_token);
            if (res.status === 200) {
                const data = res.response.data;
                const paginage = res.response.paginage
                setPagination(prevState => ({ ...prevState, total: paginage.total, last_page: paginage.last_page, current_page: paginage.current_page }));
                setTimeout(() => {
                    setState((prevState) => ({ ...prevState, posts: [...prevState.posts, ...data], isLoadingMorePost: false }));
                }, 2000);
            }
        } catch (error) {
            setState(prevState => ({ ...prevState, isLoadingMorePost: false }));
        }
    }, [id, nextPage, pagination.per_page, users.access_token])

    const getCreatorById = React.useCallback(async () => {
        let res = await RequestFanbusy(`collection/${id}`, 'GET', '', users.access_token);
        if (res.status === 200) {
            const creatorInfo = res.response?.data.creator
            setState((prev) => ({ ...prev, creatorData: creatorInfo, collection: res.response?.data }));
            getPostByCreatorId(id)
        }

        setCompletedRequests(prev => prev + 1);
        setLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getPostByCreatorId, id, state.creatorData?._id, users.access_token]);


    React.useEffect(() => {
        let follows = getCreatorFollows(users.access_token);
        follows.then((value) => {
            setFollows(value)
        });
        let bookmarks = getBookmark(users.access_token);
        bookmarks.then((value) => {
            setListBookmark(value);
        });
        let likes = getLikes(users.access_token, 'POST');
        likes.then((value) => {
            setListLikes(value);
        });

        let releasePost = getReleasePost(users.access_token);
        releasePost.then((value) => {
            setListPostRelease(value);
        });

        getCreatorById()

    }, [users.access_token, getCreatorById, id]);

    React.useEffect(() => {
        let online = getStatutByUser(id);
        online.then((value) => {
            setIsOnLine(value)
        });

        setCompletedRequests(0);

        if (state.releaseErrors?.length > 0) {
            const timer = setInterval(() => {
                setState((prev) => ({ ...prev, releaseErrors: '' }));
            }, 10000);

            return () => clearInterval(timer);
        }
    }, [state.releaseErrors.length, id]);

    React.useEffect(() => {
        if (completedRequests === 2) {
            setLoading(false);
        }
    }, [completedRequests]);

    useEffect(() => {
        const handleScroll = () => {
            setScrollY(window.scrollY);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    useEffect(() => {
        let isFetching = false;

        const handleScroll = () => {
            const { scrollTop, clientHeight, scrollHeight } = document.documentElement;
            const scrollPosition = scrollTop + clientHeight;
            const scrollThreshold = scrollHeight - 500;

            if (!isFetching && scrollPosition >= scrollThreshold && pagination.current_page !== pagination.last_page && state.posts.length >= 5) {
                isFetching = true;
                loadMorePost();
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [loadMorePost, pagination.current_page, pagination.last_page, state.posts.length]);


    const createChatWithCreator = async (creator_id) => {
        const creatorResponse = await RequestFanbusy(`creators/${creator_id}`, 'GET', '', users.access_token);
        const newCreatorObject = creatorResponse.response.data;
        try {
            let data = {
                sender: {
                    id: users.users._id,
                },
                receiver: {
                    id: newCreatorObject._id,
                }
            }
            const response = await TchatRequest('room', 'POST', data, '');
            if (response.response.status_code === 201) {
                const newRoom = response.response.data?._id;
                const res = await TchatRequest(`room/Room/souscriptions/${users.users?._id}/${creator_id}`, 'PUT', '', '')
                if (res.response.status_code === 200) {
                    navigate(`/messages/my/chat/${newRoom}/${newCreatorObject._id}`)
                }
            } else {
                const roomexist = response.response.data[0]?._id;
                navigate(`/messages/my/chat/${roomexist}/${newCreatorObject._id}`)
            }

        } catch (error) {
            setState(prevState => ({ ...prevState, errorType: error.message }));
        } finally {
        }
    }
    const handleDataReady = (data) => {
        setState((prev) => ({ ...prev, releaseErrors: data }));
    };


    return (
        <>
            <AppContainer>
                <main style={{ paddingBottom: isMobile ? '30px' : '0px', }} className={`col col-xl-6 order-xl-2 col-lg-12 order-lg-1 col-md-12 col-sm-12 col-12 border border-top-0 border-fw-light px-0  border-bottom-0  ${isMobile && ' pb-5 '}`}>
                    {isLoading ?
                        <Loader styleColor={true} marginTop={true} /> :
                        <div className="main content">
                            {scrollY >= 260 && (
                                <div style={{ height: '50px' }} className={` d-flex justify-content-between align-items-center mb-3 bg-white fix-sidebar top-0 border-bottom `}>
                                    <div className='d-flex pt-2'>
                                        <a href={`/`} className={`${darkMode ? 'text-white' : 'text-dark'} material-icons text-decoration-none  ms-3 `} >
                                            arrow_back
                                        </a>
                                        <p className={`${darkMode ? 'text-white' : 'text-body'} ms-2 mb-0 fw-bold fs-6 text-capitalize d-flex align-items-center`}>
                                            <span className="m-0 p-0"> {shortenName(state.collection.titre, screenSize === "Extra Small" ? 18 : screenSize === "Small" ? 25 : 35)}</span>

                                            {/* {state.creatorData?.account_type === 'CREATOR' &&
                                                <span className="ms-1 p-0 md-16 fw-bold mb-1">{Images.verif} </span>
                                            } */}
                                        </p>
                                    </div>

                                    <div className="d-flex align-items-end">
                                        {(users.users?.pseudo !== id && state.creatorData?.account_type === 'CREATOR') &&
                                            <button title="Send Tip" data-bs-toggle="modal" data-bs-target={`#sentTipModal${id}`} className={`text-decoration-none bg-white border-0 text-muted`}>
                                                <span className="text-decoration-none bg-white "> <MdLocalAtm size={27} /></span>
                                            </button>
                                        }
                                        <button onClick={() => { createChatWithCreator(state.creatorData?._id) }} title="Messages" className={`rounded-pill  text-decoration-none bg-white border-0 text-muted`}>
                                            <span className="text-decoration-none bg-white "><MdOutlineChat size={25} /></span>
                                        </button>

                                        <div className="dropdown">
                                            <a href={`/`} className="text-dark text-decoration-none material-icons  md-20 rounded-circle bg-transparent pe-1" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">more_vert</a>
                                            <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton1">

                                                <li>
                                                    <a href={`/`} className="dropdown-item text-muted d-flex align-items-center" data-bs-toggle="modal" data-bs-target={`#shareModal${id}`}>
                                                        <span className="material-icons md-13 me-1">share</span>{t('views.profile.shareViaAnotherApps')}
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                </div>

                            )}
                            <div className={`${darkMode ? 'bg-dark' : 'bg-white'} border-bottom profile`} style={{ position: 'relative' }}>
                                <div style={{ backgroundImage: `url(${state.creatorData?.banner.length === 0 ? Images.background : state.creatorData?.banner})`, backgroundSize: 'cover' }}>
                                    <div style={{ backgroundColor: 'rgba(0, 0, 0, .10)', height: '200px', }}>
                                        <div className={`d-flex flex-column gap-2 pt-2 ps-3`}>
                                            <div className={` d-flex align-items-center `}>
                                                <a href={`/`} className={`text-white material-icons text-decoration-none ms-0`}>arrow_back</a>
                                                <p className={`text-white ms-2 mb-0 fw-bold mt-0 fs-6 text-capitalize d-flex align-items-center`}>
                                                    <span className="m-0 p-0 ">{shortenName(state.collection.titre, 28)}</span>
                                                    {/* {state.creatorData?.account_type === 'CREATOR' &&
                                                        <span className="mb-0 ms-1 pb-1 md-16 fw-bold " style={{ marginTop: '2px' }}>{Images.verifWhite} </span>
                                                    } */}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center  px-3 pt-1">
                                    <div style={{ marginTop: -50, position: 'relative' }}>
                                        <Avatar user={state.creatorData} width={80} height={80} fontSize={'fs-4'} />
                                        {isOnLine &&
                                            <span className="rounded-circle position-absolute bottom-0 mb-2" style={{ width: 14, height: 14, right: '4px', background: '#35D063', border: '2px solid white' }}></span>
                                        }
                                    </div>
                                    {/* 
                                    <div className=" ms-auto d-flex align-items-end">
                                        {users.users?._id !== id &&
                                            <a href={`/`} title="Send Tip" data-bs-toggle="modal" data-bs-target={`#sentTipModal${id}`} className={`border rounded-pill ms-2 fb-color`} >
                                                <span style={{ padding: "13px" }} className={`material-icons md-14`}>local_atm</span>
                                            </a>
                                        }
                                        <button onClick={() => { createChatWithCreator(state.creatorData?._id) }} title="Messages" className={`fb-color border rounded-pill ms-2 text-decoration-none bg-white `} style={{ height: '40px', width: '40px' }}>
                                            <span style={{ paddingTop: '5px' }} className={`material-icons md-14`}>forum</span>
                                        </button>

                                    </div> */}
                                </div>
                                <div className="pb-2 pt-1 px-3" style={{ boxShadow: "0px 5px 0px #EDF2F6" }}>
                                    <div className="mb-2">
                                        <h6 className={`${darkMode ? 'text-white' : 'text-body'} mb-0 d-flex align-items-center fs-6 fw-bold text-capitalize`}>
                                            <span className="p-0 m-0">{shortenName(state.collection.titre, 28)}</span>
                                            {/* {state.creatorData?.account_type === 'CREATOR' &&
                                                <span className="ms-1 p-0 md-16 fw-bold " style={{ marginBottom: '4px' }}>{Images.verif} </span>
                                            } */}
                                        </h6>
                                        {/* <p className="text-muted mb-0">@{shortenName(state.creatorData?.pseudo, 28)}</p> */}
                                    </div>
                                    <DescriptionParagraph bio={state.collection.description} profile={true} />
                                </div>

                                {/* <div className="p-3 pt-1 mt-3" style={{ boxShadow: "0px 5px 0px #EDF2F6" }}>
                                    <h6 className="text-uppercase">{t('navigation.subscription')}</h6>
                                    <div className="ms-auto btn-group w-100 disabled" tabIndex="-1" role="button" aria-disabled="true">
                                        <input type="checkbox" className="btn-check" id="btncheck1" />
                                        {(users.authenticated && users.access_token !== "") ?
                                            <label className={`btn d-flex align-items-center ${state.isLoading ? 'justify-content-center' : 'justify-content-between'}  btn-sm px-3 rounded-pill ${!checkIfSubscribeToCreator(state.creatorData?._id) ? 'gradient-button text-white' : 'border-secondary fb-color'}`} htmlFor="btncheck1" onClick={!checkIfSubscribeToCreator(state.creatorData?._id) ? subscribeToCreator : () => { }} data-bs-toggle={checkIfSubscribeToCreator(state.creatorData?._id) && "modal"} data-bs-target={` ${checkIfSubscribeToCreator(state.creatorData?._id) ? `#validationModal${state.creatorData?._id}` : ''}`}>
                                                {state.isLoading ?
                                                    <Loader />
                                                    :
                                                    <>
                                                        <span className="fw-bold ">{checkIfSubscribeToCreator(state.creatorData?._id) ? `${t('words.subscribed')}` : `${t('words.subscribe')}`}</span>
                                                        {state.creatorData?.is_free_account ?
                                                            <span className="fw-bold">{t('views.profile.forFree')}</span>
                                                            :
                                                            <>
                                                                {(users.account.balance < state.creatorData?.subscription_fee) &&
                                                                    <span data-bs-toggle="modal" data-bs-target={`#sentTipModal${id}`} ref={releaseRef}></span>
                                                                }
                                                                <span className="fw-bold" >{convertAmount(state.creatorData?.subscription_fee)} {params.currency}/{t('words.month')}</span>
                                                            </>
                                                        }
                                                    </>
                                                }
                                            </label>
                                            :
                                            <a href="/" data-bs-toggle="modal" data-bs-target={`#signOrLogModal${id}`} className={`btn d-flex align-items-center justify-content-between btn-sm px-3 rounded-pill text-decoration-none  ${!checkIfSubscribeToCreator(state.creatorData?._id) ? 'gradient-button text-white' : 'border-secondary fb-color'}`} htmlFor="btncheck1">
                                                <span className="fw-bold ">{checkIfSubscribeToCreator(state.creatorData?._id) ? `${t('words.subscribed')}` : `${t('words.subscribe')}`}</span>
                                                {state.creatorData.is_free_account ?
                                                    <span className="fw-bold">{t('views.profile.forFree')}</span>
                                                    :
                                                    <span className="fw-bold" >{convertAmount(state.creatorData?.subscription_fee)} {params.currency}/{t('views.profile.month')}</span>
                                                }
                                            </a>
                                        }

                                    </div>

                                    {(state.releaseErrors.length > 0 && showReleaseError) &&
                                        <div className={`alert alert-${(state.releaseErrors.includes('suc') || state.releaseErrors.includes('Suc') || state.releaseErrors.includes('réussi')) ? 'success' : 'danger'} alert-dismissible mb-0 mt-2`} role="alert">
                                            <div>{state.releaseErrors}</div>
                                            <button type="button" className="btn-close" onClick={() => setShowReleaseError(false)} aria-label="Close"></button>
                                        </div>
                                    }
                                </div> */}

                                {/* <div style={{ position: 'absolute', top: 5, right: 5 }}>
                                    <div className="dropdown">
                                        <a href={`/`} className="text-white text-decoration-none material-icons ms-2 md-20 rounded-circle bg-transparent p-1" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">more_vert</a>
                                        <ul className="dropdown-menu fs-13 dropdown-menu-end" aria-labelledby="dropdownMenuButton1">

                                            <li>
                                                <a href={`/`} className="dropdown-item text-muted d-flex align-items-center" data-bs-toggle="modal" data-bs-target={`#shareModal${id}`}>
                                                    <span className="material-icons md-13 me-1">share</span>{t('views.profile.shareViaAnotherApps')}
                                                </a>
                                            </li>

                                        </ul>
                                    </div>
                                </div> */}
                            </div>

                            <section className="pt-4 feeds pb-3">
                                {state.posts?.length > 0 ?
                                    state.posts?.map((post, index) => {
                                        return (
                                            <Post key={post._id} postLength={{ length: state.posts?.length, index: index }} post={post} creatorId={state.creatorData?._id} bookmarks={listBookmark} likes={listLikes} released={listPostRelease} follows={follows} setListPostRelease={setListPostRelease} />
                                        )
                                    }
                                    ) : <NoContent message={`${t('views.profile.youHaveNoPublications')}`} />

                                }
                                {(state.isLoadingMorePost) && (
                                    <div className="text-center  text-muted my-3 fw-bold fs-6">
                                        <div className="spinner-border text-secondary" role="status">
                                            <span className="visually-hidden small">Loading...</span>
                                        </div>
                                    </div>

                                )}
                                {(pagination.current_page === pagination.last_page && !state.isLoadingMorePost && state.posts.length >= 2) &&
                                    <EndOfPage />
                                }
                            </section>


                        </div>
                    }
                </main>

                <AsideContainer />

            </AppContainer>
            {users.users?.verified &&
                <TransactionsModal
                    onDataReady={handleDataReady}
                    data={{ id: state.creatorData?._id, photo: state.creatorData?.photo, account_type: state.creatorData?.account_type, display_name: state.creatorData?.display_name, pseudo: state.creatorData?.pseudo, postId: id }}
                    action={'leaveTip'}
                    nameForm={`${t('navigation.leaveTip')}`}
                    amount={'0'}
                    setListPostRelease={setListPostRelease}
                    setFollows={setFollows} />}

        </>
    )
}

export default PostByIdCollection;