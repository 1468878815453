import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { CardProfile, Loader, NoContent } from '../../Components';
import { AppContainer } from '../../Containers';
import { getCreatorFollows, useScreenSize } from '../../Utils';
import { RequestFanbusy } from '../../Services';

const FollowsView = () => {
    const darkMode = useSelector((state) => state.theme.darkMode);
    const { t } = useTranslation();
    const users = useSelector(state => state.users);
    const params = useSelector(state => state.params);
    const screenSize = useScreenSize()
    const isMobile = screenSize === 'Extra Small' || screenSize === 'Small' || screenSize === 'Medium'
    const [state, setState] = React.useState({
        follows: [],
        followExpired: [],
        followsAndFollowExpired: [],
        isLoading: true
    });

    const getFollowsExp = async () => {
        const accountType = users.users.account_type;
        const token = users.access_token;
        let res = await RequestFanbusy(`souscriptions/my/${accountType}/expiry-souscription`, 'GET', '', token);
        return res

    }


    React.useEffect(() => {
        const getFollowsAndFollowsExpired = async () => {
            try {
                const [followsData, followExpiredData] = await Promise.all([
                    getCreatorFollows(users.access_token),
                    getFollowsExp()
                ]);

                setState(prevState => ({
                    ...prevState,
                    follows: followsData,
                    followExpired: followExpiredData.status === 200 ? followExpiredData.response.data : [],
                    followsAndFollowExpired: followsData.concat(followExpiredData.status === 200 ? followExpiredData.response.data : []),
                    isLoading: false
                }));

            } catch (error) {
                setState(prevState => ({ ...prevState, isLoading: false }));
            }
        };

        getFollowsAndFollowsExpired();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.refresh, users.access_token, users.users?.account_type]);

    return (
        <AppContainer>
            <main className={`col col-xl-9 order-xl-2 col-lg-12 order-lg-1 col-md-12 col-sm-12 col-12 border border-fw-light px-0 border-bottom-0  ${isMobile && 'pt-5 fixed-content pb-5'}`}>
                <div className="main-content pb-5">
                    <div style={{ height: '50px' }} className="d-flex align-items-center mb-0  m-none fix-sidebar top-0 border-bottom bg-white">
                        <a href={`/`} className={`${darkMode ? 'text-white' : 'text-dark'} material-icons text-decoration-none m-none ms-3`}>arrow_back</a>
                        <p className={`${darkMode ? 'text-white' : 'text-body'} ms-2 mb-0 fw-bold fs-6 text-uppercase`}>
                            {t('navigation.subscriptions')}
                        </p>
                    </div>
                    {state.isLoading ?
                        <Loader styleColor={true} marginTop={true} /> :
                        <div className="row px-2">
                            {(state.followsAndFollowExpired.length > 0) ?
                                state.followsAndFollowExpired.map((follow, index) => {
                                    return (
                                        <div key={follow._id} className="col col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
                                            <div className={`${!isMobile && 'ms-2'}`}>
                                                <CardProfile fan={false} creator={follow.creator} subsciption={follow} addClassName={'mt-3'} />
                                            </div>
                                        </div>
                                    )
                                }) :
                                <NoContent message={"You don't have a follow yet"} style={{ marginTop: '1rem' }} />
                            }
                        </div>
                    }
                </div>
            </main>
        </AppContainer>
    )
}

export default FollowsView;