import { useSelector } from "react-redux";
import { Images } from "../Constants"
import { shortenName, useScreenSize } from "../Utils"
import { useTranslation } from "react-i18next";

const StickyHeaderOnScroll = ({ creator, id, disabled, createChatWithCreator, checkIfSubscribeToCreator, loadchat }) => {
    const users = useSelector((state) => state.users);
    const { t } = useTranslation();
    const screenSize = useScreenSize()
    return (
        <div style={{ height: '50px' }} className={` d-flex justify-content-between align-items-center mb-3 bg-white fix-sidebar top-0 border-bottom `}>
            <div className='d-flex pt-2'>
                <a href={`/`} className={`text-dark material-icons text-decoration-none  ms-3 `} >
                    arrow_back
                </a>
                <p className={`text-body ms-2 mb-0 fw-bold fs-6 text-capitalize d-flex align-items-center`}>
                    <span className="m-0 p-0"> {shortenName(creator?.display_name, screenSize === "Extra Small" ? 18 : screenSize === "Small" ? 25 : 35)}</span>

                    {creator?.account_type === 'CREATOR' &&
                        <span className="ms-1 p-0 md-16 fw-bold mb-1">{Images.verif} </span>
                    }
                </p>
            </div>

            <div className="d-flex align-items-end">
                <div className="d-flex">
                    {(users.users?.pseudo !== id && creator?.account_type === 'CREATOR') &&
                        <button title="Send Tip" data-bs-toggle="modal" data-bs-target={`#sentTipModal${id}`} className={`text-decoration-none bg-white border-0 ${checkIfSubscribeToCreator(creator?._id) ? 'text-muted' : 'text-secondary opacity-50 pe-none'}`}>
                            <span className="material-icons text-decoration-none bg-white md-20">redeem</span>
                        </button>
                    }
                    <button onClick={() => { createChatWithCreator(creator?._id) }} title="Messages" className={`text-decoration-none bg-white border-0 ${checkIfSubscribeToCreator(creator?._id) ? 'text-muted' : 'text-secondary opacity-50 pe-none'}`}>
                        {loadchat ?
                            <div className="spinner-border spinner-border-sm mb-1" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                            :
                            <span className="text-decoration-none bg-white material-icons md-20 ">chat</span>
                        }
                    </button>
                </div>


                <div className="dropdown">
                    <a href={`/`} className="text-dark text-decoration-none material-icons  md-20 rounded-circle bg-transparent pe-1" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">more_vert</a>
                    <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton1">

                        <li>
                            <a href={`/`} className="dropdown-item text-muted d-flex align-items-center" data-bs-toggle="modal" data-bs-target={`#shareModal${id}`}>
                                <span className="material-icons md-13 me-1">share</span>{t('views.profile.shareViaAnotherApps')}
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

        </div>
    )
}
export default StickyHeaderOnScroll