export default function getSubscriptionFees(subscriptionDetails, userContinent) {
    if (!subscriptionDetails) {
        return {
            monthly: 0,
            quarterly: 0,
            halfYearly: 0,
            yearly: 0,
            hasPaymentPlan: false
        };
    }

    let continentKey;

    switch (userContinent) {
        case 'AF':
            continentKey = 'african_fee';
            break;
        case 'AS':
            continentKey = 'asian_fee';
            break;
        default:
            continentKey = 'other_fee';
    }

    const continentDetails = subscriptionDetails[continentKey];

    if (!continentDetails) {
        return {
            monthly: 0,
            quarterly: 0,
            halfYearly: 0,
            yearly: 0,
            hasPaymentPlan: false
        };
    }

    const monthly = continentDetails.month_fee ?? null;
    const quarterly = continentDetails.quarterly_fee ? continentDetails.quarterly_fee.value ?? 0 : null;
    const halfYearly = continentDetails.half_yearly_fee ? continentDetails.half_yearly_fee.value ?? 0 : null;
    const yearly = continentDetails.yearly_fee ? continentDetails.yearly_fee.value ?? 0 : null;

    const hasPaymentPlan = monthly > 0 && (quarterly > 0 || halfYearly > 0 || yearly > 0);

    return {
        monthly,
        quarterly,
        halfYearly,
        yearly,
        hasPaymentPlan
    };
}
