import { useState, useEffect } from "react";
import { i18n } from "../Services";
import { useDispatch } from "react-redux";

import Loader from "./Loader";
import { Images, Metrics } from "../Constants";
import { RequestFanbusy } from "../Services";
import { useTranslation } from "react-i18next";
import CardProfile from "./CardProfile";

const RestoreAccountComponent = ({ profile, restore, creator }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [currentLanguage, setCurrentLanguage] = useState(
        localStorage.getItem('currentLanguage') || navigator.language || navigator.userLanguage
    );
    const [error, setError] = useState({
        type: "",
        message: ""
    });
    const [email, setEmail] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [isValidMail, setIsValidMail] = useState(false)

    const textInputChange = (input) => {
        const target = input.target;
        const value = target.value;
        const name = target.name;

        if (name === "email") {
            setIsValidMail(Metrics.mailFormat.test(value))
            setEmail(value);
        }
    };

    const submitReset = async (event) => {
        event.preventDefault();

        if (email.length === 0) {
            setError({ type: "error", message: `${t('views.auth.pleaseEnterYourRecovery')}` })

            return;
        }
        else if (!isValidMail) {
            setError({ type: "error", message: `${t('views.auth.error_email')}` })

            return;
        }
        setIsLoading(true);

        let form = {
            email: email,
        };
        const res = await RequestFanbusy(`auth/request/new-password`, "POST", form, '');
        if (res.status === 201) {
            console.log(res.response.description);
            setError({ type: "success", message: res.response.description === "Request sent to your email" && t('views.auth.requestSend') })
        }
        else if (res.status === 404) {
            setError({ type: "error", message: t('views.auth.emailAddressIsIncorrect') })
        }
        setIsLoading(false);
    };

    useEffect(() => {
        changeLanguage(currentLanguage);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentLanguage]);

    const changeLanguage = (language) => {
        let updateLanguage = language === 'fr-FR' || language === 'fr' ? 'fr' : 'en';
        i18n.changeLanguage(updateLanguage);

        let action = {
            type: "LANGUAGE",
            value: {
                language: updateLanguage,
                languageName: updateLanguage === 'fr' ? t('words.french') : t('words.english')
            }
        };
        dispatch(action);
        setCurrentLanguage(updateLanguage);
        localStorage.setItem('currentLanguage', updateLanguage);
    };

    return (
        <main style={{ paddingTop: profile ? 5 : 0 }} className={`p-1 ${profile ? '' : 'col col-xl-6 order-xl-2 col-lg-12 order-lg-1 col-md-12 col-sm-12 col-12 mt-5 mb-5 mt-0 pt-0 mb-sm-none'} `}>
            <div className="main-content">
                <div className={`${profile ? 'mt-0' : 'mt-5'}`}>
                    <header className={` d-flex align-items-center ${profile ? "" : "justify-content-center"}  `}>
                        <img style={{ width: "150px", height: "30px", objectFit: "contain" }} src={Images.logov2} alt="Logo" className="web-none" />
                        <img style={{ width: "250px", height: "70px", objectFit: "contain" }} src={Images.logov2} alt="Logo" className="m-none" />
                    </header>
                    <div className="mb-3 fw">
                        <p className="mb-0 text-dark m-none d-flex align-items-center text-center">
                            <div>
                                <label className={`${!profile ? 'fs-2' : 'fs-5'}`}>{!profile && t('views.auth.aWorlOfInfinite')}</label>
                            </div>
                            {/* {!profile && <img className="ms-4" src={require("../assets/img/2.png")} alt="" style={{ maxHeight: 110 }} />} */}
                        </p>
                        <p className={`mb-0 text-dark web-none text-center ${profile ? 'pt-0' : 'pt-3'}`}>
                            <span className={`${!profile ? 'fs-5' : 'fs-6'}`}>{!profile && t('views.auth.aWorlOfInfinite')}</span>
                            {creator && <CardProfile fan={false} creator={creator} />}
                        </p>
                    </div>
                    <div>
                        <p className="mb-0 text-dark m-none d-flex align-items-center ms-0">
                            <div>
                                <label className={`${!profile ? 'fs-4' : 'fs-5'} fw`}>{t('views.auth.lostEmail')}</label>
                                <div className="text-muted text_short">
                                    {t('views.auth.pleaseEnterYourRecovery')}
                                </div>
                            </div>
                        </p>
                        <p className="mb-0 text-dark web-none">
                            <span className={`${!profile ? 'fs-4' : 'fs-6'} fw`}>{t('views.auth.lostEmail')}</span>
                            <div className="text-muted s text_short">
                                {t('views.auth.pleaseEnterYourRecovery')}
                            </div>
                        </p>
                    </div>
                </div>
                <div className="feeds">
                    <div className={`bg-white ${!profile ? 'py-0' : 'pt-0'} px-0 feed-item mb-3 faq-page mb-sm-none pb-sm-none`}>
                        <div className="row justify-content-center">
                            <div className="col-lg-12">
                                <form>
                                    <div className="form-floating mb-3 d-flex align-items-center">
                                        <input type="email" name="email" value={email} onChange={textInputChange} className={` form-control rounded-5 `} id="email" placeholder={`${t('views.auth.email')}`} />
                                        <label htmlFor="email" className={`${(!isValidMail && email.length > 0) && 'text-danger'} `}> {(!isValidMail && email.length > 0) ? 'Enter a valid email' : 'Email'} </label>
                                    </div>
                                    <div className="d-grid">
                                        <button disabled={!isValidMail} onClick={(e) => isLoading ? {} : submitReset(e)} className="btn fb-btn-color text-white rounded-pill w-100 text-decoration-none py-2 fw-bold text-uppercase m-0 gradient-button">
                                            {isLoading ?
                                                <Loader /> : <span>{t('views.auth.send')}</span>
                                            }
                                        </button>
                                    </div>
                                    <div className="mt-3">
                                        <p className="mb-sm-none">
                                            {t('views.auth.account_exist')}{" "}
                                            {!profile ? <a href={`/auth/login`} className=" text-decoration-none text-bold mb-sm-none text-gradient">
                                                {t('views.auth.login')}
                                            </a>
                                                :
                                                <span onClick={() => restore(false)} className=" text-decoration-none text-bold mb-sm-none text-gradient" style={{ cursor: 'pointer' }}>
                                                    {t('views.auth.login')}
                                                </span>
                                            }
                                        </p>
                                    </div>

                                </form>
                            </div>
                            <div className="mt-3 text-center">
                                {error.type === "success" &&
                                    <div className="alert alert-success alert-dismissible mt-2" role="alert">
                                        <div className="fw-bold"> {error.message}</div>
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                    </div>
                                }
                                {error.type === "error" &&
                                    <div className="alert alert-danger alert-dismissible mt-2" role="alert">
                                        <div className="fw-bold">{error.message}</div>
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main >
    )

}
export default RestoreAccountComponent;