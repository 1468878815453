function formatDateRelativePost(dateTimeString, t) {
    const currentDate = new Date();
    const inputDate = new Date(dateTimeString);
    currentDate.setHours(0, 0, 0, 0);
    inputDate.setHours(0, 0, 0, 0);
    const timeDifference = (currentDate - inputDate) / (24 * 60 * 60 * 1000);
    if (timeDifference < 1) {
        const startDate = new Date(dateTimeString);
        const localTime = new Date(startDate.getTime() - startDate.getTimezoneOffset() * 60000);
        const options = {
            hour: '2-digit',
            minute: '2-digit',
            hour12: t('lang.lang') === "fr-FR" ? false : true,
        };
        const formattedTime = localTime.toLocaleTimeString(`${t('lang.lang')}`, options);
        return formattedTime;
    } else if (timeDifference < 30) {
        return inputDate?.toLocaleDateString(`${t('lang.lang')}`, { day: 'numeric', month: 'short' });
    } else {
        return inputDate?.toLocaleDateString(`${t('lang.lang')}`, { month: 'short' });
    }
}

function formatTimeToAmPm(dateTimeString, t) {
    const startDate = new Date(dateTimeString);
    const localTime = new Date(startDate.getTime() - startDate.getTimezoneOffset() * 60000);
    const options = {
        hour: '2-digit',
        minute: '2-digit',
        hour12: t('lang.lang') === "fr-FR" ? false : true,
    };
    const formattedTime = localTime.toLocaleString(`${t('lang.lang')}`, options);
    return formattedTime;
}

function formatDateRelative(dateTimeString, t) {
    const currentDate = new Date();
    const inputDate = new Date(dateTimeString);
    currentDate.setHours(0, 0, 0, 0);
    inputDate.setHours(0, 0, 0, 0);
    const timeDifference = (currentDate - inputDate) / (24 * 60 * 60 * 1000);
    if (timeDifference === 0) {
        return `${t('lang.toDay')}`;
    } else if (timeDifference === 1) {
        return `${t('lang.yesterday')}`;
    } else if (timeDifference === 2) {
        return `${t('lang.days2Ago')}`;
    } else if (timeDifference < 30) {
        return inputDate.toLocaleDateString(`${t('lang.lang')}`, { day: 'numeric', month: 'short', year: 'numeric' });
    } else {
        return inputDate.toLocaleDateString(`${t('lang.lang')}`, { month: 'short', year: 'numeric' });
    }
}

function completeDateFormat(date, t) {
    const newDate = new Date(date).toLocaleDateString(`${t('lang.lang')}`, { month: 'long', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: t('lang.lang') === "fr-FR" ? false : true })
    return newDate
}

export { formatTimeToAmPm, formatDateRelative, formatDateRelativePost, completeDateFormat }