import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { Images, Routes } from "../Constants";
import { RequestFanbusy } from "../Services";
import Loader from "./Loader";
import { useTranslation } from "react-i18next";
import { i18n } from "../Services";
import CardProfile from "./CardProfile";


const LoginComponent = ({ profile, showLoginOrSign, closeModal, restore, profileLogOrSignRef, creator }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useSelector(state => state.params);
    const [showPassword, setShowPassword] = useState(false);
    const [currentLanguage, setCurrentLanguage] = useState(
        localStorage.getItem('currentLanguage') || navigator.language || navigator.userLanguage
    );

    const [userHandle, setUserHandle] = useState({
        email: "",
        password: "",
        isLoading: false,
        submitError: false,
        submitErrorMessage: "",
        isSuccess: false,
    });
    let [searchParams, setSearchParams] = useSearchParams();

    if (params.authenticated && !profile) {
        navigate(Routes.HOME);
    }

    React.useEffect(() => {
        if (searchParams.get("status") != null) {
            if (searchParams.get("status") === 10) {
                setUserHandle({ submitError: true, submitErrorMessage: `${t('views.auth.yourSessionHasExpired')}` });
            }

            setSearchParams({})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams, setSearchParams]);

    const textInputChange = (input) => {
        const target = input.target;
        const value = target.value;
        const name = target.name;
        setUserHandle((prevState) => ({ ...prevState, [name]: value }));
    };

    const submitLogin = async (event) => {
        event.preventDefault();
        setUserHandle((prev) => ({ ...prev, isLoading: true }));
        if (userHandle.email.length === 0 || userHandle.password.length === 0) {
            alert("FanBusy", `${t('views.auth.theFieldCannotBeEmpty')}`, [
                { text: "Ok", onPress: () => { } },
            ]);
            return;
        }
        let form = {
            email: userHandle.email,
            password: userHandle.password,
        };
        let response = await RequestFanbusy("auth/login/", "POST", form, "");
        if (response.status === 200) {
            setUserHandle((prevState) => ({ ...prevState, isSuccess: true, password: '' }));
            let action = {
                type: "LOGIN",
                value: {
                    users: response.response.data.creator,
                    access_token: response.response.data.access_token
                },
            };
            dispatch(action);
            getAccount(response.response.data.access_token);
            closeModal();
        }
        else if (response.status === 400) {
            setUserHandle({ submitError: true, submitErrorMessage: `${t('views.auth.incorrectEmailOrPassword')}`, email: userHandle.email, password: '', isLoading: false });
        }
        else if (response.status === 401 && response.response.description === "Please verify your email address") {
            setUserHandle({ submitError: true, submitErrorMessage: `${t('views.auth.yourEmailAddressHasNotVerified')}`, email: userHandle.email, password: '', isLoading: false });
        }
        else {
            setUserHandle({ submitError: true, submitErrorMessage: `${t('navigation.tryAgain')}`, email: userHandle.email, password: '', isLoading: false });
        }
    };

    const getAccount = async (token) => {
        let res = await RequestFanbusy("accounts/self/creator/", 'GET', '', token);
        if (res.status === 200) {
            let action = {
                type: "ACCOUNT",
                value: res.response.data
            };
            dispatch(action);
            fetchGeoLocation(token);
        }
    };

    const fetchGeoLocation = async (token) => {
        let res = await RequestFanbusy("auth/locate-me", 'GET', '', token);
        if (res.status === 200) {
            let action = {
                type: "LOCALIZATION",
                value: res.response.data
            };
            dispatch(action);
            getAllCurrencies(token);
        }
    };

    const getAllCurrencies = async (token) => {
        let res = await RequestFanbusy(`currencies/?limit=${1000}&page=${1}`, 'GET', '', token);
        if (res.status === 200) {
            let action = {
                type: "CURRENCIES",
                value: res.response.data
            };
            dispatch(action);
            if (!profile) {
                navigate(Routes.HOME);
            } else {
                if (profileLogOrSignRef) {
                    profileLogOrSignRef.current.click()
                }
                setUserHandle((prev) => ({ ...prev, isLoading: false }));
            }
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    useEffect(() => {
        changeLanguage(currentLanguage);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentLanguage]);

    const changeLanguage = (language) => {
        let updateLanguage = language === 'fr-FR' || language === 'fr' ? 'fr' : 'en';
        i18n.changeLanguage(updateLanguage);

        let action = {
            type: "LANGUAGE",
            value: {
                language: updateLanguage,
                languageName: updateLanguage === 'fr' ? t('words.french') : t('words.english')
            }
        };
        dispatch(action);
        setCurrentLanguage(updateLanguage);
        localStorage.setItem('currentLanguage', updateLanguage);
    };

    return (
        <main style={{ paddingTop: profile ? 5 : 0 }} className={`${profile ? '' : 'col col-xl-6 order-xl-2 col-lg-12 order-lg-1 col-md-12 col-sm-12 col-12 mt-5 mb-5 mt-0 pt-0 mb-sm-none'} `}>
            <div className="main-content">
                <div className={`${profile ? 'mt-0' : 'mt-5'}`}>
                    <header className={`d-flex align-items-center  ${profile ? '' : "justify-content-center"}`} >
                        <img style={{ width: "150px", height: "30px", objectFit: "contain" }} src={Images.logov2} alt="Logo" className="web-none" />
                        <img style={{ width: "250px", height: "70px", objectFit: "contain" }} src={Images.logov2} alt="Logo" className="m-none" />
                    </header>
                    <div className="mb-3 fw">
                        <p className="mb-0 text-dark m-none text-center d-flex align-items-center ">
                            <label className={`${!profile ? 'fs-2' : 'fs-5'}`}>{!profile && t('views.auth.aWorlOfInfinite')}</label>
                            {/* {!profile && <img className="ms-4" src={require("../assets/img/2.png")} alt="" style={{ maxHeight: 110 }} />} */}
                        </p>
                        <p className={`mb-0 text-dark web-none ms-1 text-center  ${profile ? 'pt-0' : 'pt-3'}`}>
                            <span className={`${!profile ? 'fs-4' : 'fs-6'}`}>{!profile && t('views.auth.aWorlOfInfinite')}</span>
                            {creator && <CardProfile fan={false} creator={creator} />}
                        </p>
                    </div>
                </div>
                <div className="feeds">
                    <div className={`bg-white ${!profile ? 'py-4' : 'pt-4'} px-0 feed-item mb-3 faq-page mb-sm-none pb-sm-none`}>
                        <h6 className="lead mb-0 fs-6 text-gradient ">{t('views.auth.login')}</h6>
                        <div className="row justify-content-center">
                            <div className="col-lg-12">
                                <form>
                                    <div className=" form-floating border form-floating-focus-within rounded-5 mb-3 d-flex align-items-center">
                                        <input type="email" name="email" value={userHandle.email} onChange={textInputChange} className="form-control rounded-5 border-0" id="email" placeholder={t('views.auth.email')} />
                                        <label htmlFor="email">{t('views.auth.email')}</label>
                                    </div>
                                    <div className="form-floating mb-3 ">
                                        <div className=" form-floating border form-floating-focus-within rounded-5 d-flex align-items-center ">
                                            <input type={showPassword ? "text" : "password"} name="password" value={userHandle.password}
                                                onChange={textInputChange} className="form-control rounded-5 border-0" id="password" placeholder={t('views.auth.password')} />
                                            <label htmlFor="password">{t('views.auth.password')}</label>
                                            <span className="me-2 cursor-pointed" onClick={togglePasswordVisibility}>
                                                {showPassword ? <i className="fs-5"> <AiFillEyeInvisible size={17} /></i> : <i className="fs-5"><AiFillEye size={17} /></i>}
                                            </span>
                                        </div>
                                    </div>
                                    <p className="my-2 ps-0">
                                        {!profile ?
                                            <a href={`/auth/restore-account-request`} className="text-gradient text-bold text-decoration-none">{t('views.auth.restaureAccount')}</a>
                                            :
                                            <span className=" text-gradient border-0 bg-transparent  text-bold mb-sm-none ps-0" style={{ cursor: "pointer" }} onClick={() => restore(true)}>{t('views.auth.restaureAccount')}</span>
                                        }
                                    </p>
                                    <div className="d-grid">
                                        <button onClick={submitLogin} className="btn gradient-button text-white rounded-pill w-100 text-decoration-none py-2 fw-bold text-uppercase m-0 ">
                                            {userHandle.isLoading ? <Loader /> : `${t('views.auth.logIn')}`}
                                        </button>
                                    </div>
                                </form>
                            </div>
                            {userHandle.submitError && <div className="alert alert-danger alert-dismissible mt-2" role="alert">
                                <div>{userHandle.submitErrorMessage} </div>
                                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                            </div>}
                            <div className="mt-3 w-100 d-flex  ps-0">
                                <p className={`${!profile ? 'mb-sm-none' : 'mb-0'}`}>{t('views.auth.new_to_fanbusy')}{" "}
                                    {!profile ?
                                        <a href={`/auth/create`} className="text-gradient text-bold text-decoration-none mb-sm-none ps-0 ">{t('views.auth.create_new_account')}</a>
                                        :
                                        <span className=" text-gradient  border-0 bg-transparent  text-bold mb-sm-none ps-0 text-decoration-none" onClick={() => showLoginOrSign(false)} style={{ cursor: "pointer" }}> {t('views.auth.create_new_account')}</span>
                                    }
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main >
    );
};
export default LoginComponent;