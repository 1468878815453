
const Routes = {
    SIGN_UP_SUCCESS: '/auth/success',
    SIGN_UP: '/auth/create',
    SIGN_IN: '/auth/login',
    VERIFY_ACCOUNT: '/auth/verify',
    HOME: '/',
    NOTIFICATIONS: '/notifications',
    MESSAGES: '/messages',
    FOLLOWS: '/follows',
    MY_PROFILE: '/profile',
    VERIFICATION: '/id-verification-creator',
    VERIFICATION_MAIL: '/auth/verifymail'
};

export default Routes;