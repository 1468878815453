import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { convertCurrency } from "../Utils";

const CheckChat = ({ definedChatPrice, tchatFee, textInputchange, params, currentTchatFee }) => {
    const darkMode = useSelector((state) => state.theme.darkMode);
    const { t } = useTranslation();

    return (
        <div className="row justify-content-center">
            <div className="col-lg-12">
                <div className=" py-3 d-flex align-items-center mb-0">
                    <label for="activeTchat">
                        <span>{t('views.editProfile.paidChat')}</span>
                    </label>
                    <span className="ms-auto form-check form-switch">
                        <input checked={definedChatPrice} className="form-check-input mt-2 ms-0" type="checkbox" id="activeTchat" name="activeTchat" value={definedChatPrice} onChange={textInputchange} />
                    </span>
                </div>
                {definedChatPrice && (
                    <div className="form-floating mb-3  align-items-center mt-2">
                        <input type="text" className={`${darkMode && 'bg-dark text-white border-secondary'} form-control rounded-5`} name="tchat_fee" placeholder="Fee" value={tchatFee} onChange={textInputchange} />
                        <label className="text-bold" for="isFreeAccount"> {currentTchatFee <= 0 ? `${t('views.editProfile.freeChatPrice')}: ${params.currency}` : `${t('views.editProfile.yourCurrentChatPrice')}: ${convertCurrency(params.currency, params.currencies, currentTchatFee, 'GET')} ${params.currency}`} </label>
                    </div>
                )}
            </div>
        </div>
    );
};

export default CheckChat
