import React, { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// AppComponent
import { Avatar, Loader } from "../../Components";
import { convertCurrency, shortenName } from "../../Utils";
import { Images } from '../../Constants';
import { RequestFanbusy } from '../../Services';

const AddCollectionModal = () => {
    const closeRef = useRef();
    const users = useSelector(state => state.users);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const params = useSelector(state => state.params);
    const [medias, setMedias] = React.useState([]);
    const [state, setState] = React.useState({
        mediaType: '',
        is_free: false,
        title: "",
        description: "",
        price_of_release: 0,
        isLoaded: false,
        asFee: 0,
        oFee: 0,
    });

    const [amount, setAmount] = React.useState({
        maxAmount: 0,
        minAmount: 0,
    })

    const [alert, setAlert] = useState({
        msg: "",
        type: ""
    }
    );

    const handleDeleteFile = (index) => {
        const mediasCopy = [...medias].filter((_, i) => i !== index);
        setMedias(mediasCopy);
    }

    const handleFileChange = async ({ target }) => {
        const { files } = target;
        const mediasCopy = [...medias];

        for (let file of files) {
            let mediaType = file.type.split('/')[0];
            let url = URL.createObjectURL(file);
            mediasCopy.push({ url, file });
            setState({ ...state, mediaType });
        }
        setMedias(mediasCopy);
    }

    React.useEffect(() => {
        let maxAmountPost = convertCurrency(params.currency, params.currencies, 200, 'GET')
        let minAmountpost = convertCurrency(params.currency, params.currencies, 1, 'GET')
        setAmount({ ...amount, maxAmount: maxAmountPost, minAmount: minAmountpost });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.currencies, params.currency, users.users?._id]);

    const checkPriceOfRelease = () => {
        if (state.is_free && (isNaN(state.price_of_release) || state.price_of_release === '' || state.price_of_release === 0 || (state.price_of_release > parseFloat(amount.maxAmount.replace(/\s/g, '')) && state.price_of_release < parseFloat(amount.minAmount.replace(/\s/g, ''))))) {
            return true;
        } else {
            return false;
        }
    }

    const convertAmount = (amountConvert, type) => {
        let convert = convertCurrency(params.currency, params.currencies, amountConvert, type);
        return parseFloat(convert);
    }

    const addCollection = async () => {
        if (checkPriceOfRelease()) {
            setState({ ...state, releaseErrors: t('navigation.pleaseEnterAValidNumber') });
        } else {
            setState((prev) => ({ ...prev, isLoaded: true }));
            let files = [];
            let formData = new FormData();
            files = medias.map((media) => media.file);
            files.forEach((file) => formData.append('fileobject', file));
            let linkNewPost = 'collection/new/collection?titre=' + state.title + '&description=' + state.description + '&price_of_release=' + convertAmount(state.price_of_release, 'POST') + '&af_fee=' + convertAmount(state.price_of_release, 'POST') + '&as_fee=' + convertAmount(state.asFee, 'POST') + '&o_fee=' + convertAmount(state.oFee, 'POST')
            let newPost = await RequestFanbusy(linkNewPost, 'POST', formData, users.access_token);
            if (newPost.status === 201) {
                // let post = newPost.response.data
                // let data = {
                //     content: state.title,
                //     description: state.description,
                //     updated_at: post.updated_at
                // }
                // let res = await RequestFanbusy(`posts/${post._id}`, 'PUT', data, users.access_token);
                // if (res.response.data.status_code !== 200) {
                //     RequestFanbusy(`posts/${post._id}`, 'DELETE', users.access_token);
                // }
                setState({ ...state, title: '', description: "", is_free: false, price_of_release: 0, asFee: 0, oFee: 0, comment_disabled: false, isLoaded: false });
                setMedias([]);
                if (closeRef.current) {
                    closeRef.current.click();
                }
                dispatch({ type: "REFRESH", value: 1 });
            }
            else if (newPost.status === 401) {
                setState({ ...state, isLoaded: false });
                setAlert({ ...alert, msg: 'Your session has expired. Please log out and log back in to post.', type: "warning" })
            } else {
                setState({ ...state, isLoaded: false });
            }
        }
    }

    const textInputChange = (input) => {
        const target = input.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        switch (name) {
            case 'title':
                setState({ ...state, title: value });
                break;
            case 'isFree':
                setState({ ...state, is_free: value });
                break;
            case 'description':
                setState({ ...state, description: value });
                break;
            case 'price':
                setState({ ...state, price_of_release: value });
                break;
            case 'asFee':
                setState({ ...state, asFee: value });
                break;
            case 'oFee':
                setState({ ...state, oFee: value });
                break;
            default:
                break;
        }
    }
    return (
        <div className="modal fade show" id="post" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="NewPostModal" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="bg-white modal-content rounded-4 shadow-sm p-3 border-0">
                    <div className="modal-header d-flex align-items-center justify-content-start border-0 p-0 mb-3">
                        <div className="modal-title text-muted d-flex align-items-center" id="staticBackdropLabel">
                            <Avatar user={users.users} width={50} height={50} />
                            <div className="text-decoration-none d-flex flex-column text-capitalize ms-2">
                                <h6 className="fw-bold mb-0 text-body mt-0 text-capitalize d-flex align-items-center">
                                    {shortenName(users.users?.display_name, 15)}
                                    {users.users?.account_type === 'CREATOR' &&
                                        <span style={{ marginTop: '-4.7px' }} className="ms-1 p-0 md-16 fw-bold">{Images.verif}</span>
                                    }
                                </h6>
                                <small className="text-muted">@{shortenName(users.users?.pseudo, 10)}</small>
                            </div>
                        </div>
                    </div>
                    <h5>{t('views.collection.createCollection')}</h5>
                    <div className="modal-body p-0 mb-2">
                        {medias.length > 0 &&
                            <div className="d-flex mb-3 pb-2" style={{ maxWidth: 470, overflowX: 'auto' }}>
                                <div className="d-flex gap-2 flex-nowrap">
                                    {medias.map((media, index) => {
                                        let type = media.file.type.split('/')[0];

                                        if (type === 'image') {
                                            return (
                                                <div key={index} className="position-relative rounded-5 overflow-hidden containHover">
                                                    <img src={media.url} alt="post_picture" style={{ width: 100, height: 100 }} />
                                                    <div className="position-absolute top-0 bottom-0 end-0 start-0 bg-black opacity-50 rounded-5 overlayBackground">
                                                        <button className="position-absolute bg-transparent text-white border-0 overlayIcon p-2 rounded-circle" name="deleteImg" onClick={() => handleDeleteFile(index)}>
                                                            <span className="material-icons">delete</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            )
                                        } else {
                                            return (
                                                <div key={index} className="position-relative rounded-5 overflow-hidden containHover">
                                                    <video controls style={{ width: 200, height: 100 }}>
                                                        <source src={media.url} type={media.file.type} />
                                                    </video>
                                                    <button className="position-absolute bg-transparent text-white border-0 top-0 start-0 p-2 rounded-circle overlayDeleteMovie" name="deleteVideo" onClick={() => handleDeleteFile(index)}>
                                                        <span className="material-icons">delete</span>
                                                    </button>
                                                </div>
                                            )
                                        }
                                    })}
                                </div>
                            </div>
                        }
                        <div className="mb-1 d-flex align-items-center">
                            <input type="text" className="form-control rounded-5" id="Rising" placeholder={t('views.collection.title')} name="title" value={state.title} onChange={textInputChange} />
                        </div>
                        <div className="form-floating">
                            <textarea className="border form-control rounded-5" style={{ height: "100px" }} placeholder={t('form.new_post.whatnew_today') + ', ' + shortenName(users.users?.display_name, 15) + ' ?'} id="floatingTextarea2" name="description" value={state.description} onChange={textInputChange}></textarea>
                            {(state.description.length === 0) &&
                                <label htmlFor="floatingTextarea2" className="h6 text-muted mb-0">{t('views.collection.briefContent')}...</label>
                            }
                        </div>
                    </div>
                    <span className='fw-bold'>{t('views.collection.addDiscovery')}</span>
                    <div className="bg-white modal-footer border justify-content-start px-1 py-1 rounded-5 mb-2">
                        <div className="rounded-4 m-0 px-3 py-2 d-flex align-items-center justify-content-between w-100 gap-2">
                            <span className="fw-bold fs-6">Photo</span>
                            <label htmlFor="imageFileChild">
                                <span className="material-icons" style={{ fontSize: 30 }}>add_photo_alternate</span>
                                <input type="file" onChange={handleFileChange} id="imageFileChild" name="image" hidden accept=".jpg,.jpeg,.png" multiple />
                            </label>
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between gap-4 mb-2 px-2">
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" checked={state.is_free} value="" id="flexCheckDefault" name="isFree" onChange={textInputChange} />
                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                {state.is_free ? `${t('form.new_post.ppv')}` : `${t('form.new_post.ppv')}`}
                            </label>
                        </div>
                    </div>
                    {state.is_free &&
                        <div>
                            <input className="form-control form-control-sm w-100 mb-1" type="number" placeholder={`${t('form.new_post.price_of_releaseCol')} ${params.currency}`} name="price" value={state.price_of_release === 0 ? '' : state.price_of_release} onChange={textInputChange} />
                            <input className="form-control form-control-sm w-100 mb-1" type="number" placeholder={`${t('form.new_post.asFeeCol')} ${params.currency}`} name="asFee" value={state.asFee === 0 ? '' : state.asFee} onChange={textInputChange} />
                            <input className="form-control form-control-sm w-100" type="number" placeholder={`${t('form.new_post.oFeeCol')} ${params.currency}`} name="oFee" value={state.oFee === 0 ? '' : state.oFee} onChange={textInputChange} />
                            <p className="my-1" style={{ marginLeft: '8px' }}>Minimum {amount.minAmount} {params.currency}, Maximum {amount.maxAmount} {params.currency}</p>
                        </div>
                    }
                    <div className="mt-3">
                        <div className="d-flex align-items-center justify-content-end gap-3">
                            <button ref={closeRef} className="btn btn-block text-secondary p-0 rounded-pill fw-bold px-4 py-1" style={{ backgroundColor: "#F0F0F0" }} data-bs-dismiss="modal">{t('words.cancel')}</button>
                            <button onClick={() => addCollection()} className={(medias.length === 0 || (state.is_free && (state.price_of_release <= 0 || state.price_of_release > parseFloat(amount.maxAmount.replace(/\s/g, '')) || state.price_of_release < parseFloat(amount.minAmount.replace(/\s/g, ''))))) ? "btn btn-secondary btn-block rounded-pill fw-bold disabled  px-4 py-1" : "btn gradient-button text-white btn-block rounded-pill fw-bold px-4 py-1"}>
                                {state.isLoaded ?
                                    <Loader />
                                    :
                                    <div className="d-flex justify-content-center gap-1">
                                        <span className="material-icons">send</span>
                                        <span className="pb-0">{t('form.new_post.post')}</span>
                                    </div>
                                }
                            </button>
                        </div>
                        {alert.msg?.length > 0 &&
                            <div className={`alert alert-${alert.type} alert-dismissible mb-0 mt-3`} role="alert">
                                <div>{alert.msg}</div>
                                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AddCollectionModal;
