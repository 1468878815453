import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { MdOutlineChat } from 'react-icons/md';
import { FiMoreVertical } from 'react-icons/fi';
import { RiUserSearchLine, RiHome5Line } from 'react-icons/ri';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { GrFormClose } from 'react-icons/gr';
import { BiBookmarks } from 'react-icons/bi';
import { MdOutlineDiversity1 } from 'react-icons/md';
import { CgGift, CgProfile } from 'react-icons/cg';
import { TfiCreditCard } from 'react-icons/tfi';
import { BsCashStack } from 'react-icons/bs';
import { MdLanguage } from 'react-icons/md';
import { MdOutlineContactSupport } from 'react-icons/md';
import { MdOutlineDiversity2 } from 'react-icons/md';
import { FiSettings } from 'react-icons/fi';
import { RiLogoutBoxLine, RiLoginBoxLine } from 'react-icons/ri';
import { RxAvatar } from 'react-icons/rx';

import { NavigationBar } from '../Navigation/NavigationBar'
import { RequestFanbusy, TchatRequest, i18n } from '../Services';
import { Avatar, ShareModal } from '../Components';
import { convertCurrency, shortenName, useScreenSize } from '../Utils';
import { Images } from '../Constants';
// import { Svg } from '../Constants';
import { getUserAccount } from '../Utils/ApiRequest';
import { useWebSocket } from '../Services';


const AppContainer = ({ children }) => {
    const dataSocket = useWebSocket();
    const { id } = useParams();
    const pseudo = encodeURIComponent(id);
    const darkMode = useSelector((state) => state.theme.darkMode);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const refCloseNavModal = React.useRef();
    const users = useSelector(state => state.users)
    const params = useSelector(state => state.params);
    const screenSize = useScreenSize()
    const [isPopoverVisible, setPopoverVisible] = React.useState(false);
    const [accountType, setAccountType] = React.useState(users?.users?.account_type);
    const pathHome = window.location.pathname === '/';
    const [isShowProfile, setIsShowProfile] = React.useState(false);
    const [room, setRoom] = React.useState(0);
    const accountTyp = users.users?.account_type;
    let href;
    const [amount, setAmount] = React.useState({
        maxAmount: 0,
        minAmount: 0,
    });

    const [state, setState] = React.useState({
        postContent: '',
        mediaType: '',
        is_free: true,
        price_of_release: 0,
        comment_disabled: false,
        likes: 0,
        Comments: 0,
        isLoading: true,
        isLoaded: false,
        fanNumber: 0,
        followNumber: 0
    });

    const getAllCurrencies = async () => {
        let res = await RequestFanbusy(`currencies/?limit=${1000}&page=${1}`, 'GET', '', "");
        if (res.status === 200) {
            let action = {
                type: "CURRENCIES",
                value: res.response.data
            };
            dispatch(action);

        }
    };

    useEffect(() => {
        const fetchGeoLocation = async () => {
            let res = await RequestFanbusy("auth/locate-me", 'GET', '', "");
            if (res.status === 200) {
                let action = {
                    type: "LOCALIZATION",
                    value: res.response.data
                };
                dispatch(action);
            }
        };
        fetchGeoLocation()
        getAllCurrencies()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [users?.access_token])

    /*const useIsBack = () => {
            const location = useLocation();
            const [isBack, setIsBack] = React.useState(false);
    
            React.useEffect(() => {
                setIsBack(sessionStorage[location.pathname + "_key"] === location.key);
                sessionStorage[location.pathname + "_key"] = location.key;
            }, [location]);
    
            return isBack;
        }
    
        const isBack = useIsBack();
        if (isBack) {
            window.scrollTo(0, 0);
        }*/


    useEffect(() => {
        const fetchUnreadMessages = async () => {
            const userId = users.users?._id;
            const ResponseStatus = await TchatRequest('message/unread/all/room/' + userId, 'GET', '', '');
            if (ResponseStatus.status === 200) {
                setRoom(ResponseStatus.response.data)
            }
        };

        fetchUnreadMessages();
    }, [users.users?._id]);

    useEffect(() => {
        const getFanAndFollowNumber = async () => {
            let res = await RequestFanbusy(`creators/number/${users.users?._id}`, 'GET', '', users?.access_token);
            if (res?.status === 200) {
                setState({ ...state, fanNumber: res.response?.data.fan_number, followNumber: res.response.data?.follow_number });
            }
        }
        getFanAndFollowNumber()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [users?.access_token, users.users?._id]);

    useEffect(() => {
        if (dataSocket && dataSocket.type === 'room') {
            setRoom(dataSocket.number_room);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataSocket]);



    const handleClosePopup = () => {
        if (isPopoverVisible) { setPopoverVisible(false) }
    }

    React.useEffect(() => {
        i18n.changeLanguage(params.language);
        let maxAmountPost = convertCurrency(params.currency, params.currencies, 200, 'GET')
        let minAmountpost = convertCurrency(params.currency, params.currencies, 1, 'GET')
        setAmount({ ...amount, maxAmount: maxAmountPost, minAmount: minAmountpost })
        const handleScroll = () => {
            if (window.innerHeight) {
                setPopoverVisible(false);
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.language, params.currencies, params.currency]);


    window.addEventListener('scroll', function () {
        if (window.scrollY + window.innerHeight >= document.documentElement.scrollHeight) {
            window.scrollTo(0, document.documentElement.scrollHeight - window.innerHeight);
        }
    });

    useEffect(() => {
        let userAccount = getUserAccount(users?.access_token);
        userAccount.then((value) => {
            let action = {
                type: "ACCOUNT",
                value: value
            };
            dispatch(action);
        });

        if (accountType !== 'CREATOR') {
            const getSelfCreator = async (creatorId) => {
                let res = await RequestFanbusy(`creators/${creatorId}`, 'GET', '', users?.access_token);
                if (res?.status === 200) {
                    setAccountType(res.response?.data?.account_type);
                    let action = {
                        type: "LOGIN",
                        value: {
                            users: res.response?.data,
                            access_token: users.access_token
                        },
                    };
                    dispatch(action);
                }
            }
            getSelfCreator(users?.users?._id);
        }
    }, [users?.access_token, dispatch, users?.users?._id, accountType]);


    const logout = async () => {
        let res = await RequestFanbusy(`auth/logout`, 'GET', "", users.access_token);
        if (res.status === 200) {
            dispatch({ type: 'LOGOUT' });
            window.location.href = '/auth/login';
        }
    }

    if (accountTyp === "CREATOR") {
        href = "payment-details";
    } else if (accountTyp === "FANS") {
        href = "/become-creator";
    } else if (accountTyp === "REQUEST_CREATOR") {
        href = "/creator-request";
    }

    const CloseModals = (ref) => {
        try {
            if (ref.current) {
                ref.current.click();
            }
        } catch (error) {
        }
    }

    return (
        <>
            <div>
                {!(window.location.pathname.startsWith('/messages')) && !(window.location.pathname.startsWith('/pay4me')) && !(window.location.pathname.startsWith('/profile')) && !(window.location.pathname.startsWith(`/${pseudo}`)) && !(window.location.pathname === '/account') && !(window.location.pathname === '/collections') && !(window.location.pathname === '/become-creator') &&
                    <nav style={{ height: "60px" }} className={`web-none  border-bottom  navbar fixed-top navbar-light  d-flex justify-content-between align-items-center shadow-sm bg-white ${pathHome && ''}`}>
                        <ul style={{ width: "10%" }} className="nav ">
                            <li className={`ms-2 w-100`}>
                                <div className={"nav-link mb-4"}>
                                    {(() => {
                                        if (window.location.pathname === '/') {
                                            return <span className={` ${darkMode && 'text-light'}  'text-dark' navbar-brand fw-bold fs-6 text-uppercase `}>🏠 {t('navigation.home')}</span>;
                                        } else if (window.location.pathname === '/notifications') {
                                            return <span className={` ${darkMode && 'text-light'}  'text-dark' navbar-brand fw-bold fs-6 text-uppercase`}>  <div className='d-flex align-items-center'><a href={`/`} className={` material-icons text-decoration-none    text-dark`} > arrow_back </a> <label className='ms-2'> {t('navigation.notifications')}</label></div> </span>;
                                        } if (window.location.pathname === '/fans') {
                                            return <span className={` ${darkMode && 'text-light'}  'text-dark' navbar-brand fw-bold fs-6 text-uppercase`}> <div className='d-flex align-items-center'><a href={`/`} className={` material-icons text-decoration-none   text-dark`} > arrow_back </a> <label className='ms-2'> Fans </label></div> </span>;
                                        } else if (window.location.pathname === '/bookmarks') {
                                            return <span className={` ${darkMode && 'text-light'}  'text-dark' navbar-brand fw-bold fs-6 text-uppercase`}> <div className='d-flex align-items-center'><a href={`/`} className={` material-icons text-decoration-none   text-dark`} > arrow_back </a> <label className='ms-2'> {t('navigation.bookmarks')} </label></div> </span>;
                                        } else if (window.location.pathname === '/follows') {
                                            return <span className={` ${darkMode && 'text-light'}  'text-dark' navbar-brand fw-bold fs-6 text-uppercase`}> <div className='d-flex align-items-center'><a href={`/`} className={` material-icons text-decoration-none   text-dark`} > arrow_back </a> <label className='ms-2'> {t('navigation.subscriptions')}</label></div> </span>;
                                        } else if (window.location.pathname === '/profile') {
                                            return <span className={` ${darkMode && 'text-light'}  'text-dark' navbar-brand fw-bold fs-6 text-uppercase`}> <div className='d-flex align-items-center'><a href={`/`} className={` material-icons text-decoration-none   text-dark`} > arrow_back </a> <label className='ms-2'>{t('navigation.my_profile')}</label></div> </span>;
                                        } else if (window.location.pathname === '/edit_profile') {
                                            return <span className={` ${darkMode && 'text-light'}  'text-dark' navbar-brand fw-bold fs-6 text-uppercase`}> <div className='d-flex align-items-center'><a href={`/`} className={` material-icons text-decoration-none   text-dark`} > arrow_back </a> <label className='ms-2'> {t('navigation.editMyProfile')}</label></div> </span>;
                                        } else if (window.location.pathname === '/settings') {
                                            return <span className={` ${darkMode && 'text-light'}  'text-dark' navbar-brand fw-bold fs-6 text-uppercase`}> <div className='d-flex align-items-center'><a href={`/`} className={` material-icons text-decoration-none   text-dark`} > arrow_back </a> <label className='ms-2'> {t('navigation.settings')} </label></div> </span>;
                                        } else if (window.location.pathname === '/help_and_support') {
                                            return <span className={` ${darkMode && 'text-light'}  'text-dark' navbar-brand fw-bold fs-6 text-uppercase`}> <div className='d-flex align-items-center'><a href={`/`} className={` material-icons text-decoration-none   text-dark`} > arrow_back </a> <label className='ms-2'>{t('navigation.helpFan')}</label></div> </span>;
                                        } else if (window.location.pathname === '/help-and-support-creator') {
                                            return <span className={` ${darkMode && 'text-light'} 'text-dark' navbar-brand fw-bold fs-6 text-uppercase`}> <div className='d-flex align-items-center'><a href={`/`} className={` material-icons text-decoration-none   text-dark`} > arrow_back </a> <label className='ms-2'> {t('navigation.helpCreator')}</label></div> </span>;
                                        } else if (window.location.pathname === '/help-and-support') {
                                            return <span className={` ${darkMode && 'text-light'}  'text-dark' navbar-brand fw-bold fs-6 text-uppercase`}>  <div className='d-flex align-items-center'> <a href={`/`} className={` material-icons text-decoration-none   text-dark`} > arrow_back </a> <label className='ms-2'>{t('navigation.help_and_support')} </label>   </div> </span>;
                                        } else if (window.location.pathname === '/become-creator') {
                                            return <span className={` ${darkMode && 'text-light'}  'text-dark' navbar-brand fw-bold fs-6 text-uppercase `}> <div className='d-flex align-items-center'><a href={`/`} className={` material-icons text-decoration-none   text-dark`} > arrow_back </a> <label className='ms-2'>{t('navigation.become_a_creator')}</label></div> </span>;
                                        } else if (window.location.pathname === '/searchresult') {
                                            return <span className={` ${darkMode && 'text-light'}  'text-dark' navbar-brand fw-bold fs-6 text-uppercase  `}> <div className='d-flex align-items-center'><a href={`/`} className={` material-icons text-decoration-none   text-dark`} > arrow_back </a> <label className='ms-2'>{t('navigation.search')}</label></div> </span>;
                                        } else if (window.location.pathname.startsWith(`/profile`)) {
                                            return <span className={` ${darkMode && 'text-light'}  'text-dark' navbar-brand fw-bold fs-6 text-uppercase `}> <div className='d-flex align-items-center'><a href={`/`} className={` material-icons text-decoration-none   text-dark`} > arrow_back </a> <label className='ms-2'> {t('navigation.profileCreator')}</label></div> </span>;
                                        } else if (window.location.pathname === '/id-verification-creator') {
                                            return <div className={` ${darkMode && 'text-light'} 'text-dark' navbar-brand fw-bold fs-6 text-uppercase pt-0`}> <div className='d-flex align-items-center'><a href={`/`} className={` material-icons text-decoration-none   text-dark`} > arrow_back </a><label className='ms-2'>{t('navigation.idVerification')}</label></div> </div>;
                                        } else if (window.location.pathname === '/payment-details') {
                                            return <div className={` ${darkMode && 'text-light'} 'text-dark' navbar-brand fw-bold fs-6 text-uppercase pt-0`}> <div className='d-flex align-items-center'><a href={`/`} className={` material-icons text-decoration-none   text-dark`} > arrow_back </a><label className='ms-2'>{t('navigation.payementDetails')}</label></div> </div>;
                                        } else if (window.location.pathname === '/add-card') {
                                            return <div className={` ${darkMode && 'text-light'} 'text-dark' navbar-brand fw-bold fs-6 text-uppercase pt-0`}> <div className='d-flex align-items-center'><a href='/account' className={` material-icons text-decoration-none   text-dark`} > arrow_back </a><label className='ms-2'>{t('navigation.addcard')}</label></div> </div>;
                                        } else if (window.location.pathname === '/creator-request') {
                                            return <div className={` ${darkMode && 'text-light'} 'text-dark' navbar-brand fw-bold fs-6 text-uppercase pt-0`}> <div className='d-flex align-items-center'><a href={`/`} className={` material-icons text-decoration-none   text-dark`} > arrow_back </a><label className='ms-2'>{t('navigation.become')}</label></div> </div>;
                                        } else if (window.location.pathname.startsWith('/post/')) {
                                            return <div className={` ${darkMode && 'text-light'} 'text-dark' navbar-brand fw-bold fs-6 text-uppercase pt-0`}> <div className='d-flex align-items-center'><a href={`/`} className={` material-icons text-decoration-none   text-dark`} > arrow_back </a><label className='ms-2'>{t('navigation.post')}</label></div> </div>;
                                        }
                                        else {
                                            return null
                                        }
                                    })()}
                                </div>
                            </li>
                        </ul>
                        <ul style={{ width: "70%" }} className="nav item d-flex align-items-center justify-content-end  ">
                            {pathHome &&
                                <>
                                    {users.users?.account_type === 'CREATOR' ?
                                        <button className={`mb-4 cursor-pointer pe-2 material-icons border-0 fb-color bg-transparent text-decoration-none text-dark`} style={{ paddingLeft: 7 }} data-bs-toggle="modal" data-bs-target={`#shareModal${users.users?.pseudo}`}>
                                            <FiMoreVertical style={{ verticalAlign: 'unset' }} />
                                        </button>
                                        :
                                        <NavLink to={`${users.users?.account_type === "REQUEST_CREATOR" ? '/creator-request' : '/become-creator'}`} className={`mb-4 cursor-pointer pe-2 material-icons border-0 fb-color bg-transparent text-decoration-none text-dark`} style={{ paddingLeft: 7 }}><FiMoreVertical style={{ verticalAlign: 'unset' }} /></NavLink>
                                    }
                                </>
                            }
                            <div style={{ paddingBottom: "25px" }} className='d-flex align-items-center'>
                                {window.location.pathname === "/help-and-support" && <NavLink to={`/help-and-support-creator`}
                                    className="d-flex col text-decoration-none justify-content-end" >
                                    <p className={`${darkMode ? 'text-white' : 'fb-color '}  mb-0 fw-bold fs-6 small`}>For creators</p>
                                    <span className={`${darkMode ? 'text-white' : 'fb-color '} material-icons text-decoration-none  me-3 pt-1 small`}> arrow_forward </span>
                                </NavLink>}
                            </div>
                        </ul>
                    </nav >}

                <div className={`${darkMode && 'bg_fan text-white'}  `} onClick={() => handleClosePopup()} >
                    <div className={`container `} style={{ minHeight: "100vh", padding: screenSize === 'Extra Small' || screenSize === 'Small' || screenSize === 'Medium' ? '0px 0px 0px 0px' : '', }}>
                        <div className="row custom-scrollbar">
                            <NavigationBar roomUnread={room} CloseModals={CloseModals} />
                            {children}
                        </div>
                    </div>

                    {!(window.location.pathname.startsWith('/messages/my/chat/')) &&
                        <nav style={{ height: "50px", zIndex: 1051 }} className={`${darkMode ? 'bg-dark border-bottom border-secondary' : 'bg-white'} web-none   fixed-bottom navbar-light d-flex justify-content-strech border-top align-items-center border-gray`}>
                            <ul style={{ paddingTop: "9px", paddingBottom: "calc(30px + env(safe-area-inset-bottom))" }} className="nav d-flex justify-content-between align-items-center w-100 bg-white fixed px-4 border-nav">
                                <li>
                                    <a href={`/`} style={{ padding: "0rem 0rem" }}>
                                        <span className={`nav_color ${window.location.pathname === `${`/`}` ? `${darkMode ? 'text-white' : 'text-dark'}` : ''} `}> <RiHome5Line size={30} /></span>
                                    </a>
                                </li>
                                {users.access_token &&
                                    <li>
                                        <a href='/searchresult' style={{ padding: "0rem 0rem" }}>
                                            <span className={`nav_color ${window.location.pathname === `${`/searchresult`}` ? `${darkMode ? 'text-white' : 'text-dark'}` : ''} `}> <RiUserSearchLine size={27} /></span>
                                        </a>
                                    </li>
                                }
                                {(users.authenticated && users.access_token) && (
                                    (users.users?.account_type === "CREATOR" || accountType === "CREATOR") ? (
                                        <li >
                                            <NavLink to={`/`} style={{ padding: "0rem 0rem" }} className="" data-bs-toggle="modal" data-bs-target="#postModal">
                                                <img src={Images.plus} alt="" className={`nav_color`} style={{ width: '42px', height: "42px" }} />
                                            </NavLink>
                                        </li>
                                    ) : (
                                        <li>
                                            <NavLink to={`#`} style={{ padding: "0rem 0rem" }}>
                                                <span onClick={() => setPopoverVisible(!isPopoverVisible)} className={`nav_color `}>
                                                    <img src={Images.plus} alt="" className={`nav_color`} style={{ width: '42px', height: "42px" }} />
                                                </span>
                                                {isPopoverVisible && (
                                                    <div className="position-absolute bottom-50 start-50 translate-middle-x p-3 rounded-3 border bg-white d-flex flex-column nowrap" style={{ marginBottom: '35px' }}>
                                                        <p className="m-0 mb-2 w-auto text-black">{t('views.home.you_cannot_publish')}</p>
                                                        <NavLink to={`${users.users?.account_type === "REQUEST_CREATOR" ? '/creator-request' : '/become-creator'}`} className="btn fb-btn-color text-decoration-none text-white rounded-pill fs-xSmall">{t('navigation.become_a_creator')}</NavLink>
                                                    </div>
                                                )}
                                            </NavLink>
                                        </li>
                                    )
                                )}
                                {(users.authenticated && users.access_token) &&
                                    <li>
                                        <a href='/messages' style={{ padding: "0rem 0rem" }} className={({ isActive }) => isActive ? "nav-link active" : "nav-link"}>
                                            <span style={{ fontSize: "28px" }} className={`nav_color ${window.location.pathname === `${`/messages`}` ? `${darkMode ? 'text-white' : 'text-dark'}` : ''}`}> <MdOutlineChat size={29} /></span>
                                            {room > 0 &&
                                                <span style={{ marginTop: '11px', marginLeft: '-5px', backgroundColor: "red" }} className={`position-absolute  translate-middle badge fb-btn-color rounded-pill me-5 ${darkMode ? 'text-white' : 'text-white'} border border-2 border-white `}>
                                                    {room}
                                                </span>
                                            }
                                        </a>
                                    </li>
                                }

                                <li>
                                    {
                                        (users.authenticated && users.access_token) ?

                                            <div style={{ paddingTop: "4px" }}>
                                                <div data-bs-target='#supermodal' data-bs-toggle='modal' style={{ padding: "0rem 0rem", textDecoration: "none" }} >
                                                    <Avatar user={users.users} width={35} height={35} />
                                                </div>
                                            </div>
                                            :
                                            <div style={{ paddingTop: "4px" }} >
                                                <div className='text-dark fw-bold' data-bs-target='#supermodal' data-bs-toggle='modal' style={{ padding: "0rem 0rem", color: '#8B95A3' }} >
                                                    <RxAvatar size={30} color='#8B95A3' />
                                                </div>
                                            </div>
                                    }
                                </li>
                            </ul>
                        </nav>
                    }
                </div>


                <div className='modal fade' id='supermodal' data-bs-keyboard="false" tabIndex="-1" aria-labelledby="superModal" aria-hidden="true" >
                    <div className='modal-dialog '>
                        <div className=' modal-content border-0'>
                            <div className="fix-sidebar" style={{ width: "65%", display: "flex", flexDirection: "column", justifyContent: "space-between", height: "calc(100% - 63.7px)", position: 'fixed', top: 0, right: 0, backgroundColor: 'white', overflowY: "scroll" }} >
                                <div className={`sidebar-nav mb-3  ${darkMode && "bg-dark"}  `}>
                                    <div className="d-flex justify-content-between align-items-center pt-2 ms-2">
                                        {users.access_token ?
                                            <a href={`/profile`} className="text-decoration-none">
                                                <header className="profile d-flex align-items-center">
                                                    <Avatar user={users.users} width={40} height={40} fontSize="fs-5" />
                                                    <div className='ms-1 d-flex row'></div>
                                                </header>
                                            </a>
                                            :
                                            <div style={{ textDecoration: "none" }} className="">
                                                <header style={{ marginLeft: '7px' }} className="profile d-flex align-items-center ">
                                                    <RxAvatar size={30} />
                                                </header>
                                            </div>
                                        }
                                        {
                                            <button ref={refCloseNavModal} style={{ backgroundColor: "#F0F0F0" }} type="button" className="close p-1 border-0 rounded-circle  d-flex justify-content-end me-2" data-bs-dismiss="modal" aria-label="Close">
                                                <GrFormClose size={15} color='gray' />
                                            </button>
                                        }
                                    </div>
                                    <ul style={{ overflowY: "scroll" }} className={`navbar-nav justify-content-end flex-grow-1 overflow-auto`}>
                                        {users.access_token &&
                                            <li className=" bg-transparent  py-2 ps-2">
                                                <button onClick={() => setIsShowProfile(!isShowProfile)}
                                                    style={{ textDecoration: "none", backgroundColor: "transparent", border: "none", width: "100%", justifyContent: "space-between" }} className="d-flex align-items-center justify-content-between pe-3  ps-3">
                                                    <span style={{ marginLeft: '-7.5px' }} className=" text-secondary fw-bold fs-6 text-capitalize"> {shortenName(users.users?.display_name, 15)}</span>
                                                    {isShowProfile ? <IoIosArrowUp size={18} /> : <IoIosArrowDown size={18} />}
                                                </button>
                                                {isShowProfile &&
                                                    <div className='py-2'>
                                                        <div className='pb-2  ps-2'><label>@{shortenName(users.users.pseudo, 15)} </label> </div>
                                                        {users.users?.account_type === 'CREATOR' && <span className='pe-5  ps-2 text-dark fw-bold'> {state?.fanNumber} <label className='ps-1'>{state?.fanNumber > 1 ? t('words.fans') : t('words.fan')}</label> </span>}
                                                        <span className='text-dark fw-bold  ps-2'> {state.followNumber} <label className='ps-1 ' > {state.followNumber > 1 ? t('words.follows') : t('words.follow')} </label> </span>
                                                    </div>}
                                            </li>
                                        }
                                        {((users.users?.account_type) === "CREATOR" && users.access_token) &&
                                            <li className="nav-item bg-transparent border-top">
                                                <a
                                                    style={{ textDecoration: "none" }} href={`/fans`} className={({ isActive }) => isActive ? `active text-decoration-none ${darkMode && 'bg-transparent text-white'} ` : `nav-link ${darkMode && 'bg-transparent text-decoration-none  '} text-decoration-none`}>
                                                    <span className="me-1 text-dark">  <MdOutlineDiversity2 size={18} /> </span>
                                                    <span style={{ marginLeft: 4 }} className=' text-dark fw-bold fs-6' >{t('navigation.fans')}</span>
                                                </a>
                                            </li>
                                        }

                                        {users.access_token && <>
                                            <li className="nav-item bg-transparent border-top">
                                                <a
                                                    style={{ textDecoration: "none" }} href={`/profile`} className={({ isActive }) => isActive ? `active text-decoration-none ${darkMode && 'bg-transparent text-white'} ` : `nav-link ${darkMode && 'bg-transparent text-decoration-none  '} text-decoration-none`}>
                                                    <span className="me-1 text-dark">  <CgProfile size={18} /> </span>
                                                    <span style={{ marginLeft: 4 }} className=' text-dark fw-bold fs-6' >{t('navigation.my_profile')}</span>
                                                </a>
                                            </li>
                                            <li className="nav-item bg-transparent">
                                                <a
                                                    style={{ textDecoration: "none" }} href={`/follows`} className={({ isActive }) => isActive ? `active text-decoration-none ${darkMode && 'bg-transparent text-white'} ` : `nav-link ${darkMode && 'bg-transparent text-decoration-none  '} text-decoration-none`}>
                                                    <span className="me-1 text-dark">  <MdOutlineDiversity1 size={18} /> </span>
                                                    <span style={{ marginLeft: 4 }} className=' text-dark fw-bold fs-6' >{t('navigation.subscriptions')}</span>
                                                </a>
                                            </li>
                                            <li className="nav-item    ">
                                                <a href={`/bookmarks`} style={{ textDecoration: "none" }} className={({ isActive }) => isActive ? ` active ${darkMode && ' bg-transparent text-white  '} ` : `nav-link ${darkMode && 'bg-transparent'}`}>
                                                    <span className=" me-1 text-dark "> <BiBookmarks size={18} /></span>
                                                    <span style={{ marginLeft: 4 }} className=' text-dark fw-bold fs-6' >{t('navigation.bookmarks')}</span>
                                                </a >
                                            </li >
                                            {/* <li className="nav-item ms-1">
                                                <a href={`/collections`} style={{ textDecoration: "none", marginLeft: -3 }} className={({ isActive }) => isActive ? `nav-link active ` : `nav-link `}>
                                                    // <span class="me-3 material-icons"> auto_stories </span> 
                                                    <span className='me-1 ms-0 ps-0'><Svg name={'collection'} height='18px' width='18px' /></span>
                                                    <span className='text-dark fw-bold fs-6' style={{ marginLeft: 4 }}>{t('navigation.collections')}</span>
                                                </a>
                                            </li> */}
                                            <li className="nav-item border-bottom">
                                                <a href={`/settings`} style={{ textDecoration: "none" }} className={({ isActive }) => isActive ? ` active ${darkMode && 'bg-transparent text-white'} ` : `nav-link ${darkMode && 'bg-transparent'}`}>
                                                    <span className=" me-1 text-dark "> <FiSettings size={18} /> </span>
                                                    <span style={{ marginLeft: 4 }} className=' text-dark fw-bold fs-6' >{t('navigation.settings')}</span>
                                                </a>
                                            </li>


                                            <li className="nav-item">
                                                <a href={`/account`} style={{ textDecoration: "none" }} className={({ isActive }) => isActive ? ` active ${darkMode && 'bg-transparent text-white '} ` : `nav-link ${darkMode && 'bg-transparent '}`}>
                                                    <span className=" me-1 text-dark "> <TfiCreditCard size={18} /></span>
                                                    <span style={{ marginLeft: 4 }} className=' text-dark fw-bold ps-1 fs-6' >{t('navigation.my_wallet')}</span>
                                                </a>
                                            </li>
                                            <li className="nav-item border-bottom">
                                                <a href={href} style={{ textDecoration: "none" }} className={({ isActive }) => isActive ? ` active ${darkMode && 'bg-transparent text-white '} ` : `nav-link ${darkMode && 'bg-transparent '}`}>
                                                    <span className=" me-1 text-dark "> <BsCashStack size={18} /></span>
                                                    <span style={{ marginLeft: 4 }} className=' text-dark fw-bold ps-1 fs-6' >{t('navigation.monetization')}</span>
                                                </a>
                                            </li>
                                            <li className="nav-item border-bottom">
                                                <a href={'/pay4me'} style={{ textDecoration: "none" }} className={({ isActive }) => isActive ? ` active ${darkMode && 'bg-transparent text-white '} ` : `nav-link ${darkMode && 'bg-transparent '}`}>
                                                    <span className=" me-1 text-dark "> <CgGift size={24} /></span>
                                                    <span style={{ marginLeft: 4 }} className=' text-dark fw-bold ps-1 fs-6' >{t('navigation.paidForMe')}</span>
                                                </a>
                                            </li>
                                        </>
                                        }
                                        <li className={`nav-item ${!users.access_token && 'pt-2 border-top'} `}>
                                            <a onClick={() => { CloseModals(refCloseNavModal) }} data-bs-toggle="modal" data-bs-target="#languageModal" href={`/`} style={{ textDecoration: "none" }} className={({ isActive }) => isActive ? ` active ${darkMode && 'bg-transparent text-white'} ` : `nav-link ${darkMode && 'bg-transparent'}`}>
                                                <span className=" me-1 text-dark "> <MdLanguage size={20} /></span>
                                                <span style={{ marginLeft: 4 }} className=' text-dark fw-bold fs-6' >{t('navigation.language')}</span>
                                            </a>
                                        </li>
                                        <li className="nav-item border-bottom">
                                            <a href={`/help-and-support`} style={{ textDecoration: "none" }} className={({ isActive }) => isActive ? ` active ${darkMode && 'bg-transparent text-white'} ` : `nav-link ${darkMode && 'bg-transparent '}`}>
                                                <span className=" me-1  text-dark "> <MdOutlineContactSupport size={20} /></span>
                                                <span style={{ marginLeft: 4 }} className=' text-dark fw-bold fs-6' >{t('navigation.help_and_support')}</span>
                                            </a>
                                        </li>
                                        {users.access_token ?
                                            <li className="nav-item">
                                                <a href onClick={() => logout()} style={{ textDecoration: "none" }} className={({ isActive }) => isActive ? ` active ${darkMode && 'bg-transparent text-white'} ` : `nav-link ${darkMode && 'bg-transparent'}`}>
                                                    <span className=" me-1 text-dark "> <RiLogoutBoxLine size={18} /> </span>
                                                    <span style={{ marginLeft: 4 }} className=' text-dark fw-bold fs-6' >{t('navigation.log_out')}</span>
                                                </a>
                                            </li>
                                            :
                                            <li className="nav-item">
                                                <a href={`/auth/login`} style={{ textDecoration: "none" }} className={({ isActive }) => isActive ? ` active ${darkMode && 'bg-transparent text-white'} ` : `nav-link ${darkMode && 'bg-transparent'}`}>
                                                    <span className=" me-1 text-dark "> <RiLoginBoxLine size={18} /> </span>
                                                    <span style={{ marginLeft: 4 }} className=' text-dark fw-bold fs-6' >{t('navigation.logIn')}</span>
                                                </a>
                                            </li>
                                        }
                                    </ul >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ShareModal share={`${t('navigation.shareProfile')}`} id={users.users?.pseudo} />
            </div>
        </>
    )
}
export default AppContainer;