import { useTranslation } from "react-i18next"

const ButtonAddCollection = () => {
    const { t } = useTranslation()
    return (
        <div className="col-6" style={{ height: "200px", width: "180px" }}>
            <div className="card h-100">
                <div className="d-flex align-items-center justify-content-center" style={{ height: '200px' }}>
                    <div className="text-center pt-3">
                        <button className="btn gradient-button text-decoration-none rounded-circle pt-2 border-0" data-bs-target='#post' data-bs-toggle="modal">
                            <span className="material-icons text-white pt-1 ">add</span>
                        </button>
                        <p className="card-text fs-6 fw-bold mt-1">{t('views.collection.addCollection')}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ButtonAddCollection