import { useRouteError } from "react-router-dom";

import { Images } from "../../../Constants";

const SignupSuccessView = () => {
    const error = useRouteError();
    console.error(error);

    return (
        <div className="p-5 text-center">
            <img src={Images.send_mail} alt="" width={370} />
            <div className="text-center pb-5">
                <h2 className="fw-bold text-black mt-4">Congratulations !</h2>
                <p className="mb-4 fs-6">Your account has been successfully created and a validation email will be sent to you to validate your email address</p>
                <a href={`/auth/login`} className="btn fb-btn-color text-white rounded-pill py-3 px-4 shadow-sm">
                    <span className="px-3">
                        Login
                    </span>
                </a>
            </div>
        </div>
    )
}

export default SignupSuccessView;