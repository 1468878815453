import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { completeDateFormat, convertCurrency } from "../Utils";


const CardPayment = ({ data, style }) => {
    const { t } = useTranslation();
    const darkMode = useSelector((state) => state.theme.darkMode);

    const params = useSelector((state) => state.params);

    const getConvertAmount = (balance) => {
        return convertCurrency(params.currency, params.currencies, balance, 'GET');
    };

    const getConvertedAmounts = (transactions, amountGetter) => {
        const convertedAmounts = [];

        for (const transaction of transactions) {
            const convertedAmount = getConvertAmount(amountGetter(transaction));
            convertedAmounts.push(convertedAmount);
        }

        return convertedAmounts;
    };

    function translateActions(action) {
        if (action === 'Post libérer') {
            return t('views.account.postUnlock')
        } if (action === 'création pay4me') {
            return t('views.account.pay4meCodeCreated')
        } if (action === 'Abonnement à un créateur') {
            return t('views.account.creatorSubscription')
        } if (action === 'Solde du compte créditer') {
            return t('views.account.accountCrediting')
        } if (action === 'Demande de retrait' || action === "Solde du compte  Débiter") {
            return t('views.account.withdrawalRequest')
        } if (action === 'Laisser un pourboire') {
            return t('views.account.leaveTip')
        } if (action === 'Debloquer messagerie') {
            return t('views.account.messageRelease')
        } if (action === 'Debloquer chat') {
            return t('views.account.chatRelease')
        } else {
            return action
        }

    }

    const renderTableRows = () => {
        const convertedAmounts = getConvertedAmounts(data, transaction => transaction.amount);
        const convertedAmountsInterest = getConvertedAmounts(data, transaction => transaction.amount_interest);
        const convertedAmountsCreator = getConvertedAmounts(data, transaction => transaction.amount_of_creator);

        return data.map((transaction, index) => (
            <React.Fragment key={index}>
                <tr >
                    <td className="ps-2">{completeDateFormat(transaction.updated_at, t)}</td>
                    <td>{transaction.type === 'DEBIT' ? '- ' : ''}{convertedAmounts[index]} {params.currency}</td>
                    <td>{convertedAmountsInterest[index]} {params.currency}</td>
                    <td>{transaction.type === 'DEBIT' ? '- ' : ''}{convertedAmountsCreator[index]} {params.currency}</td>
                </tr>
                <tr>
                    <td colSpan="3" className="fb-color ps-2">{translateActions(transaction.label)}</td>
                    <td >
                        {(transaction.status === "PENDING" || transaction.status === "PENDING_VALIDATION" || transaction.status === "REQUEST_PAYMENT") && <span className="badge rounded-pill text-bg-warning bg-warning">{t('views.account.pending')}</span>}
                        {transaction.status === "SUCCESS" && <span className="badge rounded-pill text-bg-success bg-success">{t('words.success')}</span>}
                        {transaction.status === "ERROR" && <span className="badge rounded-pill text-bg-danger bg-danger">{t('words.danger')}</span>}
                    </td>
                </tr>
            </React.Fragment>
        ));
    };

    return (
        <table className={`${darkMode && 'text-white'} table mb-0`} style={{ fontSize: '0.7em', ...style }}>
            <thead className="fix-sidebar top-0 bg-white">
                <tr>
                    <th scope="col" className="ps-2">{t('navigation.date')}</th>
                    <th scope="col">{t('navigation.amount')}</th>
                    <th scope="col">{t('views.account.fee')}</th>
                    <th scope="col">{t('views.account.net')}</th>
                </tr>
            </thead>
            <tbody>
                {renderTableRows()}
            </tbody>
        </table>
    )
}

export default CardPayment;