import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { useScreenSize } from "../../Utils";
import { Loader } from "../../Components";
import { AppContainer, AsideContainer } from "../../Containers";
import { RequestFanbusy } from "../../Services";
import { Images } from "../../Constants";



export const VerifyingSubcriptions = () => {
    const { t } = useTranslation();
    const darkMode = useSelector((state) => state.theme.darkMode);
    const userDatas = useSelector((state) => state.users.users);
    const token = useSelector((state) => state.users.access_token);
    const [editDatas, setEditDatas] = useState({
        full_name: userDatas.full_name,
        pseudo: userDatas.pseudo,
        email: userDatas.email,
        phone_number: userDatas.phone_number,
        display_name: userDatas.display_name,
        bio: userDatas.bio,
        photo: userDatas.photo,
        account_type: userDatas.account_type,
        banner: userDatas.banner,
        localisation: userDatas.localisation,
        web_site: userDatas.web_site,
        is_free_account: userDatas.is_free_account,
        subscription_fee: userDatas.subscription_fee,
        active_tchat: userDatas.active_tchat,
        tchat_fee: userDatas.tchat_fee,
        isValidLink: true,
        id_image: "",
        id_card_image: "",
        id_type: "",
        updated_at: userDatas.updated_at,
    });
    const [isLOading, setIsLoding] = useState(false);
    const [selecttedIdImage, setSelecttedIdImage] = useState();
    const [previewIdImage, setPrewiewIDImage] = useState(userDatas.banner);
    const [selectedIdCardImage, setSelectedIdCardImage] = useState();
    const [previewIdCardImage, setPreviewIdCardImage] = useState(userDatas.photo);
    const screenSize = useScreenSize()
    const isMobile = screenSize === 'Extra Small' || screenSize === 'Small' || screenSize === 'Medium'
    const [showToast, setShowToast] = useState(false);
    const [isEmptyImages, setIsEmptyImages] = useState(false);
    const [isEmptyIdImage, setIsEmptyIdImages] = useState(false);
    const [isEmptyIdCard, setIsEmptyIdCard] = useState(false);
    const [isEmptyDocType, setIsEmptyDoctype] = useState(false);
    const [badReuest, setBadRequest] = useState(false);
    const [showMore, setShowMore] = useState(true);
    const [isChecked, setIsChecked] = useState(false);

    const textInputChange = (e) => {
        const { name, value } = e.target;
        setEditDatas((prevEditDatas) => ({ ...prevEditDatas, [name]: value, }));
    };

    const handleChangeIdImage = (event) => {
        const imageFile = event.target.files[0];
        setSelecttedIdImage(imageFile);

        const reader = new FileReader();
        reader.onload = () => {
            setPrewiewIDImage(reader.result);
        };
        reader.readAsDataURL(imageFile);
    };

    const handleChangeIdCardImage = (event) => {
        const imageFile = event.target.files[0];
        setSelectedIdCardImage(imageFile);
        const reader = new FileReader();
        reader.onload = () => {
            setPreviewIdCardImage(reader.result);
        };
        reader.readAsDataURL(imageFile);
    };

    const submitEdit = async (event) => {
        event.preventDefault();
        setIsLoding(true)
        try {
            let idImage = "";
            let idCardImage = "";

            if (selecttedIdImage instanceof File) {
                const formBanner = new FormData();
                formBanner.append("fileobject", selecttedIdImage);
                const bannerResponse = await RequestFanbusy("uploads/PROFILE", "POST", formBanner, token);
                if (bannerResponse.response && bannerResponse.response.data) {
                    idImage = bannerResponse.response.data.link;
                }
            }

            if (selectedIdCardImage instanceof File) {
                const formProfil = new FormData();
                formProfil.append("fileobject", selecttedIdImage);
                const profilResponse = await RequestFanbusy("uploads/PROFILE", "POST", formProfil, token);
                if (profilResponse.response && profilResponse.response.data) {
                    idCardImage = profilResponse.response.data.link;
                }
            }

            const form = {
                id_image: idImage,
                id_card_image: idCardImage,
                id_type: editDatas.id_type,
                account_type: "CREATOR"
            };
            if (form.id_card_image === "" && form.id_image === "") {
                setIsEmptyImages(true)
                setIsLoding(false)
            }
            else if (form.id_type === "") {
                setIsEmptyDoctype(true)
                setIsLoding(false)
            }
            else if (form.id_image === "") {
                setIsEmptyIdImages(true)
                setIsLoding(false)
            }
            else if (form.id_card_image === "") {
                setIsEmptyIdCard(true)
                setIsLoding(false)
            }
            else {
                setIsLoding(true)
                const response = await RequestFanbusy("creators/send-id-document", "PUT", form, token);
                if (response.status === 200) {
                    setIsLoding(false)
                    setShowToast(true);
                    window.location.href = '/creator-request'
                }
                else {
                    setBadRequest(true)
                    setIsLoding(false)
                }
                setIsLoding(false)
            }
        } catch (error) {
            setIsLoding(false)
        }
    };

    const handleDeleteIdImage = () => {
        setSelecttedIdImage(null);
        setPrewiewIDImage(null);
    };

    const handleDeleteIdCardImage = () => {
        setPreviewIdCardImage(null);
        setSelectedIdCardImage(null);
    };

    const toggleShowMore = () => {
        setShowMore(!showMore);
    };

    const toggleShowLess = () => {
        setShowMore(false);
    };

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };


    return (
        <>
            <AppContainer>
                <main className={`"col col-xl-6 order-xl-2 col-lg-12 order-lg-1 col-md-12 col-sm-12 col-12  border-bottom-0 border border-fw-light border-bottom-0 hide-scrollbar px-0 ${isMobile && 'my-5'} `}>
                    <div className="main-content ">
                        <div className="d-flex align-items-center mb-3  m-none border-bottom py-2 fix-sidebar bg-white top-0">
                            <a href={'/become-creator'} className={`${darkMode ? "text-white" : "text-dark"} material-icons text-decoration-none m-none ms-3`}>
                                arrow_back
                            </a>
                            <p className={`${darkMode ? "text-white" : "text-body"} ms-2 mb-0 fw-bold fs-6 text-uppercase`}>{t('views.become.idVerifications')}</p>
                        </div>
                        <div className={`${darkMode && "bg_fan"} rounded-4 overflow-hidden mb-4 mb-lg-0 border-0`} >
                            <div className="feeds mb-2 px-4 ">
                                <div className={`${darkMode ? "bg-dark" : "bg-white"} py-1 feed-item rounded-4 faq-page`}>
                                    <div className=" d-flex justify-content-center mb-2 ">
                                        <h5 className={`lead fw-bold mb-0 px-4 text-uppercase ${darkMode ? "text-white" : "text-gray"} fs-6 pt-3 `}>{t('views.become.chooseTheTypeOfYourDocument')}</h5>
                                    </div>
                                    <div className="d-flex justify-content-center">
                                        <div className="form-check form-check-inline">
                                            <input className={`form-check-input fs-6 ${darkMode && "bg-dark border-secondary"}`} type="checkbox" id="checkbox1" name="id_type" value="NIC" checked={editDatas.id_type === "NIC"} onChange={textInputChange} />
                                            <label className="form-check-label fs-6" htmlFor="checkbox1">
                                                {t('views.become.idCard')}
                                            </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className={`form-check-input fs-6 ${darkMode && "bg-dark border-secondary"}`} type="checkbox" id="checkbox2" name="id_type" value="PASSPORT" checked={editDatas.id_type === "PASSPORT"} onChange={textInputChange} />
                                            <label className="form-check-label fs-6" htmlFor="checkbox2">
                                                {t('views.become.passport')}
                                            </label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className={`form-check-input fs-6 ${darkMode && "bg-dark border-secondary"}`} type="checkbox" id="checkbox3" name="id_type" value="DRIVING_LICENCE" checked={editDatas.id_type === "DRIVING_LICENCE"} onChange={textInputChange} />
                                            <label className="form-check-label fs-6" htmlFor="checkbox3">
                                                {t('views.become.drivingLicense')}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="feeds mb-2 border-bottom">
                                <div className={`${darkMode ? "bg-dark" : "bg-white"}  pt-4 pb-1 feed-item rounded-4 faq-page`}>
                                    <div className=" mb-3">
                                        <div className=" d-flex mb-2 ">
                                            <h5 className={`lead fw-bold mb-0 px-4 ${darkMode ? "text-white" : "text-gray"} fs-6`}>{t('views.become.photoOfYourIdDocument')}</h5>{" "}
                                        </div>
                                        <p className={`px-4 ${darkMode && "text-secondary"}`}>{t('views.become.pleaseUploadPhotoDocument')}</p>
                                        {<div className={`${darkMode && "bg-dark"} row justify-content-between  ms-2`}>
                                            <div style={{ width: isMobile ? "43%" : "30%", height: "65%", backgroundColor: "#EDF2F6" }} className="p-0 rounded mt-3 d-flex justify-content-center">
                                                <img src={Images.verificationIdImg} alt="Verification ID" className="pb-1 p-0 border-0" style={{ height: "138px", position: "relative", maxWidth: "100%" }} />
                                            </div>
                                            <div style={{ width: isMobile ? "52%" : "65%" }} className="">
                                                <div className="form-floating mb-3"></div>
                                                <div className={`${darkMode ? "bg-dark" : "bg-white"} mb-3 rounded-2  profile w-100 `} style={{ position: "relative" }}>
                                                    {selecttedIdImage ? (
                                                        <div style={{ background: "#edf2f6", height: "140px", backgroundSize: "contain" }} className="rounded-2 me-2 d-flex">
                                                            <img src={previewIdImage} alt="" className="" style={{ height: "140px", width: "auto" }} />
                                                            <div style={{ backgroundColor: "rgba(0, 0, 0, .0)", height: "140px" }} className="rounded-2">
                                                                <div>
                                                                    <button className="btn btn-danger delete-button position-absolute top-0 end-0 bg-transparent border-0" onClick={handleDeleteIdImage}>
                                                                        <i className="material-icons fb-color  ">delete</i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div style={{ height: "140px", backgroundSize: "contain" }}>
                                                            <div style={{ height: "140px" }} className={`d-flex flex-column align-items-center justify-content-center me-2 ${darkMode ? "bg-dark border border-secondary rounded-2" : "bg-light"}`}>
                                                                <label htmlFor="upload-input" className="d-flex flex-column align-items-center justify-content-center cursor-pointer">
                                                                    <i className="material-icons">cloud_upload</i>
                                                                    <span className="text-uppercase">{t('views.become.selectFile')}</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    )}
                                                    <input id="upload-input" type="file" accept="image/*" onChange={handleChangeIdImage} className="d-none" />
                                                </div>
                                            </div>
                                        </div>}

                                        <p className="px-4">
                                            {t('views.become.clearlyShowIdDocument')}
                                            {!showMore ? (
                                                <>
                                                    <p>{t('views.become.theImageShouldBeEdited')}</p>
                                                    <button className="text-decoration-none border-0 bg-white text-primary" onClick={toggleShowMore}>
                                                        {t('navigation.viewLess')}
                                                    </button>
                                                </>
                                            ) : (
                                                <>
                                                    <button className="text-decoration-none border-0 bg-white text-primary" onClick={toggleShowLess}>
                                                        {t('navigation.viewMore')}
                                                    </button>
                                                </>
                                            )}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            {isEmptyIdImage && <div className="alert alert-danger alert-dismissible mt-2" role="alert">
                                <div>{t('views.become.idImage')}</div>
                                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                            </div>}
                            <div className="feeds border-bottom">
                                <div className={`${darkMode ? "bg-dark" : "bg-white"}  pt-4 pb-1 feed-item rounded-4  faq-page`}>
                                    <div className=" ">
                                        <div className=" d-flex mb-2 ">
                                            <h5 className={`lead fw-bold mb-0 px-4 ${darkMode ? "text-white" : "text-gray"} fs-6`}>{t('views.become.selfieOfYourHolding')}</h5>{" "}

                                        </div>
                                        <p className={`px-4 ${darkMode && "text-secondary"}`}>{t('views.become.pleaseUploadPhoto')}</p>

                                        {<div className={`${darkMode && "bg-dark"} row justify-content-between ms-2 `}>
                                            <div style={{ width: isMobile ? "43%" : "30%", height: "65%", backgroundColor: "#EDF2F6" }} className="p-0 rounded mt-3 d-flex justify-content-center">
                                                <img src={Images.verificationIdProfileImg} alt="Verification ID" className="pb-1 p-0 border-0" style={{ maxWidth: "100%", height: "138px", position: "relative" }} />
                                            </div>
                                            <div style={{ width: isMobile ? "52%" : "65%" }} className="">
                                                <div className="form-floating mb-3 "></div>
                                                <div className={`${darkMode ? "bg-dark" : "bg-white"} mb-3 rounded-2 profile w-100 `} style={{ position: "relative" }}>
                                                    {selectedIdCardImage ? (
                                                        <div style={{ background: "#edf2f6", height: "140px", backgroundSize: "contain" }} className="rounded-2 me-2 d-flex">
                                                            <img src={previewIdCardImage} alt="" className="" style={{ height: "140px", width: "auto" }} />
                                                            <div style={{ backgroundColor: "rgba(0, 0, 0, .0)", height: "140px" }} className="rounded-2">
                                                                <div>
                                                                    <button className="btn btn-danger delete-button position-absolute top-0 end-0 bg-transparent border-0" onClick={handleDeleteIdCardImage}>
                                                                        <i className="material-icons fb-color  ">delete</i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div style={{ height: "140px", backgroundSize: "contain" }}>
                                                            <div style={{ height: "140px" }} className={`d-flex flex-column align-items-center justify-content-center me-2 ${darkMode ? "bg-dark border border-secondary rounded-2" : "bg-light"}`}>
                                                                <label htmlFor="upload-input-card" className="d-flex flex-column align-items-center justify-content-center cursor-pointer">
                                                                    <i className="material-icons">cloud_upload</i>
                                                                    <span className="text-uppercase">{t('views.become.selectFile')}</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    )}
                                                    <input id="upload-input-card" type="file" accept="image/*" onChange={handleChangeIdCardImage} className="d-none" />
                                                </div>
                                            </div>
                                        </div>}

                                        <p className="px-4">{t('views.become.asWellClearImage')}</p>
                                    </div>
                                </div>
                            </div>
                            {isEmptyDocType && <div className="alert alert-danger alert-dismissible mt-2" role="alert">
                                <div>{t('views.become.doctypeFieldIsEmpty')}</div>
                                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                            </div>}
                            {isEmptyIdCard && <div className="alert alert-danger alert-dismissible mt-2" role="alert">
                                <div> {t('views.become.imageOfHolding')}</div>
                                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                            </div>}
                            <div className="feeds mt-2 px-4">
                                <div className={`bg-transparent  py-1 feed-item  faq-page mb-3`}>
                                    <div>
                                        <div className="form-check">
                                            <input type="checkbox" className={`form-check-input ${darkMode && "bg-dark border-secondary"}`} id="termsCheckbox" checked={isChecked} onChange={handleCheckboxChange} />
                                            <label className="form-check-label" htmlFor="termsCheckbox">
                                                {t('views.become.haveReadAndAccept')} <a href="/help-and-support-creator" > {t('views.become.termsOfUse')}</a>
                                            </label>
                                        </div>
                                    </div>
                                    {isEmptyImages && <div className="alert alert-danger alert-dismissible mt-2" role="alert">
                                        <div> {t('views.become.idImage')}</div>
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                    </div>}

                                    <div className=" d-flex justify-content-center mt-2  ">
                                        <button disabled={!isChecked} onClick={submitEdit} className="btn gradient-button rounded-pill w-100 text-decoration-none py-2 fw-bold text-uppercase m-0 d-flex align-conent-center justify-content-center text-white">
                                            {isLOading ? <Loader /> : `${t('words.send')}`}
                                        </button>
                                    </div>
                                    {showToast && <div className="alert alert-success alert-dismissible mt-2" role="alert">
                                        <div>{t('views.become.yourCreatorsRequest')}</div>
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                    </div>}
                                    {badReuest && <div className="alert alert-danger alert-dismissible mt-2" role="alert">
                                        <div>{t('views.become.badRequest')}</div>
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
                <AsideContainer />
            </AppContainer>
        </>

    );
};
