import { createBrowserRouter, createRoutesFromElements, Route } from "react-router-dom";
import { AuthGuard } from "../Utils";
import { RequestCreator, SearchResult } from "../Components";
import {
	BookmarksView,
	CreatorHelpAndSupport,
	CreateStoryView,
	Cookie,
	EditProfileView,
	Error404View,
	FanView,
	FollowsView,
	HelpAndSupport,
	HomeView,
	LoginView,
	NotificationView,
	Privacy,
	PaymentAccountView,
	ProfileView,
	ProfileViewByCreator,
	RestaureAccount,
	RestaureAccountConfirm,
	SignupFormView,
	SignupSuccessView,
	SignupVerifyView,
	StoryView,
	SubcribeCreatorView,
	UserSittingsView,
	VerifyingSubcriptions,
	AddCardPaymentView,
	EditDebitMode,
	PostByIdView,
	SinupVerificationEmailView,
	CreateRoom,
	TchatLayout,
	TchatMessage,
	TermsOfService,
	Pay4meView,
	PostByIdCollection,
	MyCollection
} from "../Views";



const router = createBrowserRouter(
	createRoutesFromElements(
		<Route errorElement={<Error404View />}>
			<Route path="/" element={<AuthGuard props={<HomeView />} />} />
			<Route path="/:id" element={<ProfileViewByCreator />} />
			<Route path="auth">
				<Route path="login" element={<LoginView />} />
				<Route path="create" element={<SignupFormView />} />
				<Route path="success" element={<SignupSuccessView />} />
				<Route path="verify/:request" element={<SignupVerifyView />} />
				<Route path="restore-account-change" element={<RestaureAccountConfirm />} />
				<Route path="restore-account-request" element={<RestaureAccount />} />
				<Route path="verifymail" element={<SinupVerificationEmailView />} />
			</Route>
			<Route path="/account" element={<AuthGuard props={<PaymentAccountView />} />} />
			<Route path="/add-card" element={<AuthGuard props={<AddCardPaymentView />} />} />
			<Route path="/become-creator" element={<AuthGuard props={<SubcribeCreatorView />} />} />
			<Route path="/bookmarks" element={<AuthGuard props={<BookmarksView />} />} />
			<Route path="/collections" element={<AuthGuard props={<MyCollection />} />} />
			<Route path="/creator-request" element={<AuthGuard props={<RequestCreator />} />} />
			<Route path="/cookie" element={<AuthGuard props={<Cookie />} />} />
			<Route path="/collection/:id" element={<AuthGuard props={<PostByIdCollection />} />} />

			<Route path="/fans" element={<AuthGuard props={<FanView />} />} />
			<Route path="/follows" element={<AuthGuard props={<FollowsView />} />} />
			<Route path="/home" element={<AuthGuard props={<HomeView />} />} />
			<Route path="/help-and-support-creator" element={<CreatorHelpAndSupport />} />
			<Route path="/help-and-support" element={<HelpAndSupport />} />
			<Route path="/id-verification-creator" element={<AuthGuard props={<VerifyingSubcriptions />} />} />
			<Route path="/messages" element={<AuthGuard props={<TchatLayout />} />} >
				<Route path="creat" element={<AuthGuard props={<CreateRoom />} />} />
				<Route path="my/chat/:room/:receiverId" element={<TchatMessage />} />
			</Route>
			<Route path="/notifications" element={<AuthGuard props={<NotificationView />} />} />
			<Route path="/pay4me" element={<AuthGuard props={<Pay4meView />} />} />
			<Route path="/payment-details" element={<AuthGuard props={< EditDebitMode />} />} />
			<Route path="/profile" element={<AuthGuard props={<ProfileView />} />} />
			<Route path="/privacy" element={<AuthGuard props={<Privacy />} />} />
			<Route path="/post/:postId" element={<PostByIdView />} />
			<Route path="/profile-edit" element={<AuthGuard props={<EditProfileView />} />} />
			<Route path="/story/:id" element={<AuthGuard props={<StoryView />} />} />
			<Route path="/story/create" element={<AuthGuard props={<CreateStoryView />} />} />
			<Route path="/searchresult" exact element={<AuthGuard props={<SearchResult />} />} />
			<Route path="/settings" element={<AuthGuard props={<UserSittingsView />} />} />
			<Route path="/terms-of-service" element={<AuthGuard props={<TermsOfService />} />} />
			<Route path="verify/:request" element={<AuthGuard props={<SignupVerifyView />} />} />
			<Route path="*" element={<Error404View />} />
		</Route>
	)
);

export default router;