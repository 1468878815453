import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { t } from "i18next";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
} from "chart.js";
import { Line } from 'react-chartjs-2';
import Slider from "react-slick";

import { AppContainer } from "../../Containers";
import { RequestFanbusy } from "../../Services";
import { CardPayment, CardTransactions, Loader, NoContent } from "../../Components";
import { convertCurrency, getPaymentCard, useScreenSize } from "../../Utils";
import { Images } from "../../Constants";


const PaymentAccountView = () => {
    ChartJS.register(
        CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler
    );

    const dispatch = useDispatch()
    const screenSize = useScreenSize()
    const darkMode = useSelector((state) => state.theme.darkMode);
    const users = useSelector((state) => state.users);
    const params = useSelector((state) => state.params);
    const [activeTab, setActiveTab] = React.useState("YOUR CARDS");
    const [state, setState] = React.useState({
        rising: '',
        transactions: [],
        transactionsDebit: [],
        cardPayment: [],
        amountConvert: '',
        releaseErrors: '',
        mode: '',
        selectedAction: users.users?.account_type !== 'CREATOR' ? 'credit' : '',
        isCredit: true,
        isLoading: true,
        isLoaded: false
    });
    const isMobile = screenSize === 'Extra Small' || screenSize === 'Small' || screenSize === 'Medium'

    const getAllDebitTransactionByCreator = async () => {
        setState((prev) => ({ ...prev, isCredit: false, selectedAction: 'debit' }));
        let res = await RequestFanbusy("accounts/payment/transactions", 'GET', '', users.access_token);
        if (res.status === 200) {
            const debitData = res.response.data.filter((transaction) => transaction.type === 'DEBIT')
            setState((prev) => ({ ...prev, transactionsDebit: debitData, isLoading: false }));
        } else if (res.status === 401) {
            setState((prev) => ({ ...prev, isLoading: false }));
        }
    };

    const handleChangeAction = () => {
        setState((prev) => ({ ...prev, isCredit: true, selectedAction: 'credit' }));
    }

    const getTotalPayment = () => {
        let totalAmount = 0;
        state.transactionsDebit?.map((transaction) => {
            return (totalAmount = totalAmount + transaction.amount_of_creator);
        });
        return convertCurrency(params.currency, params.currencies, totalAmount, 'GET');
    };

    const options = {
        scales: {
            x: {
                grid: {
                    display: true,
                    color: 'gray',
                    borderColor: 'blue',
                },
            },
            y: {
                ticks: {
                    color: '',
                },
            },
        },

    }

    if (darkMode) {
        options.scales.y.ticks.color = 'blue';
        options.scales.x.grid.color = 'blue';
    } else {
        options.scales.y.ticks.color = 'gray';
        options.scales.x.grid.color = 'gray';
    }

    function getMonth() {
        const uniqueDates = new Set();
        state.transactions.forEach((transaction) => {
            const formattedDate = new Date(transaction.updated_at).toLocaleString(`${t('lang.lang')}`, { month: 'long', day: 'numeric', year: 'numeric' }).replace(',', '');
            uniqueDates.add(formattedDate);
        });

        const sortedDates = Array.from(uniqueDates).sort((a, b) => new Date(a) - new Date(b));

        return sortedDates;
    }

    const getPrice = () => {
        const sortedTransactions = [...state.transactions].sort((a, b) => new Date(a.updated_at) - new Date(b.updated_at));
        const result = {
            credit: [],
            debit: []
        };
        const amountByDate = sortedTransactions.reduce((acc, transaction) => {
            const date = new Date(transaction.updated_at).toLocaleDateString('en-US', { month: 'numeric', day: 'numeric', year: 'numeric' });

            if (!acc[date]) {
                acc[date] = { debit: 0, credit: 0 };
            }

            if (transaction.type === 'CREDIT') {
                acc[date].credit += parseFloat(transaction.amount);
            } else {
                acc[date].debit += parseFloat(transaction.amount);
            }

            return acc;
        }, {});

        Object.keys(amountByDate).forEach((date) => {
            result.credit.push(convertCurrency(params.currency, params.currencies, amountByDate[date].credit, 'GET')?.replace(/\s/g, ''));
            result.debit.push(convertCurrency(params.currency, params.currencies, amountByDate[date].debit, 'GET')?.replace(/\s/g, ''));
        });

        return result;
    };

    const priceData = getPrice();

    const data = {
        labels: getMonth(),
        datasets: [
            {
                label: t('words.debit'),
                data: users.users.account_type === 'FANS' ? [] : priceData.debit,
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 255, 255)',
                tension: 0.5
            },
            {
                label: t('words.credit'),
                data: priceData.credit,
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(255, 255, 255)',
                tension: 0.5
            }
        ],
    };

    const changeTab = (tab) => {
        setActiveTab(tab);
    };

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        swipeToSlide: true,
    };

    const deleteCardById = async (cardId) => {
        let res = await RequestFanbusy(`cards/${cardId}`, 'DELETE', '', users.access_token);
        if (res.status === 200) {
            dispatch({ type: "REFRESH", value: 1 });
        }
    }

    React.useEffect(() => {
        setState((prev) => ({ ...prev, selectedAction: 'credit' }));
        let cardPayment = getPaymentCard(users.access_token);
        cardPayment.then((value) => {
            setState((prev) => ({ ...prev, cardPayment: value }));
        });

        const getAllTransactionByCreator = async () => {
            let res = await RequestFanbusy("accounts/payment/transactions", 'GET', '', users.access_token);
            if (res.status === 200) {
                const transactionsSuccess = res.response.data.filter((transaction) => transaction.status !== "FAILED")
                setState((prev) => ({ ...prev, transactions: transactionsSuccess, isLoading: false }));
            } else if (res.status === 401) {
                setState((prev) => ({ ...prev, isLoading: false }));
            }
        }

        getAllTransactionByCreator();
    }, [params.refresh, users.access_token]);



    return (
        <AppContainer>
            <main className={`col col-xl-6 order-xl-2 col-lg-12 order-lg-1 col-md-12 col-sm-12 col-12 px-0  border boder-top-0  border-bottom-0 ${isMobile && 'fixed-content pb-5 '} ${darkMode && 'bg-dark'}`}>
                <div style={{ paddingBottom: "60px" }} className={` main-content ${darkMode && 'bg-dark'}`}>
                    <div style={{ height: '50px' }} className="d-flex align-items-center justify-content-between mb-1 bg-white fix-sidebar top-0 border-bottom shadow-sm ">
                        <div className="d-flex ">
                            <a href={`/`} className={`${darkMode ? 'text-white' : 'text-dark'} material-icons text-decoration-none ms-3`}>arrow_back</a>
                            <p className={`${darkMode ? 'text-white' : 'text-body'} ms-2 mb-0 fw-bold fs-6`}>
                                {t('views.account.transactions')}
                            </p>
                        </div>
                        {/* <div className="d-flex align-items-center justify-content-center gap-3 me-3">
                            <a href="/add-card" className="material-icons border-0 bg-transparent text-decoration-none text-black" title="Payment Mode">add_card</a>
                        </div> */}
                    </div>
                    <div id="carouselExampleDark" className="carousel carousel-dark slide bg-white mt-mobile mt-0" data-bs-interval="false">
                        <Slider {...settings} >
                            <div className={`carousel-item ${activeTab === "ALL" ? "active" : ""} mb-4`} >
                                <ul className="top-osahan-nav-tab nav nav-pills justify-content-center nav-justified overflow-hidden" id="pills-tab" role="tablist" style={{ boxShadow: "0px 2px 0px #EDF2F6" }}>
                                    <li className="nav-item m-2 mb-0 ms-0" role="presentation" onClick={handleChangeAction}>
                                        <button className={`nav-link pe-10 text-dark ${activeTab === "YOUR CARDS" ? "active bg-transparent fw-bold" : ""}`} id="pills-feed-tab" data-bs-toggle="pill" data-bs-target="#pills-feed" type="button" role="tab" aria-controls="pills-feed" aria-selected={activeTab === "YOUR CARDS"} onClick={() => changeTab("YOUR CARDS")}>
                                            {t('views.account.yourWallet')}
                                        </button>
                                        {activeTab === "YOUR CARDS" &&
                                            <span className="gradient-button d-block mb-0" style={{ width: "100%", height: 3 }}></span>
                                        }
                                    </li>
                                    <li className="nav-item m-2 mb-0 me-0" role="presentation" onClick={users.users.account_type === 'CREATOR' ? getAllDebitTransactionByCreator : () => { }}>
                                        <button className={`nav-link text-dark ${activeTab === "PAYMENTS" ? "active bg-transparent fw-bold" : ""}`} id="pills-people-tab" data-bs-toggle="pill" data-bs-target="#pills-people" type="button" role="tab" aria-controls="pills-people" aria-selected={activeTab === "PAYMENTS"} onClick={() => changeTab("PAYMENTS")} >
                                            {t('views.account.payout')}
                                        </button>
                                        {activeTab === "PAYMENTS" &&
                                            <span className="gradient-button d-block mb-0" style={{ width: "100%", height: 3 }}></span>
                                        }
                                    </li>
                                </ul>
                            </div>
                        </Slider>
                    </div>
                    <div className={`${darkMode ? 'bg-dark text-white' : 'bg-white'}  rounded- `}>
                        <div className="tab-content" id="pills-tabContent">
                            <div className="tab-pane fade show active " id="pills-feed" role="tabpanel" aria-labelledby="pills-feed-tab">
                                <CardTransactions data={{ selectedAction: state.selectedAction, isCredit: state.isCredit, cardPayment: state.cardPayment }} users={users} params={params} styleResp={'web-none m-3 mt-0'} styleShadow={{ boxShadow: "0px 5px 0px #EDF2F6" }} setEditSelectAction={setState} />
                                <div className="p-3 pt-0 overflow-auto" style={{ fontSize: "0.9em", boxShadow: "0px 5px 0px #EDF2F6", maxHeight: 170 }}>
                                    {state.isLoading ?
                                        <Loader styleColor={true} /> :
                                        state.cardPayment?.length > 0 ?
                                            state.cardPayment.map((card, index) => {
                                                return (
                                                    <div className="d-flex justify-content-between border-bottom mb-1 pb-2" key={index}>
                                                        <div>
                                                            <p className="fw-bold p-0 m-0">
                                                                {card.card_type === 'MasterCard' && <img src={Images.masterCard} className="me-1" alt="..." style={{ width: 35, height: 27 }} />}
                                                                {card.card_type === 'Visa' && <img src={Images.visaCard} className="me-1 pe-1" alt="..." style={{ width: 35, height: 27, boxShadow: "inset 0px 0px 5px 2px rgba(0, 0, 0, 0.1)" }} />}
                                                            </p>
                                                            <span>{card.mask_number}</span>
                                                        </div>
                                                        <div className="d-flex align-item-center gap-3">
                                                            <div>
                                                                <p className="p-0 m-0">{t('views.account.expirationDate')} </p>
                                                                <span> {`${card.card_expiry_month.substring(0, 3)}/${card.card_expiry_year.slice(-2)}`}</span>
                                                            </div>

                                                            <p className="mb-0">
                                                                {card.is_active ? `${t('words.activated')}` : `${t('words.idle')}`}
                                                            </p>
                                                        </div>
                                                        <button title="Delete Card" onClick={() => deleteCardById(card._id)} className="material-icons md-24 text-danger border-0 bg-transparent">close</button>
                                                    </div>
                                                )
                                            })
                                            :
                                            <p className="mb-0">{t('views.account.noCardPayment')}</p>
                                    }
                                </div>
                                <div className="px-3">
                                    <h6 className="pt-3 ">{t('views.account.transactions')}</h6>
                                    {state.isLoading ?
                                        <Loader styleColor={true} /> :
                                        state.transactions?.length < 0 ? (
                                            <NoContent message={`${t('views.account.noTransaction')}`} transaction={true} />
                                        ) : (
                                            <>
                                                <Line options={options} data={data} className="m-0 my-3" />
                                                <div style={{ maxHeight: 500, overflow: 'scroll' }}>
                                                    <CardPayment data={state.transactions} style={{ boxShadow: "0px 5px 0px #EDF2F6" }} />
                                                </div>
                                            </>
                                        )
                                    }
                                </div>
                            </div>
                            <div className="tab-pane fade px-3 pt-0 pb-2" id="pills-people" role="tabpanel"
                                aria-labelledby="pills-people-tab">
                                {users.users.account_type === "CREATOR" ?
                                    <>
                                        <CardTransactions setEditSelectAction={setState} data={{ selectedAction: state.selectedAction, isCredit: state.isCredit, cardPayment: state.cardPayment }} users={users} params={params} styleResp={'web-none mb-3'} styleShadow={{ boxShadow: "0px 5px 0px #EDF2F6" }} />
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="d-flex gap-1">
                                                <span className="material-icons md-36">shopping_bag</span>
                                                <div>
                                                    <p className="fb-color fw-bold m-0 p-0" >{t('views.account.paymentList')}</p>
                                                    <p style={{ fontSize: '0.8em' }}> {t('views.account.listOfPaymentsMade')}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="d-flex justify-content-between align-items-center pt-4 pb-0" style={{ boxShadow: "0px 5px 0px #EDF2F6" }}>
                                                <p className="fw-bold">{new Date().toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}</p>
                                                <div className="d-flex align-items-center gap-3">
                                                    <p style={{ fontSize: '0.9em', fontWeight: 'bold' }}>{t('views.account.numberOfTransaction')}{state.transactionsDebit?.length > 1 ? 's' : ''}: {state.transactionsDebit?.length}</p>
                                                    <p style={{ fontSize: '0.9em', fontWeight: 'bold' }}>{t('views.account.value')}: {getTotalPayment()} {params.currency}</p>
                                                </div>
                                            </div>
                                            {state.isLoading ?
                                                <Loader styleColor={true} /> :
                                                <>
                                                    {state.transactionsDebit?.length > 0 ?
                                                        <div className="overflow-auto mt-3" style={{ maxHeight: 450 }}>
                                                            <CardPayment data={state.transactionsDebit} />
                                                        </div>
                                                        :
                                                        <NoContent message={`${t('views.account.noTransaction')}`} transaction={true} />
                                                    }
                                                </>
                                            }
                                        </div>
                                    </>
                                    :
                                    <div className="d-flex flex-column gap-2 shadow-sm p-3">
                                        <h5 className="mb-0">{t('views.account.onlyCreatorsCanWithdrawMoney')}</h5>
                                        <a href={`/become-creator`} className="text-decoration-none p-2 px-3 text-white gradient-button rounded-pill fw-bold" style={{ width: 163 }}>{t('navigation.become')}</a>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <aside className="col col-xl-3 order-xl-3 col-lg-6 order-lg-3 col-md-6 col-sm-6 col-12" style={{ paddingLeft: '13px' }}>
                <div className="fix-sidebar">
                    <CardTransactions data={{ selectedAction: state.selectedAction, isCredit: state.isCredit, cardPayment: state.cardPayment }} users={users} params={params} styleResp={'side-trend lg-none'} setEditSelectAction={setState} />
                </div>
            </aside>
        </AppContainer >
    )

}

export default PaymentAccountView;
