import React from "react"
import { NavLink } from "react-router-dom"

const BannerBecome = ({ accountType, viewport, t }) => {
    return (
        <div className={`${viewport ? "mt-3 pt-3" : 'mt-3'}`}>
            <div className='gradient-button rounded-4 mx-2'>
                <div className='px-3 pt-3 pb-4'>
                    <h3 className='text-center text-white'>{t('navigation.welcome')} 🤑</h3>
                    <h6 className="text-white text-center">{t('navigation.turnYourContent')}</h6>
                    <NavLink
                        to={`${accountType === "REQUEST_CREATOR" ? '/creator-request' : '/become-creator'}`}
                        className="btn btn-white btn-sm border bg-white border-white px-2 py-2 mt-2 rounded-pill w-100 text-white text-center text-decoration-none"
                    >
                        <span className="text-gradient fw-bold fs-6 text-uppercase"> {t('views.home.become_Creator')}</span>
                    </NavLink >

                </div>
            </div>
        </div>
    )
}
export default BannerBecome