import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { RequestFanbusy } from "../Services";
import Loader from "./Loader";


const AddCardPayment = ({ submitFormTransaction, handleRefrechModal, isValidated, isLoaded, visible }) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const darkMode = useSelector((state) => state.theme.darkMode);
    const users = useSelector((state) => state.users);
    const [state, setState] = React.useState({
        releaseErrors: '',
        cardName: users.users.full_name,
        cardNumber: '',
        cardId: '',
        displayCardNumber: '',
        cardCvv: '',
        cardExpMonth: '',
        cardExpYear: '',
        fullExpYear: '',
        billingzip: '',
        billingcity: '',
        billingaddress: '',
        billingstate: '',
        billingcountry: '',
        isLoading: false
    });
    const [alert, setAlert] = React.useState({
        type: '',
        status: false,
        message: '',
    });

    const textInputChange = (input) => {
        const target = input.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        switch (name) {
            case 'cardName':
                setState({ ...state, cardName: value });
                break;
            case 'cardNumber':
                let card_number = formatCard(value);
                setState({ ...state, cardNumber: value.replace(/\s+/g, ""), displayCardNumber: card_number });
                break;
            case 'cardCvv':
                setState({ ...state, cardCvv: value });
                break;
            case 'cardExpMonth':
                setState({ ...state, cardExpMonth: value });
                break;
            case 'cardExpYear':
                setState({ ...state, cardExpYear: value });
                break;
            case 'billingzip':
                setState({ ...state, billingzip: value });
                break;
            case 'billingcity':
                setState({ ...state, billingcity: value });
                break;
            case 'billingaddress':
                setState({ ...state, billingaddress: value });
                break;
            case 'billingstate':
                setState({ ...state, billingstate: value });
                break;
            case 'billingcountry':
                setState({ ...state, billingcountry: value });
                break;

            default:
                break;
        }
    }

    const formatCard = (card_number) => {
        const v = card_number
            .replace(/\s+/g, "")
            .replace(/[^0-9]/gi, "")
            .substr(0, 16);
        const parts = [];

        for (let i = 0; i < v.length; i += 4) {
            parts.push(v.substr(i, 4));
        }

        return parts.length > 1 ? parts.join(" ") : card_number;
    }

    const addPaymentCard = async () => {
        let res = null
        let isAdded = false
        setAlert({ type: '', status: false, message: '' });
        if (state.cardExpYear.length < 2) {
            setAlert({ type: 'danger', status: true, message: `${t('views.addCard.enterAValidYear')}` });
            return;
        }
        if (state.billingzip.length <= 0 || state.billingcity.length <= 0 || state.billingaddress.length <= 0 || state.billingcountry.length <= 0) {
            setAlert({ type: 'danger', status: true, message: `${t('views.addCard.theBillingAddressIsMandatory')}` });
            return;
        }
        setState((prev) => ({ ...prev, isLoading: !submitFormTransaction ? true : false }));

        const form = {
            card_name: state.cardName,
            card_number: state.cardNumber,
            card_cvv: state.cardCvv,
            card_expiry_month: state.cardExpMonth,
            card_expiry_year: "20" + state.cardExpYear,
            billingzip: state.billingzip,
            billingcity: state.billingcity,
            billingaddress: state.billingaddress,
            billingstate: state.billingstate,
            billingcountry: state.billingcountry,
        }
        console.log(form);

        if (submitFormTransaction && !isAdded) {
            res = await RequestFanbusy("cards/", 'POST', form, users.access_token);
        }
        else if (!submitFormTransaction) {
            res = await RequestFanbusy("cards/", 'POST', form, users.access_token);
        }

        if (res.status === 200 || isAdded) {
            if (submitFormTransaction) {
                isAdded = true
                submitFormTransaction(res.response.data._id);
                setAlert({ type: 'success', status: true, message: t('views.addCard.yourCardHasBeenAdded') });
            }
            else {
                setAlert({ type: 'success', status: true, message: t('views.addCard.yourCardHasBeenAdded') });
                setState({ ...state, isLoading: false });
                dispatch({ type: "REFRESH", value: 1 });
            }
        }
        else if (res.status === 422 && res.response.response_type === 'Validation Error') {
            setAlert({ type: 'danger', status: true, message: t('navigation.andErrorOcured') });
            setState({ ...state, isLoading: false });
        }
        else if (res.status === 400) {
            setAlert({ type: 'danger', status: true, message: t('views.addCard.theCardNumberIsInvalid') });
            setState({ ...state, isLoading: false });
        }
        else {
            setAlert({ type: 'danger', status: true, message: t('navigation.tryAgain') });
            setState({ ...state, isLoading: false });
        }

        setState({ ...state, cardName: users.users.full_name, cardNumber: '', cardCvv: '', cardExpMonth: '', displayCardNumber: '', cardExpYear: '', billingzip: '', billingcity: '', billingaddress: '', billingstate: '', billingcountry: '' });
    }

    const isDisabled = () => {
        const {
            cardCvv,
            cardExpMonth,
            cardNumber,
            cardName,
            billingzip,
            billingcity,
            billingaddress,
            billingcountry
        } = state;

        return (
            cardCvv.length < 3 ||
            cardExpMonth === '' ||
            cardNumber === '' ||
            cardName === '' ||
            billingzip.length <= 0 ||
            billingcity.length <= 0 ||
            billingaddress.length <= 0 ||
            billingcountry.length <= 0
        );
    };


    return (
        <div className={`${visible && 'mt-heigh-mob'} overflow-auto small`}>
            <span className="fs-6 " style={{ color: "#fb9129" }}>{t('views.addCard.securePaymentByFlutterwave')}</span>
            {alert.status &&
                <div className={`alert alert-${alert.type} alert-dismissible mt-3 mb-3 p-2`} role="alert">
                    <div className="me-4">{alert.message}</div>
                    <button type="button" className="btn-close pt-2 pe-2" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>
            }
            <div className="form-floating border form-floating-focus-within rounded-0 mb-3 mt-2 d-flex align-items-center">
                <input type="text" name="cardName" value={state.cardName} onChange={textInputChange} className="form-control rounded-5 border-0" id="cardName" placeholder="" style={{ height: '45px' }} />
                <label htmlFor="cardName" className="text-uppercase">{t('views.addCard.cardName')} <span className="text-danger ">*</span></label>
            </div>
            <div className="mt-3 d-flex justify-content-between">
                <div className="form-floating border form-floating-focus-within rounded-0 border-end-0 mb-3 d-flex align-items-center no-border-hover" style={{ width: '100%' }}>
                    <input type="text" name="cardNumber" value={state.displayCardNumber} onChange={textInputChange} className="form-control rounded-5 border-0  no-border-hover small" id="cardNumber" placeholder="Card Number" style={{ height: '45px', width: '150px' }} />
                    <label className="text-uppercase small" htmlFor="cardNumber">{t('views.addCard.cardNumber')} <span className="text-danger ">*</span></label>
                </div>
                <div className="form-floating border form-floating-focus-within rounded-0 border-start-0 border-end-0 mb-3 d-flex align-items-center no-border-hover" >
                    <input type="text" name="cardExpMonth" value={state.cardExpMonth} onChange={textInputChange} maxLength={2} className="form-control rounded-5 border-0 small" id="cardExpMonth" placeholder="" style={{ height: '45px' }} />
                    <label className="small" htmlFor="cardExpMonth">{t('views.addCard.mm')}<span className="text-danger ">*</span></label>
                </div>
                <div className="form-floating border form-floating-focus-within rounded-0 border-start-0 border-end-0 mb-3 d-flex align-items-center no-border-hover" >
                    <input type="text" name="cardExpYear" value={state.cardExpYear} onChange={textInputChange} maxLength={2} className="form-control rounded-5 border-0 small" id="cardExpYear" placeholder="" style={{ height: '45px' }} />
                    <label className="small" htmlFor="cardExpYear">{t('views.addCard.yyyy')}<span className="text-danger ">*</span></label>
                </div>
                <div className="form-floating border form-floating-focus-within rounded-0 border-start-0 mb-3 d-flex align-items-center no-border-hover" >
                    <input type="text" name="cardCvv" value={state.cardCvv} onChange={textInputChange} maxLength={3} className="form-control rounded-5 border-0 small" id="cardCvv" placeholder="" style={{ height: '45px' }} />
                    <label className="small" htmlFor="cardCvv">{t('views.addCard.cvv')}<span className="text-danger ">*</span></label>
                </div>
            </div>

            <div className="row mt-3">
                <div className="col-sm-4 pe-web-3 mb-mobile">
                    <div className={`${darkMode && 'border border-secondary rounded-3 '} position-relative`}>
                        <label htmlFor="billingzip" className="form-label position-absolute start-0 ms-2 bg-white px-1 py-0 " style={{ top: -9 }}>{t('views.addCard.zipCode')}<span className="text-danger ">*</span></label>
                        <input type="text" id="billingzip" name="billingzip" value={state.billingzip} onChange={textInputChange} className={`${darkMode && 'bg-dark text-white'} form-control rounded-5`} placeholder="" style={{ height: '50px' }} />
                    </div>
                </div>
                <div className="col-sm-8 mb-mobile">
                    <div className={`${darkMode && 'border border-secondary rounded-3 '} position-relative`}>
                        <label htmlFor="billingaddress" className="form-label position-absolute start-0 ms-2 bg-white px-1 py-0 " style={{ top: -9 }}>{t('views.addCard.address')}<span className="text-danger ">*</span></label>
                        <input type="text" id="billingaddress" name="billingaddress" value={state.billingaddress} onChange={textInputChange} className={`${darkMode && 'bg-dark text-white'} form-control rounded-5`} placeholder="" style={{ height: '50px' }} />
                    </div>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-sm-4 pe-web-3 mb-mobile">
                    <div className={`${darkMode && 'border border-secondary rounded-3 '} position-relative`}>
                        <label htmlFor="billingcity" className="form-label position-absolute start-0 ms-2 bg-white px-1 py-0" style={{ top: -9 }}>{t('views.addCard.city')}<span className="text-danger ">*</span></label>
                        <input type="text" id="billingcity" name="billingcity" value={state.billingcity} onChange={textInputChange} className={`${darkMode && 'bg-dark text-white'} form-control rounded-5`} placeholder="" style={{ height: '50px' }} />
                    </div>
                </div>
                <div className="col-sm-4 pe-web-3 mb-mobile">
                    <div className={`${darkMode && 'border border-secondary rounded-3 '} position-relative`}>
                        <label htmlFor="billingstate" className="form-label position-absolute start-0 ms-2 bg-white px-1 py-0" style={{ top: -9 }}>{t('views.addCard.state')}<span className="text-danger ">*</span></label>
                        <input type="text" id="billingstate" name="billingstate" value={state.billingstate} onChange={textInputChange} className={`${darkMode && 'bg-dark text-white'} form-control rounded-5`} placeholder="" style={{ height: '50px' }} />
                    </div>
                </div>
                <div className="col-sm-4">
                    <div className={`${darkMode && 'border border-secondary rounded-3 '} position-relative`}>
                        <label htmlFor="billingcountry" className="form-label position-absolute start-0 ms-2 bg-white px-1 py-0" style={{ top: -9 }}>{t('views.addCard.country')}<span className="text-danger ">*</span></label>
                        <input type="text" id="billingcountry" name="billingcountry" value={state.billingcountry} onChange={textInputChange} className={`${darkMode && 'bg-dark text-white'} form-control rounded-5`} placeholder="" style={{ height: '50px' }} />
                    </div>
                </div>
                <div className="w-100 mt-1">
                    <p className="text-danger w-100 ">{t('views.account.allField')}</p>
                </div>

            </div>
            <div className="mt-0 pt-0 d-flex align-items-center justify-content-end">
                {submitFormTransaction &&
                    <button onClick={handleRefrechModal} className="btn btn-block text-secondary me-3 rounded-pill fw-bold px-3" style={{ backgroundColor: "#F0F0F0" }} data-bs-dismiss="modal">{t('words.cancel')}</button>
                }
                <button disabled={isDisabled()} className={`btn fb-btn-color btn-block text-white rounded-pill fw-bold d-flex align-items-center justify-content-center px-3`} onClick={() => addPaymentCard()}>
                    {((!submitFormTransaction && state.isLoading) || (submitFormTransaction && isLoaded)) ? <Loader /> :
                        <>
                            {submitFormTransaction ?
                                <span>{!isValidated ? `${t('words.confirm')}` : `${t('words.send')}`}</span> :
                                <>
                                    <span className="material-icons me-1 md-20 mb-0">add</span> {t('views.addCard.add')}
                                </>
                            }
                        </>
                    }
                </button>
            </div>

        </div>
    )
}
export default AddCardPayment;