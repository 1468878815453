import { HiBadgeCheck } from 'react-icons/hi';

/**
 * Define or change the images
 */

const avatar1 = require('../assets/img/test/rmate2.jpg');
const avatar2 = require('../assets/img/test/rmate1.jpg');
const avatar3 = require('../assets/img/test/rmate3.jpg');
const avatar4 = require('../assets/img/test/rmate4.jpg');
const avatar5 = require('../assets/img/test/rmate5.jpg');
const avatar6 = require('../assets/img/test/rmate6.jpg');
const post1 = require('../assets/img/test/post1.png');
const post2 = require('../assets/img/test/post2.png');
const post3 = require('../assets/img/test/post-img1.jpg');
const post4 = require('../assets/img/test/post-img2.jpg');
const post5 = require('../assets/img/test/post-img3.jpg');
const post6 = require('../assets/img/Untitled design (23).png');
const post7 = require('../assets/img/6680562.jpg');


const logo = require('../assets/logo/fblogo.png');
const logov2 = require('../assets/logo/FanBusy (2).png');
const logo3 = require('../assets/img/logo3.0.png');
const check = require('../assets/img/check.webp');
const error_404 = require('../assets/img/404.svg');
const send_mail = require('../assets/img/send-mail.png');
const warning = require('../assets/img/warning.png');
const to_release = require('../assets/img/to_release.png');
const verificationIdImg = require('../assets/img/verifImg2.png');
const verificationIdProfileImg = require('../assets/img/verifImg1.jpeg');
const story_media = require('../assets/img/bg-add-storieImg.jpg');
const story_text = require('../assets/img/bg-add-storieText.jpg');
const background = require('../assets/img/background.png');
const welcome = require('../assets/img/welcome.png');
const masterCard = require('../assets/logo/mast.png');
const visaCard = require('../assets/logo/visa.png');




const verif = <HiBadgeCheck size={18} color={'#0092EA'} />
const verifWhite = <HiBadgeCheck size={18} />
const plus = require('../assets/img/plus-square-svgrepo-com.png');
const plus2 = require('../assets/img/iconplus2.svg');



/**
 * export the images object
 */
const Images = {
    check,
    error_404,
    logo,
    logo3,
    logov2,
    send_mail,
    warning,
    to_release,
    story_media,
    story_text,


    avatar1,
    avatar2,
    avatar3,
    avatar4,
    avatar5,
    avatar6,
    post1,
    post2,
    post3,
    post4,
    post5,
    post6,
    post7,
    plus,
    plus2,
    verificationIdImg,
    verificationIdProfileImg,
    background,
    verif,
    verifWhite,
    welcome,
    masterCard,
    visaCard,
};
export default Images;