import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { Avatar, Loader } from "../../Components"
import { Images } from "../../Constants"

const ReportModal = ({ post, isModalOpen, isOnLine, handleRefrechModal, isLoaded, releaseErrors, setShowReleaseError, showReleaseError, reportContent, handleReportPost, textInputChange, linkRef }) => {
    const users = useSelector(state => state.users)
    const { t } = useTranslation()

    return (
        <div className={`modal fade ${isModalOpen ? 'show' : ''}`} id={`reportPostModal${post._id}`} data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true" >
            <div className={`modal-dialog modal-dialog-centered mw-web ${isModalOpen ? 'show' : ''}`} >
                <div className="modal-content rounded-4 p-3 shadow-sm bg-white" style={{ width: '100%' }}>
                    <h5 className="mb-3">Report this content</h5>
                    <div className="modal-header d-flex align-items-center justify-content-start border-0 p-0 ">
                        <div className="modal-title text-muted d-flex align-items-center" id="staticBackdropLabel">
                            <div className="position-relative">
                                <Avatar user={post.creator} width={40} height={40} />
                                {isOnLine && <span className="rounded-circle position-absolute bottom-0 mb-1" style={{ width: 10, height: 10, background: '#35D063', right: '2px', border: '2px solid white' }}></span>}
                            </div>
                            <div href={`/`} className="text-decoration-none d-flex flex-column text-captalize ms-2">
                                <h6 className="fw-bold mb-0 text-body mt-0 text-capitalize d-flex align-items-center">
                                    <span className="mx-0 p-0">{post.creator.display_name} </span>
                                    {post.account_type === 'CREATOR' &&
                                        <span className="ms-1 p-0 md-16 fw-bold mb-1">{Images.verif} </span>
                                    }


                                </h6>
                                <small className="text-muted">@{post.creator.display_name}</small>
                            </div>
                        </div>
                    </div>
                    <div className="modal-body p-0 mb-3" >
                        <div className="form-floating mt-2">
                            <textarea className={`form-control rounded-5  border`} placeholder={'Please enter a reason,' + users.users?.display_name} id="floatingTextarea2" style={{ height: '100px' }} name="reportContent" value={reportContent} onChange={textInputChange}></textarea>
                            <label htmlFor="floatingTextarea2" className="h6 text-muted mb-0">{t('views.post.PleaseEnterReason')}, {users.users?.display_name}</label>
                        </div>
                    </div>
                    <div className="mt-2">
                        <div className="d-flex align-items-center justify-content-end gap-3">
                            <button onClick={handleRefrechModal} className="btn btn-block text-secondary p-0 rounded-pill fw-bold px-3" style={{ backgroundColor: "#F0F0F0" }} data-bs-dismiss="modal" ref={linkRef}>{t('words.cancel')}</button>
                            <button className={`btn  btn-block rounded-pill fw-bold px-3 py-0 ${reportContent === '' ? 'disabled btn-secondary' : 'gradient-button text-white'}`} onClick={handleReportPost}>
                                {isLoaded ? (
                                    <Loader />
                                ) : (
                                    t('words.send')
                                )}
                            </button>
                        </div>
                        {(releaseErrors.length > 0 && showReleaseError) &&
                            <div className="alert alert-danger alert-dismissible" role="alert">
                                <div>{releaseErrors}</div>
                                <button type="button" className="btn-close" onClick={() => setShowReleaseError(false)} aria-label="Close"></button>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ReportModal