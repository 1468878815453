import { useState, useRef } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"

import { Images } from "../../Constants"
import { formatTimeToAmPm, renderFormatteContent } from "../../Utils"
import { TchatRequest } from "../../Services"
import Avatar from "../Avatar"
import TransactionsModal from "../TransactionsModal"
import { convertCurrency } from "../../Utils"
import Loader from "../Loader"
import { NavLink } from "react-router-dom"

const MessageBlocked = ({ msg, ismobile, receiver, refrech }) => {
    const [state, setState] = useState({
        unlockReponse: '',
        loading: false,
    });
    const { t } = useTranslation();
    const params = useSelector(state => state.params);
    const users = useSelector(state => state.users);
    const transactionMessageRef = useRef();
    const unlockTchatMessage = async (message_id) => {
        setState((prevState) => ({ ...prevState, loading: true }))
        let token = users.access_token;
        if (users.account?.balance < msg.unlock_fee) {
            if (transactionMessageRef) {
                transactionMessageRef.current?.click();
            }
        }
        else {
            let res = await TchatRequest(`message/unlock/${message_id}`, 'PUT', '', token);
            if (res.response.status_code === 200) {
                setState((prevState) => ({ ...prevState, unlockReponse: `${t('words.success')}` }))
                refrech(msg._id)

            }
            else if (res.response.status_code === 400 && res.response.response_type === "Error transaction") {
                setState((prevState) => ({ ...prevState, unlockReponse: (t('errors_messages.insuffisant_fund')) }))
            }
            else if (res.response.status_code === 400 && res.response.response_type === "Bad Request") {

                setState((prevState) => ({ ...prevState, unlockReponse: `${t('navigation.youDontFollowThisCreator')}` }))

            }
            else {
                setState((prevState) => ({ ...prevState, unlockReponse: `${t('navigation.tryAgain')}` }))

            }

        }
        setState((prevState) => ({ ...prevState, loading: false }))
    };

    const handleDataReady = (data) => {
        setState((prevState) => ({ ...prevState, unlockReponse: (data) }))
    };

    return (
        <>
            <div className={'bg-white pt-1 pb-2 feed-item  mb-3 d-flex '} style={{ backgroundColor: '#D0D5DA' }}>
                <NavLink to={`/${receiver.pseudo}`} className={`text-decoration-none ${ismobile && 'small'}`}>
                    <Avatar user={receiver} width={ismobile ? 30 : 40} height={ismobile ? 30 : 40} fontSize={`${ismobile ? 'small' : 'fs-6'}`} />
                </NavLink>
                <div className="align-items-start small ">
                    <div className="my-2" style={{ backgroundColor: '#D0D5DA' }}>
                        <div className="position-relative  d-flex justify-content-between " >
                            <img src={Images.to_release} className={`img-fluid mb-0 ${!ismobile && 'h-w'}`} alt="post-img" />
                            <div className="position-absolute bottom-0 start-50 translate-middle-x border rounded-3 p-1 mb-2 mt-5 w-100" >
                                <div className="d-flex align-item-center justify-content-between">
                                    <div className="d-flex align-item-center justify-content-center gap-3 mb-1">
                                        {msg.file?.filter(file => file?.mime_type?.includes('image')).length > 0 &&
                                            <div className="d-flex align-item-center justify-content-center" style={{ color: '#AFB0B4' }}>
                                                <span className="material-icons m-0 p-0 me-1" style={{ fontSize: 18 }}>image</span>
                                                <p className="m-0 p-0">{msg.file?.filter(file => file?.mime_type?.includes('image')).length}</p>
                                            </div>
                                        }
                                        {msg.file?.filter(file => file?.mime_type?.includes('video')).length > 0 &&
                                            <div className="d-flex align-item-center justify-content-center" style={{ color: '#AFB0B4' }}>
                                                <span className="material-icons m-0 p-0 me-2" style={{ fontSize: 18 }}>videocam</span>
                                                <p className="m-0 p-0">{msg.file?.filter(file => file?.mime_type?.includes('video')).length}</p>
                                            </div>
                                        }
                                    </div>
                                    <span className="material-icons" style={{ fontSize: 18, color: '#AFB0B4' }}>lock</span>
                                </div>
                                <button onClick={() => unlockTchatMessage(msg._id)} disabled={state.loading} className={`btn fb-btn-color text-white btn-block rounded-pill d-flex align-items-center justify-content-between p-1 px-4 mb-1 w-100`} >
                                    {state.loading ?
                                        <div className="text-center w-100"> <Loader />  </div>
                                        :
                                        <>
                                            <span >{t('navigation.unlockFor')}</span>
                                            {users.account?.balance < msg.unlock_fee &&
                                                <span data-bs-toggle="modal" data-bs-target={`#sentTipModal${msg._id}`} ref={transactionMessageRef}></span>
                                            }
                                            <span className='mx-2'>{convertCurrency(params.currency, params.currencies, msg.unlock_fee, 'GET')}{params.currency}</span>
                                        </>
                                    }
                                </button>
                                {state.unlockReponse !== "" &&
                                    <div className="alert alert-danger alert-dismissible p-1" role="alert">
                                        <div>{state.unlockReponse}</div>
                                        <button type="button" className="btn-close p-2" data-bs-dismiss="alert" aria-label="Close"></button>
                                    </div>
                                }
                            </div>


                        </div>
                        {msg.content.length > 0 &&
                            <div className="fw-bold text-center " >
                                <span className={`text-muted fs-6  d-inline-block px-3 ${msg.content.length > 20 ? ' message-control ' : 'normal'} `} >
                                    {renderFormatteContent(msg.content)}
                                </span>
                            </div>
                        }

                    </div>
                    <div className="d-flex align-items-center ms-2">
                        <span className="small text-muted">{formatTimeToAmPm(msg.created_at, t) !== "Invalid Date" && formatTimeToAmPm(msg.created_at, t)}</span>
                    </div>
                </div>

            </div>
            <TransactionsModal onDataReady={handleDataReady} data={{ id: msg._id, photo: receiver.photo, display_name: receiver.display_name, pseudo: receiver?.pseudo, account_type: receiver.account_type, postId: msg._id }} action={'unlockMessage'} nameForm={`${t('navigation.unlockMessages')}`} amount={convertCurrency(params.currency, params.currencies, msg.unlock_fee, 'GET')?.replace(/\s/g, '')} refrech={refrech} />

        </>
    )
}
export default MessageBlocked