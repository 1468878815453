import React, { useEffect, useState, useCallback } from "react";
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { RequestFanbusy } from "../../Services";
import { useSelector } from "react-redux";
import Select from 'react-select'
import { t } from "i18next";
import { useNavigate } from 'react-router-dom'

import { AppContainer } from "../../Containers";
import { getPaymentModeByCountry, useScreenSize } from "../../Utils";
import { Metrics } from "../../Constants";
import { CardTransactions, Loader } from "../../Components";
import { Alert } from "../../Components";




export const EditDebitMode = () => {
    const navigate = useNavigate();
    const users = useSelector((state) => state.users);
    const darkMode = useSelector((state) => state.theme.darkMode);
    const token = useSelector((state) => state.users.access_token);
    const params = useSelector((state) => state.params);
    const accountID = useSelector((state) => state.users.account._id);
    // const [renderKey, setRenderKey] = useState(0);
    const [accountData, setAccountData] = useState({
        account_number: users.account?.account_bank !== 'FMM' ? users.account?.account_number : '',
        routing_number: "",
        swift_code: "",
        isValidNumber: false,
        isValidPhone: '',
        isEditMobile: false,
        isEditBank: false,
        response: "",
        alertType: "",
        isLoading: false,
        loading: false,
        responseMobile: '',
        paymentMode: [],
        accountName: ""

    });
    const selectedOption = params.country;
    const [transfertMode, setTransfertMode] = useState({ banks: [] });
    // const banksList = transfertMode.banks ? transfertMode.banks.map(bank => ({
    //     value: bank.id,
    //     label: bank.name,
    //     code: bank.code,
    // })) : [];
    const [selectedBank, setSelectedBank] = useState(null);
    const [selectedBranche, setSelectedBranche] = useState(null);
    const [mobileOperator, setMobileOperator] = useState([]);
    const [selectedOperator, setSelectedOperator] = useState(null)
    const [phoneNumber, setPhoneNumber] = useState(users.account?.account_bank === 'FMM' ? users.account?.account_number : '')
    const currency = params.currency
    //const countryData = phoneCodeFlagList.find(item => item.code === params.country); ne pas suprimmer
    const screenSize = useScreenSize()
    const isMobileSize = screenSize === 'Extra Small' || screenSize === 'Small' || screenSize === 'Medium'
    const [bankChecked, setBankChecked] = useState(false);
    const [mobileChecked, setMobileChecked] = useState(true);
    const [showPassword, setShowPassword] = useState(false);

    function isValidAccountNumber(accountNumber) {
        const cleanedAccountNumber = accountNumber.replace(/\s/g, '');
        const regex = /^\d{16}$/;
        return regex.test(cleanedAccountNumber);
    }
    const handleChange = useCallback((selectedOption) => {
        setSelectedBank(selectedOption);
        setAccountData((prevData) => ({ ...prevData, isEditBank: true, }));
    }, []);

    useEffect(() => {
        if (selectedOption) {
            const fetchData = async () => {
                try {
                    const currentTransfertMode = await RequestFanbusy(`accounts/transfert/modes/${params.country}`, "GET", "", token);
                    setTransfertMode(currentTransfertMode);
                    if (currentTransfertMode.response.status_code === 200) {
                        setTransfertMode(currentTransfertMode.response.data);
                        // setRenderKey(prevKey => prevKey + 1);
                    }
                } catch (error) {
                    console.error(error);
                }

            };

            fetchData();
        }

    }, [selectedOption, token, params.country, handleChange]);

    useEffect(() => {
        if (selectedBank && selectedBank.value) {
            const fetchData = async () => {
                try {
                    const transfertBrancheData = await RequestFanbusy(`accounts/transfert/modes/${selectedBank.value}/branches`, "GET", "", token);
                    setSelectedBranche(transfertBrancheData.response.data[0].branch_code);
                } catch (error) {

                }
            };
            fetchData();
        }
    }, [selectedBank, token]);

    useEffect(() => {
        let paymentMode = getPaymentModeByCountry(users.access_token, params.country, params.currency);
        paymentMode.then((value) => {
            setAccountData((prev) => ({ ...prev, paymentMode: value }));
        });

    }, [params.country, params.currency, users.access_token]);



    const hasMobileObject = (obj) => {
        for (const key in obj) {
            if (key === 'mobile' && obj.hasOwnProperty(key)) {
                return true;
            }
        }
        return false;
    }

    const handleChangeAccountData = (e) => {
        const { name, value } = e.target;
        setAccountData((prevData) => ({ ...prevData, [name]: value, }));
        if (name === 'account_number') {
            setAccountData((prevData) => ({ ...prevData, isEditBank: true, }));
        } else if (name === 'account_name') {
            setAccountData((prevData) => ({ ...prevData, accountName: value, }));
        }
    };

    const submitBankDetails = async (event) => {
        event.preventDefault();
        setAccountData((prev) => ({ ...prev, loading: true }))
        const form = {
            transfert_mode: "BANKS",
            transfert_country: params.country,
            transfert_currenry: currency,
            account_number: accountData.account_number,
            account_bank: accountData.accountName,
            bank_name: accountData.accountName,
            branch_code: selectedBranche,
            routing_number: accountData.routing_number,
            swift_code: accountData.swift_code,
        }

        if (isValidAccountNumber(form.account_number)) {
            try {
                const response = await RequestFanbusy(`accounts/transfert/modes/${accountID}`, "PUT", form, token);
                if (response.status === 200) {
                    setAccountData((prev) => ({ ...prev, alertType: "success", response: `${t('views.send_the_payment_methods.bankInformationSuccess')}`, loading: false, }))
                    navigate('/account')
                }
                else {
                    setAccountData((prev) => ({ ...prev, alertType: 'warning', response: `${t('views.send_the_payment_methods.andErrorOcured')}` }))
                }
            } catch (error) {
                setAccountData((prev) => ({ ...prev, loading: false, alertType: 'warning', response: error }))
            }
        } else {
            setAccountData((prev) => ({ ...prev, isValidNumber: true }))
        }
        setAccountData((prev) => ({ ...prev, loading: false }))
    };

    // useEffect(() => {
    //     if (transfertMode && transfertMode.mobile) {
    //         const operatorData = [{ value: transfertMode.mobile?.code, label: transfertMode.mobile?.label }]
    //         setMobileOperator(operatorData);
    //     } else {
    //         setMobileOperator();
    //     }
    // }, [transfertMode]);

    useEffect(() => {
        const operatorData = [{ value: 'FMM', label: "MOBILE MONEY" }]
        setMobileOperator(operatorData);
    }, []);

    const handleOperatorChange = (selectedOption) => {
        setSelectedOperator(selectedOption);
    };

    const submitMobileDetails = async (event) => {
        event.preventDefault();
        setAccountData((prev) => ({ ...prev, isLoading: true }))
        const form = {
            transfert_mode: "MOBILE",
            transfert_country: params.country,
            transfert_currenry: currency,
            account_number: phoneNumber,
            account_bank: selectedOperator.value
        }

        if (Metrics.phoneRegex.test(form.account_number)) {
            try {
                const response = await RequestFanbusy(`accounts/transfert/modes/${accountID}`, "PUT", form, token);
                if (response.status === 200) {
                    setAccountData((prev) => ({ ...prev, alertType: "success", isLoading: false, responseMobile: `${t('views.send_the_payment_methods.bankInformationSuccess')}` }))
                    navigate('/account')
                }
                else {
                    setAccountData((prev) => ({ ...prev, alertType: "warning", isLoading: false, responseMobile: `${t('views.send_the_payment_methods.andErrorOcured')}` }))
                }
            } catch (error) {
                setAccountData((prev) => ({ ...prev, isLoading: false, alertType: "warning", responseMobile: error }))
            }
        } else {
            setAccountData((prev) => ({ ...prev, isValidPhone: `${t('views.send_the_payment_methods.phoneNumberNotValid')}`, isLoading: false }))
        }
    };

    const handleCheckboxChange = (e) => {
        const { id, checked } = e.target;
        if (id === "checkbox1") {
            setBankChecked(checked);
            setMobileChecked(!checked);
        } else if (id === "checkbox2") {
            setMobileChecked(checked);
            setBankChecked(!checked);
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleChangePhoneNumber = (value, meta) => {
        setPhoneNumber(value)
        //setState(prevState => ({ ...prevState, phone_number: value, country_code: meta.country.iso2 }));
    };

    // const checkMobilePaymentMode = () => {
    //     for (const paymentMode of accountData.paymentMode) {
    //         if (paymentMode.label === 'Mobile Money') {
    //             return true;
    //         }
    //     }
    //     return false;
    // };

    return (
        <>
            <AppContainer>
                {<main style={{ marginTop: isMobileSize ? '15px' : '' }} className={`col col-xl-6 order-xl-2 col-lg-12 order-lg-1 col-md-12 col-sm-12 col-12 px-0  pt-15 border border-fw-ligh  border-bottom-0 ${isMobileSize && 'pt-5 fixed-content pb-5 '}`}>
                    <div className="main-content ">
                        <div className="d-flex align-items-center  m-none border-bottom py-3 fix-sidebar bg-white mb-1 top-0">
                            <a href={'/account'} className={`text-dark material-icons text-decoration-none m-none ms-3`}>
                                arrow_back
                            </a>
                            <p className={`text-body ms-2 mb-0 fw-bold fs-6`}>{t('views.send_the_payment_methods.payementDetails')}</p>
                        </div>
                        <div className={` rounded-4 mb-4 overflow-hidden mb-lg-0 border-0 px-2`}>
                            <div className={`container `}>
                                <div className="d-flex justify-content-center mt-2">
                                    <div className="form-check form-check-inline">
                                        <input className={`form-check-input ${darkMode && "bg-dark border-secondary"}`}
                                            type="checkbox" id="checkbox2" name="mobile" value=""
                                            checked={mobileChecked} onChange={handleCheckboxChange}
                                        />
                                        <label className="form-check-label" htmlFor="checkbox2">
                                            {t('views.send_the_payment_methods.mobileMoney')}
                                        </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className={`form-check-input ${darkMode && "bg-dark border-secondary"}`}
                                            type="checkbox" id="checkbox1" name="bank" value=""
                                            checked={bankChecked} onChange={handleCheckboxChange}
                                        />
                                        <label className="form-check-label" htmlFor="checkbox1">
                                            {t('views.send_the_payment_methods.bank')}
                                        </label>
                                    </div>
                                </div>
                            </div>
                            {bankChecked &&
                                <div className="mb-4" style={{ height: '75vh' }}>
                                    <div className="feeds mb-2 ">
                                        <div className={`${darkMode ? "bg-dark" : "bg-white"} py-1 feed-item rounded-4 faq-page`}>
                                            <div className=" d-flex r mb-2 ">
                                                <h5 className={`lead fw-bold mb-0 px-2 ${darkMode ? "text-white" : "text-gray"} fs-5 pt-3 `}>{t('views.send_the_payment_methods.bankInformation')} </h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="px-2 pb-4">
                                        <div className={` mb-2 }`}>
                                            <label>{t('views.send_the_payment_methods.bankName')}</label>
                                            {/* <Select key={renderKey} value={selectedBank} onChange={handleChange} options={banksList} placeholder={users.account.transfert_mode === "BANKS" ? users.account.bank_name : `${t('views.send_the_payment_methods.chooseBank')}`} /> */}
                                            <input className={` form-control rounded-2 my-2 bg-transparent border boder-secondary p-2 text-uppercase fs-6`}
                                                type="text"
                                                name="account_name"
                                                value={accountData.accountName}
                                                placeholder={t('views.send_the_payment_methods.bankName')}
                                                onChange={handleChangeAccountData}
                                            />
                                        </div>
                                        <label> {t('views.send_the_payment_methods.accountNumber')}</label>
                                        <div className="input-group border rounded-2 mb-2 d-flex justify-content-between align-items-center">
                                            <input style={{ paddingBottom: "0px", paddingTop: "0px", border: 'none', outline: 'none', height: "100%" }}
                                                className={`${darkMode && 'bg-dark text-white border-fw-ligh'}  form-control rounded-2 my-2 bg-transparent`}
                                                type={showPassword ? "text" : "password"}
                                                name="account_number"
                                                value={accountData.account_number}
                                                placeholder={users.account.transfert_mode === "BANKS" ? users.account.account_number : `${t('views.send_the_payment_methods.enterYourAcountBank')}`}
                                                onChange={handleChangeAccountData}
                                            />
                                            <div className="input-group-append">
                                                <button className="border-0 bg-transparent"
                                                    type="button" onClick={togglePasswordVisibility}>
                                                    {showPassword ? (
                                                        <AiFillEyeInvisible size={17} />
                                                    ) : (
                                                        <AiFillEye size={17} />
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                        {(params.currency === "USD" || params.currency === "EUR" || params.currency === "GBP") &&
                                            <div className="d-flex justify-content-between  mb-3" >
                                                <div style={{ width: "46%" }} className="">
                                                    <label>{t('views.send_the_payment_methods.routingNumber')}</label>
                                                    <input disabled={!accountData.isEditBank} className={`${darkMode && 'bg-dark text-white border border-fw-ligh'}    py-1 form-control rounded-2  `} type="text"
                                                        name="routing_number" value={accountData.routing_number}
                                                        placeholder={users.account.transfert_mode === "BANKS" ? users.account.routing_number : `${t('views.send_the_payment_methods.routingNumber')}`}
                                                        onChange={handleChangeAccountData} />
                                                </div>
                                                <div style={{ width: "46%" }} className="">
                                                    <label className="text-uppercase">{t('views.send_the_payment_methods.swiftCode')}</label>
                                                    <input disabled={!accountData.isEditBank} className={`${darkMode && 'bg-dark text-whiteborder border-fw-ligh'}   py-1 form-control rounded-2 text-uppercase`} type="text"
                                                        name="swift_code" value={accountData.swift_code} placeholder={users.account.transfert_mode === "BANKS" ? users.account.swift_code : `${t('views.send_the_payment_methods.swiftCode')}`}
                                                        onChange={handleChangeAccountData} />
                                                </div>
                                            </div>}

                                        <div className="d-grid">
                                            <div className="d-flex justify-content-end mb-3" >
                                                <div style={{ width: "26%", marginLeft: "0px" }}>
                                                    <button onClick={submitBankDetails} className="btn gradient-button text-white rounded-pill w-100 text-decoration-none py-1 fw-bold text-uppercase m-0 border-0" >
                                                        {accountData.isLoading ? <Loader /> : `${t('views.send_the_payment_methods.send')}`}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        {accountData.isValidNumber &&
                                            <div className="alert alert-danger alert-dismissible mt-2" role="alert">
                                                <div>{t('views.send_the_payment_methods.bankAccountNotValid')}</div>
                                                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                            </div>}
                                        {accountData.response &&
                                            <Alert msg={accountData.response} type={accountData.alertType} />}

                                    </div>
                                </div>}
                        </div>
                        {(hasMobileObject(transfertMode) && mobileChecked) &&
                            <div className={`${darkMode && "bg_fan"}  overflow-hidden mb-4 mt-1 mb-lg-0 px-2 pb-5`}>
                                <div className="feeds mb-2 ">
                                    <div className={`${darkMode ? "bg-dark" : "bg-white"} py-1 feed-item rounded-4 faq-page`}>
                                        <div className=" d-flex r mb-2 ">
                                            <h5 className={`lead fw-bold mb-0 px-2 ${darkMode ? "text-white" : "text-gray"} fs-5 pt-3 `}>{t('views.send_the_payment_methods.mobileMoney')}</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="px-2 pb-5">
                                    <div className={`pb-3`}>
                                        {/* {<PhoneInput
                                            className="form-control rounded-5 customInputChild"
                                            style={{ width: '100%' }}
                                            value={phoneNumber}
                                            onChange={handleChangePhoneNumber}
                                            placeholder={t('views.send_the_payment_methods.enterNumber')}
                                            defaultCountry={users.users.country_code}
                                        />} */}
                                        <PhoneInput
                                            inputClassName="w-100 "
                                            defaultCountry="cm"
                                            value={phoneNumber}
                                            onChange={handleChangePhoneNumber}
                                        />
                                    </div>
                                    <div className=" mb-3" >
                                        <label> {t('views.send_the_payment_methods.operator')}</label>
                                        <Select className="text-uppercase" value={selectedOperator} onChange={handleOperatorChange} options={mobileOperator} placeholder={"MOBILE MONEY"} />
                                    </div>
                                    <div className="d-grid">
                                        <div className="d-flex justify-content-end mb-3" >
                                            <div style={{ width: "26%", marginLeft: "0px" }}>
                                                <button onClick={submitMobileDetails} className="btn gradient-button text-white rounded-pill w-100 text-decoration-none py-1 fw-bold text-uppercase m-0 border-0" >
                                                    {accountData.isLoading ? <Loader /> : `${t('views.send_the_payment_methods.send')}`}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    {accountData.responseMobile.length > 0 &&
                                        <Alert msg={accountData.responseMobile} type={accountData.alertType} />}
                                    {accountData.isValidPhone.length > 0 &&
                                        <div className="alert alert-danger alert-dismissible mt-2" role="alert">
                                            <div>{accountData.isValidPhone}</div>
                                            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                        </div>}
                                </div>
                            </div>}
                    </div>
                </main>}
                <aside className="col col-xl-3 order-xl-3 col-lg-6 order-lg-3 col-md-6 col-sm-6 col-12 pt-3" style={{ paddingLeft: '13px' }}>
                    <div className="fix-sidebar">
                        <CardTransactions data={{ selectedAction: 'credit', addCard: true }} users={users} params={params} styleResp={'side-trend lg-none'} />
                    </div>
                </aside>
            </AppContainer>
        </>

    );
};
