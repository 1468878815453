import * as React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams, useNavigate } from "react-router-dom";
//import 'react-phone-number-input/style.css'
//import PhoneInput from 'react-phone-number-input'

import Loader from "./Loader";
import { RequestFanbusy } from "../Services";
import PaymentModeImage from "./PaymentModeImage";
import { convertCurrency, convertCurrencyBalance, getPaymentCard, getPaymentModeByCountry, getUserAccount } from "../Utils";
import { CheckMailModalView } from "../Views";

const CardTransactions = ({ data, users, params, styleResp, styleShadow, setEditSelectAction }) => {
    const { t } = useTranslation();
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const darkMode = useSelector((state) => state.theme.darkMode);
    //const [showPhone, setShowPhone] = React.useState(false);
    const [minAmountCredit, setMinAmountCredit] = React.useState(1);
    const [maxAmount, setMaxAmount] = React.useState(0);
    const [amount, setAmount] = React.useState({
        minAmountDebit: 0,
        maxAmountDebit: 0,

    })
    const [refreshKey, setRefreshKey] = React.useState(0);
    const [state, setState] = React.useState({
        amount: '',
        paymentMode: [],
        withdrawalMode: {},
        withdrawalCardId: "",
        transactions: [],
        cardPayment: data.cardPayment ? data.cardPayment : [],
        lastFiveTransactions: [],
        creator: {},
        phoneNumber: '',
        phoneCode: '',
        amountConvert: '',
        releaseErrors: '',
        alertType: "",
        userAccount: 0,
        mode: '',
        withdrawalMethod: "",
        cardNumber: '',
        cardId: '',
        tx_ref: '',
        otp: '',
        transaction_ref: '',
        transaction_id: '',
        request_transaction_id: false,
        isCredit: true,
        isValidated: true,
        isLoading: true,
        isLoaded: false
    });
    let location = window.location;
    let [searchParams, setSearchParams] = useSearchParams();
    // const handleChangePhoneNumber = (value) => {
    //     setState(prevState => ({ ...prevState, phoneNumber: value }));
    // };

    // const handleCountryChange = (countryCode) => {
    //     setState(prevState => ({ ...prevState, phoneCode: countryCode }));
    // };


    const textInputChange = (input) => {
        const target = input.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        switch (name) {
            case 'rising':
                setState({ ...state, amount: value });

                break;
            case 'actionAccount':
                setState({ ...state, selectedAction: value });
                break;
            case 'modePayment':
                setState({ ...state, mode: value });
                break;
            case 'withdrawalMethod':
                setState({ ...state, withdrawalMethod: value });
                break;
            case 'withdrawalCardId':
                setState({ ...state, withdrawalCardId: value });
                break;
            case 'cardId':
                setState({ ...state, cardId: value });
                break;
            case 'cardNumber':
                setState({ ...state, cardNumber: value });
                break;
            case 'otp':
                setState({ ...state, otp: value });
                break;
            case 'transaction_id':
                setState({ ...state, transaction_id: value });
                break;

            default:
                break;
        }
    }

    const isPriceOutOfRange = () => {
        const price = parseFloat(state.amount);
        const maxAmount = typeof amount.maxAmountDebit === 'string' ? parseFloat(amount.maxAmountDebit.replace(/\s/g, '')) : amount.maxAmountDebit;
        const minAmount = typeof amount.minAmountDebit === 'string' ? parseFloat(amount.minAmountDebit.replace(/\s/g, '')) : amount.minAmountDebit;
        const userAccountParsed = typeof state?.userAccount === 'string' ? parseFloat(state?.userAccount.replace(/\s/g, '')) : state?.userAccount;

        return price > maxAmount || price < minAmount || price > userAccountParsed;
    };

    const handleSubmitAccount = async () => {

        if (false) {
            if (data.selectedAction === 'debit') {
                setState({ ...state, releaseErrors: `${t('navigation.pleaseEnterAValidAmountBetween')} ${minAmountCredit}${params.currency} and ${maxAmount}${params.currency}` });
            } else {
                setState({ ...state, releaseErrors: `${t('navigation.pleaseEnterAValidAmountGreaterThanOrEqualTo')} ${minAmountCredit}${params.currency}` });
            }
        }

        else {
            setState((prev) => ({ ...prev, isLoaded: true }));

            if ((users.users.phone_number === '' || (state.phoneNumber !== '')) && state.mode === 'MOBILE_MONE_XAF') {
                const formUpdateCreator = {
                    full_name: users.users.full_name,
                    display_name: users.users.display_name,
                    pseudo: users.users.pseudo,
                    photo: users.users.photo,
                    banner: users.users.banner,
                    id_image: "",
                    id_card_image: "",
                    country_code: state.phoneCode,
                    phone_number: state.phoneNumber,
                    bio: users.users.bio,
                    localisation: users.users.localisation,
                    web_site: users.users.web_site,
                    account_type: users.users.account_type,
                    is_free_account: users.users.is_free_account,
                    subscription_fee: users.users.subscription_fee,
                    active_tchat: users.users.active_tchat,
                    tchat_fee: users.users.tchat_fee,
                    currency: users.users.currency,
                    lang: users.users.lang,
                    active_creator: users.users.active_creator,
                }
                let res = await RequestFanbusy("creators/", 'PUT', formUpdateCreator, users.access_token);
                if (res.status === 200) {
                    const action = { type: "USERS", value: res.response.data };
                    dispatch(action);
                }
            }
            // const form = {
            //     amount: state.amount,
            //     payment_mode: data.selectedAction === 'credit' ? state.mode : 'ACCOUNT',
            //     currency: params.currency,
            //     country: params.country,
            //     network: "*",
            //     card_id: state.cardId,
            //     redirect_url: location.origin + location.pathname,
            //     referal_id: users.account._id,
            //     referal_type: "CREATOR",
            //     referal_action: "CREDIT"
            // };
            const form = {
                amount: state.amount,
                payment_mode: data.selectedAction === 'credit' ? state.mode : 'ACCOUNT',
                currency: params.currency,
                country: params.country,
                network: "*",
                card_id: "",
                product_image: users.users.photo.length > 0 ? users.users.photo : "https://www.fanbusy.com/assets/img/logo.png",  // users.users.banner
                product_label: "Credit Account",
                redirect_url: location.origin + location.pathname,
                redirect_view_url: location.origin + location.pathname,
                referal_id: users.account._id,
                referal_type: "CREATOR",
                referal_action: "CREDIT"
            }
            console.log(form);
            setState({ ...state, isLoaded: true, releaseErrors: t('views.account.yourTransactionIsBeingProcessed'), alertType: 'warning' })

            if (data.selectedAction === 'credit') {
                let creditData = null
                // if (state.isValidated) {
                //     console.log(form);
                //     let res = await RequestFanbusy(`transactions/generic-transaction`, 'POST', form, users.access_token);
                //     console.log(res);
                //     if (res.response.status_code === 201) {
                //         window.location.replace(res.response.data.link);
                //     }
                //     else if (res.status === 406) {
                //         setState({ ...state, releaseErrors: t('navigation.yourLastTransactionIsBeingProcessed'), mode: '', alertType: 'warning', isLoaded: false });
                //         setRefreshKey(prev => prev + 1);
                //     }
                // }

                if (state.isValidated) {
                    let res = await RequestFanbusy(`accounts/credit/${users.account._id}`, 'POST', form, users.access_token);
                    console.log(res);
                    if (res.response.status_code === 201) {
                        creditData = res.response.data
                        setState({ ...state, amount: '', card_id: '', alertType: 'warning', releaseErrors: `${t('views.account.successYourAccountHasBeenCreditedWith')} ${state.amount}${params.currency}.`, isLoaded: false });
                        dispatch({ type: "ACCOUNT", value: { account: creditData } });
                        dispatch({ type: "REFRESH", value: 1 });
                    }
                    else if (res.response.status_code === 202) {
                        window.location.assign(res.response.data.payment_info.payment_form_url);

                        // if (state.mode === 'FMM') {
                        //     let message;
                        //     if (res.response.data.narration?.includes('Orangemoney CM')) {
                        //         message = t('views.account.orangeMoneyCm');
                        //     } else if (res.response.data?.narration.includes('Insufficient')) {
                        //         message = t('views.account.insufficientFund');
                        //     } else if (res.response.data?.narration.includes('MTN CM')) {
                        //         message = t('views.account.mtnCm');
                        //     } if (res.response.data.narration?.includes('Orangemoney CI')) {
                        //         message = t('views.account.orangeMoneyCi');
                        //     } else {
                        //         message = res.response.data.narration;
                        //     }
                        //     setState({ ...state, amount: '', card_id: '', request_transaction_id: 'true', releaseErrors: message, alertType: 'warning', isLoaded: true });

                        //     if (res.response.data.redirect_payment === true) {
                        //         window.location.replace(res.response.data.redirect_url);
                        //     }
                        //     else {
                        //         let checkStatus = await RequestFanbusy(`accounts/credit/check-status/${res.response.data.tx_ref}`, 'PUT', "", users.access_token);
                        //         while (checkStatus.response.data.status === "PENDING_VALIDATION") {
                        //             await new Promise(resolve => setTimeout(resolve, 5000));
                        //             checkStatus = await RequestFanbusy(`accounts/credit/check-status/${res.response.data.tx_ref}`, 'PUT', "", users.access_token);
                        //         }
                        //         if (checkStatus.response.status_code === 201) {
                        //             creditData = res.response.data
                        //             setState({ ...state, amount: '', card_id: '', releaseErrors: `${t('views.account.successYourAccountHasBeenCreditedWith')} ${state.amount}${params.currency}.`, alertType: 'success', isLoaded: false });
                        //             dispatch({ type: "ACCOUNT", value: { account: creditData } });
                        //             dispatch({ type: "REFRESH", value: 1 });
                        //         }
                        //         else {
                        //             let errorMessage = t('views.account.processingYourTransaction');
                        //             setState({ ...state, amount: '', mode: '', card_id: '', request_transaction_id: 'true', releaseErrors: errorMessage, alertType: 'warning', isLoaded: false });
                        //             setRefreshKey(prev => prev + 1);
                        //         }

                        //     }
                        // } else if (state.mode === 'CARD') {
                        //     window.location.replace(res.response.data.redirect_url);
                        //     setState({ ...state, releaseErrors: '', tx_ref: res.response.data.tx_ref, isValidated: false, isLoaded: false });
                        //     setRefreshKey(prev => prev + 1);
                        // }
                    }
                    else if (res.status === 400 && res.response.response_type === "Insuffisant fund") {
                        setState({ ...state, releaseErrors: t('errors_messages.insuffisant_fund'), alertType: 'warning', isLoaded: false });
                        setRefreshKey(prev => prev + 1);
                    }
                    else if (res.status === 400 && res.response.response_type === "Bad Request") {
                        setState({ ...state, releaseErrors: res.response.description, alertType: 'danger', isLoaded: false });
                        setRefreshKey(prev => prev + 1);
                    }
                    else if (res.status === 406 && res.response.response_type === "Not acceptable") {
                        setState({ ...state, releaseErrors: t('navigation.andErrorOcured'), alertType: 'danger', isLoaded: false });
                        setRefreshKey(prev => prev + 1);
                    }
                    else if (res.status === 400 && res.response.description === "Min amount is 1.0 USD") {
                        let minAmount = params.currencies?.filter((dataCurrency) => dataCurrency.to_currency === params.currency)[0];
                        setState({ ...state, mode: '', releaseErrors: `${t('views.account.errorTheMinimumTransactionIs')}` + minAmount.value + ' ' + params.currency, alertType: 'warning', isLoaded: false });
                        setRefreshKey(prev => prev + 1);
                    }
                    else if (res.status === 406 && res.response.description === "Your last transaction is being processed") {
                        setState({ ...state, releaseErrors: t('navigation.yourLastTransactionIsBeingProcessed'), mode: '', alertType: 'warning', isLoaded: false });
                        setRefreshKey(prev => prev + 1);
                    }
                    else {
                        setState({ ...state, amount: '', releaseErrors: t('navigation.andErrorOcured'), alertType: 'warning', isLoaded: false });
                        setRefreshKey(prev => prev + 1);
                    }
                }
                else {
                    const formValidated = {
                        tx_ref: state.tx_ref,
                        payment_mode: data.selectedAction === 'credit' ? state.mode : 'payment user connect',
                        otp: state.otp,
                        card_id: state.cardId
                    };
                    let res = await RequestFanbusy(`accounts/payment/confirm/${users.account._id}`, 'POST', formValidated, users.access_token);
                    if (res.response.status_code === 201) {
                        setState({ ...state, amount: '', otp: '', mode: '', card_id: '', releaseErrors: `${t('views.account.successYourAccountHasBeenCreditedWith')} ${state.amount}${params.currency}.`, alertType: 'success', isValidated: true, isLoaded: false });
                        dispatch({ type: "ACCOUNT", value: { account: creditData } });
                        dispatch({ type: "REFRESH", value: 1 });
                        setRefreshKey(prev => prev + 1);
                    }
                    else if (res.status === 422 && res.response.response_type === "Unprocessable Entity") {
                        setState({ ...state, amount: '', mode: '', otp: '', releaseErrors: t('navigation.andErrorOcured'), alertType: 'warning', isValidated: false, isLoaded: false });
                        setRefreshKey(prev => prev + 1);
                    }
                }
            }
            else {
                if (state.withdrawalMode?.account_bank !== '') {
                    if (isPriceOutOfRange()) {
                        setState({
                            ...state,
                            releaseErrors: `${t('navigation.validAmount')} ${amount.minAmountDebit} ${t('words.and')} ${amount.maxAmountDebit}`,
                            alertType: 'warning',
                            isLoaded: false
                        });
                        return
                    }
                    let res = await RequestFanbusy(`accounts/debit/${users.account._id}`, 'POST', form, users.access_token);

                    if (res.status === 200) {
                        setState({ ...state, releaseErrors: t('views.account.successYourRequestHasBeenTaken'), alertType: 'success', amount: '', isLoaded: false });
                        dispatch({ type: "ACCOUNT", value: { account: res.response.data } });
                        dispatch({ type: "REFRESH", value: 1 });
                        setEditSelectAction({ ...state, selectedAction: 'debit' });
                    }
                    else if (res.status === 400 && res.response.response_type === "Insuffisant fund") {
                        setState({ ...state, releaseErrors: t('errors_messages.insuffisant_fund'), amount: '', alertType: 'warning', isLoaded: false });
                        setRefreshKey(prev => prev + 1);
                    }
                    else if (res.status === 406 && res.response.response_type === "Not Acceptable") {
                        setState({ ...state, releaseErrors: t('navigation.tryAgain'), amount: '', alertType: 'danger', isLoaded: false });
                        setRefreshKey(prev => prev + 1);
                    }
                    else if (res.status === 422 && res.response.response_type === "Unprocessable Entity") {
                        setState({ ...state, amount: '', releaseErrors: t('navigation.andErrorOcured'), alertType: 'warning', isLoaded: false });
                        setRefreshKey(prev => prev + 1);
                    }
                } else {
                    navigate('/payment-details')
                }
            }
        }
    }



    React.useEffect(() => {
        if (searchParams.get("s") != null && searchParams.get("m") != null) {
            if (searchParams.get("s") === "True") {
                setState({ ...state, releaseErrors: t('views.account.successYourAccountHasBeenCredited'), alertType: 'success' });
            }
            else {
                setState({ ...state, releaseErrors: searchParams.get("m") });
            }
            setSearchParams({})
        }

        let minAmount = params.currencies?.filter((dataCurrency) => dataCurrency.to_currency === params.currency)[0];
        setMinAmountCredit(params.currency === 'USD' ? 1 : minAmount?.value)

        let maxAmountPost = convertCurrency(params.currency, params.currencies, 200, 'GET')
        setMaxAmount(maxAmountPost)
        let minAmountD = convertCurrency(params.currency, params.currencies, 20, 'GET')
        let maxAmountD = convertCurrency(params.currency, params.currencies, 714, 'GET')
        setAmount({ ...amount, minAmountDebit: minAmountD, maxAmountDebit: maxAmountD })

        let paymentMode = getPaymentModeByCountry(users.access_token, params.country, params.currency);

        paymentMode.then((value) => {
            setState((prev) => ({ ...prev, paymentMode: value }));
        });

        let getTransfereMode = async () => {
            let res = await RequestFanbusy(`accounts/self/creator/`, 'GET', "", users.access_token);
            if (res.response.status_code === 200) {
                setState((prev) => ({ ...prev, withdrawalMode: res.response.data }));
            }
        }
        getTransfereMode()

        let cardPayment = getPaymentCard(users.access_token);
        cardPayment.then((value) => {
            setState((prev) => ({ ...prev, cardPayment: value }));
        });

        let userAccount = getUserAccount(users.access_token);
        userAccount.then((value) => {
            let convert = convertCurrencyBalance(params.currency, params.currencies, value.balance, 'GET');
            setState((prev) => ({ ...prev, userAccount: convert }));
        });

        const getfirstFiveTransactionByCreator = async () => {
            let res = await RequestFanbusy("accounts/payment/transactions", 'GET', '', users.access_token);
            if (res.status === 200) {
                const transactionsSuccess = res.response.data.filter((transaction) => transaction.status !== "PENDING")
                const firstFiveTransactions = transactionsSuccess.slice(0, 4);
                setState((prev) => ({ ...prev, lastFiveTransactions: firstFiveTransactions, isLoading: false }));
            } else if (res.status === 401) {
                setState((prev) => ({ ...prev, isLoading: false }));
            }
        }

        getfirstFiveTransactionByCreator()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.refresh, params.country, params.currency, params.currencies, users.access_token])

    React.useEffect(() => {
        setState((prev) => ({ ...prev, amount: " ", mode: "", withdrawalMethod: "" }));
    }, [data.selectedAction])


    function translatePaymentMode(label) {
        if (label === 'Mobile Money') {
            return t('views.account.mobileMoney')
        } else if (label === "Card") {
            return t('views.account.cardPayment')
        } else {
            return label
        }
    }

    const isButtonDisabled = () => {
        if (state.amount.trim().length === 0) {
            return true;
        }

        const amountParsed = parseFloat(state.amount);
        const minAmountParsed = typeof minAmountCredit === 'string' ? parseFloat(minAmountCredit.replace(/\s/g, '')) : minAmountCredit;
        const maxAmountParsed = typeof maxAmount === 'string' ? parseFloat(maxAmount.replace(/\s/g, '')) : maxAmount;

        if (data.selectedAction === 'credit') {
            return amountParsed < minAmountParsed || amountParsed > maxAmountParsed;
        } else {
            return isPriceOutOfRange();
        }
    };

    console.log(state.amount);
    return (
        <>
            <div key={refreshKey} className={` ${styleResp} ${data.selectedAction === 'debit' ? 'mt-web-5' : ''}`} style={{ styleShadow }}>
                <div className={`${darkMode ? 'bg-dark' : 'bg-white'} border account-follow mb-4`}>
                    <h5 className={`${darkMode ? 'text-white' : 'text-body'} fw-bold ps-2 pt-2 mb-1`}>{state.userAccount} {params.currency}</h5>
                    <h6 className={`${darkMode ? 'text-white' : 'text-body'} fw-bold p-2 pt-0  mb-0 border-bottom`}>{t('views.account.currentBalance')}</h6>
                    <div className="m-2 ">
                        {data.selectedAction === 'credit' ?
                            <p className="mb-1">{t('views.account.addFundsToYourWallet')}</p> :
                            <p className="mb-1">{t('views.account.withdrawFundsFromYourWallet')}</p>
                        }
                        <div className="form-floating mb-1 d-flex align-items-center">
                            <input type="Rising" className={`${darkMode && 'bg-dark text-white'} form-control rounded-5`} id="Rising" placeholder="Amount" name="rising" value={state.amount} onChange={textInputChange} style={{ height: '5px' }} />
                            <label htmlFor="Rising" className="pt-2 mt-0">{t('navigation.amount')} {params.currency}</label>
                        </div>
                        <div className="d-flex align-items-center mb-2" style={{ marginLeft: '5px', fontSize: data.selectedAction === 'debit' ? '0.9em' : '' }}>
                            <p className="mb-0">Minimum {data.selectedAction === 'debit' ? amount.minAmountDebit : minAmountCredit} {params.currency}</p>
                            {data.selectedAction === 'debit' &&
                                <p className="mb-0">, Maximum {amount.maxAmountDebit} {params.currency}</p>
                            }
                        </div>
                        {data.selectedAction === 'credit' &&
                            <select className={`${darkMode && 'bg-dark text-white'} form-select form-select-sm rounded-5 py-1"`} aria-label=".form-select-sm example" name="modePayment" onChange={textInputChange}>
                                <option selected disabled>{t('navigation.chooseYourPaymentMode')}</option>
                                {state.paymentMode?.map((mode, index) => {
                                    return (
                                        <option key={index} value={mode.code}>
                                            {translatePaymentMode(mode.label)}
                                        </option>
                                    )
                                })}

                            </select>
                        }
                        {/* {
                            (state.mode === 'CARD' && data.selectedAction === 'credit') &&
                                <div className="d-flex align-items-center gap-2 my-3">
                                    <select className={`form-select form-select-sm  rounded-5 py-1 ${state.cardPayment.length === 0 ? 'disabled' : ''}`} aria-label=".form-select-sm example" name="cardId" onChange={textInputChange}>
                                        <>
                                            <option selected disabled>{t('navigation.chooseCard')}</option>
                                            {state.cardPayment.length > 0 &&
                                                state.cardPayment?.map((card, index) => {
                                                    return (
                                                        <option key={index} value={card._id}>{card.mask_number}</option>
                                                    )
                                                })
                                            }
                                        </>
                                    </select>
                                    {!data.addCard &&
                                        <a href="/add-card" className="material-icons border-0 fb-btn-color text-decoration-none text-white rounded-3" >add</a>
                                    }
                                </div>
                        } */}


                        {/* {
                            ((state.mode === 'MOBILE_MONEY_XAF' || state.withdrawalMethod === 'MOBILE_MONEY_XAF') && data.selectedAction && (users.users.phone_number === '' || showPhone)) &&
                                <div>
                                    <div className="align-items-center mt-3 gap-2 ">
                                        <PhoneInput
                                            className="form-control rounded-5 customInputChild"
                                            style={{ width: '100%' }}
                                            value={state.phoneNumber}
                                            onChange={handleChangePhoneNumber}
                                            onCountryChange={handleCountryChange}
                                            placeholder={t('views.send_the_payment_methods.enterNumber')}
                                            defaultCountry="CM"
                                        />

                                    </div>
                                </div>
                        } */}
                        {/* {
                            ((state.mode === 'MOBILE_MONEY_XAF') && data.selectedAction === 'credit' && (users.users.phone_number !== '' || showPhone)) &&
                                <div className="d-flex align-items-center justify-content-between mt-2">
                                    <p className="mb-0" style={{ marginLeft: '12px' }}>{t('navigation.numberToCredit')}: <span className="fb-color mb-0">{users.users.phone_number}</span></p>
                                    <button onClick={() => setShowPhone(!showPhone)} className="material-icons bg-transparent  p-0 md-20 fb-color border-0">edit_note</button>
                                </div>
                        } */}

                        <div className="mt-2 d-flex flex-column gap-2 ">
                            <button
                                data-bs-toggle={!users.users?.verified ? 'modal' : ''}
                                data-bs-target={!users.users?.verified ? '#checkMailModal' : ''}
                                className={`btn fb-btn-color border-0 text-white btn-block rounded-pill py-1 px-0 fw-bold w-100 ${isButtonDisabled() ? 'disabled' : ''}`}
                                type="button"
                                onClick={handleSubmitAccount}
                            >
                                {state.isLoaded ? <Loader /> : data.selectedAction === 'credit' ? (!state.isValidated ? t('views.account.confirm') : t('views.account.credit')) : t('views.account.requestWithdrawal')}
                            </button>
                            {data.selectedAction !== 'credit' && <p className="pt-2 px-2 w-100 text-center text-gradient">NB: {t('views.account.withdrawalsTransferred')}</p>}
                            {state.releaseErrors?.length > 0 &&
                                <div className={`alert alert-${state.alertType} alert-dismissible mb-0 p-2`} role="alert">
                                    <div className="me-4">{state.releaseErrors}</div>
                                    <button type="button" className="btn-close pt-2 pe-2" data-bs-dismiss="alert" aria-label="Close"></button>
                                </div>
                            }

                        </div>
                    </div>

                </div>
                <PaymentModeImage styleResp={'small-none'} shadowTop={{ boxShadow: '0px -5px 0px #EDF2F6' }} />
            </div>

            {!users.users?.verified && users.account?.transaction_number >= 10 &&
                <CheckMailModalView />
            }
        </>
    )
}
export default CardTransactions;