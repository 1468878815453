import { useParams } from "react-router-dom";

import { Images } from "../../../Constants";

const SignupVerifyView = () => {
    const { request } = useParams();

    console.error(request);

    const renderPage = () => {
        if (request === 'success') {
            return (
                <div className="p-5 text-center">
                    <img src={Images.check} alt="" width={370} />
                    <div className="text-center pb-5">
                        <h2 className="fw-bold text-black mt-4">Congratulations !</h2>
                        <p className="mb-4 fs-4">You have successfully activated your account</p>
                        <a href={`/auth/login`} className="btn btn-primary rounded-3 py-2 px-4 shadow-sm">
                            <span className="px-1 fw-bold">
                                Login
                            </span>
                        </a>
                    </div>
                </div>
            )
        }
        else if (request === 'verified') {
            return (
                <div className="p-5 text-center">
                    <img src={Images.warning} alt="" width={370} />
                    <div className="text-center pb-5">
                        <h2 className="fw-bold text-black mt-4">Warning !</h2>
                        <p className="mb-4 fs-4">Email can only be verified once</p>
                        <a href={`/auth/login`} className="btn btn-primary rounded-3 py-2 px-4 shadow-sm">
                            <span className="px-1 fw-bold">
                                Login
                            </span>
                        </a>
                    </div>
                </div>
            )
        }
        else {
            return (
                <div className="p-5 text-center">
                    <img src={Images.warning} alt="" width={370} />
                    <div className="text-center pb-5">
                        <h2 className="fw-bold text-black mt-4">Warning !</h2>
                        <p className="mb-4 fs-4">Invalid code or user doesn't exist</p>
                        <a href={`/auth/login`} className="btn fb-btn-color text-white rounded-3 py-2 px-4 shadow-sm">
                            <span className="px-1 fw-bold">
                                Login
                            </span>
                        </a>
                    </div>
                </div>
            )
        }
    }

    return (
        renderPage()
    )
}

export default SignupVerifyView;