import React from "react";
import { AppContainer, AsideContainer } from "../../Containers";
import { useSelector } from "react-redux";
import { AccordionItem } from "../../Components";
import parse from 'html-react-parser';
import { useScreenSize } from "../../Utils";
import { useTranslation } from "react-i18next";

export const CreatorHelpAndSupport = () => {
    const { t } = useTranslation()
    const darkMode = useSelector((state) => state.theme.darkMode);
    const screenSize = useScreenSize()
    const isMobile = screenSize === 'Extra Small' || screenSize === 'Small' || screenSize === 'Medium'

    return (
        <AppContainer>
            <main style={{ marginTop: isMobile ? '20px' : '' }} className={` col col-xl-6 order-xl-2 col-lg-12 order-lg-1 col-md-12 col-sm-12 col-12 px-0 border border-fw-light border-bottom-0  ${isMobile && 'pt-5 fixed-content pb-5'} `}>
                <div className="main-content">
                    <div style={{ height: '50px' }} className="d-flex align-items-center mb-3 m-none bg-white fix-sidebar top-0 border-bottom  ">
                        <a href={`/help-and-support`} className={`${darkMode ? 'text-white' : 'text-dark'} material-icons text-decoration-none m-none ms-3 `} >
                            arrow_back
                        </a>
                        <p className={`${darkMode ? 'text-white' : 'text-body'} ms-2 mb-0 fw-bold fs-6 text-uppercase`}>{t('views.helpCreator.help')}</p>
                    </div>
                    <div className={`${darkMode ? 'bg-dark' : 'bg-white'} overflow-hidden  mb-4 mb-lg-0  m-0`}>
                        <div className="px-2">
                            <div className="row position-relative">
                                <main className="col order-xl-2 col-lg-12 order-lg-1 col-md-12 col-sm-12 col-12 pt-2">
                                    <div className=" main-content">
                                        <div className=" d-flex justify-content-center">
                                            <h1 className="lead fw-bold">{t('views.helpCreator.creatorQuestions')}</h1>
                                        </div>
                                        <div className={`${darkMode ? 'bg-dark' : 'bg-white'} py-4 feed-item rounded-4  faq-page`}>
                                            <div className="rounded-3">
                                                <div className="mb-3">
                                                    <h5 className="lead  mb-0 fs-6">{t('views.helpCreator.everytingYouNeed')}.</h5>
                                                </div>
                                                <div className="row justify-content-center">
                                                    <div className="col-lg-12">
                                                        <div className="accordion" id="accordionExample">
                                                            <AccordionItem id={18} title={t('views.helpCreator.canIBecome')} content={t('views.helpCreator.yesWeAuthorize')} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mb-3 pt-2">
                                                    <h5 className="lead fw-bold mb-0 mt-5">{t('views.helpCreator.earningsAndPayouts')}</h5>
                                                </div>
                                                <div className="row justify-content-center">
                                                    <div className="col-lg-12">
                                                        <div className="accordion" id="accordionExamplez1">
                                                            <AccordionItem id={19} title={t('views.helpCreator.howDo')} content={parse(t('views.helpCreator.cr1'))} />
                                                            <AccordionItem id={20} title={t('views.helpCreator.payouts')} content={parse(t('views.helpCreator.cr2'))} />
                                                            <AccordionItem id={21} title={t('views.helpCreator.payoutPourcentage')} content={parse(t('views.helpCreator.cr3'))} />
                                                            <AccordionItem id={22} title={t('views.helpCreator.topCreator')} content={parse(t('views.helpCreator.cr4'))} />
                                                            <AccordionItem id={23} title={t('views.helpCreator.myPayoutRequest')} content={parse(t('views.helpCreator.cr5'))} />
                                                            <AccordionItem id={24} title={t('views.helpCreator.myPayementHasBeen')} content={parse(t('views.helpCreator.cr6'))} />
                                                            <AccordionItem id={25} title={t('views.helpCreator.minimumWithdrawal')} content={parse(t('views.helpCreator.cr7'))} />
                                                            <AccordionItem id={26} title={t('views.helpCreator.initiatingAPayout')} content={parse(t('views.helpCreator.cr8'))} />
                                                            <AccordionItem id={27} title={t('views.helpCreator.cancelMyPayoutRequest')} content={parse(t('views.helpCreator.cr9'))} />
                                                            <AccordionItem id={28} title={t('views.helpCreator.myAccountIsApproved')} content={parse(t('views.helpCreator.cr10'))} />
                                                            <AccordionItem id={29} title={t('views.helpCreator.myCurrentBalance')} content={parse(t('views.helpCreator.cr11'))} />
                                                            <AccordionItem id={30} title={t('views.helpCreator.octPayments')} content={parse(t('views.helpCreator.cr12'))} />
                                                            <AccordionItem id={31} title={t('views.helpCreator.howMuchOf')} content={parse(t('views.helpCreator.cr13'))} />
                                                            <AccordionItem id={32} title={t('views.helpCreator.thirdPartyPayments')} content={parse(t('views.helpCreator.cr14'))} />
                                                            <AccordionItem id={33} title={t('views.helpCreator.willMyWithdrawals')} content={parse(t('views.helpCreator.cr15'))} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="my-3   pt-2">
                                                    <h5 className="lead fw-bold mb-0">{t('views.helpCreator.tips')}</h5>
                                                </div>
                                                <div className="row justify-content-center">
                                                    <div className="col-lg-12">
                                                        <div className={`${darkMode ? 'bg-dark text-white' : 'bg-white text-body'} accordion overflow-hidden`} id="accordionExamplez2">
                                                            <AccordionItem id={34} title={t('views.helpCreator.addingTip')} content={parse(t('views.helpCreator.cr16'))} />
                                                            <AccordionItem id={35} title={t('views.helpCreator.tipFeature')} content={parse(t('views.helpCreator.cr17'))} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="my-3   pt-2">
                                                    <h5 className="lead fw-bold mb-0">{t('views.settings.account')}</h5>
                                                </div>
                                                <div className="row justify-content-center">
                                                    <div className="col-lg-12">
                                                        <div className={`${darkMode ? 'bg-dark text-white' : 'bg-white text-body'} accordion overflow-hidden`} id="accordionExamplez3">
                                                            <AccordionItem id={36} title={t('views.helpCreator.accountApproval')} content={parse(t('views.helpCreator.cr18'))} />
                                                            <AccordionItem id={37} title={t('views.helpCreator.rejectedApplications')} content={parse(`${t('views.helpCreator.cr19')}${t('views.helpCreator.cr20')}`)} />
                                                            <AccordionItem id={38} title={t('views.helpCreator.myAccountIsPaused')} content={parse(t('views.helpCreator.cr21'))} />
                                                            <AccordionItem id={39} title={t('views.helpCreator.myAccountIsRestricted')} content={parse(t('views.helpCreator.cr22'))} />
                                                            <AccordionItem id={40} title={t('views.helpCreator.isNudityAllowed')} content={parse(t('views.helpCreator.cr23'))} />
                                                            <AccordionItem id={41} title={t('views.helpCreator.whatIsTheDifference')} content={parse(t('views.helpCreator.cr24'))} />
                                                            <AccordionItem id={42} title={t('views.helpCreator.stolen')} content={parse(t('views.helpCreator.cr25'))} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="my-3 pt-2">
                                                    <h1 className="lead fw-bold ">{t('views.helpCreator.accountAndPageSettings')}</h1>
                                                </div>
                                                <div className="row justify-content-center">
                                                    <div className="col-lg-12">
                                                        <div className={`${darkMode ? 'bg-dark text-white' : 'bg-white'} accordion overflow-hidden`} id="accordionExamplez4">
                                                            <AccordionItem id={43} title={t('views.helpCreator.whoCanSeeFanbusyPage')} content={parse(t('views.helpCreator.cr26'))} />
                                                            <AccordionItem id={44} title={t('views.helpCreator.publicProfile')} content={parse(t('views.helpCreator.cr27'))} />
                                                            <AccordionItem id={45} title={t('views.helpCreator.creatorGuide')} content={parse(t('views.helpCreator.cr28'))} />
                                                            <AccordionItem id={46} title={t('views.helpCreator.taggingFan')} content={parse(t('views.helpCreator.cr29'))} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="my-3 pt-2">
                                                    <h1 className="lead fw-bold ">{t('views.helpCreator.promotingYourFanBusy')}</h1>
                                                </div>
                                                <div className="row justify-content-center">
                                                    <div className="col-lg-12">
                                                        <div className={`${darkMode ? 'bg-dark text-white' : 'bg-white'} accordion overflow-hidden`} id="accordionExamplez5">
                                                            <AccordionItem id={47} title={t('views.helpCreator.tipsForCreators')} content={parse(t('views.helpCreator.cr30'))} />
                                                            <AccordionItem id={48} title={t('views.helpCreator.subscriptionPrices')} content={parse(t('views.helpCreator.cr31'))} />
                                                            <AccordionItem id={49} title={t('views.helpCreator.paidPrivateMessages')} content={parse(t('views.helpCreator.cr32'))} />
                                                            <AccordionItem id={50} title={t('views.helpCreator.postTroubleshooting')} content={parse(t('views.helpCreator.cr33'))} />
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </main>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <AsideContainer />
        </AppContainer>
    );
};