import React from "react"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

const NewMessage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate()
    const onCreateNewRoom = () => {
        navigate('creat')
    }
    return (
        <div className="m-none text-center " >
            <div className="comments  messages-chat overflow-auto align-items-center justify-content-center" style={{ height: "100vh" }} >
                <div class="fs-4">
                    <p class="fs-5 text-black">{t('views.messages.selectAnyConversation')}.</p>
                    <button onClick={onCreateNewRoom} class="btn gradient-button border-0 rounded-pill py-2 px-4 shadow-sm mt-4">
                        <span class="px-2 text-white text-uppercase fs-6">
                            {t('views.messages.newMessage')}
                        </span>
                    </button>
                </div>
            </div>
        </div>
    )
}
export default NewMessage