import React from "react"


const Alert = ({ msg, type }) => {
    return (
        <div className={`alert alert-${type} alert-dismissible mt-2`} role="alert">
            <div>{msg}</div>
            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
    )
}
export default Alert