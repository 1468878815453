import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RequestFanbusy } from "../../Services";
import Loader from "../../Components/Loader";
import { useTranslation } from "react-i18next";

const CheckMailModalView = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const users = useSelector((state) => state.users);
    const [isDisabled, setIsDisabled] = React.useState(true);
    const [email, setEmail] = React.useState(users.users?.email);
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [alert, setAlert] = React.useState({
        message: t('navigation.youNeed'),
        type: ""
    })

    const textInputChange = (input) => {
        const target = input.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        if (name === 'email') {
            setEmail(value);
        }
    }

    const checkMailCreator = async () => {
        setIsLoaded(true)
        let form = {
            email: email,
            callback_url: window.location.origin + window.location.pathname,
        }
        let res = await RequestFanbusy('creators/check-mail', 'PUT', form, users.access_token)
        if (res.status === 200) {
            setIsLoaded(false)
            setAlert({ message: `${t('navigation.checkmailTo')} ${email}`, type: 'text-success' })
        } else {
            setIsLoaded(false)
            setAlert({ message: t('navigation.andErrorOcured'), type: 'fb-color' })
        }
    }

    const handleRefresh = async () => {
        setAlert({ message: t('navigation.youNeed'), type: 'fb-color' })
        setIsDisabled(true)
        setIsLoaded(false)
        dispatch({ type: "REFRESH", value: 1 });
    }


    return (
        <div className="modal fade" id={`checkMailModal`} tabIndex="-1" aria-labelledby="exampleModalLabel2" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className={`modal-content rounded-4 overflow-hidden border-0`}>

                    <div className="modal-body p-0">
                        <h6 className="p-3 fw-bold text-uppercase mb-0 border-1 border-bottom">{t('navigation.verifyEmail')}</h6>
                        <div className="px-3 py-2 border border-bottom">
                            <div className={`border-2 rounded-3  position-relative mt-3`}>
                                <label htmlFor="exampleFormControlInputMail" className="form-label position-absolute start-0 ms-2 bg-white px-1 py-0" style={{ top: -9 }}>{t('navigation.currentEmail')}</label>
                                <input type="email" className={` form-control bg-transparent is-invalid`} disabled={isDisabled} placeholder="email" id="exampleFormControlInputMail" style={{ height: '50px' }} name="email" value={email} onChange={textInputChange} />
                            </div>
                            <p className="mt-1 ms-2">E-mail: <span className="fw-bold">{email}</span> {t('navigation.isNotVerify')}</p>
                        </div>
                        <div className="p-3">
                            <div className="d-flex align-items-center justify-content-between ">
                                <button className={`border  rounded-pill bg-transparent fb-color px-3 py-2 fw-bold small`} onClick={() => setIsDisabled(false)}>{t('navigation.updateEmail')}</button>
                                <button className={`border-0 rounded-pill  gradient-button text-white px-3 py-2 fw-bold small`} onClick={() => checkMailCreator()}>
                                    {isLoaded ? (
                                        <Loader />
                                    ) : (
                                        t('navigation.sendConfirmation')
                                    )}
                                </button>
                            </div>

                            <div className="d-flex gap-2 mt-4 rounded-3" style={{ border: alert.message?.length > 0 ? `1px solid #BBEFCB` : `1px solid #E7F6FD` }}>
                                <div className="pt-3" style={{ backgroundColor: alert.message === t('navigation.andErrorOcured') || alert.message === t('navigation.youNeed') ? '#E7F6FD' : '#BBEFCB', width: 32 }}><span className={`${alert.type} px-2 mx-3 rounded-circle fw-bold`} style={{ border: alert.message.length > 0 ? '2px solid #35D063' : '2px solid #36A9EF', padding: '2px 0 2px 0' }}>𝖎</span></div>
                                <h5 className="m-3" style={{ fontSize: '1.3em' }}>{alert.message}</h5>
                            </div>
                        </div>
                        <div className="p-3 border border-top d-flex justify-content-end">
                            <button onClick={handleRefresh} className="btn btn-block text-secondary p-0 rounded-pill fw-bold px-2" style={{ backgroundColor: "#F0F0F0" }} data-bs-dismiss="modal" >{t('words.cancel')}</button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}
export default CheckMailModalView;