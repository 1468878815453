const initialState = {
    language: 'en',
    languageChange: false,
    languageName: 'English',
    currencies: [],
    country: 'CM',
    currency: 'USD',
    minAmount: 1,
    refresh: 0,
};

function params(state = initialState, action) {
    let nextState;

    switch (action.type) {
        case "LANGUAGE":
            nextState = {
                ...state,
                languageChange: true,
                language: action.value.language,
                languageName: action.value.languageName,
            };

            return nextState || state;

        case "REFRESH":
            nextState = {
                ...state,
                refresh: state.refresh + action.value,
            };

            return nextState || state;

        case "GET_CURRENCIES":
            nextState = {
                ...state,
                currencies: action.value,
            };

            return nextState || state;

        case "CURRENCIES":
            nextState = {
                ...state,
                currencies: action.value,
            };

            return nextState || state;

        case "LOCALISE":
            nextState = {
                ...state,
                currency: action.value.currency,
                country: action.value.country,
                continent: action.value.continent,
            };

            return nextState || state;

        case "LOCALIZATION":
            nextState = {
                ...state,
                currency: action.value.currency,
                country: action.value.country,
                continent: action.value.continent,
            };

            return nextState || state;

        default:
            return state;
    }
}

export default params
