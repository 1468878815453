import { TCHAT_SOCKET } from "../apiconf";

const BASE_URL = TCHAT_SOCKET;

export const createSocket = (room, client_id) => {
    const SOCKET_URL = `${BASE_URL}/api/v1/ws/room/${room}/${client_id}`;
    const socket = new WebSocket(SOCKET_URL);

    socket.onopen = (event) => { };

    return socket;
};

export const createSocketStatus = (client_id) => {
    const SOCKET_URL = `${BASE_URL}/api/v1/ws/${client_id}`;
    const socketStatus = new WebSocket(SOCKET_URL);

    socketStatus.onopen = (event) => {};

    return socketStatus;
};