import { useState, useEffect } from 'react';
import { RequestFanbusy } from '../Services';
import { useSelector } from 'react-redux';


const useSearch = (url) => {
    const token = useSelector((state) => state.users.access_token);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [searchLoad, setSearchLoad] = useState(false);
    useEffect(() => {
        const fetchSearchResults = async () => {
            if (searchQuery && token) {
                const requestData = { search: searchQuery };
                let res = await RequestFanbusy(url, 'POST', requestData, token);
                console.log('reponse searchResult',res.response.data);
                if (res.status === 201) { setSearchResults(res.response.data); setSearchLoad(true) }
            } else { setSearchResults([]); }
        };
        fetchSearchResults();
    }, [token, searchQuery, url]);
    return { searchQuery, setSearchQuery, searchResults, searchLoad };
};
export default useSearch;
