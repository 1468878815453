import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const NoContent = ({ message, style, transaction }) => {
    const darkMode = useSelector((state) => state.theme.darkMode);
    const { t } = useTranslation();

    return (
        <div className={`${darkMode ? 'bg-dark' : 'bg-white'} py-5 feed-item rounded-4 mb-3  d-flex flex-column justify-content-center align-items-center`} style={{ ...style }}>
            <span className="material-icons me-1" style={{ fontSize: '90px', color: '#AFB0B4' }}>cleaning_services</span>
            <div className="text-center">
                {!transaction &&
                    <h4 className="fw-bold">{t('words.no_content')}</h4>}
                <h6 className="fw-bold fs-5" >{transaction && message}</h6>
            </div>
        </div>
    )
}

export default NoContent;