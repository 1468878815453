import React, { useState } from "react";
import { useSelector } from "react-redux";


export const NotificationsSetting = () => {
    const darkMode = useSelector((state) => state.theme.darkMode);
    const [isNotification, setIsNotification] = useState(false);
    const [isEmailNotif, setIsEmailNotif] = useState(false);
    const [isNewsLeterNotif, setIsNewsLeterNotif] = useState(false);
    const [postSummary, setPostSummary] = useState(false);
    const [isNewStream, setIsNewStream] = useState(false);
    const [isUpcoming, setIsUpcoming] = useState(false);
    const [isPrivateMsgpostSummary, setIsPrivateMsgpostSummary] = useState(false);
    const [isFewerNotif, setIsFewerNotif] = useState(false);
    const [isImpMsg, setIsImpMsg] = useState(false);

    const handleSetNotification = (event) => {
        setIsNotification(event.target.checked);
    };
    const handleIsFewerNotif = (event) => {
        setIsFewerNotif(event.target.checked);
    };
    const handleIsprivateMsg = (event) => {
        setIsPrivateMsgpostSummary(event.target.checked);
    };
    const handleImprMsg = (event) => {
        setIsImpMsg(event.target.checked);
    };
    const handleUpcoming = (event) => {
        setIsUpcoming(event.target.checked);
    };
    const handleIsNewStream = (event) => {
        setIsNewStream(event.target.checked);
    };
    const handleEmailNotif = (event) => {
        setIsEmailNotif(event.target.checked);
    };
    const handleNewsLetterNotif = (event) => {
        setIsNewsLeterNotif(event.target.checked);
    };
    const handlePostSummary = (event) => {
        setPostSummary(event.target.checked);
    };


    return (
        <main className="col order-xl-2 col-lg-12 order-lg-1 col-md-12 col-sm-12 col-12">
            <div className=" feeds">
                <div className={` ${darkMode ? "bg_fan text-white" : "bg-white"} py-4 feed-item faq-page`}>
                    <div className="rounded-3">
                        <div className="mb-3 ">  <h5 className={`${darkMode ? "text-white " : "text-body"} lead fw-bold  mb-0`}> Preferences  </h5>  </div>
                        <div className="row justify-content-center">
                            <div className="col-lg-12">
                                <div className={` ${darkMode ? "bg_fan text-white" : "bg-white"}accordion overflow-hidden `} id="accordionExamplex1">
                                    <div>

                                        <div className={`${darkMode ? 'bg_fan text-white' : 'bg-white'} accordion-item`}>
                                            <h3 className={`${darkMode ? 'bg_fan text-white ' : 'bg-white'} accordion-header`}>
                                                <button className={`${darkMode && 'bg-dark text-white'}  accordion-button collapsed fw-bold m-0`}
                                                    type="button" data-bs-toggle="collapse" data-bs-target="#collapseOnex1" aria-expanded="false" aria-controls="collapseOnex1">
                                                    Notifications
                                                </button>
                                            </h3>
                                            <div className={`accordion-collapse collapse`} id="collapseOnex1" data-bs-parent="#accordionExamplex1">
                                                <div className="accordion-body">
                                                    <p className="m-0">
                                                        <div className=" d-flex align-items-center mb-0">
                                                            <div className="d-flex align-items-center">
                                                                <span className="fw-bold medium">
                                                                    Notifications
                                                                </span>
                                                            </div>
                                                            <span className="ms-auto form-check form-switch">
                                                                <input className="form-check-input mt-2 ms-0" type="checkbox" name="isFreeAccount" checked={isNotification}
                                                                    onChange={handleSetNotification} />
                                                            </span>
                                                        </div>
                                                        <p className={` ${darkMode && "fb-color "}`}>  Recevez des notifications pour savoir ce qui se passe lorsque vous n'etes pas sur FanBusy. Vous pouvez les desactiver a tout moments...
                                                        </p>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className={`${darkMode && 'bg_fan text-white'} accordion-item`}>
                                            <h3 className={`${darkMode && 'bg_fan text-white'} accordion-header"`}>
                                                <button className={`${darkMode && 'bg-dark text-white'} accordion-button collapsed fw-bold`} type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwox1" aria-expanded="false" aria-controls="collapseTwox1">
                                                    Email notifications
                                                </button>
                                            </h3>
                                            <div className={`accordion-collapse collapse`} id="collapseTwox1" data-bs-parent="#accordionExamplex1">
                                                <div className="accordion-body">
                                                    <p className="m-0 shadow-bottom border-bottom"> <div className=" d-flex align-items-center mb-0"> <div className="d-flex align-items-center">
                                                        <span className="fw-bold medium"> Email notifications </span> </div>
                                                        <span className="ms-auto form-check form-switch"> <input className="form-check-input mt-2 ms-0" type="checkbox" name="isFreeAccount" checked={isEmailNotif} onChange={handleEmailNotif} /> </span>
                                                    </div>
                                                        <p className="fb-color "> Get notifications to know what's going on when you are not on FanBusy. You can them deactivate at any time... </p>
                                                    </p>
                                                    <p className="border-bottom py-2 mb-4"> <div className=" d-flex align-items-center mb-0"> <div className="d-flex align-items-center">
                                                        <span className="fw-bold medium"> Monthly newsletter </span> </div> <span className="ms-auto form-check form-switch">
                                                            <input className="form-check-input mt-2 ms-0" type="checkbox" name="isFreeAccount" checked={isNewsLeterNotif} onChange={handleNewsLetterNotif} />
                                                        </span>
                                                    </div>
                                                    </p>
                                                    <p className="m-0 mt-2 "> <div className=" d-flex align-items-center mb-0 borde-bottom"> <div className="d-flex align-items-center">
                                                        <span className="fw-bold medium"> Subscriptions and following </span> </div> </div>
                                                    </p>
                                                    <div className=" shadow-bottom"> <div className="col-lg-12"> <div className=" d-flex align-items-center mb-0"> <div className="d-flex align-items-center"> <span>New post summary</span> </div> <span className="ms-auto form-check form-switch">
                                                        <input className="form-check-input mt-2 ms-0" type="checkbox" name="isFreeAccount" checked={postSummary} onChange={handlePostSummary} /> </span>
                                                    </div>
                                                        {postSummary ? (
                                                            <>
                                                                <div className=" py-4"> <select className="form-select rounded-5 small" id="floatingSelect"> <option selected>24 heures</option> <option value="1"> 48 heures</option>
                                                                    <option value="2">Hebdomadaires</option> </select> </div>
                                                            </>
                                                        ) : null}
                                                    </div>
                                                    </div>
                                                    <div className=" shadow-bottom"> <div className="d-flex align-items-center mb-0"> <div className="d-flex align-items-center"> <span className="">New Stream</span>
                                                    </div> <span className="ms-auto form-check form-switch">
                                                            <input className="form-check-input mt-2 ms-0" type="checkbox" name="isFreeAccount" checked={isNewStream} onChange={handleIsNewStream} />
                                                        </span>
                                                    </div>
                                                    </div>
                                                    <div className=" shadow-bottom border-bottom"> <div className="d-flex align-items-center "> <div className="d-flex align-items-center"><span className="">Upcoming Stream reminders</span>
                                                    </div> <span className="ms-auto form-check form-switch">
                                                            <input className="form-check-input mt-2 ms-0" type="checkbox" name="isFreeAccount" checked={isUpcoming} onChange={handleUpcoming} />
                                                        </span>
                                                    </div>
                                                    </div>

                                                    <p className="m-0 mt-2"> <div className=" d-flex align-items-center mb-0 borde-bottom">
                                                        <div className="d-flex align-items-center"> <span className="fw-bold medium">New Messages</span> </div> </div>
                                                    </p>
                                                    <div className=" shadow-bottom"> <div className="col-lg-12"> <div className=" d-flex align-items-center mb-0">
                                                        <div className="d-flex align-items-center"> <span>New private messages summary</span> </div>
                                                        <span className="ms-auto form-check form-switch">
                                                            <input className="form-check-input mt-2 ms-0" type="checkbox" name="isFreeAccount" checked={isPrivateMsgpostSummary} onChange={handleIsprivateMsg} />
                                                        </span>
                                                    </div>
                                                        {isPrivateMsgpostSummary ? (
                                                            <>
                                                                <div className=" py-4"> <select className="form-select rounded-5 small" id="floatingSelect">
                                                                    <option selected>24 heures</option>
                                                                    <option value="1"> 48 heures</option>
                                                                    <option value="2">Hebdomadaires</option>
                                                                </select>
                                                                </div>
                                                            </>
                                                        ) : null}
                                                    </div>
                                                    </div>
                                                    <div className=" shadow-bottom border-bottom pb-2"> <div className="d-flex align-items-center "> <div className="d-flex align-items-center"><span className="">Receive fewer notifications</span> <br /> </div>
                                                        <span className="ms-auto form-check form-switch">
                                                            <input className="form-check-input mt-2 ms-0" type="checkbox" name="isFreeAccount" checked={isFewerNotif} onChange={handleIsFewerNotif} />
                                                        </span>
                                                    </div>
                                                        <span className="mb-2 fb-color ">Receive email notifications only for direct messages.</span>
                                                    </div>
                                                    <div className="shadow-bottom mt-2"> <div className="d-flex align-items-center mb-0"><div className="d-flex align-items-center"> <span className="fw-bold medium">Important Subscription Notifications</span> <br /> </div>
                                                        <span className="ms-auto form-check form-switch">
                                                            <input className="form-check-input mt-2 ms-0" type="checkbox" name="isFreeAccount" checked={isImpMsg} onChange={handleImprMsg} />
                                                        </span>
                                                    </div>
                                                        <span className="mb-2 fb-color ">
                                                            Upcoming subscription renewals and expirations.
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className={`${darkMode && 'bg_fan text-white'} accordion-item`}>
                                            <h3 className={`${darkMode && 'bg_fan'} accordion-header`} >
                                                <button className={`${darkMode && 'bg-dark text-white'} accordion-button collapsed fw-bold`} type="button" data-bs-toggle="collapse" data-bs-target="#collapseThreex1" aria-expanded="false" aria-controls="collapseThreex1">
                                                    Site Notices
                                                </button>
                                            </h3>
                                            <div className={`accordion-collapse collapse `} id="collapseThreex1" data-bs-parent="#accordionExamplex1">
                                                <div className="accordion-body">
                                                    <p className="m-0 mt-2 "> <div className=" d-flex align-items-center mb-0 borde-bottom">
                                                        <div className="d-flex align-items-center"> <span className="fw-bold medium"> Related to you and your Post </span> </div> </div> </p>
                                                    <div className=" shadow-bottom">
                                                        <div className="col-lg-12"> <div className=" d-flex align-items-center mb-0 border-bottom mt-2"> <div className="d-flex align-items-center"> <span>New Comment</span></div>
                                                            <span className="ms-auto form-check form-switch"><input className="form-check-input mt-2 ms-0" type="checkbox" name="isFreeAccount" checked={postSummary} onChange={handlePostSummary} /> </span>
                                                        </div>
                                                        </div>
                                                    </div>
                                                    <div className=" shadow-bottom mt-2"> <div className="d-flex align-items-center mb-0 border-bottom"> <div className="d-flex align-items-center"> <span className="">New Likes</span> </div>
                                                        <span className="ms-auto form-check form-switch"> <input className="form-check-input mt-2 ms-0" type="checkbox" name="isFreeAccount" checked={isNewStream} onChange={handleIsNewStream} />
                                                        </span>
                                                    </div>
                                                    </div>

                                                    <p className="m-0 mt-4 "> <div className=" d-flex align-items-center mb-0 borde-bottom"> <div className="d-flex align-items-center"> <span className="fw-bold medium"> Related to you and your Post </span></div></div></p>
                                                    <div className=" shadow-bottom border-bottom mt-2"> <div className="d-flex align-items-center mb-0 "> <div className="d-flex align-items-center"> <span className="">Discount for User I used to follow</span> <br />
                                                    </div> <span className="ms-auto form-check form-switch">
                                                            <input className="form-check-input mt-2 ms-0" type="checkbox" name="isFreeAccount" checked={isFewerNotif} onChange={handleIsFewerNotif} />
                                                        </span>
                                                    </div>
                                                    </div>
                                                    <div className="shadow-bottom border-bottom">
                                                        <div className="d-flex align-items-center mb-0 mt-2"> <div className="d-flex align-items-center"> <span className="">New Stream</span> <br /> </div>
                                                            <span className="ms-auto form-check form-switch">
                                                                <input className="form-check-input mt-2 ms-0" type="checkbox" name="isFreeAccount" checked={isImpMsg} onChange={handleImprMsg} />
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="shadow-bottom"> <div className="d-flex align-items-center mb-0 mt-2"> <div className="d-flex align-items-center"> <span className="">Upcoming stream reminders</span> <br /> </div>
                                                        <span className="ms-auto form-check form-switch">
                                                            <input className="form-check-input mt-2 ms-0" type="checkbox" name="isFreeAccount" checked={isImpMsg} onChange={handleImprMsg} />
                                                        </span>
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </main>
    );
};
