import React from 'react';
import { RouterProvider } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";

import router from './Navigation/AppNavigation';


function App() {
    function fallbackRender({ error, resetErrorBoundary }) {
        // Call resetErrorBoundary() to reset the error boundary and retry the render.

        return (
            <div role="alert">
                <p>Something went wrong:</p>
                <pre style={{ color: "red" }}>{error.message}</pre>
            </div>
        );
    }

    const logError = (error, info) => {
        // Do something with the error, e.g. log to an external API
        console.error(error)
    };

    return (
        <ErrorBoundary FallbackComponent={fallbackRender} onError={logError}>
            <RouterProvider router={router} />
        </ErrorBoundary>
    );
}

export default App;
