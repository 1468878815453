import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux';


import { convertCurrency, formatDateRelative } from '../../Utils'


const Pay4meList = ({ isLoading, isLoaded, listCode }) => {
    const { t } = useTranslation();
    const params = useSelector((state) => state.params);
    const [copyStates, setCopyStates] = useState(Array(listCode.length).fill(false));
    const copyCode = (code, index) => {
        const newCopyStates = [...copyStates];
        newCopyStates[index] = true;
        setCopyStates(newCopyStates);

        const textarea = document.createElement('textarea');
        textarea.value = code;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);

        setTimeout(() => {
            const resetCopyStates = [...copyStates];
            resetCopyStates[index] = false;
            setCopyStates(resetCopyStates);
        }, 2000)
    };

    return (
        <table className="table mb-0 text-nowrap fontSize pt-0">
            <thead className="table-light w-100 fix-sidebar mb-2 top-0 pt-0">
                <tr>
                    <th className="border-0">{t('views.pay4me.date')}</th>
                    <th className="border-0">{t('views.pay4me.code')}</th>
                    <th className="border-0"></th>
                    <th className="border-0">{t('views.pay4me.amount')}</th>
                    <th className="border-0">{t('views.pay4me.status')}</th>
                </tr>
            </thead>
            <tbody className='small'>
                {isLoading &&
                    <tr>
                        <td colSpan={3} className="text-center">
                            <div className="spinner-border text-primary" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </td>
                    </tr>
                }
                {!isLoaded && listCode.length <= 0 &&
                    <tr>
                        <th colSpan={3} className="text-center">{t('views.pay4me.noData')}</th>
                    </tr>
                }
                {!isLoaded &&
                    listCode.map((data, index) => (
                        <tr className='small' key={data._id}>
                            <td className="align-middle border-top-0 fw-bold ">
                                {formatDateRelative(data.created_at, t)}
                            </td>
                            <td className="align-middle border-top-0 fw-bold ">
                                <span >{data.code}</span>
                            </td>
                            <td className="align-middle border-top-0 fw-bold ">
                                <span className="btn btn-outline-success btn-sm px-3 small my-0 " onClick={() => { copyCode(data.code, index) }}>
                                    <span>{copyStates[index] ? t('words.copied') : t('words.copy')}</span>
                                </span>
                            </td>
                            <td className="align-middle border-top-0 fw-bold ">
                                {convertCurrency(params.currency, params.currencies, data.value, 'GET')} {params.currency}
                            </td>
                            <td className="align-middle border-top-0 fw-bold ">
                                {data.valid_status ?
                                    <span className='btn btn-success btn-sm  small'> {t('views.pay4me.notUse')} </span> :
                                    <span className='btn btn-danger btn-sm small px-3'>{t('views.pay4me.use')}</span>}
                            </td>
                        </tr>
                    ))
                }
            </tbody>
        </table>
    )
}

export default Pay4meList