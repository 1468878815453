function reverseString(str) {
    return str.split('').reverse().join('');
}

function restore_numbers_in_filename(filename) {
    const parts = filename.split('.');
    const name = parts[0];
    const ext = parts.length > 1 ? parts.slice(1).join('.') : '';

    const original_name = reverseString(name);

    return `${original_name}.${ext}`;
}

export default restore_numbers_in_filename