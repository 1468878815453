import * as React from "react";
import { useSelector } from "react-redux";
import { t } from "i18next";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
} from "chart.js";


import { AppContainer } from "../../Containers";
import { RequestFanbusy } from "../../Services";
import { Loader, NoContent } from "../../Components";
import { useScreenSize } from "../../Utils";
import Pay4meList from "./pay4meCode";
import Pay4meCodeCreate from "./pay4meCodeCreate";


const PaidForMe = () => {
    ChartJS.register(
        CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler
    );

    const screenSize = useScreenSize()
    const darkMode = useSelector((state) => state.theme.darkMode);
    const users = useSelector((state) => state.users);

    const [state, setState] = React.useState({
        amount: '',
        releaseErrors: '',
        isLoading: true,
        isLoaded: false
    });

    const [listCode, setListCode] = React.useState([]);

    const isMobile = screenSize === 'Extra Small' || screenSize === 'Small' || screenSize === 'Medium'

    React.useEffect(() => {
        const loadCode = async () => {
            let res = await RequestFanbusy(`pay4me/?limit=${1000}&page=${1}`, 'GET', '', users.access_token)
            if (res.status === 200) {
                setState((prev) => ({ ...prev, isLoading: false }));
                setListCode(res.response.data)
            } else if (res.status === 401) {
                setState((prev) => ({ ...prev, isLoading: false }));
            }
        }

        loadCode();

    }, [users.access_token]);

    return (
        <AppContainer>
            <main className={`col col-xl-6 order-xl-2 col-lg-12 order-lg-1 col-md-12 col-sm-12 col-12 px-0  border boder-top-0  border-bottom-0 ${isMobile && 'fixed-content pb-5 '}`}>
                <div style={{ paddingBottom: "60px" }} className={` main-content`}>
                    <div style={{ height: '50px' }} className="d-flex align-items-center justify-content-between mb-1 bg-white fix-sidebar top-0 border-bottom">
                        <div className="d-flex ">
                            <a href={`/`} className={`${darkMode ? 'text-white' : 'text-dark'} material-icons text-decoration-none ms-3`}>arrow_back</a>
                            <p className={`${darkMode ? 'text-white' : 'text-body'} ms-2 mb-0 fw-bold fs-6`}>
                                {t('navigation.paidForMe')}
                            </p>
                        </div>

                    </div>

                    <div className={`${darkMode ? 'bg-dark text-white' : 'bg-white'}  rounded- `}>
                        <div className="tab-content" id="pills-tabContent">
                            <div className="tab-pane fade show active " id="pills-feed" role="tabpanel" aria-labelledby="pills-feed-tab">
                                <Pay4meCodeCreate classParent={'web-none pb-0 mb-0'} setListCode={setListCode} />

                                <div className="px-3 pt-0 mt-0">
                                    <h6 className={`p-2 border-bottom  text-center text-muted  m-none`}>{t('views.pay4me.paySubscriptionsFor')}</h6>
                                    {state.isLoading ?
                                        <Loader styleColor={true} /> :
                                        listCode.length < 0 ? (
                                            <NoContent message={`${t('views.account.noTransaction')}`} transaction={true} />
                                        ) : (
                                            <>
                                                <div className="pt-0 mt-0 fontSize" style={{ maxHeight: 550, overflow: 'auto' }}>
                                                    <Pay4meList isLoading={state.isLoading} isLoaded={state.isLoaded} listCode={listCode} />
                                                </div>

                                            </>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <aside className="col col-xl-3 order-xl-3 col-lg-6 order-lg-3 col-md-6 col-sm-6 col-12" style={{ paddingLeft: '13px' }}>
                <Pay4meCodeCreate classParent={'m-none'} setListCode={setListCode} />
            </aside>
        </ AppContainer>
    )

}

export default PaidForMe;
