import { RequestFanbusy } from '../Services';
import { TchatRequest } from '../Services';

const getBookmark = async (access_token) => {
    let bookmarks = [];

    let res = await RequestFanbusy('bookmarks/self/creator/type/POST', 'GET', '', access_token);
    if (res.status === 200) {
        bookmarks = res.response.data;
    }

    return bookmarks;
}

const getLikes = async (access_token, type) => {
    let likes = [];

    let res = await RequestFanbusy('likes/self/creator/' + type, 'GET', '', access_token);
    if (res.status === 200) {
        likes = res.response.data;
    }

    return likes;
}

const getReleasePost = async (access_token) => {
    let releasePost = [];

    let res = await RequestFanbusy('posts/release/creator', 'GET', '', access_token);
    if (res.status === 200) {
        releasePost = res.response.data;
    }

    return releasePost;
}

const getCreatorFollows = async (access_token) => {
    let follows = [];
    let res = await RequestFanbusy('souscriptions/my/follows', 'GET', '', access_token);
    if (res.status === 200) {
        follows = res.response.data;
    }
    return follows;
}

const getMyFAns = async (token) => {
    let res = await RequestFanbusy('souscriptions/my/fans', 'GET', '', token);
    return res;
};



const getUserAccount = async (access_token) => {
    let userAccount = {}
    let res = await RequestFanbusy("accounts/self/creator/", 'GET', '', access_token);
    if (res.status === 200) {
        userAccount = res.response.data
    }
    return userAccount;
}

const getPaymentCard = async (access_token) => {
    let cardPayment = [];
    let res = await RequestFanbusy("cards/", 'GET', '', access_token);
    if (res.status === 200) {
        cardPayment = res.response.data
    }
    return cardPayment;
}

const getPaymentModeByCountry = async (access_token, country, currency) => {
    let paymentMode = [];
    let res = await RequestFanbusy(`accounts/payment/modes/${country}/${currency}`, 'GET', '', access_token);
    if (res.status === 200) {
        paymentMode = res.response.data
    }
    return paymentMode;
};

const convertCurrency = (currencyCreator, currencyData, amount, type) => {
    let convertedAmount = 0;
    let currencyAccount = currencyData.filter(dataCurrency => dataCurrency.to_currency === currencyCreator);

    if (currencyCreator) {

        if (type === 'GET') {

            convertedAmount = amount * currencyAccount[0]?.value;
            if (Number.isInteger(convertedAmount)) {
                return convertedAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
            } else {
                const decimalPart = parseFloat(convertedAmount).toFixed(2).split('.')[1];
                if (decimalPart === '00') {
                    return parseFloat(convertedAmount).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
                } else {
                    return parseFloat(convertedAmount).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
                }
            }
        }

        convertedAmount = amount / currencyAccount[0]?.value;
        return convertedAmount;
    }
};

const convertCurrencyBalance = (currencyCreator, currencyData, amount, type) => {
    let convertedAmount = 0;
    let currencyAccount = currencyData?.filter((dataCurrency) => dataCurrency.to_currency === currencyCreator);

    if (currencyCreator !== 'USD') {

        if (type === 'GET') {
            convertedAmount = amount * currencyAccount[0]?.value;
            if (Number.isInteger(convertedAmount)) {
                return convertedAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
            } else {
                const decimalPart = parseFloat(convertedAmount).toFixed(2).split('.')[1];
                if (decimalPart === '00') {
                    return parseFloat(convertedAmount).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
                } else {
                    return parseFloat(convertedAmount).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
                }
            }
        }

        convertedAmount = amount / currencyAccount[0]?.value;
        return convertedAmount;
    }
    else {
        if (type === 'GET') {
            if (Number.isInteger(amount)) {
                return amount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
            }
            const decimalPart = parseFloat(amount).toFixed(2).split('.')[1];
            if (decimalPart === '00') {
                return parseFloat(amount).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
            } else {
                return parseFloat(amount).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
            }
        }

        return amount;
    }
}




const getStatutByUser = async (user_id) => {
    let online = false
    let res = await TchatRequest(`statut/${user_id}`, 'GET', '', '');
    if (res?.status === 200) {
        online = res?.response?.data?.online
    }
    return online
}

const getRoomAndAllStatusReceiverByUser = async (user_id) => {
    const ResponseStatus = await TchatRequest('room/unread/' + user_id, 'GET', '', '');
    if (ResponseStatus.status === 200) {
        return ResponseStatus.response.data
    }

}

const getAllMessageUnreadInAllRoomUsers = async (userId) => {
    const ResponseStatus = await TchatRequest('message/unread/all/room/' + userId, 'GET', '', '');
    if (ResponseStatus.status === 200) {
        return ResponseStatus.response.data
    }
}


export { getMyFAns, getBookmark, getLikes, getReleasePost, getCreatorFollows, convertCurrency, convertCurrencyBalance, getUserAccount, getPaymentCard, getStatutByUser, getPaymentModeByCountry, getRoomAndAllStatusReceiverByUser, getAllMessageUnreadInAllRoomUsers }
