import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { CardProfile, Loader, NoContent } from '../../Components';
import { AppContainer } from '../../Containers';
import { useScreenSize } from '../../Utils';
import { getMyFAns } from '../../Utils';

const FanView = () => {
    const darkMode = useSelector((state) => state.theme.darkMode);
    const { t } = useTranslation();
    const users = useSelector(state => state.users);
    const params = useSelector(state => state.params);
    const [state, setState] = React.useState({
        follows: [],
        isLoading: true
    });
    const screenSize = useScreenSize()
    const isMobile = screenSize === 'Extra Small' || screenSize === 'Small' || screenSize === 'Medium'

    React.useEffect(() => {
        const res = getMyFAns(users.access_token)
        res.then((res) => {
            if (res.status === 200) {
                setState((prev) => ({ ...prev, follows: res.response.data, isLoading: false }));
            } else if (res.status === 401) {
                setState((prev) => ({ ...prev, isLoading: false }));
            }
        })
    }, [params.refresh, users.access_token]);

    return (
        <AppContainer>
            <main className={`col col-xl-9 order-xl-2 col-lg-12 order-lg-1 col-md-12 col-sm-12 col-12  border border-fw-light border-bottom-0 px-0 ${isMobile && 'pt-5 fix-content pb-5'}`}>
                <div className="main-content pb-5">
                    <div style={{ height: "50px" }} className="d-flex align-items-center mb-0  m-none bg-white fix-sidebar top-0 border-bottom">
                        <a href={`/`} className={`${darkMode ? 'text-white' : 'text-dark'} material-icons text-decoration-none m-none ms-3`}>arrow_back</a>
                        <p className={`${darkMode ? 'text-white' : 'text-body'} ms-2 mb-0 fw-bold fs-6 text-uppercase`}>
                            {t('navigation.fans')}
                        </p>
                    </div>
                    {state.isLoading ?
                        <Loader styleColor={true} marginTop={true} /> :
                        <div className="row px-2">
                            {state.follows.length > 0 ?
                                state.follows.map((follow, index) => {
                                    return (
                                        <div key={follow._id} className="col col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12">
                                            <div className={`${!isMobile && 'ms-2'}`}>
                                                <CardProfile fan={true} creator={follow.fan} subsciption={follow} addClassName={'mt-3'} />
                                            </div>
                                        </div>
                                    )
                                }) : <NoContent message={`${t('navigation.youHaveNoFanYet')}`} transaction={true} style={{ marginTop: '1rem' }} />
                            }
                        </div>
                    }
                </div>
            </main>
        </AppContainer>
    )
}

export default FanView;