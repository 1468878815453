import { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { i18n } from "../../../Services";

import { Images } from "../../../Constants";
import { RequestFanbusy } from "../../../Services";
import { Routes } from "../../../Constants";
import { useTranslation } from "react-i18next";

const SinupVerificationEmailView = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const users = useSelector(state => state.users);
    const [currentLanguage, setCurrentLanguage] = useState(() => {
        try {
            return localStorage.getItem('currentLanguage') || navigator.language || navigator.userLanguage;
        } catch (error) {
            return navigator.language || navigator.userLanguage;
        }
    });
    const navigate = useNavigate();
    const [isEmailVerified, setIsEmailVerified] = useState(false);

    const checkEmailVerificationStatus = useCallback(async () => {
        const response = await RequestFanbusy(`creators/${users.users?._id}`, 'GET', '', users.access_token);
        if (response.status === 200) {
            let verified = response.response.data?.verified;
            setIsEmailVerified(verified);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        checkEmailVerificationStatus();
        const intervalId = setInterval(checkEmailVerificationStatus, 10000);
        return () => {
            clearInterval(intervalId);
        };
    }, [checkEmailVerificationStatus]);

    if (isEmailVerified) {
        navigate(Routes.HOME);
    }

    useEffect(() => {
        changeLanguage(currentLanguage);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentLanguage]);

    const changeLanguage = (language) => {
        let updateLanguage = language === 'fr-FR' || language === 'fr' ? 'fr' : 'en';
        i18n.changeLanguage(updateLanguage);

        let action = {
            type: "LANGUAGE",
            value: {
                language: updateLanguage,
                languageName: updateLanguage === 'fr' ? t('words.french') : t('words.english')
            }
        };
        dispatch(action);
        setCurrentLanguage(updateLanguage);
        localStorage.setItem('currentLanguage', updateLanguage);
    };

    return (


        <div className={`container p-5 text-center text-start align-items-center justify-content-center `}>

            <div className="pb-5" style={{ marginTop: 100 }}>
                <img className="img-fluid me-5" src={Images.send_mail} alt="" width={200} />
                <div>
                    <img className='img-fluid' src={Images.logo} alt="famla" style={{ width: 140 }} />
                    <div className='mt-3'>
                        <span className='text-black'>{t('views.auth.hi')}<span className='fb-color ps-1'>{users?.users?.display_name} </span></span>
                        <p className="mb-4 fs-6 text-black">{t('views.auth.p1')}<br />{t('views.auth.p2')}</p>
                    </div>
                    <p className='text-black mt-3'>{t('views.auth.thanks')}, <br /> Fanbusy Team </p>
                </div>
            </div>

        </div>


    )
}

export default SinupVerificationEmailView;