import React, { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { convertCurrencyBalance, shortenName } from "../Utils";

const CheckPremiumComponent = ({ onDeletePlan, definedSubscriptionPrice, isEditingProfile, textInputchange, params, subscriptionFee, subscriptionFeeInAsian, subscriptionFeeInWorld, onSavePlans }) => {
	const darkMode = useSelector((state) => state.theme.darkMode);
	const users = useSelector((state) => state.users);
	let africanFee = users.users?.subscriptions_details?.african_fee?.month_fee;
	// let asianFee = users.users?.subscriptions_details?.asian_fee?.month_fee;
	// let otherFee = users.users?.subscriptions_details?.other_fee?.month_fee;
	// const currentAsianFee = asianFee ? asianFee : 0;
	// const currentOtherFee = otherFee ? otherFee : 0;
	const currentAfricanFee = africanFee ? africanFee : 0;
	const { t } = useTranslation();
	const showModal = useRef();
	const [check, setCheck] = useState({ definedSubscriptionBundle: false });
	const [selectedPlan, setSelectedPlan] = useState({ duration: '', discount: '' });
	const [plans, setPlans] = useState([]);
	const [currentSelection, setCurrentSelection] = useState('');

	useEffect(() => {
		if (users.users?.subscriptions_details) {
			const reductions = users.users.subscriptions_details?.african_fee;

			const initialPlans = [
				{ duration: `3 months`, discount: `${reductions?.quarterly_fee?.reduction || '0'}%` },
				{ duration: `6 months`, discount: `${reductions?.half_yearly_fee?.reduction || '0'}%` },
				{ duration: `12 months`, discount: `${reductions?.yearly_fee?.reduction || '0'}%` },
			];

			const allZero = initialPlans.every(plan => parseFloat(plan.discount.replace('%', '')) === 0);

			if (allZero) {
				setPlans([]);
			} else {
				onSavePlans(initialPlans);
				setPlans(initialPlans);
			}

		}

		if (users.users?.subscriptions_details) {
			const hasSubscription = Boolean(
				users.users.subscriptions_details.asian_fee ||
				users.users.subscriptions_details.other_fee
			);
			setCheck({ definedSubscriptionBundle: hasSubscription });
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


	function handleChange() {
		setCheck((prev) => ({ ...prev, definedSubscriptionBundle: !check.definedSubscriptionBundle }));
	}
	function handleSave() {
		const planExists = plans.some(
			(plan) => plan.duration === selectedPlan.duration && plan.discount === selectedPlan.discount
		);

		if (!planExists) {
			let newPlans;
			if (plans.length < 3) {
				newPlans = [...plans, selectedPlan];
			} else {
				newPlans = [...plans.slice(1), selectedPlan];
			}

			onSavePlans(newPlans);
			setPlans(newPlans);
		}

		setSelectedPlan({ duration: "", discount: "" });
	}

	function handleSelectChange(event) {
		const { name, value } = event.target;
		setSelectedPlan((prev) => {
			const newPlan = { ...prev, [name]: value };
			setCurrentSelection(`${newPlan.duration} (${newPlan.discount})`);
			return newPlan;
		});
	}

	const handleDeletePlan = (index) => {
		setPlans((prev) => prev.filter((_, i) => i !== index));
		onDeletePlan(index);
	};

	const checkCurrentPrice = (price, continent) => {
		let continentLabel;

		switch (continent) {
			case "Af":
				// continentLabel = t('views.editProfile.yourCurrentSubscriptionPriceInAfrica');
				continentLabel = t('views.editProfile.yourCurrentSubscriptionPrice');
				break;
			case "As":
				continentLabel = t('views.editProfile.yourCurrentSubscriptionPriceInAsian');
				break;
			case "Other":
				continentLabel = t('views.editProfile.yourCurrentSubscriptionPriceOther');
				break;
			default:
				continentLabel = t('views.editProfile.yourCurrentSubscriptionPrice');
				break;
		}

		let convertedPrice;
		if (price > 0) {
			convertedPrice = convertCurrencyBalance(params.currency, params.currencies, price, 'GET');
		} else {
			convertedPrice = isEditingProfile ? t('views.editProfile.freePrice') : '';
		}

		return `${continentLabel}: ${convertedPrice} ${params.currency}`;
	};

	const availableDiscounts = ["10%", "20%", "35%"];
	const availableDurations = [`3 months`, `6 months`, `12 months`].filter((duration) => !plans.filter(plan => parseFloat(plan.discount.replace('%', '')) !== 0).some((plan) => plan.duration === duration));

	return (
		<>
			<div className="row justify-content-center">
				<div className="col-lg-12">
					<div className="py-3 d-flex align-items-center mb-0">
						<label htmlFor="isFreeAccount">
							<span>{t('views.editProfile.subscriptionPlan')}</span>
						</label>
						<span className="ms-auto form-check form-switch">
							<input checked={definedSubscriptionPrice} className="form-check-input mt-2 ms-0" id="isFreeAccount" type="checkbox" name="isFreeAccount" value={definedSubscriptionPrice} onChange={textInputchange} />
						</span>
					</div>
					{definedSubscriptionPrice && (
						<>
							<div>
								<div className="form-floating mb-3 d-flex align-items-center mt-2">
									<input type="text" placeholder="" className={`${darkMode && 'bg-dark text-white border-secondary'} form-control rounded-5`} name="subscription_fee" value={subscriptionFee === 0 ? "" : subscriptionFee} onChange={textInputchange} />
									<label htmlFor="subscription_fee">
										{checkCurrentPrice(currentAfricanFee, "Af")}
									</label>
								</div>
								{/* <div className="form-floating mb-3 d-flex align-items-center mt-2">
									<input type="number" placeholder="" className={`${darkMode && 'bg-dark text-white border-secondary'} form-control rounded-5`} name="subscription_fee_asian" value={subscriptionFeeInAsian === 0 ? "" : subscriptionFeeInAsian} onChange={textInputchange} />
									<label htmlFor="subscription_fee_asian">
										{checkCurrentPrice(currentAsianFee, "As")}
									</label>
								</div>
								<div className="form-floating mb-3 d-flex align-items-center mt-2">
									<input type="number" placeholder="" className={`${darkMode && 'bg-dark text-white border-secondary'} form-control rounded-5`} name="subscription_fee_world" value={subscriptionFeeInWorld === 0 ? "" : subscriptionFeeInWorld} onChange={textInputchange} />
									<label htmlFor="subscription_fee_world">
										{checkCurrentPrice(currentOtherFee, "Other")}
									</label>
								</div> */}
							</div>



							<div className="py-3 d-flex align-items-center mb-0">
								<label htmlFor="isFreeAccount">
									<span>{t('navigation.subscriptionPlans')}</span>
								</label>
								<span className="ms-auto form-check form-switch">
									<input checked={check.definedSubscriptionBundle} className="form-check-input mt-2 ms-0" id="isFreeAccount" type="checkbox" name="isFreeAccount" value={check.definedSubscriptionBundle} onChange={handleChange} />
								</span>
							</div>
							{check.definedSubscriptionBundle &&
								<ul className="list-group">
									{plans.filter(plan => parseFloat(plan.discount.replace('%', '')) !== 0)
										.map((plan, index) => (
											<li key={index} className="list-group-item d-flex justify-content-between">
												<span>{`${plan.duration} (${plan.discount})`}</span>
												<button
													onClick={() => handleDeletePlan(index)}
													className="material-icons text-decoration-none border-0 bg-white px-0 text-muted"
												>
													delete
												</button>
											</li>
										))}

									{plans.filter(plan => parseFloat(plan.discount.replace('%', '')) !== 0).length < 3 &&
										<li className="list-group-item d-flex justify-content-between" data-bs-target='#subscriptionModal' data-bs-toggle='modal' style={{ cursor: 'pointer' }}>
											<p className="m-0">{t('views.editProfile.subscribersPayAdvance')} 🤑</p>
											<button ref={showModal} data-bs-target='#subscriptionModal' data-bs-toggle='modal' className="material-icons text-decoration-none border-0 bg-white px-0 ">add</button>
										</li>
									}
								</ul>
							}
						</>
					)}

				</div>
			</div>
			<div className="modal fade" id="subscriptionModal" tabIndex="-1" aria-labelledby="choiceModal" aria-hidden="true" style={{ zIndex: 2050 }}>
				<div className="modal-dialog modal-dialog-centered">
					<div className={`modal-content border-0 rounded-4 pb-0`}>
						<div className={`modal-header border-0 px-4 pt-2`}>
							<h6 className={`modal-title fw-bold fs-6 text-uppercase`} id="exampleModalLabel1">{t('navigation.addSubscriptionBundle')}</h6>
						</div>
						<div className='px-4 pt-3'>
							<div className="modal-body pt-0 px-0">
								<div className="d-flex">

									<div className="col-6 ">
										<div className={`position-relative`}>
											<label htmlFor="duration" className="form-label position-absolute start-0 ms-2 bg-white px-1 py-0" style={{ top: -9 }}>{t('words.duration')}</label>
											<select className="form-select" name="duration" id="duration" required style={{ height: '50px' }} onChange={handleSelectChange} value={selectedPlan.duration}>
												<option value=""  >{t('words.duration')}</option>
												{availableDurations.map((duration, index) => (
													<option key={index} value={duration}>{duration}</option>
												))}
											</select>
										</div>
									</div>
									<div className="col-6 ms-1">
										<div className={`position-relative`}>
											<label htmlFor="discount" className="form-label position-absolute start-0 ms-2 bg-white px-1 py-0" style={{ top: -9 }}>{shortenName(t('navigation.discountPercent'), 15)}</label>
											<select className="form-select" name="discount" id="discount" required style={{ height: '50px' }} onChange={handleSelectChange} value={selectedPlan.discount}>
												<option value=""  >{t('navigation.discountPercent')}</option>
												{availableDiscounts.map((discount, index) => (
													<option key={index} value={discount}>{discount}</option>
												))}
											</select>
										</div>
									</div>
								</div>
								<p className="pt-3 pb-0 fs-6">{t('navigation.subscriptionFor')} {currentSelection}</p>
							</div>
							<div className="modal-footer border-0 w-100 mx-0 px-0">
								<button type="button" className="btn btn-secondary text-uppercase bg-white border-0 text-primary" data-bs-dismiss="modal">{t('words.close')}</button>
								<button type="button" className="btn btn-primary text-uppercase bg-white border-0 text-primary px-0 mx-0" onClick={handleSave} data-bs-dismiss="modal">{t('words.save')}</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default CheckPremiumComponent;
