import { useRouteError } from "react-router-dom";
import { APP_HOST } from "../../Services/apiconf";

const Error404View = () => {
    const error = useRouteError();
    console.error(error);

    return (
        <div className="p-5 text-center">
            <img src={`${APP_HOST}/assets/404.svg`} className="img-fluid col-md-4" alt="osahan" />
            <div className="text-center pb-5">
                <h2 className="fw-bold text-black mt-4">Oh no! Where did you go?</h2>
                <p className="mb-4">We can’t seem to find the page you were looking for.</p>
                <p>
                    <i>{error?.message}</i>
                </p>
                <a href={`/`} className="btn btn-primary rounded-pill py-3 px-4 shadow-sm">
                    <span className="px-3">
                        Go Back
                    </span>
                </a>
            </div>
        </div>
    )
}

export default Error404View;