import React from "react";
import { LoginComponent } from "../../Components";
import { useScreenSize } from "../../Utils";

const LoginView = () => {
	const closeModal = () => {
	}
	const screenSize = useScreenSize()

	return (
		<div className="container position-relative width-mobile" style={{ height: '100vh' }}>
			<div className={`position-relative  ${screenSize === 'Medium' || screenSize === 'Large' || screenSize === 'Extra Large' ? 'd-flex justify-content-center' : ''} `}>
				<LoginComponent closeModal={closeModal} />
				<div className="d-flex justify-content-center align-items-center small-none web-none mt-3">
					<img className="mw-mobile" src={require("../../assets/img/2.png")} alt="" style={{ maxHeight: 200 }} />
				</div>
				<aside className="col col-xl-3 order-xl-1 col-lg-6 order-lg-2 col-md-6 col-sm-6 col-12"></aside>
				<aside className="col col-xl-3 order-xl-3 col-lg-6 order-lg-3 col-md-6 col-sm-6 col-12"></aside>
			</div>
		</div>
	)
};

export default LoginView;
