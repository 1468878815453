import React from "react"
import { useSelector } from "react-redux"

import Loader from "./Loader"

const Button = ({ sref, checkIfSubscribeToCreator, isLoading, creator, subscribeToCreator, t, id, amount, releaseRef, time, text, addClassName, subscriptionPeriod }) => {
    const params = useSelector((state => state.params));

    return (
        <>
            <button
                className={`btn d-flex align-items-center text w-100 ${addClassName} ${isLoading ? 'justify-content-center' : 'justify-content-between'}  btn-sm px-3 rounded-pill ${!checkIfSubscribeToCreator(creator?._id) ? 'gradient-button text-white border-0 ' : 'border border-secondary fb-color'}`}
                htmlFor="btncheck1"
                onClick={!checkIfSubscribeToCreator(creator) ? () => subscribeToCreator(subscriptionPeriod) : () => { }}
                data-bs-toggle={checkIfSubscribeToCreator(creator?._id) && "modal"}
                data-bs-target={` ${checkIfSubscribeToCreator(creator?._id) ? `#validationModal${creator?._id}` : ''}`}>

                {isLoading ?
                    <Loader />
                    :
                    <>
                        <span className="fw-bold ">{checkIfSubscribeToCreator(creator?._id) ? `${t('words.subscribed')}` : `${text}`}</span>
                        {creator?.is_free_account ?
                            <span className="fw-bold">{t('views.profile.forFree')}</span>
                            :
                            <>
                                <span data-bs-toggle="modal" data-bs-target={`#sentTipModal${id}`} ref={sref}></span>
                                <span className="fw-bold" >{amount} {params.currency}{time && `/${time}`}</span>
                            </>
                        }
                    </>
                }
            </button>

            <span data-bs-toggle="modal" data-bs-target={`#sentTipModal${id}`} ref={releaseRef}></span>

        </>
    )
}
export default Button