import { useTranslation } from "react-i18next"


const EndOfPage = () => {
    const { t } = useTranslation();
    return (
        <div className="text-start text-center mt-0 py-3">
            <span className="material-icons text-primary ">check_circle_outline</span>
            <h6 className="fw-bold text-black"> {t('navigation.thatIsAllForNow')}</h6>
        </div>
    )
}
export default EndOfPage