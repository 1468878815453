import { useTranslation } from "react-i18next"
import { useEffect, useState } from "react";

import { Images } from "../../Constants";
import { i18n } from "../../Services";
import { useDispatch } from "react-redux";

function Cookie() {
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const [currentLanguage, setCurrentLanguage] = useState(() => {
        try {
            return localStorage.getItem('currentLanguage') || navigator.language || navigator.userLanguage;
        } catch (error) {
            return navigator.language || navigator.userLanguage;
        }
    });

    useEffect(() => {
        changeLanguage(currentLanguage);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentLanguage]);

    const changeLanguage = (language) => {
        let updateLanguage = language === 'fr-FR' || language === 'fr' ? 'fr' : 'en';
        i18n.changeLanguage(updateLanguage);

        let action = {
            type: "LANGUAGE",
            value: {
                language: updateLanguage,
                languageName: updateLanguage === 'fr' ? t('words.french') : t('words.english')
            }
        };
        dispatch(action);

        setCurrentLanguage(updateLanguage);
        localStorage.setItem('currentLanguage', updateLanguage);
    };
    return (
        <>
            <div className="container-fluid text-white fixed-top py-2 fs-5 " style={{ backgroundColor: '#0092EA' }}> <img src={Images.logo3} alt="" style={{ height: '35px' }} /></div>
            <span className="my-5 text-white">.</span>
            <div className="container align-items-center my-5 px-5">
                <div className="container align-items-center my-5">
                    <h6 className="fw-blod fs-5 border-bottom pb-3 text-black fix-sidebar bg-white top-0 text-uppercase" > {t('views.reviews.cookie.cookieNotice')}</h6>
                    <div className="fs-6 text-black P-4 ">
                        <p className="flex-wrap mt-4">{t('views.reviews.cookie.c1')}</p>
                        <li className="text-muted mt-3">{t('views.reviews.cookie.typesOfCookiesUsed')}</li>
                        <ol>
                            <li><span className="text-muted mt-3">{t('views.reviews.cookie.essentialCookies')}:</span>{t('views.reviews.cookie.c2')}</li>
                            <li><span className="text-muted mt-3">{t('views.reviews.cookie.analyticalPerformanceCookies')}: </span>{t('views.reviews.cookie.c3')}</li>
                            <li><span className="text-muted mt-3">{t('views.reviews.cookie.functionalityCookies')}:</span>{t('views.reviews.cookie.c4')}</li>
                        </ol>
                        <li className="text-muted mt-3">{t('views.reviews.cookie.managingCookies')}</li>

                        <p>{t('views.reviews.cookie.c5')}</p>

                        <li className="text-muted mt-3">{t('views.reviews.cookie.moreInformation')}</li>

                        <p>{t('views.reviews.cookie.c6')}</p>
                    </div>
                </div>
            </div >
        </>
    )
} export default Cookie