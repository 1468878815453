import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RequestFanbusy } from "../../Services";
import { useNavigate } from "react-router-dom";
import { Routes } from "../../Constants";
import { useTranslation } from "react-i18next";
import { Loader } from "../../Components";

export const AccountComponent = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const userDatas = useSelector((state) => state.users.users);
	const darkMode = useSelector((state) => state.theme.darkMode);
	const token = useSelector((state) => state.users.access_token);
	const [state, setState] = useState({
		currentPassword: "",
		password: "",
		confirmPassword: "",
		responseWhenChangingPassword: '',
		isLoading: false,
	});
	const isCreator = userDatas.account_type;

	const deleteCreatorAccount = async () => {
		try {
			let response = await RequestFanbusy(`creators/${userDatas._id}`, "DELETE", "", token);
			if (response.status === 200) {
				navigate(Routes.SIGN_IN);
			} else {
				alert("Please try again later.", [
					{ text: "Ok", onPress: () => { } },
				]);

			}
		} catch (error) {
		}
	};

	const textInputChange = (input) => {
		const target = input.target;
		const value = target.value;
		const name = target.name;

		if (name === "currentPassword") {
			setState({ ...state, currentPassword: value });

		} else if (name === "password") {
			setState({ ...state, password: value });
		} else if (name === "confirmPassword") {
			setState({ ...state, confirmPassword: value });
		}
	};

	const submitNewLogin = async (event) => {
		event.preventDefault();
		setState({ ...state, isLoading: true })
		if (state.password.length === 0 || state.confirmPassword.length === 0) {
			alert("FanBusy, The field(s) cannot be empty.", [
				{ text: "Ok", onPress: () => { } },
			]);

			return;
		}

		if (state.confirmPassword !== state.password) {
			alert("Passwords do not match!");
			return;
		}

		const form = {
			oldPassword: state.currentPassword,
			newPassword: state.password,
			passwordConfirm: state.confirmPassword,
		};

		let response = await RequestFanbusy("auth/change-password", "POST", form, token);
		console.log(response);
		if (response.status === 201) {
			setState({ ...state, responseWhenChangingPassword: `${t('views.settings.responseChangingPassword')}` })
			setTimeout(() => window.location.reload(), 10000);
			setState({ ...state, isLoading: false })
		} else {
			setState({ ...state, responseWhenChangingPassword: `${t('views.settings.responseError')}` })
		}
		setState({ ...state, isLoading: false });
	};

	return (
		<main className={` ${darkMode && 'bg_fan text-white'} col order-xl-2 col-lg-12 order-lg-1 col-md-12 col-sm-12 col-12`}>
			<div className="feeds">
				<div className="bg-white mt-4  feed-item faq-page">
					<div className="">
						<h5 className="lead fw-bold text-body mb-2">{t('views.settings.changePassword')}</h5>
					</div>
					<div className="row justify-content-center">
						<div className="col-lg-12">
							<form>
								<div className="form-floating mb-3 d-flex align-items-center">
									<input type="password" className="form-control rounded-5 small" name="currentPassword" value={state.currentPassword} placeholder="Mot de passe actuel" onChange={textInputChange} />
									<label htmlFor="floatingBirth">{t('views.settings.currentPassword')}</label>
								</div>
								<div className="form-floating mb-3 d-flex align-items-center">
									<input type="password" className="form-control rounded-5 small" name="password" value={state.password} onChange={textInputChange} placeholder="New password" id="password" />
									<label htmlFor="floatingEmail">{t('views.settings.newPassword')}</label>
								</div>
								<div className="form-floating mb-3 d-flex align-items-center">
									<input type="password" name="confirmPassword" value={state.confirmPassword} className="form-control rounded-5 small" placeholder="Confirm new password" onChange={textInputChange} />
									<label htmlFor="floatingPass">{t('views.settings.confirmNewPassword')}</label>
								</div>
								<div className="d-grid">
									<button onClick={submitNewLogin} className="btn btn-primary rounded-pill w-100 text-decoration-none py-2 fw-bold text-uppercase m-0">{state.isLoading ? <Loader /> : `${t('views.settings.submit')}`}</button>
								</div>
								{state.responseWhenChangingPassword !== '' &&
									<div className="alert alert-success alert-dismissible" role="alert">
										<div>{state.responseWhenChangingPassword}</div>
										<button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
									</div>
								}
							</form>
						</div>
					</div>
				</div>

				<div className="bg-white mt-5  feed-item  faq-page mb-3">
					<div className="mb-3">
						<h5 className="lead fw-bold text-body mb-2">{t('views.settings.deleteAccount')}</h5>
						<p className="mb-0">
							<div> <div> <p> {t('views.settings.sentence')} <br />
								{isCreator ? null : "Sincerely, The support team."}  </p>
								{isCreator ? null : (<button data-bs-toggle="modal" data-bs-target="#deleteAccountModal" className="btn  btn-danger text-decoration-none small fw-bold Capitalyse rounded-end mt-2"	> Delete Account </button>)}
							</div>
								<span> {isCreator ? (
									<p>
										<div className="control-content-post">
											{t('views.settings.sentence0')}
											<br /> <br />
											{t('views.settings.sincerely')} <br /> <b> {t('views.settings.support')}</b>
										</div>
									</p>
								) : null}
								</span>
							</div>
						</p>
					</div>
					<div className="row justify-content-center">
						<div className="col-lg-12">
							<div className="d-grid">
								<button data-bs-toggle="modal" data-bs-target="#deleteAccountModal" className="btn btn-danger w-100 text-decoration-none rounded-pill py-2 fw-bold text-uppercase m-0">{t('views.settings.delete')}</button>
							</div>
						</div>
					</div>
				</div>
				<div className="modal fade" id="deleteAccountModal" tabindex="-1" aria-labelledby="deleteAccountModal" aria-hidden="true">
					<div className="modal-dialog modal-dialog-centered">
						<div className={`modal-content border-0 rounded-4 ${darkMode && 'bg-dark'}`}>
							<div className={`  modal-header border-0  ${darkMode && "text-white"}`}>
								<h6 className={` modal-title fw-bold fs-6 d-flex justify-content-center `} id="exampleModalLabel1"> <p className="p-3"> {t('views.settings.ireversible')}</p></h6>
								<button type="button" className={` ${darkMode && 'text-white'} fs-2 bg-transparent border-0`} data-bs-dismiss="modal" aria-label="Close"><span className="material-icons me-3 fb-color ">close</span></button>
							</div>
							<div className="d-flex justify-content-center align-items-center pb-4 ">
								<button onClick={deleteCreatorAccount} className="btn btn-danger w-50 text-decoration-none rounded-5  fw-bold text-uppercase ">{t('views.settings.confirm')}</button>
							</div>
						</div>
					</div>
				</div>

			</div>
		</main>
	);
};
