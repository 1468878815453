import { useTranslation } from "react-i18next"
const SelectUser = () => {
    const { t } = useTranslation()
    return (
        <div className="bg-white text-center w-100" style={{ height: "100vh" }} >
            <div className="border-bottom position-absolute top-0 text-center fw-bold text-body fs-6 text-black  w-100 " style={{ padding: '19px', height: '60px' }} >
                {t('views.messages.select')}
            </div>
            <div className="border border-info rounded align-items-center  justify-content-start m-none position-absolute bottom-0 mb-1 d-flex mx-3" role="alert" style={{ width: '95%' }} >
                <div className="d-flex h-100 " >
                    <div className="alert alert-info" style={{ height: '50px', marginBottom: 0 }}></div>
                    <div className="position-absolute  translate-middle" style={{ top: '27px', left: '39px' }}>
                        <svg className="bi flex-shrink-0 me-2" width="30" height="30" role="img" aria-label="Info">
                            <path fill="#007BFF" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zM11 7h2v2h-2zm0 4h2v6h-2z" />
                        </svg>
                    </div>
                </div>
                <div className="alert-text-wrapper align-items-center  fw-bold text-bodye fs-6  text-center text-black ms-3 ">
                    {t('views.messages.pleaseSelectRecipient')}
                </div>
            </div>
        </div>
    )
}
export default SelectUser