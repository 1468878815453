import { useTranslation } from "react-i18next"
import { useEffect, useState } from "react";

import { Images } from "../../Constants";
import { i18n } from "../../Services";
import { useDispatch } from "react-redux";

function Privacy() {
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const [currentLanguage, setCurrentLanguage] = useState(() => {
        try {
            return localStorage.getItem('currentLanguage') || navigator.language || navigator.userLanguage;
        } catch (error) {
            return navigator.language || navigator.userLanguage;
        }
    });

    useEffect(() => {
        changeLanguage(currentLanguage);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentLanguage]);

    const changeLanguage = (language) => {
        let updateLanguage = language === 'fr-FR' || language === 'fr' ? 'fr' : 'en';
        i18n.changeLanguage(updateLanguage);

        let action = {
            type: "LANGUAGE",
            value: {
                language: updateLanguage,
                languageName: updateLanguage === 'fr' ? t('words.french') : t('words.english')
            }
        };
        dispatch(action);

        setCurrentLanguage(updateLanguage);
        localStorage.setItem('currentLanguage', updateLanguage);
    };
    return (
        <>
            <div className="container-fluid mb-5 bg-white">
                <div className="text-white fixed-top  fs-5 mb-0  pt-2" style={{ backgroundColor: '#0092EA', height: '50px' }}> <span className="ms-3"> <img src={Images.logo3} alt="" style={{ height: '35px' }} /></span></div>
                <div className="container "><h6 className=" container fw-blod fs-5 border-bottom pb-3 text-black position-fixed  bg-white pt-0 pt-3 ms-1 text-uppercase">{t('views.reviews.privacy.privacy')}</h6> </div>
            </div>
            <span className="my-5 text-white">.</span>
            <div className="container align-items-center my-5">
                <div className="container align-items-center my-5">
                    <ol className="fs-6 text-black p-4">
                        <li className="text-muted fs-4">{t('views.reviews.privacy.introduction')}</li>
                        <p className="flex-wrap">{t('views.reviews.privacy.p0')}</p>
                        <li className="text-muted mt-3 fs-4">{t('views.reviews.privacy.informationCollection')}</li>
                        <ol>
                            <li>{t('views.reviews.privacy.p1')}</li>
                            <li>{t('views.reviews.privacy.p2')}</li>
                            <li>{t('views.reviews.privacy.p3')}</li>
                        </ol>
                        <li className="text-muted mt-3 fs-4">{t('views.reviews.privacy.useOfInformation')}</li>
                        <ol>
                            <li>{t('views.reviews.privacy.p4')}</li>
                            <li>{t('views.reviews.privacy.p5')}</li>
                            <li>{t('views.reviews.privacy.p6')}</li>
                            <li>{t('views.reviews.privacy.p7')}</li>
                        </ol>
                        <li className="text-muted mt-3 fs-4">{t('views.reviews.privacy.dataSharingAndDisclosure')}</li>
                        <ul>
                            <li>{t('views.reviews.privacy.p8')}</li>
                            <li>{t('views.reviews.privacy.p9')}</li>
                            <li>{t('views.reviews.privacy.p10')}</li>
                        </ul>
                        <li className="text-muted mt-3 fs-4 ">{t('views.reviews.privacy.dataSecurity')}</li>
                        <p> {t('views.reviews.privacy.p11')}</p>
                        <li className="text-muted mt-3 fs-4">{t('views.reviews.privacy.yourRights')}</li>
                        <p> {t('views.reviews.privacy.p12')}</p>
                        <li className="text-muted mt-3 fs-4">{t('views.reviews.privacy.childrenPrivacy')}</li>
                        <p>{t('views.reviews.privacy.p13')}</p>
                    </ol>
                </div>
            </div >
        </>
    )
} export default Privacy