const ModalImageView = ({ msgId, fileLink, pseudo }) => {
    return (
        <div className="modal fade w-100 " id={`p_imageview${msgId}`} data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-modal="true" role="dialog" onContextMenu={(e) => e.preventDefault()}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content  p-3 border-0 bg-light">
                    <div className="modal-header align-items-center justify-content-start border-0 p-0 mb-3">
                        <a href="/" className="text-muted text-decoration-none material-icons" data-bs-dismiss="modal">close</a>
                    </div>
                    <div>
                        <img className='img-fluid img' src={fileLink} alt="file-img" style={{ maxHeight: ' 100%', maxWidth: '100%', width: "auto", heigh: "auto" }} onContextMenu={(e) => e.preventDefault()} />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ModalImageView