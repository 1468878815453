import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { useScreenSize } from "../../Utils";
import { AddCardPayment, CardTransactions } from "../../Components";
import { AppContainer } from "../../Containers";



const AddCardPaymentView = () => {
    const { t } = useTranslation();
    const darkMode = useSelector((state) => state.theme.darkMode);
    const users = useSelector((state) => state.users);
    const params = useSelector((state) => state.params);
    const screenSize = useScreenSize()
    const isMobile = screenSize === 'Extra Small' || screenSize === 'Small' || screenSize === 'Medium'

    return (
        <AppContainer>
            <main className={`col col-xl-6 order-xl-2 col-lg-12 order-lg-1 col-md-12 col-sm-12 col-12 px-0  border boder-top-0  border-bottom-0 ${isMobile && 'fixed-content'} ${darkMode && 'bg-dark'}`}>
                <div className={` main-content ${darkMode && 'bg-dark'} `} >
                    <div style={{ height: '50px' }} className="align-items-center justify-content-between mb-3 bg-white fix-sidebar top-0 border-bottom mb-md-mobile m-none">
                        <div className="d-flex pt-2">
                            <a href='/account' className={`${darkMode ? 'text-white' : 'text-dark'} material-icons text-decoration-none ms-3`}>arrow_back</a>
                            <p className={`${darkMode ? 'text-white' : 'text-body'} ms-2 mb-0 fw-bold fs-6 text-uppercase`}>
                                {t('views.addCard.addCard')}
                            </p>
                        </div>
                    </div>
                    <div className="px-3 mt-2" >
                        <AddCardPayment visible={true} />
                    </div>
                </div>

            </main>
            <aside className="col col-xl-3 order-xl-3 col-lg-6 order-lg-3 col-md-6 col-sm-6 col-12 pt-3" style={{ paddingLeft: '13px' }}>
                <div className="fix-sidebar">
                    <CardTransactions data={{ selectedAction: 'credit', addCard: true }} users={users} params={params} styleResp={'side-trend lg-none'} />
                </div>
            </aside>
        </AppContainer >
    )
}

export default AddCardPaymentView