
import React from "react";
import { useTranslation } from "react-i18next";

import { CardProfile, LoginComponent, RestoreAccountComponent, SignUpcomponent } from "../../Components";
const LogOrSignToSubscribeView = ({ id, creator }) => {
    const { t } = useTranslation();
    const profileLogOrSignRef = React.useRef();
    const [showLogin, setShowLogin] = React.useState(false);
    const [isRestore, setIsRestore] = React.useState(false);

    const closeModal = () => {
        profileLogOrSignRef.current.click();
    }

    return (
        <div className="modal fade " id={`signOrLogModal${id}`} tabIndex="-1" aria-labelledby="exampleModalLabel2" aria-hidden="true" >
            <div className="modal-dialog modal-dialog-centered modal-lg" >
                <div className={`modal-content rounded-4 overflow-hidden border-0`}>
                    <div className="modal-body p-0">
                        <div className="p-3">
                            {/* <h6 className="fw-bold text-uppercase mb-0 mb-mobile">{`${showLogin ? `${t('views.auth.logIn')}` : `${t('views.auth.signUp')}`}`}</h6> */}
                            <div className="d-flex gap-3">
                                <div className="m-none w-50">
                                    <CardProfile fan={false} creator={creator} profile={true} addClassName={'mt-3'} />
                                </div>
                                {(!showLogin && !isRestore) ?
                                    <SignUpcomponent profile={true} closeModal={closeModal} showLoginOrSign={setShowLogin} profileLogOrSignRef={profileLogOrSignRef} creator={creator} /> :
                                    (showLogin && !isRestore) ?
                                        <LoginComponent profile={true} showLoginOrSign={setShowLogin} closeModal={closeModal} restore={setIsRestore} creator={creator} /> :
                                        <RestoreAccountComponent profile={true} restore={setIsRestore} creator={creator} />
                                }
                            </div>
                        </div>
                        <div className="p-3 border border-top d-flex justify-content-end">
                            <button className="btn btn-block text-secondary p-0 rounded-pill fw-bold px-2" style={{ backgroundColor: "#F0F0F0" }} data-bs-dismiss="modal" ref={profileLogOrSignRef}>{t('words.cancel')}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default LogOrSignToSubscribeView;







