import React, { useEffect, useState } from "react";
import { AppContainer, AsideContainer } from "../../Containers";
import { AccountComponent } from "./AccountComponent";
import { NotificationsSetting } from "./NotificationsSetting";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useScreenSize } from "../../Utils";

export const UserSittingsView = () => {

    const [isdarkMode, setIsDarkMode] = useState(false);
    const darkMode = useSelector((state) => state.theme.darkMode);
    const screenSize = useScreenSize()
    const isMobileSize = screenSize === 'Extra Small' || screenSize === 'Small' || screenSize === 'Medium'
    const params = useSelector(state => state.params);
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState(0);

    useEffect(() => {
        setIsDarkMode(darkMode);
    }, [darkMode]);

    useEffect(() => {
        localStorage.setItem("darkMode", JSON.stringify(isdarkMode));
    }, [isdarkMode]);


    useEffect(() => {
        const savedDarkMode = localStorage.getItem("darkMode");
        if (savedDarkMode) {
            setIsDarkMode(JSON.parse(savedDarkMode));
        }
    }, []);

    const tabs = [
        { label: `${t('views.settings.account')}`, target: 'pills-feed' },
        { label: `${t('views.settings.appSettings')}`, target: 'pills-mentions' },
    ];

    const changeTextColor = (index) => {
        setActiveTab(index);
    };

    return (
        <AppContainer>
            <main className={`col col-xl-6 order-xl-2 col-lg-12 order-lg-1 col-md-12 col-sm-12 col-12 py-2 border border-top-0 border-fw-light px-0 border-bottom-0 ${isMobileSize && 'pt-5 fixed-content pb-5'} `}>
                <div style={{ height: '50px' }} className={`  ${screenSize === 'Extra Small' || screenSize === 'Small' ? 'border-0' : 'border-bottom'}  mb-4 d-flex align-items-center  pb-2  m-none bg-white fix-sidebar top-0 border-bottom `}>
                    <div className="d-flex align-items-center  ">
                        <a href={`/`} className={`${darkMode ? 'text-white' : 'text-dark'} material-icons text-decoration-none m-none ms-3`} > arrow_back </a>
                        <p className={`${darkMode ? 'text-white' : 'text-body'} ms-2 mb-0 fw-bold  fs-6`}>{t('views.settings.settings')}</p>
                    </div>
                </div>
                <div style={{ paddingBottom: "80px" }} className={` ${darkMode && 'bg_fan text-white'}  main-content px-2`}>
                    <ul className={` ${darkMode ? 'bg_fan ' : 'bg-white'}top-osahan-nav-tab bg-white nav nav-pills justify-content-center nav-justified rounded-4 overflow-hidden mt-4`} id="pills-tab" role="tablist">
                        {tabs.map((tab, index) => (
                            <li className="nav-item" role="presentation" key={index}>
                                <button className={`${darkMode ? 'bg-dark' : activeTab === index ? 'text-white' : 'text-muted'} p-1 nav-link fb-btn-color ${activeTab === index ? 'active' : ''} rounded-pill ${isMobileSize && 'small'}`}
                                    id={`pills-tab-${index}`} data-bs-toggle="pill" data-bs-target={`#${tab.target}`} type="button" role="tab" aria-controls={`#${tab.target}`}
                                    aria-selected={activeTab === index} onClick={() => changeTextColor(index)}  >
                                    {tab.label}
                                </button>
                            </li>
                        ))}
                    </ul>

                    <div className="tab-content" id="pills-tabContent">  <div className="tab-pane fade show active" id="pills-feed" role="tabpanel" aria-labelledby="pills-feed-tab"> <AccountComponent /> </div>
                        <div className="tab-pane fade" id="pills-people" role="tabpanel" aria-labelledby="pills-people-tab" > <NotificationsSetting /> </div>
                        <div className="tab-pane fade" id="pills-mentions" role="tabpanel" aria-labelledby="pills-mentions-tab" >
                            <div className={`${darkMode ? 'bg_fan text-white' : 'bg-white'} p-4 feed-item faq-page`}>
                                <div className={` mb-3`}>  <h5 className={` ${darkMode ? 'text-white' : 'text-body'} lead fw-bold  mb-0`}>{t('views.settings.language')}</h5> </div>
                                <div className={`${darkMode && 'bg_fan'} row justify-content-center`}>
                                    <div className={` col-lg-12 `}>
                                        <p className={` pt-3 d-flex Justify-content-center  align-items-center mb-0 w-100`}>
                                            <button className="btn fb-btn-color text-white rounded-pill w-100 text-decoration-none py-2  text-uppercase m-0 d-flex align-conent-center justify-content-center shadow-0 border-0" data-bs-toggle="modal" data-bs-target="#languageModal" >
                                                <span>{t('navigation.language')} ({params.languageName})</span>
                                            </button>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <AsideContainer />
        </AppContainer>
    );
};
