import { useSelector } from "react-redux";
import React from "react";

import { Loader, SuggestionCreator } from "../Components";
import { RequestFanbusy } from "../Services";
import Slider from "react-slick";

const SuggestionContainer = ({ styleResp, margin }) => {
    const users = useSelector((state) => state.users);
    const params = useSelector((state) => state.params);
    const [state, setState] = React.useState({
        creators: [],
        isLoading: true
    });

    const settings = {
        className: "slider variable-width",
        centerMode: false,
        infinite: true,
        centerPadding: "0",
        slidesToShow: 1.0,
        speed: 500,
        nextArrow: <></>,
        prevArrow: <></>,
        slidesToScroll: 1,
        variableWidth: true,
        dots: false
    };

    const getAllCreator = React.useCallback(async () => {
        let res = await RequestFanbusy("souscriptions/my/recommendation", 'GET', '', users.access_token);
        if (res.status === 200) {
            setState((prev) => ({ ...prev, creators: res.response.data, isLoading: false }));
        }
    }, [users.access_token]);

    React.useEffect(() => {
        getAllCreator();
    }, [params.refresh, getAllCreator]);

    return (
        <>
            {!state.isLoading ? (
                state.creators.length > 0 && (
                    <div className={` pb-3 ${margin ? 'border-bottom' : "border-0"} ${styleResp}`}>
                        {window.location.pathname === '/' && <h6 className=" fw-bold mb-1 px-3">Who to follow</h6>}
                        <Slider  {...settings} className={`${margin}`}>
                            {state.creators.map((subCreator, subIndex) => (
                                <div key={subIndex} style={{ width: 291 }}>
                                    <SuggestionCreator key={subCreator._id} getAllCreator={getAllCreator} suggestionRight={false} creatorLength={{ index: subIndex, length: state.creators.length }} data={subCreator} styleResp={styleResp} />
                                </div>
                            ))}
                        </Slider>
                    </div>
                )
            ) :
                (<Loader styleColor={true} marginTop={true} />)
            }

        </>
    );
}
export default SuggestionContainer;