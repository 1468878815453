import React from "react";
import { AppContainer, AsideContainer } from "../../Containers";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { AccordionItem } from "../../Components";
import parse from 'html-react-parser';
import { useScreenSize } from "../../Utils";
import { useTranslation } from "react-i18next";


export const HelpAndSupport = () => {
	const { t } = useTranslation();
	const darkMode = useSelector((state) => state.theme.darkMode);
	const screenSize = useScreenSize()
	const isMobile = screenSize === 'Extra Small' || screenSize === 'Small' || screenSize === 'Medium'

	return (
		<AppContainer>
			<main style={{ marginTop: isMobile ? '20px' : '' }} className={` col col-xl-6 order-xl-2 col-lg-12 order-lg-1 col-md-12 col-sm-12 col-12 px-0 border border-fw-light border-bottom-0  ${isMobile && 'pt-5 fixed-content pb-5 '} `}>
				<div className={`"main-content `}>
					<div style={{ height: '50px' }} className="d-flex justify-content-between align-items-center bg-white fix-sidebar top-0 border-bottom m-none pt-2">
						<div className="d-flex align-items-center mb-3  m-none bg-white pt-2  ">
							<a href={`/`} className={`${darkMode ? 'text-white' : 'text-dark'} material-icons text-decoration-none m-none ms-3`}>
								arrow_back
							</a>
							<p className={`${darkMode ? 'text-white' : 'text-body'} ms-2 mb-0 fw-bold fs-6 text-uppercase`}>{t('views.help.help')}</p>
						</div>
						<div className="d-flex align-items-center m-none mb-3">
							<NavLink to={`/help-and-support-creator`} className="d-flex col text-decoration-none" >
								<p className={`${darkMode ? 'text-white' : 'fb-color '} ms-2 mb-0 fw-bold  pe-2 fs-5 `}>For creators</p>
								<span className={`${darkMode ? 'text-white' : 'fb-color '} material-icons text-decoration-none m-none me-3 fs-5`} style={{ paddingTop: '7px' }}> arrow_forward </span>

							</NavLink>
						</div>
					</div>
					<div className={`${darkMode ? 'bg-dark' : 'bg-white'}  overflow-hidden  mb-4 mb-lg-0  m-0`}>
						<div className="py-4">
							<div className="px-2">
								<div className="row position-relative">
									<main className="col order-xl-2 col-lg-12 order-lg-1 col-md-12 col-sm-12 col-12">
										<div className=" d-flex justify-content-center">
											<h1 className="lead fw-bold">{t('views.help.generalQuestions')}</h1>
										</div>
										<div className={`${darkMode ? 'bg-dark' : 'bg-white'} py-4 feed-item rounded-4  faq-page`}>
											<div className="rounded-3">
												<div className="mb-3">
													<h5 className="lead fw-bold mb-0">{t('views.help.account')}</h5>
												</div>
												<div className="row justify-content-center">
													<div className="col-lg-12">
														<div className="accordion" id="accordionExample">
															<AccordionItem id={1} title={t('views.help.t1')} content={parse(t('views.help.c1'))} />
															<AccordionItem id={2} title={t('views.help.t2')} content={t('views.help.c2')} />
															<AccordionItem id={3} title={t('views.help.t3')} content={t('views.help.c3')} />
														</div>
													</div>
												</div>
												<div className="d-flex justify-content-center">
													<h1 className="lead fw-bold mt-5">{t('views.help.fanQuestions')}</h1>
												</div>
												<div className="mb-3 pt-2">
													<h5 className="lead fw-bold mb-0">{t('views.help.questions')}</h5>
												</div>
												<div className="row justify-content-center">
													<div className="col-lg-12">
														<div className="accordion" id="accordionExample1">
															<AccordionItem id={4} title={t('views.help.whySubscribe')} content={t('views.help.response1')} />
															<AccordionItem id={5} title={t('views.help.howToSubscribe')} content={parse(t('views.help.response2'))} />
															<AccordionItem id={6} title={t('views.help.findCreator')} content={parse(t('views.help.response3'))} />
															<AccordionItem id={7} title={t('views.help.unableToSubscribe')} content={parse(t('views.help.response4'))} />
															<AccordionItem id={8} title={t('views.help.cancelSubscription')} content={parse(t('views.help.response5'))} />
															<AccordionItem id={9} title={t('views.help.autoRenewalSubscriptions')} content={parse(t('views.help.response6'))} />
														</div>
													</div>
												</div>
												<div className=" d-flex justify-content-center">
													<h1 className="lead fw-bold mt-5">{t('views.help.payments')}</h1>
												</div>
												<div className="mb-3   pt-2">
													<h5 className="lead fw-bold mb-0">{t('views.help.acceptedPaymentsMethods')}</h5>
												</div>
												<div className="row justify-content-center">
													<div className="col-lg-12">
														<div className={`${darkMode ? 'bg-dark text-white' : 'bg-white text-body'} accordion overflow-hidden`} id="accordionExample2">
															<AccordionItem id={10} title={t('views.help.whatPayment')} content={parse(t('views.help.response7'))} />
															<AccordionItem id={11} title={t('views.help.howDoes')} content={parse(t('views.help.response8'))} />
															<AccordionItem id={12} title={t('views.help.transactionCharge')} content={parse(t('views.help.response9'))} />
															<AccordionItem id={13} title={t('views.help.thirdPartyPayments')} content={t('views.help.response10')} />
															<AccordionItem id={14} title={t('views.help.secure')} content={parse(t('views.help.response11'))} />
														</div>
													</div>
												</div>
												<div className="d-flex justify-content-center">
													<h1 className="lead fw-bold mt-5">{t('views.help.tips')}</h1>
												</div>
												<div className="mb-3 pt-2">
													<h1 className="lead fw-bold">{t('views.help.tipsissue')}</h1>
												</div>
												<div className="row justify-content-center">
													<div className="col-lg-12">
														<div className={`${darkMode ? 'bg-dark text-white' : 'bg-white'} accordion overflow-hidden`} id="accordionExample3">
															<AccordionItem id={15} title={t('views.help.tippedIncorrectAmount')} content={parse(t('views.help.response12'))} />
															<AccordionItem id={16} title={t('views.help.contentNotReceived')} content={t('views.help.response13')} />
															<AccordionItem id={17} title={t('views.help.myWallet')} content={t('views.help.response14')} />
														</div>
													</div>
												</div>
											</div>
										</div>
									</main>
								</div>
							</div>
						</div>
					</div>
				</div>
			</main>
			<AsideContainer />
		</AppContainer>
	);
};