//React components
import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

//App component
import { Avatar } from '../../Components'
import { formatDateRelativePost } from '../../Utils'
import { Images } from '../../Constants'
import { RequestFanbusy } from '../../Services';
import { shortenName } from '../../Utils';




const PostHeader = ({ post, isOnLine, refrechAfterDeletePost, setShowReleaseError, showReleaseError, updatePostContent }) => {

    const users = useSelector((state) => state.users);
    const dispatch = useDispatch()
    const { t } = useTranslation();
    const [state, setState] = useState({
        releaseErrors: '',
        editContent: post?.content
    })
    const handeleDeletePost = async () => {
        let res = await RequestFanbusy(`posts/${post._id}`, 'DELETE', '', users.access_token);

        if (res.status === 200) {
            refrechAfterDeletePost(post._id)
            dispatch({ type: "REFRESH", value: 1 });
        }
    }

    const handleEditPost = async () => {
        let form = {
            content: state.editContent
        }
        let res = await RequestFanbusy(`posts/${post._id}`, 'PUT', form, users.access_token);
        if (res.status === 200) {
            updatePostContent(post._id, state.editContent)
            dispatch({ type: "REFRESH", value: 1 });
        } else {
            setShowReleaseError(true)
            setState({ ...state, releaseErrors: 'Error, please try again.' });
        }
    }

    const handleEditChange = (input) => {
        const target = input.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        if (name === 'postContent') {
            setState({ ...state, editContent: value })
        }


    }


    return (
        <>

            <div className="d-flex justify-content-between px-3">
                <NavLink to={users.users?._id === post.creator._id ? '/profile' : `/${post.creator.pseudo}`} className="d-flex gap-2 text-decoration-none ">
                    <div className='mt-1 position-relative'>
                        <Avatar user={post.creator} width={50} height={50} fontSize="fs-6" />
                        {isOnLine && <span className="rounded-circle position-absolute bottom-0 mb-2" style={{ width: 10, height: 10, background: '#35D063', right: '-1px', border: '2px solid white' }}></span>}
                    </div>
                    <div style={{ marginTop: 0 }} className="d-flex flex-column " >
                        <div className='d-flex align-items-center'>
                            <h6 className={`text-body fw-bold mb-0 d-flex align-items-center justify-content-center text-capitalize `} style={{ marginTop: '2px' }}>
                                {shortenName(post.creator.display_name, 24)}
                            </h6>
                            {post.creator.account_type === 'CREATOR' &&
                                <span className="me-0 ms-0 md-16 fw-bold mb-1 ">{Images.verif} </span>
                            }

                        </div>
                        <small className="text-muted">@{shortenName(post.creator.pseudo, 20)}</small>
                    </div>
                </NavLink>
                <div className="d-flex small  mt-1 ">
                    <p className="text-muted">{formatDateRelativePost(post.updated_at, t)}</p>
                    <div className="dropdown">
                        <a href={`/`} className={`text-muted text-decoration-none material-icons ms-2 md-20 rounded-circle bg-lights`} id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">more_horiz</a>
                        <ul className="dropdown-menu fs-13 dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                            {post.creator._id === users.users?._id ?
                                <>
                                    <li>
                                        <button className="dropdown-item text-muted" data-bs-target={`#editpost${post._id}`} data-bs-toggle='modal'>
                                            <span className="material-icons md-13 me-1">edit</span> {t('words.edit')}
                                        </button>
                                    </li>
                                    <li>
                                        <button className="dropdown-item text-muted" onClick={() => handeleDeletePost()}>
                                            <span className="material-icons md-13 me-1">delete</span> {t('words.delete')}
                                        </button>
                                    </li>
                                </> :
                                <li>
                                    <a href={`/`} className={`dropdown-item ${post.reported ? 'fb-color ' : 'text-muted'}`} data-bs-toggle="modal" data-bs-target={`#reportPostModal${post._id}`}>
                                        <span className="material-icons md-13 me-1">dangerous</span> {t('words.report')}
                                    </a>
                                </li>
                            }
                            <li>
                                <a href={`/`} className="dropdown-item text-muted d-flex align-items-center" data-bs-toggle="modal" data-bs-target={`#shareModal${post._id}`}>
                                    <span className="material-icons md-13 me-1">share</span> {t('words.share')}
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="modal" tabindex="-1" id={`editpost${post._id}`}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content rounded-4 p-0 shadow-sm border-0 bg-white">
                        <div className="modal-header py-2">
                            <h5>{t('views.post.editPostContent')}</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">

                            <textarea className="form-control rounded-4 border-0 shadow-sm mb-2 h-100 " autoFocus={true} value={state.editContent} rows={4} name="postContent" onChange={handleEditChange}></textarea>

                            {(state.releaseErrors.length > 0 && showReleaseError) &&
                                <div className="alert alert-danger alert-dismissible" role="alert">
                                    <div>{state.releaseErrors}</div>
                                    <button type="button" className="btn-close" onClick={() => setShowReleaseError(false)} aria-label="Close"></button>
                                </div>
                            }

                        </div>
                        <div className="modal-footer">
                            <button type="button" class="btn btn-secondary rounded-pill" data-bs-dismiss="modal">{t('words.close')}</button>
                            <button type="button" class="btn gradient-button text-white rounded-pill " data-bs-dismiss="modal" onClick={handleEditPost}>{t('words.edit')}</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default PostHeader