//React component
import React, { useRef } from "react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next";


//App component
import { Images } from "../../Constants"
import { Loader } from "../../Components";
import { convertCurrency, getReleasePrice } from "../../Utils";


const PostBlocked = ({ post, checkIfSubscribeToCreator, creatorId, isLoaded, subscribeToCreator, releasePost, haveFunds, releaseErrors, showReleaseError, setShowReleaseError, releaseRefs, alertType, fee }) => {
    const users = useSelector((state) => state.users);
    const params = useSelector((state) => state.params);
    const { t } = useTranslation()
    const sref = useRef(null);

    const releasePrice = getReleasePrice(post, params.continent);

    const convertAmount = (amount) => {
        let convert = convertCurrency(params.currency, params.currencies, amount, 'GET');
        return convert;
    }

    const checkPaiementPlan = () => {
        const isSubscribe = checkIfSubscribeToCreator(creatorId)
        if (!isSubscribe) {
            if (fee.hasPaymentPlan) {
                sref && sref.current.click();
            } else {

                subscribeToCreator(1);
            }
        } else {
            releasePost(post._id);
        }
    };

    return (
        <>
            <div className="position-relative" >
                <img src={Images.to_release} className="img-fluid mb-0 w-100" alt="post-img" style={{ height: '78%' }} />
                <div className="position-absolute bottom-0 start-50 translate-middle-x border rounded-3 p-1 mb-2" style={{ width: '96.5%' }}>
                    <div className="d-flex align-item-center justify-content-between">
                        <div className="d-flex align-item-center justify-content-center gap-3 mb-1">
                            {post.illustrations.filter(file => file.mime_type.includes('image')).length > 0 &&
                                <div className="d-flex align-item-center justify-content-center" style={{ color: '#AFB0B4' }}>
                                    <span className="material-icons m-0 p-0 me-1" style={{ fontSize: 20 }}>image</span>
                                    <p className="m-0 p-0">{post.illustrations.filter(file => file.mime_type.includes('image')).length}</p>
                                </div>
                            }
                            {post.illustrations.filter(file => file.mime_type.includes('video')).length > 0 &&
                                <div className="d-flex align-item-center justify-content-center" style={{ color: '#AFB0B4' }}>
                                    <span className="material-icons m-0 p-0 me-2" style={{ fontSize: 20 }}>videocam</span>
                                    <p className="m-0 p-0">{post.illustrations.filter(file => file.mime_type.includes('video')).length}</p>
                                </div>
                            }
                        </div>
                        <span className="material-icons" style={{ fontSize: 20, color: '#AFB0B4' }}>lock</span>
                    </div>
                    {users.authenticated && users.access_token !== "" ?
                        <button disabled={isLoaded} onClick={checkPaiementPlan} className={`btn gradient-button text-white btn-block rounded-pill fw-bold d-flex align-items-center gradient-button ${isLoaded ? 'justify-content-center' : 'justify-content-between'}  w-100 p-1 px-4 mb-1`}>
                            {!checkIfSubscribeToCreator(creatorId) ? (
                                isLoaded ?
                                    <Loader /> :
                                    <>
                                        {t('words.subscribe')}
                                        {post.creator.is_free_account ?
                                            <span className="fw-bold">{t('words.free')}</span>
                                            :
                                            <>
                                                {(users.account.balance < releasePrice || users.account.balance < fee?.monthly || !haveFunds) &&
                                                    <span data-bs-toggle="modal" data-bs-target={users.users && `#sentTipModal${post._id}`} ref={releaseRefs}></span>
                                                }
                                                <span data-bs-toggle="modal" data-bs-target={`#subscriptionPlansModal`} ref={sref}></span>
                                                <span className="fw-bold" >{convertAmount(fee?.monthly)} {params.currency}/{t('words.month')}</span>
                                            </>
                                        }
                                    </>

                            ) : (
                                isLoaded ?
                                    <Loader />
                                    :
                                    <>
                                        {t('views.post.release_post')}
                                        {(users.account.balance < releasePrice || users.account.balance < fee?.monthly || !haveFunds) &&
                                            <span data-bs-toggle="modal" data-bs-target={users.users?.verified ? `#sentTipModal${post._id}` : '#checkMailModal'} ref={releaseRefs}></span>
                                        }
                                        <span>{convertAmount(releasePrice)} {params.currency}</span>
                                    </>
                            )}
                        </button>
                        :
                        <a href="/" data-bs-toggle="modal" data-bs-target={`#signOrLogModal${post.creator._id}`} className="btn gradient-button text-white btn-block rounded-pill fw-bold text-decoration-none d-flex align-items-center justify-content-between w-100 p-1 px-4 mb-1">
                            {!checkIfSubscribeToCreator(creatorId) ? (
                                <>
                                    {t('words.subscribe')}
                                    {post.creator.is_free_account ?
                                        <span className="fw-bold">{t('words.free')}</span>
                                        :
                                        <span className="fw-bold" >{convertAmount(fee?.monthly)} {params.currency}/{t('words.month')}</span>
                                    }
                                </>

                            ) : (
                                <>
                                    {t('views.post.release_post')}
                                    <span>{convertAmount(releasePrice)} {params.currency}</span>
                                </>
                            )}
                        </a>
                    }
                    {(releaseErrors.length > 0 && showReleaseError) &&
                        <div className={`alert alert-${alertType} alert-dismissible m-2 mb-0 `} role="alert">
                            <div>{releaseErrors}</div>
                            <button type="button" className="btn-close" onClick={() => setShowReleaseError(false)} aria-label="Close"></button>
                        </div>
                    }
                </div>
            </div>


        </>
    )
}

export default PostBlocked