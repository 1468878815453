import { useTranslation } from "react-i18next"
import { useEffect, useState } from "react";

import { Images } from "../../Constants";
import { i18n } from "../../Services";
import { useDispatch } from "react-redux";

function TermsOfService() {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [currentLanguage, setCurrentLanguage] = useState(() => {
        try {
            return localStorage.getItem('currentLanguage') || navigator.language || navigator.userLanguage;
        } catch (error) {
            return navigator.language || navigator.userLanguage;
        }
    });

    useEffect(() => {
        changeLanguage(currentLanguage);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentLanguage]);

    const changeLanguage = (language) => {
        let updateLanguage = language === 'fr-FR' || language === 'fr' ? 'fr' : 'en';
        i18n.changeLanguage(updateLanguage);

        let action = {
            type: "LANGUAGE",
            value: {
                language: updateLanguage,
                languageName: updateLanguage === 'fr' ? t('words.french') : t('words.english')
            }
        };
        dispatch(action);

        setCurrentLanguage(updateLanguage);
        localStorage.setItem('currentLanguage', updateLanguage);
    };
    return (
        <>
            <div className="container-fluid mb-5 bg-white">
                <div className="text-white fixed-top  fs-5 mb-0  pt-2" style={{ backgroundColor: '#0092EA', height: '50px' }}> <span className="ms-3"> <img src={Images.logo3} alt="" style={{ height: '35px' }} /></span></div>
                <div className="container "><h6 className=" container fw-blod fs-5 border-bottom pb-3 text-black position-fixed  bg-white pt-0 pt-3 ms-1 ">{t('views.reviews.termsOfService.termsOfService')}</h6></div>
            </div>
            <span className="my-5 text-white">.</span>
            <div className="container align-items-center my-5">
                <div className="container align-items-center my-5">
                    <ol className="fs-6 text-black p-4">
                        <li className="text-muted fs-4">{t('views.reviews.termsOfService.introduction')}</li>
                        <p className="flex-wrap">{t('views.reviews.termsOfService.t0')}</p>
                        <li className="text-muted fs-4 mt-3">{t('views.reviews.termsOfService.userAccounts')}</li>
                        <ul>
                            <li>{t('views.reviews.termsOfService.t1')}</li>
                            <li>{t('views.reviews.termsOfService.t2')}</li>
                            <li>{t('views.reviews.termsOfService.t3')}</li>
                        </ul>
                        <li className="text-muted fs-4 mt-3">{t('views.reviews.termsOfService.userConduct')}</li>
                        <ul>
                            <li>{t('views.reviews.termsOfService.t4')}</li>
                            <li>{t('views.reviews.termsOfService.t5')}</li>
                        </ul>
                        <li className="text-muted fs-4 mt-3">{t('views.reviews.termsOfService.paymentsAndSubscriptions')}</li>
                        <ul>
                            <li>{t('views.reviews.termsOfService.t6')}</li>
                            <li>{t('views.reviews.termsOfService.t7')}</li>
                        </ul>
                        <li className="text-muted fs-4 mt-3">{t('views.reviews.termsOfService.intellectualProperty')}</li>
                        <ul>
                            <li>{t('views.reviews.termsOfService.t8')}</li>
                            <li>{t('views.reviews.termsOfService.t9')}</li>
                        </ul>
                        <li className="text-muted fs-4 mt-3">{t('views.reviews.termsOfService.privacyAndDataSecurity')}</li>
                        <ul>
                            <li>{t('views.reviews.termsOfService.t10')}</li>
                            <li>{t('views.reviews.termsOfService.t11')}</li>
                        </ul>
                        <li className="text-muted fs-4 mt-3">{t('views.reviews.termsOfService.terminationAndSuspension')}</li>
                        <ul>
                            <li>{t('views.reviews.termsOfService.t12')}</li>
                            <li>{t('views.reviews.termsOfService.t13')}</li>
                        </ul>
                        <li className="text-muted fs-4 mt-3">{t('views.reviews.termsOfService.disclaimersAndLimitationOfLiability')}</li>
                        <ul>
                            <li>{t('views.reviews.termsOfService.t14')}</li>
                            <li>{t('views.reviews.termsOfService.t15')}</li>
                        </ul>
                        <li className="text-muted fs-4 mt-3">{t('views.reviews.termsOfService.changesToTerms')}</li>
                        <ul>
                            <li>{t('views.reviews.termsOfService.t16')}</li>
                        </ul>
                        <li className="text-muted fs-4 mt-3">{t('views.reviews.termsOfService.governingLaw')}</li>
                        <ul>
                            <li>{t('views.reviews.termsOfService.t17')}</li>
                        </ul>
                    </ol>
                </div>
            </div >
        </>
    )
} export default TermsOfService