import React from 'react';
import { useSelector } from 'react-redux';
import { RequestFanbusy } from '../Services';
import { useSearch } from '../Utils';
import { useTranslation } from 'react-i18next';
import { AsidePeopleComponent } from '../Components';

const AsidePeople = ({ styleResp }) => {
    const { t } = useTranslation();
    const users = useSelector((state) => state.users);
    const params = useSelector((state) => state.params);
    const sliderRef = React.useRef(null);
    const [state, setState] = React.useState({
        creators: [],
        follows: [],
        isLoading: true,
        isLoadingExp: true,
        numDisplayed: 3
    });
    const { searchQuery, setSearchQuery, searchResults, searchLoad } = useSearch("creators/search");

    const handleRefreshClick = () => {
        const shuffledCreators = [...state.creators];
        shuffledCreators.sort(() => Math.random() - 0.5);
        setState({ creators: shuffledCreators });
    };

    const handleNext = () => {
        sliderRef.current.slickNext();
    };

    const handlePrevious = () => {
        sliderRef.current.slickPrev();
    };

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <></>,
        prevArrow: <></>
    };

    React.useEffect(() => {
        const getAllCreator = async () => {
            let res = await RequestFanbusy("souscriptions/my/recommendation", 'GET', '', users.access_token);
            if (res.status === 200) {
                setState((prev) => ({ ...prev, creators: res.response.data, isLoading: false }));
            } else if (res.status === 401) {
                setState((prev) => ({ ...prev, isLoading: false }));
            }
        }
        getAllCreator();
    }, [params.refresh, users.access_token, searchQuery]);


    React.useEffect(() => {
        const getFollowsExp = async () => {
            const accountType = users.users.account_type;
            const token = users.access_token;
            let res = await RequestFanbusy(`souscriptions/my/${accountType}/expiry-souscription`, 'GET', '', token);
            if (res.status === 200) {
                setState((prev) => ({ ...prev, follows: res.response.data, isLoadingExp: false }));
            } else if (res.status === 401) {
                setState((prev) => ({ ...prev, isLoadingExp: false }));
            }


        }
        getFollowsExp();
    }, [params.refresh, users.access_token, users.users?.account_type]);

    return (
        <aside className="fix-sidebar">
            <div className={`${styleResp ? styleResp : 'lg-none'} position-relative`}>

                <AsidePeopleComponent
                    customStyle={""}
                    icon={'👋'}
                    title={t('navigation.whoToFollow')}
                    expired={false}
                    styleResp={styleResp}
                    creators={state.creators}
                    searchQuery={searchQuery}
                    searchResults={searchResults}
                    searchLoad={searchLoad}
                    isLoading={state.isLoading}
                    sliderRef={sliderRef}
                    settings={settings}
                    setSearchQuery={setSearchQuery}
                    handleRefreshClick={handleRefreshClick}
                    handlePrevious={handlePrevious}
                    handleNext={handleNext}
                />
                {state.follows?.length > 0 &&
                    <AsidePeopleComponent
                        customStyle={'custom-my'}
                        icon={'⌛'}
                        title={t('container.aside_people.subscriptionExpired')}
                        expired={true}
                        styleResp={styleResp}
                        creators={state.follows}
                        searchQuery={""}
                        searchResults={[]}
                        searchLoad={searchLoad}
                        isLoading={state.isLoadingExp}
                        sliderRef={sliderRef}
                        settings={settings}
                        setSearchQuery={() => { }}
                        handleRefreshClick={() => { }}
                        handlePrevious={() => { }}
                        handleNext={() => { }}
                    />}

                <footer className='px-0 m-none pt-0' style={{ marginTop: '-10px' }}>
                    <hr />
                    <div className='d-flex justify-content-between px-3 text-muted pb-3' style={{ fontSize: "14px" }}>
                        <span> <a href='/privacy' target='_blank' className='text-decoration-none custom-color-footer-aside me-1'>{t('container.aside_people.privacy')}</a></span>. <span><a className='text-decoration-none  custom-color-footer-aside ms-1 mx-1' href='/cookie' target='_blank'>{t('container.aside_people.cookie')}</a></span>. <span> <a className='text-decoration-none custom-color-footer-aside ms-1' href='/terms-of-service' target='_blank' > {t('container.aside_people.termsOfService')}</a></span>
                    </div>
                </footer>


            </div>

        </aside>
    );
};

export default AsidePeople;