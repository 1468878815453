//React
import React from "react"
import { useSelector } from "react-redux"

//App
import ButtonAddCollection from "./buttonAddCollection"
import CollectionLocked from "./collectionLocked"
import Collection from "./collection"
import AddCollectionModal from "./AddCollectionModal"
import { Loader, NoContent } from "../../Components"


const CollectionsContainer = ({ collections, follows, released, setListPostRelease, deleteCollection, isLoading, fee, subscribeToCreator, addClassName }) => {
    const users = useSelector(state => state.users);
    console.log(collections);
    const checkIfSubscribeToCreator = React.useCallback((collection) => {
        if (users.users?._id === collection?.creator?._id) {
            return true;
        } else {
            const myRelease = released?.filter(releasedPostId => releasedPostId === collection?._id);
            if (myRelease?.length > 0) {
                return true;
            } else if (follows?.length > 0) {
                const followId = follows?.filter(follow => follow.fan?._id === collection.creator?._id || follow.creator?._id === collection?.creator?._id);
                if (followId?.length > 0) {
                    return true;
                }
            }
            return false;
        }
    }, [follows, released, users.users]);


    const checkIfReleaseCoolection = React.useCallback((collection) => {
        if (users.users?._id === collection.creator?._id) {
            return true
        } else {
            const myRelease = released?.filter(post => post === collection._id);
            if (collection.is_free === true) {
                return true;
            }
            else if (myRelease?.length > 0) {
                return true
            }
            return false;
        }
    }, [released, users.users?._id])

    return (
        <>
            {isLoading ?
                <Loader />
                :
                <>
                    {collections.length === 0 ?
                        <NoContent />
                        :
                        <section className={`d-flex flex-wrap gap-2 pb-5 ms-1 ${addClassName}`}>
                            {window.location.pathname === '/profile' &&
                                <ButtonAddCollection />
                            }

                            {collections.map((collection, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        {!checkIfReleaseCoolection(collection.collection) || !checkIfSubscribeToCreator(collection.collection) ? (
                                            <CollectionLocked
                                                collection={collection.collection}
                                                checkIfSubscribeToCreator={checkIfSubscribeToCreator}
                                                setListPostRelease={setListPostRelease}
                                                fee={fee}
                                                collection_number={collection.post_number}
                                                subscribeToCreator={subscribeToCreator}
                                            />
                                        ) : (
                                            <Collection
                                                collection={collection.collection}
                                                deleteCollection={deleteCollection}
                                            />
                                        )}
                                    </React.Fragment>
                                )
                            })}



                        </section>
                    }
                </>
            }
            <AddCollectionModal />
        </>
    )
}

export default CollectionsContainer