//React
import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";


//App

import { EditPostModal, PostModal } from "../../Components";
import { RequestFanbusy } from "../../Services";

const Collection = ({ collection, deleteCollection }) => {
    const { t } = useTranslation();
    const users = useSelector(state => state.users)
    const dispatch = useDispatch()
    const [state, setState] = React.useState({
        content: collection.titre,
    })


    const handleEditPost = async () => {
        let form = {
            content: state.editContent
        }
        let res = await RequestFanbusy(`posts/${collection._id}`, 'PUT', form, users.access_token);
        if (res.status === 200) {
            //  updatePostContent(post._id, state.content)
            dispatch({ type: "REFRESH", value: 1 });
        } else {
            //  setShowReleaseError(true)
            setState({ ...state, releaseErrors: 'Error, please try again.' });
        }
    }

    const handleEditChange = (input) => {
        const target = input.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        if (name === 'postContent') {
            setState({ ...state, content: value })
        }


    }


    return (
        <>
            <NavLink to={`/collection/${collection._id}`} className="col-6 text-decoration-none" style={{ height: "200px", width: "180px" }}>
                <div className="card h-100" style={{ backgroundImage: `url(${collection?.banner[0]?.link})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                    <div className="card-body text-white" style={{ height: '200px' }}>
                        <h5 className="card-title">{collection.titre}</h5>
                        <p className="card-text">{collection.description}</p>
                    </div>
                    <div className="card-footer border-0" onClick={(event) => { event.preventDefault(); event.stopPropagation(); }}>
                        {users.users._id === collection.creator._id &&
                            <div className="dropup">
                                <a href={`/`} className={`text-white text-decoration-none material-icons ms-2 md-20 rounded-circle bg-lights`} id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">more_horiz</a>
                                <ul className="dropdown-menu fs-13 dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                                    <>
                                        <li>
                                            <button className="dropdown-item text-muted" data-bs-target={`#editpost${collection._id}`} data-bs-toggle='modal'>
                                                <span className="material-icons md-13 me-1">edit</span> {t('words.edit')}
                                            </button>
                                        </li>
                                        <li>
                                            <button className="dropdown-item text-muted" onClick={() => { deleteCollection(collection.id) }}>
                                                <span className="material-icons md-13 me-1">delete</span> {t('words.delete')}
                                            </button>
                                        </li>
                                        <li>
                                            <button className="dropdown-item text-muted" data-bs-target={`#newPostModal${collection.id}`} data-bs-toggle='modal'>
                                                <span className="material-icons md-13 me-1">publish</span> {t('words.publish')}
                                            </button>
                                        </li>
                                    </>

                                    {/* <li>
                                <a href={`/`} className="dropdown-item text-muted d-flex align-items-center" data-bs-toggle="modal" data-bs-target={`#shareModal`}>
                                    <span className="material-icons md-13 me-1">share</span> {t('words.share')}
                                </a>
                            </li> */}
                                </ul>
                            </div>}
                    </div>
                </div>
            </NavLink>

            <PostModal collectionId={collection.id} postType={'COLLECTION'} />
            <EditPostModal
                postId={collection._id}
                t={t}
                title={t('views.collection.editAcollection')}
                releaseErrors={state.msg}
                showReleaseError={state.showReleaseError}
                editContent={state.content}
                setShowReleaseError={() => { }}
                handleEditPost={handleEditPost}
                handleEditChange={handleEditChange}
            />
        </>
    )
}
export default Collection