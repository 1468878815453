import React from 'react'
import PaidForMe from '../../feature/pay4me/paidForMe'


const Pay4meView = () => {

    return (
        <>
            <PaidForMe />
        </>
    )
}
export default Pay4meView