import { useTranslation } from "react-i18next";

import Avatar from "../Avatar"
import { formatTimeToAmPm, renderFormatteContent } from "../../Utils"
import ModalImageView from "./ModalImageView"



const MessageUser = ({ msg, ismobile, receiver }) => {
    const { t } = useTranslation();
    return (
        <div className="d-flex mb-2 " onContextMenu={(e) => e.preventDefault()}>
            <a href={receiver.account_type === 'CREATOR' && `/${receiver.pseudo}`} className={`text-decoration-none ${ismobile && 'small'}`}>
                <Avatar user={receiver} width={ismobile ? 30 : 40} height={ismobile ? 30 : 40} fontSize={`${ismobile ? 'small' : 'fs-6'}`} />
            </a>
            <div className="ms-0 small">
                <div className=" px-3 py-1 rounded rounded-top rounded-right rounded-bottom-0 rounded-left mb-1 chat-text " style={{ backgroundColor: '#D0D5DA' }} >
                    {msg.file && msg.file.map((file, index) => (
                        <div key={index} className={`${index === file.length - 1 ? '' : 'mb-2'}`}>
                            <>
                                <ModalImageView msgId={file._id} fileLink={file.link} pseudo={receiver.pseudo} />
                                {file.mime_type === 'image' ? (
                                    <div data-bs-toggle="modal" data-bs-target={`#p_imageview${file._id}`} className={` bg-transparent position-relative`}>
                                        <img className='img-fluid img' src={file.link} alt="file-img" style={{ maxHeight: 400, maxWidth: 280 }} onContextMenu={(e) => e.preventDefault()} />
                                    </div>
                                ) : (
                                    <div className="position-relative">
                                        <video preload="auto" src={file.link} controls controlsList="nodownload" className="img-fluid" style={{ maxHeight: 400, maxWidth: 280 }} onContextMenu={(e) => e.preventDefault()}></video>
                                    </div>
                                )}

                            </>
                        </div>
                    ))
                    }

                    {msg.content.length > 0 &&
                        <span
                            className={` text-muted fs-6 fw-200 d-inline-block ${msg.content?.length > 20 ? 'costom-control ' : 'normal'}`} >
                            {renderFormatteContent(msg.content)}
                        </span>
                    }
                </div>

                <div className="align-items-center ms-2">
                    <span className="small text-muted">{formatTimeToAmPm(msg.created_at, t) !== "Invalid Date" && formatTimeToAmPm(msg.created_at, t)}</span>
                </div>

            </div>
        </div >

    )
}
export default MessageUser