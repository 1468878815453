import React from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { AppContainer, AsideContainer } from '../Containers'
import { useScreenSize } from '../Utils'

const RequestCreator = () => {
    const { t } = useTranslation()
    const darkMode = useSelector((state) => state.theme.darkMode);
    const screenSize = useScreenSize()
    const isMobile = screenSize === 'Extra Small' || screenSize === 'Small' || screenSize === 'Medium'

    return (
        <AppContainer>
            <main style={{ maxHeight: "100vh" }} className={`col col-xl-6 order-xl-2 col-lg-12 order-lg-1 col-md-12 col-sm-12 col-12 py-2 border border-top-0 border-fw-light px-0 border-bottom-0 mb-0 ${isMobile && 'pt-5 fixed-content'} `}>
                <div className="main-content pb-0 mb-0">
                    <div style={{ height: "50px" }} className="d-flex align-items-center m-none border-bottom pb-2 fix-sidebar bg-white top-0 ">
                        <a href={`/`} className={`${darkMode ? 'text-white' : 'text-dark'} material-icons text-decoration-none m-none ms-3`}>arrow_back</a>
                        <p className={`${darkMode ? 'text-white' : 'text-body'} ms-2 mb-0 fw-bold fs-6 text-uppercase`}>
                            {t('navigation.become')}
                        </p>
                    </div>
                    <div className="pt-5 feeds d-flex  justify-content-center  align-items-center  ">
                        <span className="material-icons me-1" style={{ fontSize: '90px', color: '#AFB0B4' }}>cleaning_services</span>
                    </div>
                    <div className='d-flex justify-content-center align-items-center'>
                        <h4 className="fw-bold">{t('navigation.pendingRequest')} </h4>
                    </div>
                </div>
            </main>
            <AsideContainer />
        </AppContainer>
    )
}

export default RequestCreator

